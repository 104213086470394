import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoLogData, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicket } from '../getTicket'
import { getTicketSession } from '../getTicketSession'
import {
  validateTicketDiagnosticDataFor3SSearch,
  validateTicketDiagnosticDataForConversation,
} from '../validators/validateTicketDiagnosticData'
import { validateTicketKustoAIF, validateTicketKustoExpiration } from '../validators/validateTicketKustoData'
import { validateTicketUserConsent } from '../validators/validateTicketUserConsent'
import { fetchTicketKustoData } from './fetch/fetchTicketKustoData'

export function getTicketKustoData<T extends Exclude<KustoLogTable, KustoLogTable.BizChat3SLatencyLog>>(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
  table: T,
): PromiseSnapshot<IKustoLogData> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.kusto?.[table]
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      const kusto = (turn.kusto ??= {})
      kusto[table] = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<IKustoLogData> {
    const session = await getTicketSession(context, ticketId).promise

    // 1. Check User consent
    validateTicketUserConsent(ticketId, session)

    // 2. Check 3s search
    if (table === KustoLogTable.GwsLog) {
      // 2.1 Check ticket diagnostic data
      validateTicketDiagnosticDataFor3SSearch(ticketId, messageId, session)

      // 2.2 Check ticket Kusto AIF
      const ticket = await getTicket(context, ticketId).promise
      validateTicketKustoAIF(ticketId, table, ticket)
    }

    // Avalon doesn't have EventLog
    if (table === KustoLogTable.EventLog) {
      const ticket = await getTicket(context, ticketId).promise
      if (ticket.isAvalon) {
        throw TicketError.create('NoKustoEventLogDueToAvalon', { ticketId, kustoLogTable: table })
      }
    }

    // 3. Check interaction
    const interaction = session.interactions.find((item) => item.messageId === messageId)
    if (!interaction) {
      // 3.1. Check ticket Kusto expiration
      validateTicketKustoExpiration(ticketId, table, session)

      // 3.2. Throw error
      throw TicketError.create('NoKustoDueToInteractionFieldIsMissing', { ticketId, kustoLogTable: table })
    }

    const result = await fetchTicketKustoData(context, {
      table,
      interactionTime: interaction.interactionTime ?? '',
      messageId,
    })
    if (result[table].length > 0) {
      return result
    }

    // 5. Check ticket Kusto expiration
    validateTicketKustoExpiration(ticketId, table, session)

    // 6. Check ticket conversation diagnostic data
    // TODO: Some tables may not require conversation data
    validateTicketDiagnosticDataForConversation(ticketId, messageId, session)

    // 6. Unknown error
    throw TicketError.create('NoKusto', { ticketId, kustoLogTable: table })
  }
}
