import { useFeatureFlight } from '@copilot-dash/settings'
import { makeStyles } from '@fluentui/react-components'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { TicketScreenOutlineTab } from '../../store/IState'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketScreenOverflowTab } from '../common/TicketScreenOverflowTab'
import { TicketScreenOverflowTabs } from '../common/TicketScreenOverflowTabs'
import { z } from 'zod'

export function TicketOutlineBar() {
  const styles = useStyles()
  const actions = TicketScreenStore.useActions()
  const supportedOutlineTabs = TicketScreenStore.use((state) => state.supportedOutlineTabs)
  const selectedOutlineTab = TicketScreenStore.use((state) => state.selectedOutlineTab)
  const [isTicketCallFlowEnabled] = useFeatureFlight('EnableTicketCallFlow')

  const targetTabs = useMemo(() => {
    return compact(
      supportedOutlineTabs.map((tab): TicketScreenOverflowTab | undefined => {
        if (!isTicketCallFlowEnabled && tab === TicketScreenOutlineTab.CallFlow) {
          return undefined
        }

        return {
          id: tab,
          name: TicketScreenOutlineTabNames[tab].name,
          disabled: false,
        }
      }),
    )
  }, [isTicketCallFlowEnabled, supportedOutlineTabs])

  return (
    <TicketScreenOverflowTabs
      className={styles.root}
      tabs={targetTabs}
      selectedId={selectedOutlineTab}
      onSelect={(tabId: string) => {
        const tab = z.nativeEnum(TicketScreenOutlineTab).safeParse(tabId).data
        if (tab) {
          actions.selectOutlineTab(tab)
        }
      }}
    />
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0 4px',
  },
})

const TicketScreenOutlineTabNames: {
  readonly [key in TicketScreenOutlineTab]: { readonly name: string }
} = {
  [TicketScreenOutlineTab.Conversation]: {
    name: 'User Conversation',
  },
  [TicketScreenOutlineTab.CallFlow]: {
    name: 'Call Flow',
  },
}
