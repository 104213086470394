import { Node } from '@xyflow/react'
import { mapValues, sortBy } from 'lodash'
import { CallFlowNode, CallFlowNodeType } from '../store/IState'

interface IParams {
  readonly nodes: Node<CallFlowNode>[]
  readonly columnCount: number
}

export function layoutNodesColumn({ nodes, columnCount }: IParams): Record<CallFlowNodeType, number> {
  // 1. Count types
  const acc: Record<CallFlowNodeType, number> = mapValues(CallFlowNodeType, () => 0)
  const typeCounts = nodes.reduce((acc, node) => {
    const type = node.data.type
    acc[type] = acc[type] + 1
    return acc
  }, acc)

  // 2. Sort types by count
  const types = Object.values(CallFlowNodeType)
  const sortedTypes = sortBy(types, (type) => typeCounts[type]).reverse()

  // 3. Initialize columns
  const columns: number[] = Array.from({ length: columnCount }, () => 0)
  const result: Record<CallFlowNodeType, number> = mapValues(CallFlowNodeType, () => 0)

  // 4. Special case for the first node. It should always be the middle column
  // const firstNodeType = nodes[0]?.data.type
  // if (firstNodeType) {
  //   const columnIndex = Math.floor(columnCount / 2)
  //   columns[columnIndex]! += typeCounts[firstNodeType]
  //   result[firstNodeType] = columnIndex
  // }

  // 5. Greedy distribution
  for (const type of sortedTypes) {
    const columnIndex = columns.indexOf(Math.min(...columns))
    columns[columnIndex]! += typeCounts[type]
    result[type] = columnIndex
  }

  return result
}
