import { BotRegular } from '@fluentui/react-icons'
import { Row } from '../../Layout'
import { Text, Tooltip } from '@fluentui/react-components'
import { useStyles } from './ActivityTitle.styles'

export const ActivityTitleAvatarForSystem = () => {
  const styles = useStyles()

  return (
    <Tooltip content="Copilot Dash Service" relationship={'label'}>
      <Row vAlign="center">
        <BotRegular fontSize={24} className={styles.bot} />
        <Text className={styles.text}>Copilot Dash Service</Text>
      </Row>
    </Tooltip>
  )
}
