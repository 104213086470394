import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useStyles as useOuterStyles } from '../layout/TicketSummaryPanelLayout.styles'
import { useGlobalStore } from '../../../store'
import { useTicketSummaryPanelActions, useTicketSummaryPanelSelector } from '../store'
import { useShallow } from 'zustand/react/shallow'
import {
  COPILOT_DASH_STATE_OPTIONS,
  ElementType,
  mapADOStateToCopilotDashState,
  mapCopilotDashStateToADOReason,
  mapCopilotDashStateToADOState,
  mapPostTicketInfoToTicketActionFormValue,
} from './utils'
import { INewTicketData, IUpdateTicketStatusInfoData } from '@copilot-dash/domain'
import { ITicketActionFormProps, ITicketActionFormRef, TicketActionForm } from '../../TicketActionForm/TicketActionForm'
import { EMPTY_ROOT_CAUSE, getRootCauseSelectOptionIds, getTicketStepStageByFields } from '../../TicketActionForm/utils'
import { Column, Spacer } from '../../Layout'
import { TicketStatusStep } from '../../TicketActionForm/components/TicketStatusStep'

interface ITicketSummaryProps {
  onValidationChange?: (isValid: boolean) => void
  onModified?: (data: ITicketActionFormProps['defaultValues']) => void
  productId?: number
}

export interface ITicketSummaryRef {
  readonly setSummaryDraft: (
    produce: (pre: Partial<IUpdateTicketStatusInfoData>) => Partial<IUpdateTicketStatusInfoData>,
  ) => void
}

export function mapTicketInfoToTicketActionFormValue(
  ticketInfo: Pick<
    INewTicketData,
    'status' | 'reasoning' | 'closedComment' | 'priority' | 'teamArea' | 'rootCauseList' | 'assignTo' | 'customTags'
  >,
  ticketCustomTags?: string[],
) {
  return {
    state: mapADOStateToCopilotDashState(ticketInfo.status, ticketInfo.reasoning),
    noActionableReason: ticketInfo.closedComment || 'N/A',
    priority: ticketInfo.priority,
    area: ticketInfo.teamArea ? ticketInfo.teamArea : '',
    rootCauseIDs: getRootCauseSelectOptionIds(ticketInfo.rootCauseList),
    assignTo: ticketInfo.assignTo,
    customTags: ticketCustomTags,
  }
}

export const TicketSummary = memo(
  forwardRef<ITicketSummaryRef, ITicketSummaryProps>(({ onValidationChange, onModified, productId }, ref) => {
    const formRef = useRef<ITicketActionFormRef | null>(null)
    const actions = useTicketSummaryPanelActions()
    const outerStyles = useOuterStyles()
    const ticketInfo = useTicketSummaryPanelSelector((state) => state.ticketInfo)
    const customTagsSnapshot = useTicketSummaryPanelSelector((state) => state.customTagsSnapshot)
    const isModifiedRef = useRef(false)

    // NOTE: @Ethan - this value is used for the TicketStatusStep component to determine the current step.
    const [formValuesCopy, setFormValuesCopy] = useState<{
      state?: ElementType<typeof COPILOT_DASH_STATE_OPTIONS>
      priority?: number
      area?: string
      rootCauseIDs?: string[]
    }>({})

    const basePostTicketInfo = useTicketSummaryPanelSelector(
      useShallow((state) => ({
        workItemId: state.ticketInfo?.workItemId,
        ticketId: state.ticketInfo?.ticketId,
      })),
    )

    useEffect(() => {
      if (ticketInfo) {
        const data = mapTicketInfoToTicketActionFormValue(ticketInfo, ticketInfo.customTags)
        formRef.current?.setValues(data)
        setFormValuesCopy(data)
      }
    }, [ticketInfo])

    useEffect(() => {
      if (ticketInfo && formRef.current) {
        formRef.current.watch(async (values) => {
          const pass = await formRef.current?.validate()
          typeof pass === 'boolean' && onValidationChange?.(pass)
          if (!values) return
          setFormValuesCopy(values)
          if (!values.area) return
          if (!values.state) return

          // const rootCauseList = useGlobalStore.getState().getRootCauseListByAreaPath(values.area)
          const allTeamsRootCauseList = useGlobalStore.getState().computed.allTeamsRootCauseList
          const postData = {
            ...basePostTicketInfo,
            status: mapCopilotDashStateToADOState(values.state, values.area),
            priority: values.priority,
            areaPath: values.area,
            issueList: values.rootCauseIDs
              ? values.rootCauseIDs
                  .filter(Boolean)
                  .map((issueId) => allTeamsRootCauseList.find((item) => item.issueId === issueId)!)
                  .filter(Boolean)
              : [],
            reasoning: mapCopilotDashStateToADOReason(values.state),
            closedComment: values.noActionableReason ? values.noActionableReason : '',
            assignTo: values.assignTo,
            customTags: values.customTags,
          }
          actions.setSummaryDraft(postData, isModifiedRef.current)
        })
      }
      return () => {}
    }, [actions, basePostTicketInfo, onValidationChange, ticketInfo, customTagsSnapshot.status])

    useImperativeHandle(
      ref,
      () => ({
        setSummaryDraft: (produce) => {
          const values = formRef.current?.getValues()
          if (!values) return
          if (!values.area) return
          if (!values.state) return
          const postData = produce({
            ...basePostTicketInfo,
            status: mapCopilotDashStateToADOState(values.state, values.area),
            priority: values.priority,
            areaPath: values.area,
            issueList: values['rootCauseList'],
            reasoning: mapCopilotDashStateToADOReason(values.state),
            closedComment: values.noActionableReason,
            assignTo: values.assignTo,
            customTags: values.customTags,
          })
          formRef.current?.setValues(mapPostTicketInfoToTicketActionFormValue(postData))
        },
      }),
      [basePostTicketInfo],
    )

    if (!ticketInfo) return null

    return (
      <Column>
        <Spacer height={16} />
        <TicketStatusStep
          withoutRootCause={
            (formValuesCopy.state === 'Closed - By Design' || formValuesCopy.state === 'Closed - Not Actionable') &&
            !(formValuesCopy.rootCauseIDs && formValuesCopy.rootCauseIDs.filter((v) => v !== EMPTY_ROOT_CAUSE).length)
          }
          currentStep={getTicketStepStageByFields(
            formValuesCopy.state,
            formValuesCopy.rootCauseIDs?.filter((v) => v !== EMPTY_ROOT_CAUSE),
            formValuesCopy.area,
          )}
        />
        <Spacer height={16} />
        {customTagsSnapshot.status === 'done' && (
          <Column className={outerStyles.summary}>
            <TicketActionForm
              ref={formRef}
              defaultValues={mapTicketInfoToTicketActionFormValue(ticketInfo, ticketInfo.customTags)}
              ticketInfo={{
                teamName: ticketInfo.teamArea,
                vsoAccount: ticketInfo.vsoAccount,
              }}
              onModified={(modify, data) => {
                isModifiedRef.current = modify
                if (data) {
                  onModified?.(data)
                }
              }}
              productId={productId}
            />
          </Column>
        )}
      </Column>
    )
  }),
)

TicketSummary.displayName = 'TicketSummary'
