import { ApiOdsTicket3sOfflineData } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../IDashStoreContext'
import { getTicketFiles } from './getTicketFiles'
import { getTicketSession } from './getTicketSession'
import { validateTicket3sOfflineDataTimeliness } from './validators/validateTicket3sOfflineData'
import { validateOfflineDiagnosticCode } from './validators/validateTicketDiagnosticData'

export function getRaw3sOfflineData(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ApiOdsTicket3sOfflineData> {
  return context.getOrFetch<ApiOdsTicket3sOfflineData>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.raw3sOfflineData
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.raw3sOfflineData = snapshot
    },
    fetch: async () => {
      // Validate user consent
      const session = await getTicketSession(context, ticketId).promise
      if (!session.hasUserConsent) {
        throw TicketError.create('NoUserConsentGiven', { ticketId })
      }

      // Validate diagnostic data
      validateOfflineDiagnosticCode(ticketId, turnId, session)

      // Get turn index
      const interaction = session.interactions.find((item) => item.messageId === turnId)
      const index = interaction?.index
      if (index === undefined) {
        throw TicketError.create('No3sOffline', { ticketId, ticketMessageId: turnId })
      }

      // Get turn file
      const files = await getTicketFiles(context, ticketId).promise
      const file = files.sssOffline.find((file) => file.index === index && file.group === 'Legacy')
      if (!file) {
        // Validate offline data timeliness
        validateTicket3sOfflineDataTimeliness(ticketId, session.feedbackTime)

        throw TicketError.create('No3sOffline', { ticketId, ticketMessageId: turnId })
      }

      return await context.api.ods.get3sOfflineData(file.fileUrl)
    },
  })
}
