import { ISearchTicketOptions } from '@copilot-dash/store'

export const SUPPORTED_TAGS: {
  key: keyof ISearchTicketOptions
  tagName: string
}[] = [
  {
    key: 'client',
    tagName: 'Endpoints',
  },
  {
    key: 'customerTypes',
    tagName: 'Customer type',
  },
  {
    key: 'ring',
    tagName: 'Ring',
  },
  {
    key: 'thumbs',
    tagName: 'Emotion type',
  },
  {
    key: 'hasVerbatim',
    tagName: 'Has user verbatim',
  },
  {
    key: 'hasUserConsent',
    tagName: 'Has user consent',
  },
  {
    key: 'priority',
    tagName: 'Priority',
  },
  {
    key: 'botEndPoint',
    tagName: 'Bot end point',
  },
  {
    key: 'promptLanguages',
    tagName: 'Prompt language',
  },
  {
    key: 'groundedPrompts',
    tagName: 'Grounded prompts',
  },
  {
    key: 'isApology',
    tagName: 'Is apology',
  },
  {
    key: 'hasCitation',
    tagName: 'Has citation',
  },
  {
    key: 'hitAvalon',
    tagName: 'Hit avalon',
  },
  {
    key: 'triggeredSkill',
    tagName: 'Triggered skill',
  },
  {
    key: 'has3SLog',
    tagName: 'Has 3s log',
  },
  {
    key: 'hasConversationHistory',
    tagName: 'Has conversation history',
  },
  {
    key: 'hasEntityCard',
    tagName: 'Has entity representation',
  },
  {
    key: 'hasScreenshot',
    tagName: 'Has screenshot',
  },
  {
    key: 'invocationType',
    tagName: 'Invocation type',
  },
  {
    key: 'isWebToggleOn',
    tagName: 'Is web toggle on',
  },
  {
    key: 'searchResultSegment',
    tagName: 'Search result segment',
  },
  {
    key: 'isTest',
    tagName: 'Is test',
  },
  {
    key: 'isSTCAChina',
    tagName: 'Is STCA China',
  },
  {
    key: 'isTopi18N',
    tagName: 'Is Top i18N Tenants',
  },
  {
    key: 'responseHeroType',
    tagName: 'Response hero type',
  },
  {
    key: 'responseLinkType',
    tagName: 'Response link type',
  },
  {
    key: 'semanticSearchType',
    tagName: 'Semantic search type',
  },
  {
    key: 'scenarioGroup',
    tagName: 'Scenario group',
  },
  {
    key: 'bizchatScenario',
    tagName: 'BizChat scenario',
  },
  {
    key: 'experienceType',
    tagName: 'Experience type',
  },
  {
    key: 'hasGPTExtension',
    tagName: 'Has GPT-Extension keywords',
  },
  {
    key: 'hasMessageExtension',
    tagName: 'Has MessageExtension keywords',
  },
  {
    key: 'hasConnector',
    tagName: 'Has Connector keywords',
  },
  {
    key: 'hasCopilotExtensionIds',
    tagName: 'Has CopilotExtensionIds',
  },
  {
    key: 'optionsSets',
    tagName: 'Options Sets',
  },
  { key: 'errorCode', tagName: 'Error code' },
  { key: 'channel', tagName: 'Feedback Entry' },
  { key: 'isGCIntent', tagName: 'Is GC Intent' },
  { key: 'hasConnectorResult', tagName: 'Has Connector Result' },
  { key: 'dSATStatus', tagName: 'State' },
  { key: 'customTags', tagName: 'Tags' },
  { key: 'sliceIds', tagName: 'Slice Ids' },
  { key: 'copilotExtensionIds', tagName: 'CopilotExtensionIds' },
  { key: 'dSATAssignedTo', tagName: 'Assigned To' },
]
