import { uuid } from '@copilot-dash/core'
import { ICommentRequest } from '@copilot-dash/domain'
import { Button, Drawer, DrawerBody, DrawerFooter, DrawerHeader, Spinner, Text } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'
import { useEffect, useRef, useState } from 'react'
import { useToast } from '../../../hooks/useToast'
import { TicketScreenStore } from '../../../screens/ticket/TicketScreenStore'
import { ErrorView } from '../../Error'
import { getErrorMessage } from '../../ErrorConfigs/utils/getErrorMessage'
import { getErrorMessageDetails } from '../../ErrorConfigs/utils/getErrorMessageDetails'
import { Row, Spacer } from '../../Layout'
import { TicketDiscussionPanel } from '../TicketDiscussionPanel'
import { CommentTextArea } from '../children/CommentTextArea'
import { DiscussionPanelBody } from './DiscussionPanelBody'
import { useStyles } from './DiscussionPanelDrawer.styles'

export function DiscussionPanelDrawer() {
  const styles = useStyles()
  const containerRef = useRef<HTMLDivElement>(null)
  const [sentDone, setSentDone] = useState(true)
  const [commentValue, setCommentValue] = useState<string | undefined>('')
  const ticketId = TicketDiscussionPanel.use((state) => state.ticketId)
  const actions = TicketDiscussionPanel.useActions()
  const commentsSnapshot = TicketDiscussionPanel.use((state) => state.commentsSnapshot)
  const postCommentSnapshot = TicketDiscussionPanel.use((state) => state.postCommentSnapshot)
  const deleteCommentSnapshot = TicketDiscussionPanel.use((state) => state.deleteCommentSnapshot)
  const isOpenDiscussionPanel = TicketScreenStore.use((state) => state.isOpenDiscussionPanel)
  const ticketScreenActions = TicketScreenStore.useActions()
  const toast = useToast()
  const onSendComment = () => {
    if (commentValue?.trim() === '') {
      toast.showWarning('Please type a comment to continue!')
    } else {
      setSentDone(false)
      if (commentValue) {
        const param: ICommentRequest = {
          commentId: uuid(),
          content: commentValue,
        }
        actions.postComment(ticketId, param)
      }
    }
  }

  useEffect(() => {
    switch (postCommentSnapshot.status) {
      case 'none':
        break
      case 'waiting':
        setSentDone(false)
        break
      case 'done':
        Logger.telemetry.trackEvent('TicketDetail/CreateComment', {
          ticketId: ticketId,
          status: 'Succeed',
        })
        setSentDone(true)
        setCommentValue('')
        toast.showSuccess('Sent a comment successfully!')
        break
      case 'error':
        toast.showError(
          'Failed to send!',
          getErrorMessageDetails(postCommentSnapshot.error) ?? getErrorMessage(postCommentSnapshot.error),
        )
        Logger.telemetry.trackEvent('TicketDetail/CreateComment', {
          ticketId: ticketId,
          status: 'Failed',
          errorMessage: String(postCommentSnapshot.error),
        })
        setSentDone(true)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCommentSnapshot.status])

  useEffect(() => {
    switch (deleteCommentSnapshot.status) {
      case 'none':
        break
      case 'waiting':
        break
      case 'done':
        toast.showSuccess('Delete a comment successfully!')
        break
      case 'error':
        toast.showError(
          'Failed to delete!',
          getErrorMessageDetails(deleteCommentSnapshot.error) ?? getErrorMessage(deleteCommentSnapshot.error),
        )
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCommentSnapshot.status])

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsSnapshot.data?.slice(-1)[0]?.commentId, isOpenDiscussionPanel])

  return (
    <Drawer className={styles.card} separator open={isOpenDiscussionPanel} modalType="non-modal" position="end">
      <DrawerHeader className={styles.drawerHeader}>
        <Row vAlign="center" fill>
          <Button
            appearance="subtle"
            aria-label="Close"
            icon={<DismissRegular />}
            onClick={ticketScreenActions.handleDiscussionPanelButtonClick}
          />
          <Text className={styles.title}>Discussion</Text>
          <Spacer />
        </Row>
      </DrawerHeader>

      <DrawerBody ref={containerRef} className={styles.drawerBody}>
        {(() => {
          switch (commentsSnapshot.status) {
            case 'waiting':
              return (
                <Row className={styles.waiting} fill vAlign="center" hAlign="center">
                  <Spinner label="Loading..." />
                </Row>
              )
            case 'error':
              return <ErrorView error={commentsSnapshot.error} />
            case 'done':
              return <DiscussionPanelBody />
            default:
              return null
          }
        })()}
      </DrawerBody>

      <DrawerFooter className={styles.footer}>
        <CommentTextArea
          onSendComment={onSendComment}
          onCommentValueChange={setCommentValue}
          commentValue={commentValue}
          sentDone={sentDone}
        />
      </DrawerFooter>
    </Drawer>
  )
}
