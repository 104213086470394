import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  button: {
    ...shorthands.borderRadius('50%'),
    ...shorthands.padding('15px', 0),
    backgroundColor: tokens.colorNeutralBackground1,
    boxShadow: tokens.shadow4,
    '&:hover': {
      backgroundColor: tokens.colorBrandBackground2Hover,
    },
    position: 'fixed',
    right: '20px',
    bottom: '130px',
  },
  icon: {
    color: tokens.colorNeutralForeground1,
    ...shorthands.margin(0, '4px', 0, 0),
  },
})
