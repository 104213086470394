import { Fragment } from 'react'
import { Button, makeStyles, shorthands, tokens, mergeClasses, Body1Strong } from '@fluentui/react-components'
import { Wrench16Regular } from '@fluentui/react-icons'
import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'

interface IProps {
  placeholder?: string
  isColumnSettingPanelOpen?: boolean
  onClickButton?: () => void
}

export const ColumnSettingButton: React.FC<IProps> = ({ ...props }) => {
  const styles = useStyles()

  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <Fragment>
          <Body1Strong> Column Settings </Body1Strong>
        </Fragment>
      }
      toolTipParentContent={
        <Button
          className={mergeClasses(
            styles.columnSetting,
            props.isColumnSettingPanelOpen ? styles.activeColumnSettingBtn : '',
          )}
          icon={<Wrench16Regular />}
          onClick={props.onClickButton}
          aria-label="Close Panel"
        >
          {props.placeholder}
        </Button>
      }
    />
  )
}

const useStyles = makeStyles({
  columnSetting: {
    '&:hover': {
      ...shorthands.border('1px', 'solid', tokens.colorBrandBackground),
      backgroundColor: tokens.colorNeutralBackground5Pressed,
    },
  },
  activeColumnSettingBtn: {
    ...shorthands.border('1px', 'solid', tokens.colorBrandBackground),
    backgroundColor: tokens.colorNeutralBackground5Pressed,
  },
})
