import { AxiosError, AxiosResponse } from 'axios'
import { ZodError } from 'zod'
import { ApiRequestConfig } from '../client/ApiClient'
import { ApiClientConfig } from '../client/ApiClientConfig'
import { ApiErrorData } from '../client/ApiErrorData'
import { get } from 'lodash'

export class ApiErrorDataUtils {
  static onRequestError(client: ApiClientConfig, request: ApiRequestConfig, error: unknown): ApiErrorData {
    if (error instanceof AxiosError) {
      return this.onAxiosError(client, request, error)
    }

    return this.onUnknownError(client, request, undefined, error)
  }

  static onResponseError(
    client: ApiClientConfig,
    request: ApiRequestConfig<unknown>,
    response: AxiosResponse,
    error: unknown,
  ): ApiErrorData {
    if (error instanceof ZodError) {
      return this.onZodError(client, request, response, error)
    }

    return this.onUnknownError(client, request, response, error)
  }

  private static onAxiosError(client: ApiClientConfig, request: ApiRequestConfig, error: AxiosError): ApiErrorData {
    let xmlRequest: XMLHttpRequest | undefined
    if (error.request instanceof XMLHttpRequest) {
      xmlRequest = error.request
    }

    return {
      endpoint: client.endpoint,
      baseUrl: request.baseURL || error.config?.baseURL || client.config.baseURL || '',
      url: xmlRequest?.responseURL || request.url || '',
      method: request.method || 'GET',
      statusCode: error.response?.status,
      statusMessage: error.response?.statusText,
      errorCode: error.code,
      errorMessages: error.message,
      errorResponse: error.response?.data,
      details: {
        type: 'axios',
        cause: error,
      },
    }
  }

  private static onZodError(
    client: ApiClientConfig,
    request: ApiRequestConfig<unknown>,
    response: AxiosResponse,
    error: ZodError,
  ): ApiErrorData {
    const responseURL = get(response.request, 'responseURL')

    return {
      endpoint: client.endpoint,
      baseUrl: response.config.baseURL || request.baseURL || client.config.baseURL || '',
      url: responseURL || request.url || '',
      method: request.method || 'GET',
      statusCode: response.status,
      statusMessage: response.statusText,
      errorCode: undefined,
      errorMessages: undefined,
      errorResponse: null,
      details: {
        type: 'zod',
        cause: error,
      },
    }
  }

  private static onUnknownError(
    client: ApiClientConfig,
    request: ApiRequestConfig,
    response: AxiosResponse | undefined,
    error: unknown,
  ): ApiErrorData {
    return {
      endpoint: client.endpoint,
      baseUrl: request.baseURL || client.config.baseURL || '',
      url: request.url || '',
      method: request.method || 'GET',
      statusCode: response?.status,
      statusMessage: response?.statusText,
      errorCode: undefined,
      errorMessages: undefined,
      errorResponse: null,
      details: {
        type: 'unknown',
        cause: error,
      },
    }
  }
}
