import { FC, memo, useEffect } from 'react'
import { Column, Spacer } from '../../../../components/Layout'
import { TopIssue } from './children/topIssue'
import { useStyles } from './FeedbackInsights.styles'
import { VIPUserDSATs } from './children/vipUserDSATs'
import { ErrorViewBoundary } from '../../../../components/Error'
import { TelemetryScope } from '@copilot-dash/logger'
import { useSearchScreenActions } from '../../store'

export const FeedbackInsights: FC = memo(() => {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  useEffect(() => {
    actions.onDismissTicketSummaryPanel()
  }, [actions])
  return (
    <ErrorViewBoundary label="FeedbackInsights">
      <TelemetryScope scope="FeedbackInsights">
        <Column className={styles.container}>
          <TopIssue />
          <Spacer height={34} />
          <VIPUserDSATs />
        </Column>
      </TelemetryScope>
    </ErrorViewBoundary>
  )
})

FeedbackInsights.displayName = 'FeedbackInsights'
