import { IEditCommentRequest } from '@copilot-dash/domain'
import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Text } from '@fluentui/react-components'
import { Delete16Filled, Edit16Filled, MoreHorizontalRegular } from '@fluentui/react-icons'
import { useEffect, useState } from 'react'
import { useToast } from '../../../hooks/useToast'
import { getErrorMessage } from '../../ErrorConfigs/utils/getErrorMessage'
import { getErrorMessageDetails } from '../../ErrorConfigs/utils/getErrorMessageDetails'
import { Column, Row } from '../../Layout'
import { UserPhoto } from '../../User/UserPhoto'
import { TicketDiscussionPanel } from '../TicketDiscussionPanel'
import { CommentContent } from './CommentContent'
import { CommentTextArea } from './CommentTextArea'
import { useStyles } from './UserMessage.styles'
import { MessageHeader } from './MessageHeader'

interface IProps {
  userId: string
  ticketId: string
  commentId: string
  createTime: string
  message: string
  modifyTime?: string
}

export function UserMessage({ userId, ticketId, commentId, createTime, message, modifyTime }: IProps) {
  const styles = useStyles()
  const activeAccount = app.auth.activeAccount
  const actions = TicketDiscussionPanel.useActions()
  const user = application.store.use.getUserProfile(userId).data
  const [openEdit, setOpenEdit] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [commentValue, setCommentValue] = useState<string | undefined>(message)
  const [sentDone, setSentDone] = useState(true)
  const editCommentSnapshot = TicketDiscussionPanel.use((state) => state.editCommentSnapshot)
  const toast = useToast()
  const onSubmitEditComment = () => {
    if (commentValue?.trim() === '') {
      toast.showWarning('Please type a comment to continue!')
    } else {
      setSentDone(false)
      if (commentValue) {
        const param: IEditCommentRequest = {
          content: commentValue,
        }
        actions.editComment(ticketId, commentId, param)
      }
    }
  }

  const onDeleteComment = () => {
    if (ticketId && commentId) {
      actions.deleteComment(ticketId, commentId)
    }
  }

  useEffect(() => {
    switch (editCommentSnapshot.status) {
      case 'none':
        break
      case 'waiting':
        break
      case 'done':
        if (editCommentSnapshot.data.commentId === commentId) {
          toast.showSuccess('Edit a comment successfully!')
          setSentDone(true)
          setOpenEdit(false)
        }
        actions.resetEditCommentSnapshot()
        break
      case 'error':
        toast.showError(
          'Failed to edit!',
          getErrorMessageDetails(editCommentSnapshot.error) ?? getErrorMessage(editCommentSnapshot.error),
        )
        setSentDone(true)
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCommentSnapshot.status])

  return (
    <Row>
      <UserPhoto userId={userId} />
      <Column
        className={styles.messageContainer}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Row>
          <MessageHeader createTime={createTime} userId={userId} />
          <Column>
            {isHovered && (
              <Row className={styles.messageToolBar}>
                <Menu positioning={'before-bottom'}>
                  <MenuTrigger>
                    {activeAccount?.localAccountId === userId && user?.displayName && sentDone && !openEdit ? (
                      <Button icon={<MoreHorizontalRegular />} appearance="transparent" />
                    ) : null}
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      <MenuItem
                        icon={<Edit16Filled />}
                        onClick={() => {
                          setOpenEdit(true)
                        }}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        icon={<Delete16Filled />}
                        className={styles.deleteButton}
                        onClick={() => onDeleteComment()}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              </Row>
            )}
          </Column>
          {modifyTime && (
            <Row className={styles.editedContainer}>
              <Text className={styles.editedTag}>Edited</Text>
            </Row>
          )}
        </Row>
        {!openEdit ? (
          <CommentContent rawComment={message} />
        ) : (
          <Row className={styles.textareaContainer}>
            <CommentTextArea
              onSendComment={onSubmitEditComment}
              onCommentValueChange={setCommentValue}
              commentValue={commentValue}
              sentDone={sentDone}
              isEdit={true}
              setOpenEdit={() => setOpenEdit(false)}
            />
          </Row>
        )}
      </Column>
    </Row>
  )
}
