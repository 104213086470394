import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Row } from '../../../Layout'
import { CallFlowNodeType } from '../../store/IState'
import { TicketCallFlowBarLegendItem } from './TicketCallFlowBarLegendItem'

export function TicketCallFlowBar() {
  const styles = useStyles()

  return (
    <Row wrap gap="gap.small" className={styles.root}>
      {Object.values(CallFlowNodeType).map((item) => {
        return <TicketCallFlowBarLegendItem key={item} type={item} />
      })}
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '8px 16px',
    ...shorthands.borderBottom('1px', 'solid', tokens.colorNeutralStroke2),

    // position: 'sticky',
    // top: 0,
    // backgroundColor: tokens.colorNeutralBackgroundAlpha,
    // zIndex: 1,
    // backdropFilter: 'blur(10px)',
  },
})
