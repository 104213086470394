import { useAsyncLoader } from '@copilot-dash/core'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CopilotDashSearchableDropdown } from '../Filter/CopilotDashSearchableDropdown'
import { IFilterOption } from '../Filter/IFilterOption.types'

interface TenantFilterProps {
  placeholder?: string
  onChangeTenant: (selectedOptions: string[] | undefined) => void
  value: string[] | undefined
  className?: string
}

export const TenantFilter: React.FC<TenantFilterProps> = ({ ...props }) => {
  const [tenantKeyword, setTenantKeyword] = useState<string | undefined>(props.value?.[0])

  const handleTenantChange = (selectedOptions: string[] | undefined) => {
    if (!selectedOptions || selectedOptions.length === 0) {
      setTenantKeyword(undefined)
    }
    props.onChangeTenant(selectedOptions)
    Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FromTenant', value: selectedOptions })
  }

  const [submit, snapshot] = useAsyncLoader(
    useCallback(async (keyword: string | undefined): Promise<IFilterOption[]> => {
      const optionList: IFilterOption[] = []
      const tenants = await application.store.actions.searchM365Tenants(keyword)
      tenants.map((tenant) => {
        optionList.push({
          key: tenant.tenantId.toString(),
          text: tenant.tenantName.toString(),
        })
      })
      return optionList
    }, []),
  )

  const debouncedSubmit = useMemo(() => debounce(submit, 300), [submit])

  useEffect(() => {
    debouncedSubmit(tenantKeyword)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantKeyword])

  return (
    <CopilotDashSearchableDropdown
      comboId={'tenantFilter'}
      onChangeFilter={handleTenantChange}
      defaultSelectedOption={props.value}
      setkeyword={setTenantKeyword}
      keyword={tenantKeyword}
      className={props.className}
      snapshot={snapshot}
      customSearchOptionHint={'tenant ID'}
      supportCustomSearchOption={true}
    />
  )
}
