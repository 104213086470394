import { z } from 'zod'

export interface ApiOds3sOnlineDataGroup3 {
  readonly ReplayResponse: ApiOds3sOnlineDataGroup3ReplayResponse
  readonly [key: string]: unknown
}

export interface ApiOds3sOnlineDataGroup3ReplayResponse {
  readonly TraceId: string
  readonly [key: string]: unknown
}

export const apiOds3sOnlineDataGroup3ReplayResponseSchema = z.record(z.unknown()).and(
  z.object({
    TraceId: z.string(),
  }),
)

export const apiOds3sOnlineDataGroup3Schema = z.record(z.unknown()).and(
  z.object({
    ReplayResponse: apiOds3sOnlineDataGroup3ReplayResponseSchema,
  }),
)
