import { Dialog, DialogSurface } from '@fluentui/react-components'
import { useState } from 'react'

interface ImageModalProps {
  imageUrl: string
  onClose: () => void
}

export const ImageModal: React.FC<ImageModalProps> = ({ imageUrl, onClose }) => {
  const [scale, setScale] = useState(2)

  const handleWheel = (event: React.WheelEvent<HTMLImageElement>) => {
    event.preventDefault()
    const newScale = scale - event.deltaY * 0.01
    setScale(Math.min(Math.max(newScale, 1), 5))
  }

  return (
    <Dialog open={!!imageUrl} onOpenChange={onClose}>
      <DialogSurface style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <img
          src={imageUrl}
          style={{
            width: '100%',
            transform: `scale(${scale})`,
            transition: 'transform 0.3s ease-in-out',
          }}
          onWheel={handleWheel}
        />
      </DialogSurface>
    </Dialog>
  )
}
