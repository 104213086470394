import { IProductChannelData } from '../IProductData'
import { ProductIds } from './ProductIds'
import { ProductNames } from '../ProductName'
import { ScenarioNames } from '../ScenarioNames'

export const DefaultProductChannels: IProductChannelData[] = [
  {
    productId: ProductIds.M365Chat,
    name: ScenarioNames.CopilotChatFeedback,
    title: 'Copilot Response Feedback',
  },
  {
    productId: ProductIds.M365Chat,
    name: ScenarioNames.CopilotGlobalFeedback,
    title: 'Copilot Global Feedback',
  },
  {
    productId: ProductIds.M365Chat,
    name: ScenarioNames.CopilotCatchUpFeedback,
    title: 'Copilot Catch Up Feedback',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: ScenarioNames.CopilotChatFeedback,
    title: 'Copilot Response Feedback',
  },
  {
    productId: ProductIds.M365ChatWebChat,
    name: ScenarioNames.CopilotGlobalFeedback,
    title: 'Copilot Global Feedback',
  },
  {
    productId: ProductIds.ExcelCopilot,
    name: ScenarioNames.CopilotChatFeedback,
    title: 'Copilot Response Feedback',
  },
  {
    productId: ProductIds.WordCopilot,
    name: ScenarioNames.CopilotChatFeedback,
    title: 'Copilot Response Feedback',
  },
  {
    productId: ProductIds.PPTCopilot,
    name: ScenarioNames.CopilotChatFeedback,
    title: 'Copilot Response Feedback',
  },
  {
    productId: ProductIds.TeamsMeetingCopilot,
    name: ScenarioNames.CopilotChatFeedback,
    title: 'Copilot Response Feedback',
  },
  {
    productId: ProductIds.ODSPCopilot,
    name: ScenarioNames.CopilotChatFeedback,
    title: 'Copilot Response Feedback',
  },
  {
    productId: ProductIds.LoopCopilot,
    name: ScenarioNames.CopilotChatFeedback,
    title: 'Copilot Response Feedback',
  },
  {
    productId: ProductIds.LoopCopilot,
    name: ScenarioNames.CopilotAssistantFeedback,
    title: 'Copilot Assistant Feedback',
  },
  {
    productId: ProductIds.OneNoteCopilot,
    name: ScenarioNames.CopilotChatFeedback,
    title: 'Copilot Response Feedback',
  },
  {
    productId: ProductIds.TenantAdminFeedback,
    name: ScenarioNames.AIF,
    title: 'Admin-Initiated Feedback',
  },
]

export const DefaultProductChannelMapping: Record<keyof typeof ProductNames, string[] | undefined> = {
  M365Chat: [ScenarioNames.CopilotChatFeedback],
  M365ChatWebChat: [ScenarioNames.CopilotChatFeedback],
  ExcelCopilot: undefined,
  WordCopilot: undefined,
  PPTCopilot: undefined,
  TeamsMeetingCopilot: undefined,
  ODSPCopilot: undefined,
  LoopCopilot: undefined,
  OneNoteCopilot: undefined,
  TenantAdminFeedback: undefined,
}
