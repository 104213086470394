import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    width: '100%',
    ...shorthands.overflow('hidden'),
  },

  tableLayout: {
    ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStencil1Alpha),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    ...shorthands.overflow('auto', 'hidden'),
    maxHeight: 'fit-content',
    width: '100%',
  },

  table: {
    width: '100%',
    minWidth: 'fit-content',
  },
  header: {
    backgroundColor: tokens.colorNeutralBackground3,
  },
  headerRow: {
    height: '56px',
  },
  list: {
    overflowX: 'auto',
    overflowY: 'auto',
  },
})
