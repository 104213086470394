import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.gap('8px'),
  },
  interactionTagPrimary: {
    height: '24px',
    backgroundColor: tokens.colorBrandBackground2,
    ':hover': {
      backgroundColor: tokens.colorBrandBackground2,
    },
    ':active': {
      backgroundColor: tokens.colorBrandBackground2,
    },
  },
  textStyle: {
    marginRight: '10px',
    color: tokens.colorNeutralForeground1,
    display: 'block',
    width: 'auto',
  },
  strong1: {
    whiteSpace: 'nowrap',
  },
  tooltip: {
    boxShadow: tokens.shadow2,
    paddingRight: '0px',
  },
  tooltipContent: {
    paddingRight: '16px',
    fontSize: tokens.fontSizeBase300,
    lineHeight: tokens.lineHeightBase300,
  },
  text: {
    color: tokens.colorNeutralForeground1,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    overflow: 'hidden',
  },
})
