import { createContext, useContext } from 'react'
import { IDashSettings } from '../IDashSettings'

export const DashSettingsContext = createContext<IDashSettings | null>(null)

export const useDashSettings = () => {
  const settings = useContext(DashSettingsContext)
  if (settings === null) {
    throw new Error('useDashSettings must be used within a DashSettingsProvider')
  }
  return settings
}
