import { Node } from '@xyflow/react'
import { CallFlowNode } from '../store/IState'
import { layoutNodesColumn } from './layoutNodesColumn'

interface IParams {
  readonly nodes: Node<CallFlowNode>[]
  readonly nodeWidth: number
  readonly nodeHeight: number
  readonly gapX: number
  readonly gapY: number
  readonly initialX: number
  readonly initialY: number
  readonly columnCount: number
}

export function layoutNodesPositions({
  nodes,
  nodeWidth,
  nodeHeight,
  gapX,
  gapY,
  initialX,
  initialY,
  columnCount,
}: IParams) {
  const nodeTypeColumns = layoutNodesColumn({ nodes, columnCount })

  let xIndex = 0
  let yIndex = 0
  // const moveToNextCell = () => {
  //   if (xIndex === columnCount) {
  //     xIndex = 0
  //     yIndex++
  //   } else {
  //     xIndex++
  //   }
  // }

  const moveToNextLine = () => {
    xIndex = 0
    yIndex++
  }

  const moveToNextColumn = (columnIndex: number) => {
    if (columnIndex < xIndex) {
      xIndex = columnIndex
      yIndex++
    } else if (columnIndex > xIndex) {
      xIndex = columnIndex
    }
  }

  // 1. Update positions
  nodes.forEach((node): void => {
    const columnIndex = nodeTypeColumns[node.data.type]
    moveToNextColumn(columnIndex)

    node.position.x = initialX + xIndex * (nodeWidth + gapX)
    node.position.y = initialY + yIndex * (nodeHeight + gapY)
    moveToNextLine()
  })
}
