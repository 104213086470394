import { PromiseSnapshots } from '@copilot-dash/core'
import { I3sLogData, I3sLogDataItem } from '@copilot-dash/domain'
import { makeStyles, Tree, TreeItem, TreeItemLayout } from '@fluentui/react-components'
import { useMemo } from 'react'
import { TreeItemErrorView } from './common/TreeItemErrorView'
import { TreeItemJsonView } from './common/TreeItemJsonView'
import { TreeItemLoadingView } from './common/TreeItemLoadingView'
import { TreeItemTitle } from './common/TreeItemTitle'

interface IProps {
  readonly data: I3sLogData
  readonly item: I3sLogDataItem
}

export function SssLogViewTreeItemOnlineGeneric({ data, item }: IProps) {
  const classes = useStyles()
  const snapshot = app.store.use.getRaw3sOnlineDataGroup3(data.ticketId, data.ticketTurnId)

  const snapshotForCurrentTransaction = useMemo(() => {
    return PromiseSnapshots.map(snapshot, (data) => {
      return data.find((e) => e.ReplayResponse.TraceId === item.transactionId)
    })
  }, [item.transactionId, snapshot])

  return (
    <TreeItem itemType="branch">
      <TreeItemTitle type="onlineGeneric" snapshot={snapshot} />
      <Tree>
        <TreeItem itemType="leaf">
          <TreeItemLayout className={classes.content}>
            {(() => {
              switch (snapshotForCurrentTransaction.status) {
                case 'waiting':
                  return <TreeItemLoadingView />
                case 'done':
                  return <TreeItemJsonView data={snapshotForCurrentTransaction.data} />
                case 'error':
                  return <TreeItemErrorView error={snapshotForCurrentTransaction.error} />
              }
            })()}
          </TreeItemLayout>
        </TreeItem>
      </Tree>
    </TreeItem>
  )
}

const useStyles = makeStyles({
  content: {
    width: '100%',
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
})
