import { memo } from 'react'
import { CopilotDashDropdown } from '../../../Filter/CopilotDashDropdown'

interface IProps {
  value: string | undefined
  onChangeValue: (value?: string) => void
}

export const SliceIdsFilter: React.FC<IProps> = memo(({ value, onChangeValue }) => {
  return (
    <CopilotDashDropdown value={value} onChangeValue={onChangeValue} title="Slice IDs" placeholder="Search sliceIds" />
  )
})

SliceIdsFilter.displayName = 'SliceIdsFilter'
