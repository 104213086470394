import { usePromise } from '@copilot-dash/core'
import { ITicketRootCauseItem, IUpdateTicketStatusInfoData } from '@copilot-dash/domain'
import { Button, makeStyles, shorthands, Spinner, tokens } from '@fluentui/react-components'
import { isEqual } from 'lodash'
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Column, Row, Spacer } from '../../../../components/Layout'
import { ITicketActionFormRef, TicketActionForm } from '../../../../components/TicketActionForm/TicketActionForm'
import { summarySatisfyPost } from '../../../../components/TicketActionForm/utils'
import { RootCauseActionType } from '../../../../components/TicketSummaryPanel/children/Suggestions'
import { mapTicketInfoToTicketActionFormValue } from '../../../../components/TicketSummaryPanel/children/TicketSummary'
import {
  mapCopilotDashStateToADOReason,
  mapCopilotDashStateToADOState,
} from '../../../../components/TicketSummaryPanel/children/utils'
import { useToast } from '../../../../hooks/useToast'
import { useGlobalStore } from '../../../../store'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketScreenRootCauseSuggestions } from './TicketScreenRootCauseSuggestions'

export const TicketScreenActionContent: FC = memo(() => {
  const styles = useStyles()
  const formRef = useRef<ITicketActionFormRef | null>(null)
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const afterSavedCallback = TicketScreenStore.use((state) => state.afterSavedCallback)
  const ticketSnapshot = app.store.use.getTicket(ticketId)
  const customTagsSnapshot = app.store.use.getTicketCustomTags(ticketId)

  const [formModified, setFormModified] = useState(false)
  const [saving, setSaving] = useState(false)
  const [submittable, setSubmittable] = useState(false)
  const draftRef = useRef<Partial<IUpdateTicketStatusInfoData>>({})
  const toast = useToast()
  const [hasSuggestionAdded, setHasSuggestionAdded] = useState(false)

  const rootCausePromise = useMemo(() => {
    return app.store.actions.getTicketRootCause(ticketId)
  }, [ticketId])
  const rootCauseSnapshot = usePromise(rootCausePromise)

  const [invisibleRootCauseIds, setInvisibleRootCauseIds] = useState<string[]>([])

  const suggestions = useMemo(
    () =>
      rootCauseSnapshot.data?.items?.filter(
        (item) =>
          item.userAction === RootCauseActionType.UserNoAction || item.userAction === RootCauseActionType.DirectLinked,
      ),
    [rootCauseSnapshot.data],
  )
  const [rootCauseSuggestions, setSuggestions] = useState<ITicketRootCauseItem[] | undefined>(suggestions)

  useEffect(() => {
    if (ticketSnapshot.status === 'done') {
      const { rootCauseIDs } = mapTicketInfoToTicketActionFormValue(ticketSnapshot.data)
      setInvisibleRootCauseIds(rootCauseIDs)
      const visibleSuggestions = suggestions?.filter((item) => !rootCauseIDs.includes(item.adoIssueId))
      if (visibleSuggestions?.length === 0) {
        setSuggestions(undefined)
      } else {
        setSuggestions(suggestions)
      }
    }
  }, [suggestions, ticketSnapshot])

  const triggerOnSave = useCallback(
    (postData: IUpdateTicketStatusInfoData) => {
      useGlobalStore
        .getState()
        .postTicketDetails(postData)
        .then((resp) => {
          if (resp.updateStatus === true) {
            if (isEqual(postData, draftRef.current)) {
              setFormModified(false)
            }

            const existingTicket = ticketSnapshot.data
            if (existingTicket) {
              app.store.actions.updateTicket(ticketId, {
                ...existingTicket,
                priority: postData.priority,
                teamArea: postData.areaPath,
                status: postData.status,
                rootCauseList: postData.issueList.map((item) => ({
                  issueId: item.issueId,
                  rootCauseTitle: item.title,
                })),
                reasoning: postData.reasoning,
                closedComment: postData.closedComment,
                assignTo: postData.assignTo,
                customTags: postData.customTags,
              })
              app.store.actions.updateAllCustomTags(postData.customTags ?? [])
            }
            afterSavedCallback?.(postData)
          } else {
            throw new Error(resp.errorMessage)
          }
        })
        .catch((err) => {
          toast.showError('Failed to save', err.message)
        })
        .finally(() => {
          setSaving(false)
        })
    },
    [ticketId, ticketSnapshot.data, toast, afterSavedCallback],
  )

  const handleSave = useCallback(() => {
    if (!summarySatisfyPost.safeParse(draftRef.current).success) return
    setSaving(true)
    const postData = summarySatisfyPost.safeParse(draftRef.current).data
    if (ticketSnapshot.status === 'done' && customTagsSnapshot.status === 'done' && postData) {
      const { state } = mapTicketInfoToTicketActionFormValue(ticketSnapshot.data, customTagsSnapshot.data)
      const currentState = mapTicketInfoToTicketActionFormValue(postData!).state
      if (state !== currentState && currentState.startsWith('Closed')) {
        const firstPostData: IUpdateTicketStatusInfoData = { ...postData, status: 'Active', reasoning: '' }
        useGlobalStore
          .getState()
          .postTicketDetails(firstPostData)
          .then(() => {
            triggerOnSave(postData)
          })
          .catch((err) => {
            toast.showError('Failed to save', err.message)
          })
      } else {
        triggerOnSave(postData)
      }
    }
  }, [
    ticketSnapshot.status,
    ticketSnapshot.data,
    customTagsSnapshot.status,
    customTagsSnapshot.data,
    triggerOnSave,
    toast,
  ])

  const handleAcceptSuggestion = useCallback((suggestion: ITicketRootCauseItem) => {
    if (!formRef.current) return
    const values = formRef.current.getValues()
    formRef.current.setValues({
      ...values,
      rootCauseIDs: (values?.rootCauseIDs || []).concat(suggestion.adoIssueId).filter((v) => v),
    })
    setHasSuggestionAdded(true)
  }, [])

  useEffect(() => {
    if (hasSuggestionAdded) {
      setTimeout(() => {
        handleSave()
        setHasSuggestionAdded(false)
      }, 100)
    }
  }, [hasSuggestionAdded, handleSave])

  useEffect(() => {
    if (ticketSnapshot.status === 'done' && formRef.current) {
      formRef.current?.watch(async (values) => {
        const pass = await formRef.current?.validate()
        if (!pass) {
          setSubmittable(false)
          return
        }
        if (!values) return
        if (!values.state) return
        if (!values.area) return
        if (!ticketSnapshot.data.workItemId) return
        if (!ticketSnapshot.data.ticketId) return
        const postData = {
          workItemId: ticketSnapshot.data.workItemId,
          ticketId: ticketSnapshot.data.ticketId,
          status: mapCopilotDashStateToADOState(values.state, values.area),
          priority: values.priority,
          areaPath: values.area,
          assignTo: values.assignTo,
          issueList: values['rootCauseList'],
          reasoning: mapCopilotDashStateToADOReason(values.state),
          closedComment: values.noActionableReason ?? '',
          customTags: values.customTags,
        }
        const satisfyPost = summarySatisfyPost.safeParse(postData).success
        setSubmittable(satisfyPost)
        draftRef.current = postData
      })
    }
  }, [ticketSnapshot, customTagsSnapshot])

  return (
    <Row className={styles.root}>
      {ticketSnapshot.status === 'done' && customTagsSnapshot.status === 'done' && (
        <>
          <Column fill>
            <TicketActionForm
              gridLayout={{
                templateColumns: '1fr minmax(10px,80px) 1fr minmax(10px,80px) 1fr minmax(10px,80px) 1fr',
                templateRows: 'auto 8px auto 8px auto',
                templateAreas: [
                  ['state', '.', 'priority', '.', 'area', '.', 'assignTo'],
                  ['.', '.', '.', '.', '.', '.', '.'],
                  [
                    'noActionableReason',
                    'noActionableReason',
                    'noActionableReason',
                    'noActionableReason',
                    'noActionableReason',
                    'noActionableReason',
                    'noActionableReason',
                  ],
                  ['.', '.', '.', '.', '.', '.', '.'],
                  ['customTags', 'customTags', 'customTags', 'customTags', 'rootCauses', 'rootCauses', 'rootCauses'],
                ],
              }}
              ref={formRef}
              defaultValues={mapTicketInfoToTicketActionFormValue(ticketSnapshot.data, customTagsSnapshot.data)}
              ticketInfo={{
                teamName: ticketSnapshot.data.teamArea,
                vsoAccount: ticketSnapshot.data.vsoAccount,
              }}
              onModified={(modified, data) => {
                setFormModified(modified)
                if (data && data.rootCauseIDs) {
                  setInvisibleRootCauseIds?.(data.rootCauseIDs)
                  const visibleSuggestions = suggestions?.filter(
                    (item) => !data.rootCauseIDs?.includes(item.adoIssueId),
                  )
                  if (visibleSuggestions?.length === 0) {
                    setSuggestions(undefined)
                  } else {
                    setSuggestions(suggestions)
                  }
                }
              }}
              productId={ticketSnapshot.data.productId}
            >
              {(rootCauseSuggestions ?? []).length > 0 && (
                <TicketScreenRootCauseSuggestions
                  onAccept={handleAcceptSuggestion}
                  invisibleRootCauseIds={invisibleRootCauseIds}
                  rootCauseSnapshot={rootCauseSnapshot}
                />
              )}
            </TicketActionForm>
          </Column>
          <Spacer style={{ width: '144px', minWidth: '10px', flexShrink: 1 }} />
          <Column>
            <Button
              disabled={!formModified || !submittable || saving}
              appearance="primary"
              onClick={handleSave}
              icon={saving ? <Spinner size="extra-tiny" /> : null}
            >
              Save
            </Button>
          </Column>
        </>
      )}
    </Row>
  )
})

TicketScreenActionContent.displayName = 'TicketScreenActionContent'

const useStyles = makeStyles({
  root: {
    backgroundColor: tokens.colorNeutralBackground1,
    padding: '16px',
    borderRadius: tokens.borderRadiusLarge,
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
  },
})
