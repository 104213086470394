import { Times } from '@copilot-dash/core'
import { useUserSetting } from '@copilot-dash/settings'
import { FC, useMemo } from 'react'
import { Column } from '../../../../components/Layout'
import { useSearchScreenState } from '../../store'
import { SearchScreenCopilotDashTag } from './SearchScreenCopilotDashTag'
import { useStyles } from './SearchScreenTag.styles'

interface IProps {
  onClickTagFunction: () => void
  isSmallScreen: boolean
}

export const TimeRangeTag: FC<IProps> = ({ onClickTagFunction, isSmallScreen }) => {
  const styles = useStyles()
  const range = useSearchScreenState((state) => state.form.range ?? state.form.defaultRange)

  const [timezone] = useUserSetting('timeZone')
  const content = useMemo(() => {
    switch (range.type) {
      case 'absolute': {
        const fromDateString = Times.format(range.from, { timezone, format: 'MM/DD' }) ?? range.from
        const toDateString = Times.format(range.to, { timezone, format: 'MM/DD' }) ?? range.to
        return `${fromDateString} to ${toDateString}`
      }
      case 'relative':
        return Times.toStringOfRelativeTimeRange(range)
    }
  }, [range, timezone])

  return (
    <Column className={styles.container}>
      <SearchScreenCopilotDashTag
        isTagClearable={false}
        tagKey={'date'}
        toolTipContentValue={content}
        truncatedTagValue={content}
        tagName={'Date'}
        onClickTagFunction={onClickTagFunction}
        isSmallScreen={isSmallScreen}
      />
    </Column>
  )
}
