import { Button, CounterBadge } from '@fluentui/react-components'
import { ChatMultipleRegular } from '@fluentui/react-icons'
import { useStyles } from './DiscussionPanelTriggerButton.styles'
import { Row } from '../Layout'

interface IProps {
  ticketId: string
  openDialog: () => void
}
export function DiscussionPanelTriggerButton({ ticketId, openDialog }: IProps) {
  const styles = useStyles()

  const commentCountSnapshot = app.store.use.getTicketCommentCount(ticketId)

  return (
    <Row style={{ position: 'relative' }}>
      <Button size="medium" onClick={openDialog}>
        <ChatMultipleRegular fontSize={20} className={styles.icon} />
        Discussion
      </Button>
      {commentCountSnapshot.status === 'done' && commentCountSnapshot.data > 0 ? (
        <CounterBadge
          count={commentCountSnapshot.data ?? 0}
          appearance="filled"
          style={{ position: 'absolute', top: '-10px', right: '-7px' }}
        />
      ) : null}
    </Row>
  )
}
