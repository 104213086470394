import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  activeBtn: {
    backgroundColor: tokens.colorCompoundBrandBackground,
    color: tokens.colorNeutralCardBackgroundHover,
    fontWeight: 600,
    height: '36px',
  },
  btn: {
    fontSize: '14px',
    fontWeight: 400,
    height: '36px',
  },
})
