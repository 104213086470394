import { forwardRef } from 'react'
import { Column } from '../../Layout'
import { ISearchPageFilterProps } from '../TicketFilterPanel.types'
import { TicketFilterForm } from '../children/TicketFilterForm/TicketFilterForm'
import { ITicketFilterFormRef } from '../children/TicketFilterForm/TicketFilterForm.types'

export const FilterPanelBody = forwardRef<ITicketFilterFormRef, ISearchPageFilterProps>(
  (props: ISearchPageFilterProps, ref) => {
    return (
      <Column fill>
        <TicketFilterForm
          ref={ref}
          onSubmit={props.onSubmitFilterPanel}
          defaultValues={{
            ...props.ticketFilterForm,
            range: props.ticketFilterForm.range ?? props.defaultRange,
          }}
        />
      </Column>
    )
  },
)
FilterPanelBody.displayName = 'FilterPanelBody'
