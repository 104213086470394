import { z } from 'zod'

export const timeTypeSchema = z.union([z.string(), z.number(), z.date(), z.undefined()]).nullable()

export const timezoneDataSchema = z.object({
  value: z.string(),
  valueLabel: z.string(),
  offset: z.number(),
  offsetLabel: z.string(),
})

export const relativeTimeUnitSchema = z.union([
  z.literal('hours'),
  z.literal('days'),
  z.literal('weeks'),
  z.literal('months'),
])

export const relativeTimeRangeSchema = z.object({
  type: z.literal('relative'),
  value: z.number(),
  unit: relativeTimeUnitSchema,
})

export const absoluteTimeRangeSchema = z.object({
  type: z.literal('absolute'),
  from: z.string(),
  to: z.string(),
})

export const timeRangeSchema = z.union([relativeTimeRangeSchema, absoluteTimeRangeSchema])
