import { ComponentProps } from 'react'
import { JSONTree } from 'react-json-tree'

interface IProps {
  readonly data: unknown
}

export function JsonView(props: IProps & Partial<ComponentProps<typeof JSONTree>>) {
  return <JSONTree {...DEFAULT_PROPS} {...props} />
}

/**
 * https://github.com/reduxjs/redux-devtools/blob/75322b15ee7ba03fddf10ac3399881e302848874/src/react/themes/brewer.js
 */
const DEFAULT_PROPS: Partial<ComponentProps<typeof JSONTree>> = {
  hideRoot: true,
  shouldExpandNodeInitially: () => false,
  invertTheme: true,
  theme: {
    base00: 'transparent',
    base01: '#2e2f30',
    base02: '#515253',
    base03: '#737475',
    base04: '#959697',
    base05: '#b7b8b9',
    base06: '#dadbdc',
    base07: '#fcfdfe',
    base08: '#e31a1c',
    base09: '#e6550d',
    base0A: '#dca060',
    base0B: '#31a354',
    base0C: '#80b1d3',
    base0D: '#3182bd',
    base0E: '#756bb1',
    base0F: '#b15928',
  },
}
