import { PromiseSnapshot } from '@copilot-dash/core'
import { IKustoBizChat3SLatencyLogItem, ITicketSessionInteractionData, KustoLogTable } from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicket } from '../getTicket'
import { getTicketSession } from '../getTicketSession'
import { validateTicketDiagnosticDataForConversation } from '../validators/validateTicketDiagnosticData'
import { validateTicketKustoExpiration } from '../validators/validateTicketKustoData'
import { validateTicketUserConsent } from '../validators/validateTicketUserConsent'
import { convertTo3SLatencyLog } from './converter/convertTo3sLatencyLogs'

export function get3SLatencyLogs(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IKustoBizChat3SLatencyLogItem[]> {
  return context.getOrFetch<IKustoBizChat3SLatencyLogItem[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.threeSLatencyLogs
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.threeSLatencyLogs = snapshot
    },
    fetch: async () => {
      // Check User consent
      const session = await getTicketSession(context, ticketId).promise
      validateTicketUserConsent(ticketId, session)

      // Check interaction
      const interaction = session.interactions.find((item) => item.messageId === messageId)
      if (!interaction) {
        // Check ticket Kusto expiration
        validateTicketKustoExpiration(ticketId, KustoLogTable.BizChat3SLatencyLog, session)

        // Check failed
        throw TicketError.create('NoKustoDueToInteractionFieldIsMissing', {
          ticketId,
          kustoLogTable: KustoLogTable.BizChat3SLatencyLog,
        })
      }

      const logs = await doFetch(interaction)
      if (logs.length > 0) {
        return logs
      }

      // Check 3s is triggered
      const ticket = await getTicket(context, ticketId).promise
      if (!ticket.tag.sssTriggered) {
        throw TicketError.create('No3SDueToNoNotTriggered', { ticketId, ticketMessageId: messageId })
      }

      // Check ticket Kusto expiration
      validateTicketKustoExpiration(ticketId, KustoLogTable.BizChat3SLatencyLog, session)

      // Check ticket conversation diagnostic data
      // TODO: Some tables may not require conversation data
      validateTicketDiagnosticDataForConversation(ticketId, messageId, session)

      // Check failed
      throw TicketError.create('NoKusto', { ticketId, kustoLogTable: KustoLogTable.BizChat3SLatencyLog })
    },
  })

  async function doFetch(interaction: ITicketSessionInteractionData): Promise<IKustoBizChat3SLatencyLogItem[]> {
    const response = await context.api.logCollector.getSydneySingleInfo({
      FetchLogName: 'bizChat3SLatencyLog',
      HappenTimeArray: interaction.interactionTime ? [interaction.interactionTime] : [],
      MessageId: messageId,
    })

    return convertTo3SLatencyLog(response)
  }
}
