import {
  INewTicketData,
  TeamViewSubMenuIds,
  SearchTextPrefixType,
  DefaultProductChannelMapping,
  getProductIdByName,
} from '@copilot-dash/domain'
import { useUserSetting } from '@copilot-dash/settings'
import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
  Button,
  Text,
} from '@fluentui/react-components'
import * as React from 'react'
import { ErrorView } from '../../../components/Error'
import { Row, Spacer } from '../../../components/Layout'
import { ExtendedTableColumnDefinition } from '../../../components/Table/ExtendedTableColumn'
import { ITableRef, ResizableColumnsTable } from '../../../components/Table/ResizableColumnsTable'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { useTeamViewStore } from '../storeNew'
import { QuickSearch } from './QuickSearch'
import { TableLoading } from './TableLoading'
import { useStyles } from './TicketsTableForRootCause.styles'
import { ToggleLeftDrawerIcon } from './ToggleDrawerLeftIcon'
import { ClockRegular, EditRegular } from '@fluentui/react-icons'
import { RootCauseCreateDialog } from '../../../components/RootCauseCreateDialog/RootCauseCreateDialog'
import { FieldNames, IFormData } from '../../../components/RootCauseCreateDialog/RootCauseCreateForm'
import { useToast } from '../../../hooks/useToast'
import { useGlobalStore } from '../../../store'
import { DefaultRootCauseTab, EMPTY_TEAM_ID, RootCauseTab, LinkedDSATsTab } from '../storeNew/const'
import { OverflowTabList } from '../../../components/TabList/OverflowTabList'
import { TeamRoute } from '../../../router'
import { compact, isNil } from 'lodash'
import { UserProfile } from '../../../components/User/UserProfile'

interface ITicketsTableForRootCauseProps {
  onRowClick?: (row?: INewTicketData) => void
  clickedTicketKey?: string
  keyPicker?: (item: INewTicketData, index: number) => string
}

export const TicketsTableForRootCause: React.FC<ITicketsTableForRootCauseProps> = React.memo(
  ({
    onRowClick,
    clickedTicketKey,
    keyPicker = (item: INewTicketData, _: number) => {
      return item.ticketId
    },
  }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [currentTab, setCurrentTab] = React.useState<RootCauseTab>(RootCauseTab.LinkedDSATs)
    const [showPeddingConfirmedDSATs, setShowPeddingConfirmedDSATs] = React.useState(false)
    const [overDue, setOverDue] = React.useState(false)
    const [dueDays, setDueDays] = React.useState(0)
    const tableRef = React.useRef<ITableRef>(null)
    const linkedTicketsSnapshot = useTeamViewStore((state) => state.tickets.ticketListByRootCause)
    const recommendTicketsSnapshot = useTeamViewStore((state) => state.tickets.recommendTicketListByRootCause)
    const linkedTicketsHasMore = useTeamViewStore((state) => state.tickets.hasMoreLinkedTicketsForRootCause)
    const recommendTicketsHasMore = useTeamViewStore((state) => state.tickets.hasMoreRecommendTicketsForRootCause)
    const loadMoreLinkedTickets = useTeamViewStore((state) => state.tickets.loadMoreTicketListByRootCause)
    const loadMoreRecommendTickets = useTeamViewStore((state) => state.tickets.loadMoreRecommendTicketListByRootCause)
    const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
    const selectedRootCause = useTeamViewStore((state) => state.rootCauses.computed.selectedRootCause)
    const teamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
    const filter = useTeamViewStore((state) => state.tickets.filterForm)
    const productName = TeamRoute.navigator.useArgsOptional()?.product

    const styles = useStyles()
    const [columnSetting] = useUserSetting('teamViewColumns')
    const toast = useToast()

    const searchText = useTeamViewStore((state) => state.tickets.filterForm.searchText)
    const email = useTeamViewStore((state) => state.tickets.filterForm.email)
    const tenantIds = useTeamViewStore((state) => state.tickets.filterForm.tenantIds)
    const searchTextPrefix = useTeamViewStore((state) => state.tickets.filterForm.searchTextPrefix)
    const productId = getProductIdByName(productName)
    const rootCauseSnapshot = application.store.use((state) => state.team.rootCausesMap?.[`${productId}-${teamId}`])

    const tableColumns = React.useMemo(() => {
      const defaultColumns = AllSearchTableColumns(
        searchTextPrefix || SearchTextPrefixType.All,
        searchText,
        email,
        tenantIds,
      )
      const newSelectedColumns: ExtendedTableColumnDefinition<INewTicketData>[] = compact(
        columnSetting.map((columnId) => defaultColumns.find((column) => column.columnId === columnId)),
      )

      return newSelectedColumns
    }, [columnSetting, searchTextPrefix, email, searchText, tenantIds])

    const linkedDSATsCount = linkedTicketsSnapshot.status === 'done' ? linkedTicketsSnapshot.data?.ticketCount ?? 0 : ''
    const recommendedDSATsCount =
      recommendTicketsSnapshot.status === 'done' ? recommendTicketsSnapshot.data?.ticketCount ?? 0 : ''

    const handleSelectionChange = React.useCallback((selectedItems: INewTicketData[] | undefined) => {
      useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(null)
      useTeamViewStore.getState().batchTicketsSubStore.setTickets(selectedItems ?? [])
      useTeamViewStore.getState().toggleBatchUpdatePanel(!!(selectedItems && selectedItems.length > 0))
      selectedItems &&
        useTeamViewStore
          .getState()
          .batchTicketsSubStore.fetchBatchTicketCustomTags(selectedItems.map((item) => item.ticketId))
    }, [])

    const handleUpdateRootCause = React.useCallback(
      (data: IFormData) => {
        if (isNil(teamId) || teamId === EMPTY_TEAM_ID || !selectedRootCause) return Promise.resolve(undefined)
        let formattedDate = ''
        if (data[FieldNames.DUEDATE]) {
          const timeStr = String(data[FieldNames.DUEDATE])
          const date = new Date(timeStr)

          const year = date.getFullYear()
          const month = String(date.getMonth() + 1).padStart(2, '0')
          const day = String(date.getDate()).padStart(2, '0')

          formattedDate = `${year}-${month}-${day}`
        }

        const postData = {
          title: data[FieldNames.TITLE],
          description: data[FieldNames.DESCRIPTION] ?? selectedRootCause?.rootCauseDescription ?? '',
          priority: data[FieldNames.PRIORITY],
          rootCauseStatus: data[FieldNames.STATE],
          vsoAccount: selectedRootCause.vsoAccount,
          workItemId: selectedRootCause.issueId,
          teamId: teamId,
          owner: data[FieldNames.ASSIGNEDTO],
          originalCommittedDate: formattedDate,
          productId: productId,
        }
        return useTeamViewStore
          .getState()
          .rootCauses.updateTeamRootCause(postData)
          .then((resp) => {
            if (resp.errorMessage === '') {
              setDialogOpen(false)
              useGlobalStore.getState().updateRootCause(postData)
              productId &&
                application.store.actions.updateRootCauseByTeam(productId, teamId, {
                  rootCauseTitle: postData.title,
                  eTA: postData.originalCommittedDate,
                  owner: postData.owner,
                  issueId: postData.workItemId,
                  vsoAccount: postData.vsoAccount,
                  rootCauseStatus: postData.rootCauseStatus,
                  rootCausePriority: postData.priority,
                })
            } else {
              toast.showError('Failed to save', resp.errorMessage)
            }
          })
      },
      [teamId, selectedRootCause, productId, toast],
    )

    React.useEffect(() => {
      if (selectedRootCause) {
        useTeamViewStore
          .getState()
          .tickets.fetchTicketListByRootCause(selectedRootCause.issueId, selectedRootCause.vsoAccount, filter)
        useTeamViewStore
          .getState()
          .tickets.fetchRecommendTicketListByRootCause(selectedRootCause.issueId, selectedRootCause.vsoAccount, filter)
      }
    }, [filter, selectedRootCause])

    React.useEffect(() => {
      if (productId && teamId) {
        application.store.actions.getOrFetchRootCauseListByTeam(productId, teamId)
      }
    }, [teamId, productId])

    React.useEffect(() => {
      if (rootCauseSnapshot?.data?.rootCauseList) {
        const result = rootCauseSnapshot.data.rootCauseList.find((item) => item.issueId === selectedRootCause?.issueId)
        if (result) {
          setShowPeddingConfirmedDSATs(!!result.enable)
        }
      }
    }, [rootCauseSnapshot, selectedRootCause])

    const table = React.useMemo(() => {
      const curTicketsSnapshot =
        currentTab === RootCauseTab.LinkedDSATs ? linkedTicketsSnapshot : recommendTicketsSnapshot
      const loadMoreFunction =
        currentTab === RootCauseTab.LinkedDSATs ? loadMoreLinkedTickets : loadMoreRecommendTickets
      const hasMore = currentTab === RootCauseTab.LinkedDSATs ? linkedTicketsHasMore : recommendTicketsHasMore

      if (curTicketsSnapshot.status === 'waiting') {
        return <TableLoading />
      }
      if (curTicketsSnapshot.status === 'error') {
        return <ErrorView error={curTicketsSnapshot.error} />
      }
      if (curTicketsSnapshot.status === 'done') {
        return curTicketsSnapshot.data.ticketCount > 0 ? (
          <ResizableColumnsTable
            key={currentTab}
            ref={tableRef}
            className={styles.container}
            items={curTicketsSnapshot.data?.tickets}
            columns={tableColumns}
            keyPicker={keyPicker}
            rowClick={onRowClick}
            supportLoadMore={hasMore}
            virtualizationConfig={{
              itemSize: 56,
            }}
            selectedRowId={clickedTicketKey}
            loadMoreFunction={(offset) => loadMoreFunction(offset)}
            selectable={true}
            selectionMode="multiselect"
            onSelectionChange={handleSelectionChange}
          />
        ) : (
          <ErrorView.Custom className={styles.flexGrow} level="WARNING" message="No tickets found for this query" />
        )
      }
      return null
    }, [
      currentTab,
      linkedTicketsSnapshot,
      recommendTicketsSnapshot,
      loadMoreLinkedTickets,
      loadMoreRecommendTickets,
      linkedTicketsHasMore,
      recommendTicketsHasMore,
      styles.container,
      styles.flexGrow,
      tableColumns,
      keyPicker,
      onRowClick,
      clickedTicketKey,
      handleSelectionChange,
    ])

    const updateSelectedTab = (tab: React.SetStateAction<RootCauseTab>) => {
      setCurrentTab(tab)
    }

    const renderItemCount = (item: RootCauseTab) => {
      if (item === RootCauseTab.LinkedDSATs) {
        return typeof linkedDSATsCount === 'number' && ` (${linkedDSATsCount})`
      } else {
        return typeof recommendedDSATsCount === 'number' && ` (${recommendedDSATsCount})`
      }
    }

    const handleGoBack = React.useCallback(() => {
      const product = useTeamViewStore.getState().product
      const lastSelectedTeam = useTeamViewStore.getState().teams.lastSelectedTeam
      lastSelectedTeam &&
        product &&
        TeamRoute.navigator.navigate({
          product,
          subMenu: TeamViewSubMenuIds.RootCauses,
          teamName: lastSelectedTeam,
          channel: DefaultProductChannelMapping[product],
        })
    }, [])

    React.useEffect(() => {
      return () => {
        useTeamViewStore.getState().toggleFilterPanel(false)
      }
    }, [])

    React.useEffect(() => {
      if (selectedRootCause?.eTA) {
        const now = new Date()
        const eTADate = new Date(selectedRootCause.eTA)
        const targetDate = new Date(selectedRootCause.eTA)
        targetDate.setDate(eTADate.getDate() + 10)
        const timeDifference = targetDate.getTime() - now.getTime()
        const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
        setDueDays(daysLeft)

        const timer = setInterval(() => {
          const now = new Date()
          const timeDifference = targetDate.getTime() - now.getTime()
          const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
          setDueDays(daysLeft)
          if (daysLeft < 0) {
            setOverDue(true)
            clearInterval(timer)
          }
        }, 60000)
        return () => clearInterval(timer)
      } else {
        setDueDays(0)
        return
      }
    }, [selectedRootCause])

    return (
      <>
        <Row className={styles.header} wrap>
          {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
          <Breadcrumb>
            <BreadcrumbItem>
              <BreadcrumbButton size="large" onClick={handleGoBack} style={{ height: 'unset', minWidth: 80 }}>
                <Text wrap size={500} className={styles.breakWord}>
                  Root Causes
                </Text>
              </BreadcrumbButton>
            </BreadcrumbItem>
            <BreadcrumbDivider />
          </Breadcrumb>
        </Row>
        <Row>
          <Text wrap size={500} weight="semibold" className={styles.rootCauseTitle}>
            {selectedRootCause?.rootCauseTitle}
          </Text>
          <Spacer className={styles.minGap} />
          <Button
            className={styles.editBtn}
            appearance="transparent"
            icon={<EditRegular />}
            onClick={() => setDialogOpen(true)}
          >
            Edit Root Cause
          </Button>
        </Row>

        <Row className={styles.item}>
          <Text size={300} weight="semibold">
            Assigned to:&nbsp;
          </Text>
          {selectedRootCause?.owner ? (
            <Text>
              <UserProfile
                userId={selectedRootCause.owner}
                fallback={selectedRootCause.owner}
                customUserPhotoClassName={styles.customUserPhoto}
                customUserDisplayNameClassName={styles.customUserName}
              />
            </Text>
          ) : (
            <Text>Not Assigned</Text>
          )}
          <Spacer width={40} />
          <Text size={300} weight="semibold">
            Fix by:&nbsp;
          </Text>
          <Text size={300} style={{ display: 'flex' }}>
            <ClockRegular className={styles.clock} />
            {selectedRootCause?.eTA?.split(' ')[0] ? (
              selectedRootCause?.eTA?.split(' ')[0]
            ) : (
              <div className={styles.addETA}>Add ETA</div>
            )}
            {dueDays > 0 && <div className={styles.dueDays}>{`Due in ${dueDays} days`}</div>}
            {overDue && <div className={styles.overDue}>Overdue</div>}
          </Text>
          <Spacer width={40} />
          <Text size={300} weight="semibold">
            State:&nbsp;
          </Text>
          <Text size={300}>{selectedRootCause?.rootCauseStatus}</Text>
          <Spacer width={40} />
          <Text size={300} weight="semibold">
            Priority:&nbsp;
          </Text>
          <Text size={300}>P{selectedRootCause?.rootCausePriority}</Text>
        </Row>
        <QuickSearch />
        <OverflowTabList
          items={showPeddingConfirmedDSATs ? DefaultRootCauseTab : LinkedDSATsTab}
          renderItem={(item) => (
            <>
              {item}
              {renderItemCount(item)}
            </>
          )}
          onSelect={updateSelectedTab}
        />
        <Spacer height={16} />
        {table}
        <RootCauseCreateDialog
          fields={[FieldNames.PRIORITY, FieldNames.TITLE, FieldNames.STATE, FieldNames.DUEDATE, FieldNames.ASSIGNEDTO]}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onOk={handleUpdateRootCause}
          okBtnText="Save"
          defaultValue={{
            [FieldNames.PRIORITY]: selectedRootCause?.rootCausePriority as 1 | 2 | 3 | 4 | undefined,
            [FieldNames.TITLE]: selectedRootCause?.rootCauseTitle,
            [FieldNames.STATE]: selectedRootCause?.rootCauseStatus,
            [FieldNames.DUEDATE]: selectedRootCause?.eTA,
            [FieldNames.ASSIGNEDTO]: selectedRootCause?.owner,
          }}
          title="Edit root cause"
        />
      </>
    )
  },
)

TicketsTableForRootCause.displayName = 'TicketsTableForRootCause'
