import { z } from 'zod'
import { ApiGwsLogDiagnosticPlugin } from './ApiKustoLogData'

export const apiBaseKustoLogItemSchema = z.object({
  tIMESTAMP: z.string(),
  message: z.string(),
  logLevel: z.string(),
  traceId: z.string(),
  correlationId: z.string(),
  scopeId: z.string(),
})

export const apiStateAndDurationKustoLogItemSchema = apiBaseKustoLogItemSchema.extend({
  scopeName: z.string(),
  state: z.string(),
  serviceVersion: z.string(),
  conversationId: z.string(),
  causalityNameChain: z.string(),
  causalityIdChain: z.string(),
  trafficType: z.string(),
})

export const apiTraceKustoLogItemSchema = apiBaseKustoLogItemSchema.extend({
  serviceVersion: z.string(),
  eventName: z.string().optional(),
  sourceBranch: z.string().optional(),
})

export const apiEventKustoLogItemSchema = apiBaseKustoLogItemSchema.extend({
  scopeName: z.string(),
  eventName: z.string(),
  exception: z.string(),
  exceptionMessage: z.string(),
  exceptionType: z.string(),
  status: z.string(),
})

export const apiLlmKustoLogItemSchema = z.object({
  preciseTimeStamp: z.string(),
  appName: z.string(),
  correlationVector: z.string(),
  requestId: z.string(),
  aOAIRequestId: z.string(),
  exceptionType: z.string(),
  exceptionStack: z.string(),
  aOAIHttpResponseStatusCode: z.string(),
  resolvedModelName: z.string(),
  requestedModelName: z.string(),
  eventMessage: z.string(),
  llmApiProcessingTimeMs: z.string(),
  openaiProcessingTimeMs: z.string(),
  messageId: z.string(),
  tag: z.string().optional(),
  promptTokenCountBucket: z.string().optional(),
  completionTokenCountBucket: z.string().optional(),
})

export const apiBizPerfLogItemSchema = z.record(z.unknown()).and(
  z.object({
    chatFCFR: z.string().optional(),
    decodedPerfTraceFile: z.string().optional(),
    tIMESTAMP: z.string().optional(),
  }),
)

export const apiBizChat3SLatencyLogItemSchema = z.record(z.unknown()).and(
  z.object({
    decodedPerfTraceFile: z.string().optional(),
    env_time: z.string().optional(),
  }),
)

export const apiGwsLogItemSchema = z.record(z.unknown()).and(
  z.object({
    diagnosticData: z.string().optional(),
    responseMetaJson: z.string().optional(),
    transactionId: z.string().optional(),
    env_time: z.string().optional(),
    routeAction: z.string().optional(),
    requestedActions: z.string().optional(),
  }),
)

export const apiGwsLogDiagnosticPluginSchema: z.ZodSchema<ApiGwsLogDiagnosticPlugin> = z.lazy(() =>
  z.object({
    PN: z.string().optional(),
    DC: z.union([z.string(), z.any()]).optional(),
    InnerD: z.array(apiGwsLogDiagnosticPluginSchema).optional(),
  }),
)

export const apiGwsLogItemDiagnosticDataSchema = z.object({
  PN: z.string().optional(),
  InnerD: z.array(apiGwsLogDiagnosticPluginSchema).optional(),
})

export const apiGwsLogItemDiagnosticSchema = z.record(z.union([z.string(), z.any()]))

export const apiKustoEventLogItemMessageSchema = z.object({
  CountWorkResults: z.string().optional(),
  CountWebResults: z.string().optional(),
})

export const apiKustoLogDataSchema = z.object({
  'turingBotMonitoredScopeLogs (Kusto)': z.array(apiStateAndDurationKustoLogItemSchema).optional(),
  'turingBotEventMDSLogs (Kusto)': z.array(apiEventKustoLogItemSchema).optional(),
  'turingBotTraceMDSLogs (Kusto)': z.array(apiTraceKustoLogItemSchema).optional(),
  'modelDHttpQAS (Kusto)': z.array(apiLlmKustoLogItemSchema).optional(),
  'substrateSearchInfoEvent (Kusto)': z.array(apiGwsLogItemSchema).optional(),
  'bizchatPerformanceEvent (Kusto)': z.array(apiBizPerfLogItemSchema).optional(),
  'bizChat3SLatencyLogs (Kusto)': z.array(apiBizChat3SLatencyLogItemSchema).optional(),
  'substrateSearchExceptionEvent (Kusto)': z.array(apiGwsLogItemSchema).optional(),
})
