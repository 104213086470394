import { ApiConversationGroup3 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../IDashStoreContext'
import { getTicketFiles } from './getTicketFiles'
import { getTicketSession } from './getTicketSession'
import { validateTicketDiagnosticDataForConversation } from './validators/validateTicketDiagnosticData'

export function getRawConversationGroup3(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ApiConversationGroup3> {
  return context.getOrFetch<ApiConversationGroup3>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.rawConversationGroup3
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.rawConversationGroup3 = snapshot
    },
    fetch: async () => {
      // Validate user consent
      const session = await getTicketSession(context, ticketId).promise
      if (!session.hasUserConsent) {
        throw TicketError.create('NoUserConsentGiven', { ticketId })
      }

      // Validate diagnostic data
      validateTicketDiagnosticDataForConversation(ticketId, turnId, session)

      // Get turn index
      const turnIndex = session.interactions.find((item) => item.messageId === turnId)?.index
      if (turnIndex === undefined) {
        throw TicketError.create('NoConversationDueToMessageIdNotFound', { ticketId })
      }

      // Get from grouped file
      const files = await getTicketFiles(context, ticketId).promise
      const file = files.groupedConversationFiles.find((item) => item.index === turnIndex)?.group3
      if (!file) {
        throw TicketError.create('NoConversations', { ticketId, ticketMessageId: turnId })
      }

      return await context.api.ods.getConversationGroup3(file.fileUrl)
    },
  })
}
