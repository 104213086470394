import { makeStyles, Text, Toolbar } from '@fluentui/react-components'
import { Spacer } from '../../../../Layout'
import { AnyDataStore } from '../../../AnyDataStore'

export function AnyTableFooter() {
  const styles = useStyles()
  const count = AnyDataStore.use((state) => {
    return Array.isArray(state.data) ? state.data.length : undefined
  })

  return (
    <Toolbar className={styles.root}>
      <Text>Total entries: {count}</Text>
      <Spacer />
    </Toolbar>
  )
}

const useStyles = makeStyles({
  root: {
    minHeight: '44px',
    paddingLeft: '16px',
  },
})
