import { ApiTicketType, ApiTicketsRequest } from '@copilot-dash/api'
import { Times, uuid } from '@copilot-dash/core'
import {
  DefaultProductChannels,
  DefaultProductEndpoints,
  ProductNames,
  ScenarioNames,
  SearchTextPrefixType,
  TicketRingType,
  getProductIdByName,
} from '@copilot-dash/domain'
import { EnableExceptionTracking, Logger } from '@copilot-dash/logger'
import { IDashStoreContext } from '../../IDashStoreContext'
import { NewTicketConverter } from './converters/NewTicketConverter'
import { NewSearchCopilotTicketsResult } from './NewSearchTicketsAction.types'
import { ISearchTicketOptions } from './SearchTicketAction.types'
import { SearchTicketIdByODSAction } from './SearchTicketIdByODSAction'
import { SearchTicketWithIdListAction } from './SearchTicketWithIdListAction'

export class SearchTicketAction {
  private readonly context: IDashStoreContext
  private readonly SearchTicketIdByODSAction: SearchTicketIdByODSAction
  private readonly SearchTicketWithIdListAction: SearchTicketWithIdListAction

  constructor(context: IDashStoreContext) {
    this.context = context
    this.SearchTicketIdByODSAction = new SearchTicketIdByODSAction(context)
    this.SearchTicketWithIdListAction = new SearchTicketWithIdListAction(context)
  }

  async search(options: ISearchTicketOptions): Promise<NewSearchCopilotTicketsResult> {
    const searchId = uuid()
    const startTime = Date.now()
    const odsSearchTriggered = this.needToSearchODS(options)
    const page = this.pageLocation(options)
    try {
      Logger.telemetry.trackEvent('Search', { filters: options, searchId })
      Logger.telemetry.trackEvent('SearchStart', { filters: options, searchId })
      return await this.doSearch(options, searchId, odsSearchTriggered)
    } finally {
      const duration = Date.now() - startTime

      Logger.telemetry.trackEvent('SearchEnd', { filters: options, searchId })
      Logger.telemetry.trackMetric('SearchTime', { duration, filters: options, searchId, page, odsSearchTriggered })
    }
  }

  @EnableExceptionTracking()
  private async doSearch(
    options: ISearchTicketOptions,
    searchId: string,
    odsSearchTriggered: boolean,
  ): Promise<NewSearchCopilotTicketsResult> {
    const updatedOptions: ISearchTicketOptions = {
      ...options,
      client:
        options.client ||
        DefaultProductEndpoints.filter(
          (endpoint) => endpoint.productId === getProductIdByName(options.product || 'M365Chat'),
        ).map((endpoint) => endpoint.name),
      channel: options.channel,
      ring: options.ring || [
        TicketRingType.DONMT,
        TicketRingType.MSIT,
        TicketRingType.SDFV2,
        TicketRingType.SIP,
        TicketRingType.WW,
      ],
      searchTextPrefix: options.searchTextPrefix || SearchTextPrefixType.All,
    }
    if (options.isAIF) {
      return await this.searchAIFTickets(updatedOptions, searchId)
    } else {
      if (odsSearchTriggered) {
        return await this.searchWithODS(updatedOptions, searchId)
      } else {
        return await this.searchWithoutODS(updatedOptions, searchId)
      }
    }
  }

  private needToSearchODS = (options: ISearchTicketOptions): boolean => {
    const updateChannel =
      options.channel ||
      DefaultProductChannels.filter(
        (endpoint) => endpoint.productId === getProductIdByName(options.product || ProductNames.M365Chat),
      ).map((channel) => channel.name)
    const hasUtteranceOrResponseSearch =
      options.searchText &&
      (options.searchTextPrefix === SearchTextPrefixType.Utterance ||
        options.searchTextPrefix === SearchTextPrefixType.Response ||
        options.searchTextPrefix === SearchTextPrefixType.All)

    const OdsSearchTriggered =
      hasUtteranceOrResponseSearch || options.optionsSets || options.sliceIds || options.copilotExtensionIds

    if (updateChannel?.includes(ScenarioNames.CopilotChatFeedback) && OdsSearchTriggered) {
      return true
    }
    return false
  }

  private pageLocation = (options: ISearchTicketOptions): 'Overview' | 'Team View' => {
    if (options.teamId || options.teamName) {
      return 'Team View'
    }
    return 'Overview'
  }

  private async searchWithODS(options: ISearchTicketOptions, searchId: string): Promise<NewSearchCopilotTicketsResult> {
    // escaped content
    const parsedSearchText = this.escapeForJSON(options.searchText)
    const startTime = Date.now()

    const { from, to } = Times.formatTimeRange(options.range ?? options.defaultRange, { timezone: 'UTC' })
    const ticketIdList = await this.SearchTicketIdByODSAction.search({
      utterance:
        options.searchTextPrefix === SearchTextPrefixType.Utterance ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? parsedSearchText
          : undefined,
      response:
        options.searchTextPrefix === SearchTextPrefixType.Response ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? parsedSearchText
          : undefined,

      optionsSets: options.optionsSets,
      sliceIds: options.sliceIds,
      copilotExtensionIds: options.copilotExtensionIds,
      clients: options.client || [],
      fromDate: from,
      toDate: to,
    })
    const duration = Date.now() - startTime
    Logger.telemetry.trackMetric('OdsSearchTime', { duration, filters: options, searchId })

    return await this.SearchTicketWithIdListAction.search({
      utteranceOrResponseOdsSessionIdList: ticketIdList.utteranceOrResponseOdsSessionIdList,
      otherOdsSessionIdList: ticketIdList.otherOdsSessionIdList,
      options: options,
      searchId: searchId,
      odsSearchTriggered: true,
    })
  }
  //escaping any special characters within the string to avoid issues with the API call
  private escapeForJSON = (str: string | undefined) => {
    if (!str) {
      return ''
    }
    return JSON.stringify(str).slice(1, -1)
  }

  private async searchWithoutODS(
    options: ISearchTicketOptions,
    searchId: string,
  ): Promise<NewSearchCopilotTicketsResult> {
    const ticketIdList: string[] = []
    return await this.SearchTicketWithIdListAction.search({
      utteranceOrResponseOdsSessionIdList: ticketIdList,
      otherOdsSessionIdList: ticketIdList,
      options: options,
      searchId: searchId,
      odsSearchTriggered: false,
    })
  }

  private async searchAIFTickets(
    options: ISearchTicketOptions,
    searchId: string,
  ): Promise<NewSearchCopilotTicketsResult> {
    const request = this.createAIFRequest(options)
    const response = await this.context.api.logCollector.searchAIFTickets(request)
    return {
      searchId,
      ticketCount: response.ticketCount ?? response.aifTickets.length,
      tickets: response.aifTickets.map(NewTicketConverter.fromApi),
      hasMore: response.hasMore,
      errorMessage: response.errorMessage ?? '',
    }
  }

  private createAIFRequest(options: ISearchTicketOptions): ApiTicketsRequest {
    const { from, to } = Times.formatTimeRange(options.range ?? options.defaultRange, { timezone: 'UTC' })

    return {
      Count: options.count,
      Offset: options.offset,
      ClientNames: options.client,
      ScenarioNames: options.channel,
      Rings: options.ring,
      From: from,
      To: to,
      TicketType: ApiTicketType.SearchTicket,
      HasVerbatim: options.hasVerbatim?.length ? 'Yes' : 'All',
      TenantIds: options.tenantIds,
    }
  }
}
