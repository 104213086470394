import { InternalAxiosRequestConfig } from 'axios'
import { z } from 'zod'
import { ApiClient } from '../../client/ApiClient'
import { ApiClientEndpoint } from '../../client/ApiClientEndpoint'
import { ApiClientOptions } from '../../client/ApiClientOptions'

const BASE_URL_PROD = 'https://api.copilotdash.microsoft.com'
const BASE_URL_STAGING = 'https://api.copilotdash.microsoft.com'
const BASE_URL_TEST = 'https://api.copilotdash.microsoft.com'

const SCOPES = ['d97af45c-a676-4654-a778-236750b20726/.default']

export class LogCollectorApiClient extends ApiClient {
  constructor(options: ApiClientOptions) {
    super({
      ...options,
      endpoint: ApiClientEndpoint.CopilotDash,
      config: {},
    })
  }

  protected override async onRequest(config: InternalAxiosRequestConfig<unknown>): Promise<void> {
    const token = await this.config.acquireToken(SCOPES)

    config.baseURL = this.getBaseUrl()
    config.headers.Authorization = `Bearer ${token}`
  }

  private getBaseUrl(): string {
    const endpointUrl = this.config.getServerEndpointUrl()
    if (endpointUrl) {
      const url = z.string().url().safeParse(endpointUrl)
      if (url.success) {
        return endpointUrl
      }
    }

    const ring = this.config.getRing()
    switch (ring) {
      case 'dev':
      case 'test':
        return BASE_URL_TEST
      case 'sdf':
        return BASE_URL_STAGING
      case 'msit':
      case 'unknown':
        return BASE_URL_PROD
    }
  }
}
