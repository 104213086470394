import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  tooltip: {
    backgroundColor: tokens.colorNeutralBackground3,
    boxShadow: tokens.shadow2,
    ...shorthands.padding('16px', '0px', '16px', '16px'),
  },
  tooltipContent: {
    paddingRight: '16px',
    fontSize: tokens.fontSizeBase300,
    lineHeight: tokens.lineHeightBase300,
  },
})
