import {
  ApiBizPerfLogItem,
  ApiEventKustoLogItem,
  ApiGwsLogItem,
  ApiGwsLogItemDiagnostic,
  ApiGwsLogItemDiagnosticData,
  ApiKustoEventLogItemMessage,
  ApiLlmKustoLogItem,
  ApiStateAndDurationKustoLogItem,
  ApiTraceKustoLogItem,
  apiGwsLogItemDiagnosticDataSchema,
  apiGwsLogItemSchema,
  apiKustoEventLogItemMessageSchema,
  parseBase64GzipStringTo,
  parseTo,
} from '@copilot-dash/api'
import {
  IKustoBizPerfLogItem,
  IKustoEventLogItem,
  IKustoGwsLogItem,
  IKustoLLMLogItem,
  IKustoStateDurationLogItem,
  IKustoTraceLogItem,
} from '@copilot-dash/domain'
import { Logger } from '@copilot-dash/logger'
import { z } from 'zod'

export class KustoLogItemConvert {
  static fromApiBizPerfLogItem(api: ApiBizPerfLogItem, logName: string): IKustoBizPerfLogItem {
    return {
      ...api,
      logName: logName,
    }
  }

  static fromApiEventKustoLogItem(api: ApiEventKustoLogItem): IKustoEventLogItem {
    const { tIMESTAMP, ...rest } = api
    let messageObject: ApiKustoEventLogItemMessage | undefined
    try {
      if (api.eventName === 'WebPlusWorkResultInfo') {
        messageObject = parseTo(api.message, apiKustoEventLogItemMessageSchema)
      }
    } catch (e) {
      Logger.trace.error('failed to parse EventKustoLog', e)
    }
    return {
      ...rest,
      timestamp: tIMESTAMP,
      messageObject: messageObject,
    }
  }

  static fromApiGwsLogItem(api: ApiGwsLogItem, isSubstrateSearchExceptionEvent: boolean): IKustoGwsLogItem {
    function decodeString(str?: string): unknown {
      if (!str) {
        return {}
      }

      try {
        return parseBase64GzipStringTo(str, apiGwsLogItemSchema)
      } catch (error) {
        return {
          originalString: str,
          error: String(error),
        }
      }
    }

    function decodeDiagnosticDataString(str?: string): { [key: string]: unknown } {
      if (str) {
        try {
          const decodeDiagnosticData = parseBase64GzipStringTo(str, z.array(apiGwsLogItemDiagnosticDataSchema))
          const diagnosticData = {}
          parseDiagnosticDataRecursive(decodeDiagnosticData, 'XapSearchWorkflowProviderV3', diagnosticData)
          return diagnosticData || {}
        } catch (error) {
          return {
            originalString: str,
            error: String(error),
          }
        }
      }

      return {}
    }

    function parseDiagnosticDataRecursive(
      rawDataArray: ApiGwsLogItemDiagnosticData[],
      targetPN: string,
      parsedData: ApiGwsLogItemDiagnostic,
    ): void {
      rawDataArray.forEach((d) => {
        if (d.PN === targetPN && d.InnerD) {
          const innerData = d.InnerD
          innerData.forEach((plugin) => {
            if (plugin.PN && plugin.DC !== undefined) {
              // check the type of plugin.DC
              if (typeof plugin.DC === 'string') {
                try {
                  const fixedJson = plugin.DC.replace(/,\s*([}\]])/g, '$1')
                  const parsedObject = JSON.parse(fixedJson)
                  parsedData[plugin.PN] = parsedObject
                } catch (error) {
                  // If parsing fails for plugin.DC, we assign the raw value.
                  parsedData[plugin.PN] = plugin.DC
                }
              } else if (typeof plugin.DC === 'object') {
                // Recursively call the function for nested InnerD
                const nestedParsedData: ApiGwsLogItemDiagnostic = {}
                parseDiagnosticDataRecursive([plugin], plugin.PN, nestedParsedData)
                parsedData[plugin.PN] = nestedParsedData
              }
            }
          })
        }
      })
    }

    return {
      ...api,
      diagnosticData: decodeDiagnosticDataString(api.diagnosticData),
      responseMetaJson: decodeString(api.responseMetaJson),
      isSubstrateSearchExceptionEvent,
    }
  }

  static fromApiLlmKustoLogItem(api: ApiLlmKustoLogItem): IKustoLLMLogItem {
    return api
  }

  static fromApiStateAndDurationKustoLogItem(api: ApiStateAndDurationKustoLogItem): IKustoStateDurationLogItem {
    const { tIMESTAMP, ...rest } = api
    return {
      ...rest,
      timestamp: tIMESTAMP,
    }
  }

  static fromApiTraceKustoLogItem(api: ApiTraceKustoLogItem): IKustoTraceLogItem {
    const { tIMESTAMP, ...rest } = api
    return {
      ...rest,
      timestamp: tIMESTAMP,
    }
  }
}
