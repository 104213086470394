import { IKustoStateDurationLogItem } from '@copilot-dash/domain'

export interface IState {
  readonly ticketId: string
  readonly selectedTurnId?: string
  readonly selectedScopeId?: string
  readonly selectedTypes: Array<CallFlowNodeType>
}

export type CallFlowNode = {
  readonly id: string
  readonly label: string
  readonly type: CallFlowNodeType
  readonly turnId: string
  readonly item: IKustoStateDurationLogItem
}

export type CallFlowEdge = {
  readonly source: CallFlowNode
  readonly target: CallFlowNode
}

export enum CallFlowNodeType {
  Auth = 'Auth',
  Storage = 'Storage',
  LLM = 'LLM',
  SSS = 'SSS',
  Message = 'Message',
  Extension = 'Extension',
  Other = 'Other',
}

export const CallFlowNodeTypeLabels = {
  [CallFlowNodeType.Auth]: 'Auth',
  [CallFlowNodeType.LLM]: 'LLM',
  [CallFlowNodeType.SSS]: '3S',
  [CallFlowNodeType.Storage]: 'Storage',
  [CallFlowNodeType.Message]: 'Message',
  [CallFlowNodeType.Extension]: 'Extension',
  [CallFlowNodeType.Other]: 'Other',
} as const satisfies Record<CallFlowNodeType, string>

export const CallFlowNodeTypeFlags = {
  [CallFlowNodeType.Auth]: [
    'Auth', //
    'FirstToken',
    'LastToken',
    'LicenseValidator',
    'SecureChatHubAuth',
    'TuringBot.ChatHub',
  ],
  [CallFlowNodeType.LLM]: [
    'LLM', //
    'PolymerLLM',
  ],
  [CallFlowNodeType.SSS]: [
    'SubstrateRestContentProvider', //
    'SubstrateSearchService',
  ],
  [CallFlowNodeType.Storage]: [
    'ConversationObjectStore', //
    'ConversationStorage',
    'ConversationSdsStorage',
    'ContentProviderCache',
  ],
  [CallFlowNodeType.Message]: [
    'StreamMessage', //
  ],
  [CallFlowNodeType.Extension]: [
    'ExtensionRunner', //
  ],
  [CallFlowNodeType.Other]: [
    'DeepLeoEngine', //
    'InitiateConversationDetector',
    'ExactMatchResponseBlocklist',
    'ChatApiImplementation_ResponseReturned',
  ],
} as const satisfies Record<CallFlowNodeType, string[]>

const getCallFlowNodeTypeColors = (alpha: number = 1.0) => {
  return {
    [CallFlowNodeType.Auth]: `rgba(255, 165, 0, ${alpha})`,
    [CallFlowNodeType.LLM]: `rgba(60, 179, 113, ${alpha})`,
    [CallFlowNodeType.SSS]: `rgba(255, 69, 0, ${alpha})`,
    [CallFlowNodeType.Storage]: `rgba(30, 144, 255, ${alpha})`,
    [CallFlowNodeType.Message]: `rgba(138, 43, 226, ${alpha})`,
    [CallFlowNodeType.Extension]: `rgba(165, 42, 42, ${alpha})`,
    [CallFlowNodeType.Other]: `rgba(211, 211, 211, ${alpha})`,
  } as const satisfies Record<CallFlowNodeType, string>
}

export const CallFlowNodeTypeColors = getCallFlowNodeTypeColors(1.0)
export const CallFlowNodeTypeColorsAlpha = getCallFlowNodeTypeColors(0.6)
export const CallFlowNodeTypeColorsAlpha2 = getCallFlowNodeTypeColors(0.3)
