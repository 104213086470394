import { Logger } from '@copilot-dash/logger'
import { ZodType } from 'zod'
import { IDashSettingStore } from './IDashSettingStore'

interface IParams<T> {
  readonly key: string
  readonly defValue: T
  readonly schema: ZodType<T>
}

export class DashSettingStore<T> implements IDashSettingStore<T> {
  private readonly subscribers: Set<(value: T) => void> = new Set()

  private readonly key: string
  private readonly schema: ZodType<T>

  private default: T
  private local: T | null

  constructor(params: IParams<T>) {
    this.key = params.key
    this.schema = params.schema

    this.default = params.defValue
    this.local = this.read()
  }

  getValue(): T {
    if (this.local !== null) {
      return this.local
    }

    return this.default
  }

  setValue(value: T) {
    if (value === this.local) {
      return
    }

    const oldFinalValue = this.getValue()
    this.local = value
    this.write(value)

    if (oldFinalValue !== value) {
      this.publish(value)
    }
  }

  setDefaultValue(value: T): void {
    if (value === this.default) {
      return
    }

    const oldFinalValue = this.getValue()
    this.default = value

    const newFinalValue = this.getValue()
    if (oldFinalValue !== newFinalValue) {
      this.publish(newFinalValue)
    }
  }

  subscribe(subscriber: (value: T) => void): () => void {
    this.subscribers.add(subscriber)

    return () => {
      this.subscribers.delete(subscriber)
    }
  }

  private read(): T | null {
    const raw = localStorage.getItem(this.key)
    if (raw === null) {
      return null
    }

    try {
      return this.schema.parse(JSON.parse(raw))
    } catch (e) {
      return null
    }
  }

  private write(value: T): void {
    if (value === undefined || value === null) {
      localStorage.removeItem(this.key)
      return
    }

    try {
      localStorage.setItem(this.key, JSON.stringify(value))
    } catch (e) {
      Logger.trace.warn(`Failed to write setting "${this.key}" to local storage`, e)
    }
  }

  private publish(value: T): void {
    this.subscribers.forEach((subscriber) => subscriber(value))
  }
}
