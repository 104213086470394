import { DashRing } from '@copilot-dash/core'
import { z } from 'zod'
import { DashSettingStore } from './framework/DashSettingStore'
import { IDashSettingStore } from './framework/IDashSettingStore'
import { IDashSettingsForFlights } from './IDashSettingsForFlights'

export function createDashSettingsForFlights(ring: DashRing): IDashSettingsForFlights {
  return {
    ExperimentalFeatures: create({
      key: 'ExperimentalFeatures',
      rings: ['dev', 'test', 'sdf'],
    }),

    EnableTicketBasicInfo: create({
      key: 'EnableTicketBasicInfo',
      rings: ['dev'],
    }),

    EnableMcpExperimentFeedbackBoard: create({
      key: 'EnableMcpExperimentFeedbackBoard',
      rings: ['dev'],
    }),

    EnableTicketCallFlow: create({
      key: 'EnableTicketCallFlow',
      rings: ['dev', 'test'],
    }),

    EnableSliceIdsOptionsSetsFilter: create({
      key: 'EnableSliceIdsOptionsSetsFilter',
      rings: ['dev', 'test'],
    }),
  }

  function create(params: { key: string; rings: Exclude<DashRing, 'unknown'>[] }): IDashSettingStore<boolean> {
    const defValue = ring === 'unknown' ? false : params.rings.includes(ring)

    return new DashSettingStore({
      key: 'dash_flight_' + params.key,
      defValue,
      schema: z.boolean(),
    })
  }
}
