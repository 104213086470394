import { Text, mergeClasses } from '@fluentui/react-components'
import { FC, useMemo } from 'react'
import { Row, Spacer, Column } from '../../../Layout'
import { useStyles } from './SatusField.styles'
import { IActivityHistory, TicketIssueData, ActivityHistoryRootCauseContext } from '@copilot-dash/domain'

interface IProps {
  readonly activity?: IActivityHistory
}

export const TeamFieldChangeRow: FC<IProps> = ({ activity }) => {
  const styles = useStyles()
  const isChange = useMemo(() => {
    return !!(activity?.fieldDiffs && activity?.fieldDiffs['teamArea'] && activity?.fieldDiffs['teamArea'].newValue)
  }, [activity])

  const renderValue = (
    value: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined,
  ) => {
    if (typeof value === 'string') {
      return value
    }
    return null
  }

  const newValue = useMemo(() => renderValue(activity?.fieldDiffs?.['teamArea']?.newValue), [activity])
  const oldValue = useMemo(() => renderValue(activity?.fieldDiffs?.['teamArea']?.oldValue), [activity])

  if (!isChange) return null

  return (
    <Row>
      <Column className={styles.columnContainer}>
        <Text className={styles.statueKey}>Team:</Text>
      </Column>
      {newValue && (
        <Text className={styles.tag} title={newValue}>
          {newValue}
        </Text>
      )}
      <Spacer width="4px" />
      {oldValue && (
        <Text className={mergeClasses(styles.tag, styles.deleted)} title={oldValue}>
          {oldValue}
        </Text>
      )}
    </Row>
  )
}
