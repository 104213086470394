import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  ComboboxFilter: {
    maxHeight: '240px',
  },
  loading: {
    height: '100px',
  },
})
