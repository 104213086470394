import { memo, useEffect, useMemo, useState } from 'react'
import { Text } from '@fluentui/react-components'
import { Column, Row, Spacer } from '../../../../../../components/Layout'
import { ResizableColumnsTable } from '../../../../../../components/Table/ResizableColumnsTable'
import { useSearchScreenActions, useSearchScreenState } from '../../../../store'
import { AllSearchTableColumns } from '../../../../../../components/TicketTableColumnConfigs'
import { useUserSetting } from '@copilot-dash/settings'
import { SearchRoute } from '../../../../../../router'
import { TimeRange, Times } from '@copilot-dash/core'
import { INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { LoaderComponent } from '../../../loader/LoaderComponent'

export const VIPUserDSATs = memo(() => {
  const [tableItems, setTableItems] = useState<INewTicketData[]>([])
  const count = tableItems.length
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)

  const [columns] = useUserSetting('feedbackInsightsVipDSATsColumns')
  const selectedColumns = useMemo(() => {
    const allColumns = AllSearchTableColumns(SearchTextPrefixType.All)
    return columns.map((column) => allColumns.find((c) => c.columnId === column)!).filter((c) => c)
  }, [columns])

  const [loading, setLoading] = useState(true)
  const args = SearchRoute.navigator.useArgsOptional()
  const batchList = application.store.use((state) => state.topIssue.batchList)

  const range = useMemo((): TimeRange | undefined => {
    const batchInfo = batchList?.data?.find((item) => item.batchId === args?.issueBatchId)
    if (batchInfo) {
      const from = Times.startOfDay(batchInfo.from, 'UTC')
      const to = Times.endOfDay(batchInfo.to, 'UTC')
      return {
        type: 'absolute',
        from,
        to,
      }
    } else {
      return undefined
    }
  }, [args?.issueBatchId, batchList])

  useEffect(() => {
    if (!range) {
      return
    }

    setLoading(true)
    app.store.actions
      .searchTickets({
        // defaultRange
        defaultRange: range,
        count: 500,
        offset: 0,
        isAIF: false,
        customerTypes: ['VIP Power User'],
        thumbs: ['Negative'],
      })
      .then((resp) => {
        setTableItems(resp.tickets || [])
      })
      .finally(() => {
        setLoading(false)
      })
  }, [range])

  return (
    <Column>
      <Row>
        <Text weight="semibold" size={500}>
          VIP User DSATs ({count})
        </Text>
      </Row>
      <Spacer height={22} />
      <Row>
        {loading ? (
          <Column vAlign="center" fill>
            <Spacer height={50} />
            <LoaderComponent />
          </Column>
        ) : (
          <ResizableColumnsTable
            items={tableItems}
            columns={selectedColumns}
            keyPicker={(data) => data.ticketId}
            supportLoadMore={false}
            enableColumnResizing={true}
            rowClick={actions.rowClick}
            selectedRowId={ticketId}
          />
        )}
      </Row>
    </Column>
  )
})
VIPUserDSATs.displayName = 'VIPUserDSATs'
