import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  button: {
    borderRadius: '0%',
    borderRight: '0px',
    borderLeft: '0px',
    borderTop: '0px',
    textAlign: 'left',
    fontWeight: tokens.fontWeightRegular,
    padding: '0px',
    marginTop: '8px',
    marginLeft: '0px',
  },
  selected: {
    ':hover': {
      cursor: 'pointer',
    },
  },
})
