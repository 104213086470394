import { Toast, ToastBody, ToastTitle, useToastController } from '@fluentui/react-components'
import { uniqueId } from 'lodash'
import * as React from 'react'

export const toasterId = uniqueId('toaster-')

export const useToast = () => {
  const { dispatchToast } = useToastController(toasterId)

  const showInfo = React.useCallback(
    (title?: string, message?: string) => {
      dispatchToast(
        <Toast>
          {title && <ToastTitle>{title}</ToastTitle>}
          {message && <ToastBody>{message}</ToastBody>}
        </Toast>,
        { intent: 'info' },
      )
    },
    [dispatchToast],
  )

  const showWarning = React.useCallback(
    (title?: string, message?: string) => {
      dispatchToast(
        <Toast>
          {title && <ToastTitle>{title}</ToastTitle>}
          {message && <ToastBody>{message}</ToastBody>}
        </Toast>,
        { intent: 'warning' },
      )
    },
    [dispatchToast],
  )

  const showError = React.useCallback(
    (title?: string, message?: string) => {
      dispatchToast(
        <Toast>
          {title && <ToastTitle>{title}</ToastTitle>}
          {message && <ToastBody>{message}</ToastBody>}
        </Toast>,
        { intent: 'error' },
      )
    },
    [dispatchToast],
  )

  const showSuccess = React.useCallback(
    (title?: string, message?: string) => {
      dispatchToast(
        <Toast>
          {title && <ToastTitle>{title}</ToastTitle>}
          {message && <ToastBody>{message}</ToastBody>}
        </Toast>,
        { intent: 'success' },
      )
    },
    [dispatchToast],
  )

  return {
    showInfo,
    showWarning,
    showError,
    showSuccess,
  }
}
