import { useTicketKustoEventLog } from '@copilot-dash/store'
import { TableCell, TableRow, Text } from '@fluentui/react-components'
import { useMemo } from 'react'
import { SymptomReportHealthLabel } from './SymptomReportHealthLabel'

const LICENSE_SCOPE_NAME = 'LicenseValidator'
const LICENSE_MESSAGE_SUCCESS = 'LicenseValidator: [Success]'

type Status = 'OK' | 'Error' | 'Unknown'

interface IProps {
  readonly ticketId: string
  readonly turnId: string
}

export function RowForPreChecks({ ticketId, turnId }: IProps) {
  const eventLogSnapshot = useTicketKustoEventLog({ ticketId, messageId: turnId })
  const eventLog = eventLogSnapshot.data

  const licenseStatus: Status = useMemo(() => {
    if (!eventLog) {
      return 'Unknown'
    }

    return eventLog.some((item) => {
      return item.scopeName === LICENSE_SCOPE_NAME && item.message.includes(LICENSE_MESSAGE_SUCCESS)
    })
      ? 'OK'
      : 'Error'
  }, [eventLog])

  if (licenseStatus !== 'Error') {
    return null
  }

  return (
    <TableRow>
      <TableCell>
        <Text>Pre-checks</Text>
      </TableCell>
      <TableCell>
        {/* 1. License */}
        {licenseStatus === 'Error' && (
          <span>
            <span>License: </span>
            <SymptomReportHealthLabel status="error" />
          </span>
        )}
      </TableCell>
    </TableRow>
  )
}
