import { useMemo, FC } from 'react'
import { Text } from '@fluentui/react-components'
import { CopilotDashTooltip } from '../../../../../components/CopilotDashTooltip/CopilotDashTooltip'
import { Column } from '../../../../../components/Layout'
import { CopilotDashTag } from '../../../../../components/Tag/CopilotDashTag'
import { useStyles } from './InsightsTag.styles'

interface IProps {
  isTagClearable: boolean
  tagKey: string
  toolTipContentValue?: string | React.ReactNode
  truncatedTagValue: string | React.ReactNode
  className?: string
}

export const InsightsTag: FC<IProps> = ({
  isTagClearable,
  tagKey,
  toolTipContentValue,
  truncatedTagValue,
  className,
}) => {
  const styles = useStyles()
  const tagContent = useMemo(() => {
    if (toolTipContentValue) {
      return (
        <CopilotDashTooltip
          relationShip="label"
          toolTipContent={
            <Column className={styles.tooltipContent}>
              <Text>{toolTipContentValue}</Text>
            </Column>
          }
          className={styles.tooltip}
          toolTipParentContent={
            <Text truncate wrap={false} className={styles.textStyle}>
              {truncatedTagValue}
            </Text>
          }
        />
      )
    }
    return (
      <Text truncate wrap={false} className={styles.textStyle}>
        {truncatedTagValue}
      </Text>
    )
  }, [toolTipContentValue, truncatedTagValue, styles.textStyle, styles.tooltip, styles.tooltipContent])
  return (
    <CopilotDashTag
      isTagClearable={isTagClearable}
      tagValue={tagKey}
      tagKey={tagKey}
      tagContent={tagContent}
      className={className}
    />
  )
}
