import { Times } from '@copilot-dash/core'
import { DashErrorLevel } from '../core/DashErrorLevel'
import { TicketErrorDefinition } from './TicketErrorDefinition'
import { TicketErrorSource } from './TicketErrorSource'

export const TicketErrorDefinitions = {
  /**
   * No user consent
   */
  NoUserConsentGiven: {
    code: 100,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No consent given',
    description: 'User Consent was not given for this ticket to collect diagnostic data.',
  },

  /**
   * No Ticket
   */
  NoTicket: {
    code: 101,
    level: DashErrorLevel.Warning,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No ticket found',
    description:
      'Please check back later. Log files may take up to 1 hour to become available. Possible reasons: the ticket may have expired, is not yet available, or does not exist.',
  },

  /**
   * No Utterance
   */
  NoUtterance: {
    code: 102,
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No utterance found',
    description: 'No utterance found in this ticket.',
  },

  /**
   * No Response
   */
  NoResponse: {
    code: 103,
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No response found',
    description: 'No response found in this ticket. Please contact the team for further investigation.',
  },

  /**
   * No 3S online
   */
  No3sOnline: {
    code: -1,
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No 3S online data found',
    description: 'No 3S online data found in this ticket. Please contact the team for further investigation.',
  },

  /**
   * No Verbatim
   */
  NoVerbatim: {
    code: 104,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.User,
    message: 'No verbatim given',
    description: 'User did not provide verbatim for this ticket.',
  },

  /**
   * No Verbatim
   */
  NoVerbatimDueToBackfill: {
    code: 106,
    level: DashErrorLevel.Warning,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'Not available',
    description: `The verbatim is not available for current ticket due to backfill failure.`,
  },

  /**
   * No Email
   */
  NoEmail: {
    code: 107,
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No email found',
    description: 'No email found for this ticket.',
  },

  /**
   * No Email
   */
  NoEmailDueToTenantNotInScope: {
    code: 108,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'N/A',
    description: 'Tenant not in scope of MSIT.',
  },

  /**
   * No Conversation
   */
  NoConversations: {
    code: 109,
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No conversation found',
    description: 'This ticket does not have conversation history.',
  },

  /**
   * No Turn/Interaction
   */
  NoConversationDueToMessageIdNotFound: {
    code: 110,
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No conversation found',
    description: 'No conversation found for the given message ID.',
  },

  /**
   * No Kusto
   */
  NoKusto: {
    code: 111,
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No Kusto data found',
    description: 'No Kusto data found for this ticket.',
  },

  /**
   * No Kusto
   */
  NoKustoEventLogDueToAvalon: {
    code: 111.5,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No Kusto data found (EventLog)',
    description: 'EventLog is not supported for Avalon tickets.',
  },

  /**
   * No Kusto
   */
  NoKustoDueToExpired: {
    code: 112,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No Kusto data found',
    description: 'Data in Kusto has expired (30 days retention for now).',
  },

  /**
   * No Kusto
   */
  NoKustoDueToInteractionFieldIsMissing: {
    code: 113,
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No Kusto data found',
    description: 'Missing interaction field in ODS metadata.',
  },

  /**
   * No Kusto Data (3S)
   */
  No3SData: {
    code: 114,
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No 3S diagnostic data found',
    description: 'This ticket does not have 3S diagnostic data',
  },

  /**
   * No Kusto Data (3S)
   */
  No3SDataDueToAifTicket: {
    code: 114,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No 3S diagnostic data found',
    description: '3S diagnostic data is currently not collected for Admin-Initiated feedback',
  },

  /**
   * No Kusto Data (3S)
   */
  No3SDataDueToExpired: {
    code: 115,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No 3S diagnostic data found',
    description: '3S diagnostic data has expired (28 days retention for now)',
  },
  /**
   * No Extensibility Log
   */
  NoExtensibilityLog: {
    code: 115,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No extensibility log',
    description: 'This ticket does not have extensibility log',
  },

  /**
   * No 3S offline data
   */
  No3sOffline: {
    code: 116,
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No 3S offline data found',
    description: 'Failed to fetch the 3S offline data due to some service/pipeline issue.',
  },

  /**
   * No 3S offline data
   */
  No3sOfflineDueToNotLatestTurn: {
    code: 117,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No 3S offline data found',
    description: '3S Offline data is only available for the latest turn of the conversation.',
  },

  /**
   * No 3S offline data
   */
  No3sOfflineDueTo6Hours: {
    code: 118,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No 3S diagnostic data found',
    description: (extra) => {
      const remainingRelativeTime = Times.remainingRelativeTime(extra.ticketCreationTime, 6)
      if (remainingRelativeTime) {
        return `3S diagnostic data is not available yet. It could take up to 6h for the offline data to be uploaded, please come back ${remainingRelativeTime} later.`
      }

      return '3S diagnostic data is not available yet. It could take up to 6h for the offline data to be uploaded, please come back later.'
    },
  },

  /**
   * No 3S offline data
   */
  No3sOfflineDueTo48Hours: {
    code: 119,
    level: DashErrorLevel.Warning,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No 3S offline data found',
    description: (extra) => {
      const remainingRelativeTime = Times.remainingRelativeTime(extra.ticketCreationTime, 48)
      if (remainingRelativeTime) {
        return `Service is retrying to fetch the offline data. Please come back ${remainingRelativeTime} later.`
      }

      return 'Something went wrong for fetching the offline data, service is retrying. Please come back later.'
    },
  },

  /**
   * No 3S data
   */
  No3S: {
    code: 150,
    level: DashErrorLevel.Error,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'No substrate search data found',
    description: 'Failed to fetch the substrate search data due to some service/pipeline issue.',
  },

  /**
   * No 3S data
   */
  No3SDueToNoNotTriggered: {
    code: 150,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No need to call 3S',
  },

  /**
   * Before 180 days
   */
  Before180Days: {
    code: 120,
    level: DashErrorLevel.Fine,
    source: TicketErrorSource.ByDesign,
    message: 'No data found',
    description: 'Ticket has expired due to over 180 days old.',
  },

  /**
   * Before March 18, 2024 due to an ODS incident
   */
  BeforeMarch182024: {
    code: 121,
    level: DashErrorLevel.Warning,
    source: TicketErrorSource.ODS,
    message: 'No data found',
    description:
      'Tickets for dates before March 18, 2024, are unavailable due to the ODS incident. Please check details at https://portal.microsofticm.com/imp/v5/incidents/details/513646667',
  },

  /**
   * Confidential Data contained
   */
  ConfidentialDataContained: {
    code: 122,
    level: DashErrorLevel.Warning,
    source: TicketErrorSource.ByDesign,
    message: 'Feedback Data Redacted',
    description:
      'Feedback data contains confidential information and cannot be displayed. If you have any questions, please contact the feedback team by submitting a feedback request.',
  },

  /**
   * DSR Ticket
   */
  DSRTicket: {
    code: 123,
    level: DashErrorLevel.Warning,
    source: TicketErrorSource.FeedbackPlatform,
    message: 'Ticket deleted prescriptively',
    description: 'This ticket was deleted in accordance with Data Subject Request(DSR).',
  },
} as const satisfies Record<string, TicketErrorDefinition>
