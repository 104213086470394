import { useCallback, useSyncExternalStore } from 'react'
import { IDashSettingStore } from '../framework/IDashSettingStore'

export function useDashSettingStore<T>(store: IDashSettingStore<T>): [value: T, setValue: (value: T) => void] {
  // 1. Listen
  const subscribe = useCallback(
    (onChanged: () => void) => {
      return store.subscribe(() => {
        onChanged()
      })
    },
    [store],
  )

  // 2. Get
  const getValue = useCallback(() => {
    return store.getValue()
  }, [store])
  const value = useSyncExternalStore(subscribe, getValue)

  // 3. Set
  const setValue = useCallback(
    (value: T) => {
      store.setValue(value)
    },
    [store],
  )

  return [value, setValue]
}
