import { $getRoot, EditorState, LexicalEditor } from 'lexical'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { Divider } from '@fluentui/react-components'
import { ToolbarPlugin } from './plugins/ToolbarPlugin/ToolbarPlugin'
import { MentionsPlugin } from './plugins/MentionsPlugin/MentionsPlugin'
import { ListItemNode, ListNode } from '@lexical/list'
import { QuoteNode } from '@lexical/rich-text'
import { LinkNode } from '@lexical/link'
import { MentionNode } from './nodes/MentionNode'
import { $generateHtmlFromNodes } from '@lexical/html'
import { useStyles } from './LexicalRichText.styles'
import CustomTheme from './CustomTheme'

interface IProps {
  sentDone: boolean
  isEdit: boolean
  commentValue?: string | undefined
  onCommentValueChange: (value: string | undefined) => void
}

export function LexicalRichTextEditor({ sentDone, isEdit, commentValue, onCommentValueChange }: IProps) {
  const styles = useStyles()
  const placeholder = 'Add a comment'
  const editorConfig = {
    namespace: 'CopilotDash rich text',
    nodes: [ListNode, ListItemNode, LinkNode, QuoteNode, MentionNode],
    onError(error: Error) {
      throw error
    },
    theme: CustomTheme,
  }

  const onChange = (editorState: EditorState, editor: LexicalEditor, tags: Set<string>) => {
    editor.read(() => {
      const root = $getRoot()
      if (root.getChildren().length === 0 || root.getTextContent().trim() === '') {
        onCommentValueChange('')
      } else {
        const htmlString = $generateHtmlFromNodes(editor)
        onCommentValueChange(htmlString)
      }
    })
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <div className="lexical">
        <ToolbarPlugin sentDone={sentDone} isEdit={isEdit} value={commentValue} />
        <Divider className={styles.divider} />
        <div className={styles.editorInner}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className={styles.editorInput}
                aria-placeholder={placeholder}
                placeholder={<div className={styles.editorPlaceholder}>{placeholder}</div>}
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <AutoFocusPlugin />
          <ListPlugin />
          <OnChangePlugin onChange={onChange} />
          <MentionsPlugin />
        </div>
      </div>
    </LexicalComposer>
  )
}
