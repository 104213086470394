import { ApiCreateRootCauseRequest, ApiRootCauseContext, ApiUpdateRootCauseRequest } from '@copilot-dash/api'
import { ICreateRootCause, ITicketRootCauseItem, IUpdateRootCause } from '@copilot-dash/domain'

export class RootCauseDataConverter {
  static fromApi(api: ApiRootCauseContext): ITicketRootCauseItem {
    return api
  }

  static postDataToApi(data: ICreateRootCause): ApiCreateRootCauseRequest {
    return {
      Title: data.title,
      Description: data.description,
      Priority: data.priority,
      // TeamName: data.teamName,
      TeamId: data.teamId,
      VsoAccount: data.vsoAccount,
      ProductId: data.productId,
    }
  }

  static updateDataToApi(data: IUpdateRootCause): ApiUpdateRootCauseRequest {
    return {
      Title: data.title,
      Description: data.description,
      Priority: data.priority,
      RootCauseStatus: data.rootCauseStatus,
      // TeamName: data.teamName,
      TeamId: data.teamId,
      VsoAccount: data.vsoAccount,
      WorkItemId: data.workItemId,
      Owner: data.owner,
      OriginalCommittedDate: data.originalCommittedDate,
      ProductId: data.productId,
    }
  }
}
