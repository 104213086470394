import {
  ApiAreaListAndRootCauseListResponse,
  ApiIssueClusteringBatchInfoListResponse,
  ApiIssueClusteringInfoListResponse,
  ApiTeamListResponse,
  ApiTeamNameListResponse,
} from '@copilot-dash/api'
import {
  AreaPathListWithRootCauseList,
  ITeamList,
  ITeamNameList,
  ITopIssueList,
  ITopIssuesBatchInfoList,
} from '@copilot-dash/domain'

export class TeamViewDataConverter {
  static fromApiTeamNameListResponse(apiTeamNameListResponse: ApiTeamNameListResponse): ITeamNameList {
    return apiTeamNameListResponse.teamNamesList
  }

  static fromApiTeamListResponse(apiTeamListResponse: ApiTeamListResponse): ITeamList {
    return apiTeamListResponse.teamList.map((item) => {
      return {
        name: item.teamName,
        id: item.teamId,
      }
    })
  }

  static fromApiAreaListWithRootCauseListResponse(
    apiAreaListWithRootCauseListResponse: ApiAreaListAndRootCauseListResponse,
  ): AreaPathListWithRootCauseList {
    return (apiAreaListWithRootCauseListResponse.areaPathAndRootCauseList ?? []).map((item) => {
      return {
        areaPath: item.teamArea || item.areaPath || '', // First, use v2 interface fields, then fallback to v1 interface fields
        rootCauseList: item.rootCauseList,
      }
    })
  }

  static fromApiTopIssuesBatchInfoListResponse(
    apiTopIssuesBatchInfoListResponse: ApiIssueClusteringBatchInfoListResponse,
  ): ITopIssuesBatchInfoList {
    return apiTopIssuesBatchInfoListResponse.clusteringBatchInfoList.map((item) => ({
      batchId: item.batchId,
      from: item.from,
      to: item.to,
    }))
  }

  static fromApiTopIssueClusteringInfoListResponse(
    apiTopIssueClusteringInfoListResponse: ApiIssueClusteringInfoListResponse,
  ): ITopIssueList {
    return apiTopIssueClusteringInfoListResponse.issueClusteringInfoList.map((item) => ({
      issueId: item.issueId,
      issueTitle: item.issueTitle,
      priority: item.priority,
      coverage: item.coverage,
      trends: item.trends,
      isNew: item.isNew,
      ticketCount: item.ticketCount,
      impactedTenantsCount: item.impactedTenantsCount,
    }))
  }
}
