import { DrawerBody, DrawerHeader, Drawer, Button, Text, Spinner } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'
import { useStyles } from './ActivityHistoryPanelLayout.styles'
import { useActivityHistoryPanelStore } from './store/store'
import { ActivityHistoryBody } from './children/ActivityHistoryBody'
import { Row, Spacer } from '../Layout'
import { ErrorView } from '../Error'
import { ActivityHistoryEmpty } from './children/ActivityHistoryEmpty'
import { useScrollbar } from '../Scrollbar/useScrollbar'
interface IProps {
  readonly isPanelOpen: boolean
  readonly setIsPanelOpen: () => void
}

export function ActivityHistoryPanelLayout({ isPanelOpen, setIsPanelOpen }: IProps) {
  const styles = useStyles()
  const activityHistorySnapshot = useActivityHistoryPanelStore((state) => state.activityHistory)
  const scrollbarRef = useScrollbar()

  return (
    <Drawer className={styles.card} separator open={isPanelOpen} modalType="non-modal" position="end">
      <DrawerHeader className={styles.drawerHeader}>
        <Row vAlign="center" fill>
          <Button appearance="subtle" aria-label="Close" icon={<DismissRegular />} onClick={() => setIsPanelOpen()} />
          <Text className={styles.title}>History</Text>
          <Spacer />
        </Row>
      </DrawerHeader>
      <DrawerBody ref={scrollbarRef} className={styles.body}>
        {(() => {
          switch (activityHistorySnapshot.status) {
            case 'waiting':
              return (
                <Row className={styles.waiting} fill vAlign="center" hAlign="center">
                  <Spinner label="Loading..." />
                </Row>
              )
            case 'error':
              return <ErrorView error={activityHistorySnapshot.error} />
            case 'done':
              if (activityHistorySnapshot.data.length === 0) {
                return <ActivityHistoryEmpty />
              }
              return <ActivityHistoryBody />
            default:
              return null
          }
        })()}
      </DrawerBody>
    </Drawer>
  )
}
