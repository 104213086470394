import { IDashSettings } from '../IDashSettings'
import { DashSettingsContext } from './DashSettingsContext'

interface IProps {
  readonly settings: IDashSettings
  readonly children: React.ReactNode
}

export function DashSettingsProvider(props: IProps) {
  return <DashSettingsContext.Provider value={props.settings}>{props.children}</DashSettingsContext.Provider>
}
