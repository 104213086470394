import { useUserSetting } from '@copilot-dash/settings'
import { Switch, SwitchProps, Tooltip } from '@fluentui/react-components'
import { ReactNode } from 'react'

export function HeaderPrivacySwitch(): ReactNode {
  const [checked, setChecked] = useUserSetting('privacyModeEnabled')

  const onChange: SwitchProps['onChange'] = (ev) => {
    Logger.telemetry.trackEvent('SwitchPrivacy', { checked: ev.currentTarget.checked })
    setChecked(ev.currentTarget.checked)
  }

  return (
    <Tooltip content="Mask sensitive data before share screen" relationship="label">
      <Switch label="Mask" onChange={onChange} checked={checked} />
    </Tooltip>
  )
}
