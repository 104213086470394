import { useFeatureFlight } from '@copilot-dash/settings'
import { Button, Tooltip } from '@fluentui/react-components'
import { CloudBeakerRegular } from '@fluentui/react-icons'
import { SettingsRoute } from '../../../../router'

export function HeaderLaborButton() {
  const [isEnabled] = useFeatureFlight('ExperimentalFeatures')
  if (!isEnabled) {
    return null
  }

  const handleClick = () => {
    SettingsRoute.navigateToModal()
  }

  return (
    <Tooltip content={'Experimental Features'} relationship={'label'}>
      <Button appearance="subtle" icon={<CloudBeakerRegular />} onClick={handleClick} />
    </Tooltip>
  )
}
