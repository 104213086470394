import { z } from 'zod'

export enum ApiTicketRequestType {
  ByTraceId = 0,
  ByUserId = 1,
  ByClientRequestId = 2,
  ByCortanaTraceId = 3,
  BySpeechTraceId = 4,
  GetUxoBasicLog = 5,
  GetSpeechLog = 6,
  GetCortexMdsLog = 9,
  ByLogicalId = 10,
  GetPersonalGrammar = 11,
  GetSkillLog = 12,
  GetUxoTraceLog = 13,
  GetGrammarFetcherLog = 14,
  UpdateSpeechTraceId = 15,
  GetLURuntimeParams = 16,
  GetSuggestionResponseLog = 17,
  GetCortexServiceLog = 18,
  GetCortexLog = 19,
  ByTicketId = 20,
  ByExternalTicketId = 21,
  ByCorrelationId = 22,
  ByQueryId = 23,
  GetIcmRule = 24,
  GetDocKPERule = 25,
  ByTeeRequestId = 26,
  ByCortanaClientRequestId = 27,
  BySessionId = 28,
  ByCorrelationVector = 29,
  None = 30,
  BySydneyMessageId = 31,
  ByLLMCorrelationVector = 32,
}

export const apiRequestTypeSchema = z.nativeEnum(ApiTicketRequestType)
