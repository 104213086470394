import { PromiseSnapshots } from '@copilot-dash/core'
import { useTicketKustoEventLog } from '@copilot-dash/store'
import { TableCell, TableRow, Text } from '@fluentui/react-components'
import { useMemo } from 'react'
import { ErrorView } from '../../Error'
import { SymptomReportLoadingIcon } from './SymptomReportLoadingIcon'

interface IProps {
  readonly ticketId: string
  readonly turnId: string
}

export function RowForRetrieval3S({ ticketId, turnId }: IProps) {
  const ticket = application.store.use.getTicket(ticketId).data
  const tag = ticket?.tag

  // Get countWorkResults from the event log
  const eventLogSnapshot = useTicketKustoEventLog({ ticketId, messageId: turnId })
  const countWorkResultsSnapshot = useMemo(() => {
    return PromiseSnapshots.map(eventLogSnapshot, (data) => {
      return data.find((item) => {
        return item.eventName === 'WebPlusWorkResultInfo'
      })?.messageObject?.CountWorkResults
    })
  }, [eventLogSnapshot])

  if (!tag) {
    return null
  }

  const renderContent = () => {
    if (!tag.sssTriggered) {
      return '3S is not triggered'
    }

    return (
      <span>
        <span>3S is triggered </span>
        {(() => {
          if (ticket.isAvalon) {
            return
          }

          switch (countWorkResultsSnapshot.status) {
            case 'waiting':
              return <SymptomReportLoadingIcon />
            case 'done':
              if (countWorkResultsSnapshot.data) {
                return `, CountWorkResults: ${countWorkResultsSnapshot.data}`
              } else {
                return null
              }

            case 'error':
              return <ErrorView type="inline" error={countWorkResultsSnapshot.error} />
          }
        })()}
      </span>
    )
  }

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Retrieval - 3S</Text>
      </TableCell>
      <TableCell>{renderContent()}</TableCell>
    </TableRow>
  )
}
