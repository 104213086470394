import {
  mergeClasses,
  TableCell,
  TableColumnId,
  TableHeaderCellProps,
  TableRow,
  TableRowId,
  TableSelectionCell,
} from '@fluentui/react-components'
import { CSSProperties, memo, useCallback, useMemo } from 'react'
import { Column } from '../Layout'
import { Loading } from './Loading'
import { useStyles } from './TableRowItem.styles'
import { ExtendedTableColumnDefinition } from './ExtendedTableColumn'

interface ITableRowItemProps<T> {
  columns: ExtendedTableColumnDefinition<T>[]
  row?: {
    onClick: (e: React.MouseEvent) => void
    onKeyDown: (e: React.KeyboardEvent) => void
    selected: boolean
    appearance: 'none' | 'brand'
    item: T
    rowId: TableRowId
  }
  getTableCellProps: (columnId: TableColumnId) => Pick<TableHeaderCellProps, 'style'>
  style?: CSSProperties
  loadMore?: () => void
  rowClick?: (item?: T | undefined) => void
  focused?: boolean
  selected?: boolean
  selectable?: boolean
  selectionMode?: 'single' | 'multiselect'
  showLoadingRow?: boolean
}

export const TableRowItem = memo(function TableRowItemInner<T>({
  columns,
  row,
  style,
  getTableCellProps,
  loadMore = () => {},
  rowClick,
  focused = false,
  selected = false,
  selectable = false,
  selectionMode = 'multiselect',
  showLoadingRow = false,
}: ITableRowItemProps<T>) {
  const styles = useStyles()
  const handleRowClick = useCallback(() => {
    row && rowClick?.(row.item)
  }, [row, rowClick])

  const cells = useMemo(() => {
    if (!row) return null
    return columns.map((column) => (
      <TableCell key={column.columnId} {...getTableCellProps(column.columnId)}>
        {column.renderCell(row.item)}
      </TableCell>
    ))
  }, [columns, getTableCellProps, row])

  if (showLoadingRow) {
    return (
      <Column fill hAlign="center" vAlign="center" style={style}>
        {/** NOTE: @Ethan - keep using loadMore as a fallback  */}
        <Loading onVisible={loadMore} />
      </Column>
    )
  }

  return (
    <TableRow
      onClick={handleRowClick}
      style={style}
      className={mergeClasses(styles.commonRow, focused ? styles.selectedRow : '', selected ? styles.selectedRow : '')}
      appearance={row?.appearance}
      onKeyDown={row?.onKeyDown}
    >
      {selectable ? (
        selectionMode === 'multiselect' ? (
          <TableSelectionCell
            onClick={row?.onClick}
            checked={selected}
            checkboxIndicator={{ 'aria-label': 'Select row' }}
            aria-selected={selected}
          />
        ) : (
          <TableSelectionCell
            onClick={row?.onClick}
            checked={selected}
            type="radio"
            radioIndicator={{ 'aria-label': 'Select row' }}
          />
        )
      ) : null}
      {cells}
    </TableRow>
  )
}) as <T>(props: ITableRowItemProps<T>) => JSX.Element
