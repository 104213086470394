import {
  INewTicketData,
  ITicketRootCauseItem,
  IUpdateTicketStatusInfoData,
  TeamViewSubMenuIds,
  getProductIdByName,
} from '@copilot-dash/domain'
import { Button, Drawer, DrawerBody, DrawerHeader, Field, ProgressBar, Spinner, Text } from '@fluentui/react-components'
import { AddRegular, DismissRegular, LightbulbFilamentRegular, ThumbDislikeRegular } from '@fluentui/react-icons'
import { isNil } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { z } from 'zod'
import { Column, Row, Spacer } from '../../../components/Layout'
import {
  FieldNames,
  ITicketActionFormRef,
  TicketActionForm,
} from '../../../components/TicketActionForm/TicketActionForm'
import { RootCauseActionType } from '../../../components/TicketSummaryPanel/children/Suggestions'
import {
  mapADOStateToCopilotDashState,
  mapCopilotDashStateToADOReason,
  mapCopilotDashStateToADOState,
} from '../../../components/TicketSummaryPanel/children/utils'
import { TeamRoute } from '../../../router'
import { sleep } from '../../../utils/time'
import { useTeamViewStore } from '../storeNew'

import { useStyles } from './BatchUpdateTicketsPanel.styles'
import { findCommonCustomTags } from './utils'
const postDataValidation = z.array(
  z.object({
    workItemId: z.string(),
    ticketId: z.string(),
    status: z.enum(['New', 'Active', 'Resolved', 'Closed', '']),
    priority: z.number(),
    areaPath: z.string(),
    assignTo: z.string().optional(),
    issueList: z.array(z.object({ issueId: z.string(), title: z.string(), vsoAccount: z.string() })),
    reasoning: z.string(),
    closedComment: z.string(),
    customTags: z.array(z.string()).optional(),
  }),
)

function reportAction(
  suggestion: ITicketRootCauseItem | undefined | null,
  userAction: string,
  ticket: INewTicketData | undefined | null,
) {
  if (isNil(suggestion) || isNil(ticket)) return
  if (!ticket.ticketId) return

  application.store.actions.updateTicketRootCause(ticket.ticketId, {
    items: [{ ...suggestion, userAction }],
    updateAreaAndIssues: false,
  })
}
interface BatchUpdateTicketsPanelProps {
  open: boolean
  onClose?: () => void
  afterSave?: () => void
}
export const BatchUpdateTicketsPanel: React.FC<BatchUpdateTicketsPanelProps> = ({ open, onClose, afterSave }) => {
  const styles = useStyles()
  const tickets = useTeamViewStore((state) => state.batchTicketsSubStore.tickets)
  const suggestion = useTeamViewStore((state) => state.batchTicketsSubStore.suggestion)
  const teamName = useTeamViewStore((state) => state.teams.lastSelectedTeam)
  const ticketsTabType = useTeamViewStore((state) => state.tickets.ticketsTabType)
  const currentSelectedSubMenu = useTeamViewStore((state) => state.teams.selectedSubMenu)
  const batchCustomTagsSnapshot = useTeamViewStore((state) => state.batchTicketsSubStore.batchTicketCustomTagsSnapshot)

  const [selectedRootCause, setSelectedRootCause] = useState<string[]>([])
  const [disappearRCR, setDisappearRCR] = useState(false)
  const [value, setValue] = useState(0)
  const intervalDelay = 1000
  const groupSize = 20
  const controller = useRef(new AbortController())
  const signal = controller.current.signal

  const isOnAllDSATsPage = ticketsTabType === 'category' && currentSelectedSubMenu === TeamViewSubMenuIds.All
  const isOnRootCausedPages = ticketsTabType === 'rootCause'

  const productName = TeamRoute.navigator.useArgsOptional()?.product

  const formRef = useRef<ITicketActionFormRef | null>(null)
  const [saving, setSaving] = useState(false)
  const [allowSave, setAllowSave] = useState(false)
  const [formModified, setFormModified] = useState(false)
  const modifiedFieldRef = useRef<{
    [FieldNames.state]: boolean
    [FieldNames.priority]: boolean
    [FieldNames.area]: boolean
    [FieldNames.assignTo]: boolean
    [FieldNames.customTags]: boolean
    [FieldNames.rootCauseIDs]: boolean
    [FieldNames.noActionableReason]: boolean
  }>({
    [FieldNames.state]: false,
    [FieldNames.priority]: false,
    [FieldNames.area]: false,
    [FieldNames.assignTo]: false,
    [FieldNames.customTags]: false,
    [FieldNames.rootCauseIDs]: false,
    [FieldNames.noActionableReason]: false,
  })
  const [hasSuggestionAdded, setHasSuggestionAdded] = useState(false)

  const stateMixed = new Set(tickets.map((t) => mapADOStateToCopilotDashState(t.status, t.reasoning))).size > 1
  const priorityMixed = new Set(tickets.map((t) => t.priority)).size > 1
  const areaMixed = new Set(tickets.map((t) => t.teamArea)).size > 1
  const assignToMixed = new Set(tickets.map((t) => t.assignTo)).size > 1
  const rootCausesMixed = tickets.some((t) => t.rootCauseList !== undefined && t.rootCauseList.length > 1)
  const commonCustomTags = findCommonCustomTags(tickets)

  const allStateSame = new Set(tickets.map((t) => mapADOStateToCopilotDashState(t.status, t.reasoning))).size === 1
  const allPrioritySame = new Set(tickets.map((t) => t.priority)).size === 1
  const allAreaSame = new Set(tickets.map((t) => t.teamArea)).size === 1
  const allRootCausesSingleAndSame =
    tickets.every((t) => t.rootCauseList !== undefined && t.rootCauseList.length === 1) &&
    new Set(tickets.map((t) => t.rootCauseList?.[0]?.issueId)).size === 1
  const allAssignToSame = new Set(tickets.map((t) => t.assignTo)).size === 1

  useEffect(() => {
    const rcrIssueId = suggestion?.adoIssueId
    const disappear = rcrIssueId && selectedRootCause?.includes(rcrIssueId)
    if (disappear === undefined || disappear === '') {
      setDisappearRCR(false)
    } else {
      setDisappearRCR(disappear)
    }
  }, [selectedRootCause, suggestion?.adoIssueId])

  const validateSavable = useCallback(
    (formValues: ReturnType<ITicketActionFormRef['getValues']>) => {
      if (!stateMixed && isNil(formValues?.state)) return false
      if (!priorityMixed && isNil(formValues?.priority)) return false
      if (!areaMixed && isNil(formValues?.area)) return false
      return true
    },
    [stateMixed, priorityMixed, areaMixed],
  )

  const chunkArray = (array: IUpdateTicketStatusInfoData[], size: number) => {
    const result = []
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size))
    }
    return result
  }

  const refreshPage = useCallback(
    (postData: IUpdateTicketStatusInfoData[]) => {
      // NOTE: @Ethan - As our service save is async, we need to wait for a while to refresh the page.
      return sleep(Math.min(3000, 500 * postData.length)).then(() => {
        const args = TeamRoute.navigator.getArgs()
        useTeamViewStore.getState().toggleBatchUpdatePanel(false)
        onClose?.()
        TeamRoute.navigator.navigate({ ...args, refreshTicker: (args?.refreshTicker ?? 0) + 1 })
      })
    },
    [onClose],
  )

  const groupAndFetch = useCallback(
    async (array: IUpdateTicketStatusInfoData[], size: number) => {
      const groups = chunkArray(array, size)
      let completedRequests = 0
      let progress = 0
      const id = setInterval(() => {
        if (value > array.length) {
          clearInterval(id)
        } else {
          progress += 1
          if (progress < groupSize) {
            setValue(progress)
          } else {
            clearInterval(id)
          }
        }
      }, intervalDelay)

      for (const group of groups) {
        try {
          const isClosed = group.every((item) => item.status === 'Closed')
          if (!stateMixed && isClosed) {
            const firstPostData: IUpdateTicketStatusInfoData[] = group.map((item) => {
              return { ...item, status: 'Active', reasoning: '' }
            })
            await application.store.actions.batchUpdateTicketStatusInfo({ TicketStatusInfoList: firstPostData, signal })
            await application.store.actions.batchUpdateTicketStatusInfo({ TicketStatusInfoList: group, signal })
            completedRequests += group.length
            setValue(completedRequests)
            await afterSave?.()
          } else {
            await application.store.actions.batchUpdateTicketStatusInfo({ TicketStatusInfoList: group, signal })
            completedRequests += group.length
            setValue(completedRequests)
            await afterSave?.()
          }
          // Update custom tags in store
          for (const item of group) {
            if (item.issueList.length > 0) {
              application.store.actions.updateTicketCustomTag(item.ticketId, item.customTags ?? [])
            }
          }
        } catch (err) {
          setSaving(false)
          throw new Error('Failed to save. Cause:' + err)
        }
      }
      refreshPage(array)
      setSaving(false)
      setFormModified(false)
    },
    [afterSave, signal, value, refreshPage, stateMixed],
  )

  const handleSave = useCallback(() => {
    if (!formRef.current) return
    if (!tickets || tickets.length === 0) return
    const values = formRef.current.getValues()
    if (!values) return

    const postDataDraft = tickets.map((ticket) => {
      const filteredCustomTags = (ticket.customTags ?? []).filter((tag) => !commonCustomTags.includes(tag))
      const mergedCustomTags = Array.from(new Set([...(values.customTags ?? []), ...filteredCustomTags])).filter(
        Boolean,
      )

      return {
        workItemId: ticket.workItemId,
        ticketId: ticket.ticketId,
        status: values.state
          ? mapCopilotDashStateToADOState(values.state, values.area ?? ticket.teamArea ?? '')
          : ticket.status,
        priority: values.priority ?? ticket.priority,
        areaPath: values.area ?? ticket.teamArea,
        assignTo: values.assignTo ?? ticket.assignTo,
        issueList: modifiedFieldRef.current?.rootCauseIDs
          ? values['rootCauseList']
          : ticket.rootCauseList
            ? ticket.rootCauseList.map((rootCause) => ({
                issueId: rootCause.issueId,
                title: rootCause.rootCauseTitle,
                vsoAccount: rootCause.vsoAccount,
              }))
            : [],
        reasoning: values.state ? mapCopilotDashStateToADOReason(values.state) : ticket.reasoning,
        closedComment: values.noActionableReason ?? '',
        customTags: mergedCustomTags,
      }
    })

    const validation = postDataValidation.safeParse(postDataDraft)
    const postData = validation.data
    if (!postData) return
    setValue(0)
    setSaving(true)

    groupAndFetch(postData, groupSize)
  }, [tickets, groupAndFetch, commonCustomTags])

  const handleCancel = () => {
    controller.current.abort()
  }

  const onRejectSuggestion = useCallback(
    (suggestion: ITicketRootCauseItem) => {
      useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(null)
      tickets.forEach((ticket) => {
        reportAction(suggestion, RootCauseActionType.UserRejected, ticket)
      })
    },
    [tickets],
  )
  const onAcceptSuggestion = useCallback(
    (suggestion: ITicketRootCauseItem) => {
      useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(null)
      const values = formRef.current?.getValues()
      values &&
        formRef.current?.setValues({
          ...values,
          rootCauseIDs: [...(values.rootCauseIDs ?? []), suggestion.adoIssueId].filter((v) => v),
        })
      tickets.forEach((ticket) => {
        reportAction(suggestion, RootCauseActionType.UserConfirmed, ticket)
      })
      setHasSuggestionAdded(true)
    },
    [tickets],
  )

  useEffect(() => {
    if (hasSuggestionAdded) {
      setTimeout(() => {
        handleSave()
        setHasSuggestionAdded(false)
      }, 100)
    }
  }, [hasSuggestionAdded, handleSave])

  useEffect(() => {
    if (!formRef.current) return

    formRef.current.watch(async (values, key) => {
      const pass = await formRef.current?.validate()
      if (!pass) {
        setAllowSave(false)
        return
      }
      setAllowSave(validateSavable(values))
      if (modifiedFieldRef.current) {
        modifiedFieldRef.current[key as FieldNames] = true
      }
    })
  }, [validateSavable, batchCustomTagsSnapshot.status])

  if (tickets.length === 0) return null
  const theLonelyTicket = tickets.length === 1 ? tickets[0] : null
  const disableRootCause = (isOnAllDSATsPage || isOnRootCausedPages) && rootCausesMixed
  return (
    <Drawer className={styles.drawer} open={open} type="inline" separator position="end">
      <DrawerHeader>
        <Row vAlign="center" fill>
          <Button
            appearance="subtle"
            aria-label="Close"
            icon={<DismissRegular />}
            onClick={() => {
              useTeamViewStore.getState().toggleBatchUpdatePanel(false)
              onClose?.()
            }}
          />
          <Text className={styles.title}>Batch Management</Text>
          <Spacer />
          <Button
            disabled={!formModified || !allowSave || saving}
            appearance="primary"
            onClick={handleSave}
            icon={saving ? <Spinner size="extra-tiny" /> : null}
          >
            Save
          </Button>
        </Row>
      </DrawerHeader>
      <DrawerBody>
        <Column>
          <Text>{tickets.length} tickets selected, you will manage these tickets.</Text>
          <Spacer height={20} />
          {tickets.length > groupSize && saving && (
            <Row>
              <Field
                validationMessage={`${value} ticket updated......`}
                validationState="none"
                style={{ width: '100%' }}
              >
                <ProgressBar max={tickets.length} value={value} />
              </Field>
              <text
                style={{ color: 'blue', cursor: 'pointer', position: 'absolute', left: '146px' }}
                onClick={handleCancel}
              >
                Cancel
              </text>
            </Row>
          )}
          {batchCustomTagsSnapshot.status === 'done' && (
            <TicketActionForm
              key={tickets.map((t) => t.ticketId).join(',')}
              ref={formRef}
              className={styles.form}
              gridLayout={{
                templateColumns: 'auto',
                templateRows: 'auto 0px auto 20px auto 20px auto 20px auto 20px auto 20px auto',
                templateAreas: [
                  ['state'],
                  ['.'],
                  ['noActionableReason'],
                  ['.'],
                  ['priority'],
                  ['.'],
                  ['area'],
                  ['.'],
                  ['assignTo'],
                  ['.'],
                  ['customTags'],
                  ['.'],
                  ['rootCauses'],
                ],
              }}
              orientation="vertical"
              disabled={{
                state: false,
                priority: false,
                area: false,
                rootCauseIDs: disableRootCause,
              }}
              defaultValues={{
                state: theLonelyTicket?.status
                  ? mapADOStateToCopilotDashState(theLonelyTicket.status, theLonelyTicket.reasoning)
                  : allStateSame
                    ? mapADOStateToCopilotDashState(tickets[0]!.status, tickets[0]!.reasoning)
                    : undefined,
                noActionableReason: theLonelyTicket?.closedComment || 'N/A',
                priority: theLonelyTicket?.priority ?? (allPrioritySame ? tickets[0]!.priority : undefined),
                area: areaMixed ? undefined : allAreaSame ? tickets[0]?.teamArea : undefined,
                assignTo: assignToMixed ? undefined : allAssignToSame ? tickets[0]?.assignTo : undefined,
                rootCauseIDs:
                  theLonelyTicket?.rootCauseList?.map((rootCause) => rootCause.issueId) ??
                  (allRootCausesSingleAndSame
                    ? tickets[0]!.rootCauseList?.map((rootCause) => rootCause.issueId)
                    : undefined),
                customTags: commonCustomTags,
              }}
              defaultValueTexts={{
                state: stateMixed ? 'Mixed' : '',
                priority: priorityMixed ? 'Mixed' : '',
                area: areaMixed ? 'Mixed' : teamName ?? '',
                assignTo: assignToMixed ? 'Mixed' : '',
                rootCauseIDs: rootCausesMixed ? 'Mixed' : '',
                customTags: tickets.length > 1 && commonCustomTags.length > 0 ? 'Mixed' : '',
              }}
              fieldsProps={
                disableRootCause
                  ? {
                      rootCauses: {
                        validationState: 'none',
                        validationMessage:
                          'Some ticket(s) got more than 1 root causes, please check and manage the ticket(s) separately to avoid mis-operation',
                      },
                    }
                  : {}
              }
              enableRules={{
                state: stateMixed ? false : true,
                noActionableReason: true,
                priority: priorityMixed ? false : true,
                area: areaMixed ? false : true,
                rootCauseIDs: rootCausesMixed ? false : true,
              }}
              ticketInfo={{
                teamName: teamName ?? '',
                vsoAccount: tickets[0]?.vsoAccount,
              }}
              onModified={(modified, data) => {
                setFormModified(modified)
                if (data && data.rootCauseIDs) {
                  setSelectedRootCause?.(data.rootCauseIDs)
                }
              }}
              productId={getProductIdByName(productName)}
            />
          )}
          <Spacer height={32} />
          {suggestion && !disappearRCR && (
            <>
              <Text size={400} weight="bold">
                Root Cause Recommendation
              </Text>
              <Spacer height={10} />
              <Column className={styles.suggestion}>
                <Text size={400} weight="bold">
                  {suggestion.title}
                </Text>
                <Text size={300}>Team: {suggestion.teamName}</Text>
                <Row className={styles.suggestionActionArea}>
                  <Spacer />
                  <Button onClick={() => onRejectSuggestion(suggestion)} icon={<ThumbDislikeRegular fontSize={14} />}>
                    This is incorrect
                  </Button>
                  <Spacer width={8} />
                  <Button
                    onClick={() => onAcceptSuggestion(suggestion)}
                    className={styles.addBtn}
                    icon={<AddRegular fontSize={14} />}
                  >
                    Add
                  </Button>
                </Row>
                <Row vAlign="center">
                  <Spacer />
                  <LightbulbFilamentRegular fontSize={'16px'} />
                  <Spacer width={2} />
                  <Text>Generated by AI. Check for accuracy.</Text>
                </Row>
              </Column>
            </>
          )}
        </Column>
      </DrawerBody>
    </Drawer>
  )
}

BatchUpdateTicketsPanel.displayName = 'BatchUpdateTicketsPanel'
