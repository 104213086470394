import { ApiTicketRequestType, apiRequestTypeSchema } from './ApiRequestType'
import { z } from 'zod'

export interface ApiTicketRequest {
  readonly TicketId?: string
  readonly ExternalTicketId?: string
  readonly LogicalId?: string
  readonly CorrelationId?: string
  readonly RequestType: ApiTicketRequestType
}

export const apiTicketRequestSchema = z.object({
  TicketId: z.string().optional(),
  ExternalTicketId: z.string().optional(),
  LogicalId: z.string().optional(),
  CorrelationId: z.string().optional(),
  RequestType: apiRequestTypeSchema,
})
