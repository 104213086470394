import { Caption1, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { Handle, Node, NodeProps, Position } from '@xyflow/react'
import { motion, TargetAndTransition } from 'framer-motion'
import { memo } from 'react'
import { Column, Row } from '../../../Layout'
import { CallFlowNode, CallFlowNodeTypeColors, CallFlowNodeTypeColorsAlpha } from '../../store/IState'
import { TicketCallFlowStore } from '../../TicketCallFlowStore'

export const TurnViewFlowNodeView = memo(function TurnViewFlowNodeView({
  data,
  isConnectable,
  sourcePosition = Position.Bottom,
  targetPosition = Position.Top,
}: NodeProps<Node<CallFlowNode>>) {
  const styles = useStyles()
  const actions = TicketCallFlowStore.useActions()
  const selection: 'selected' | 'unselected' | 'rested' = TicketCallFlowStore.use((state) => {
    if (state.selectedScopeId) {
      return state.selectedScopeId === data.item.scopeId ? 'selected' : 'unselected'
    } else {
      return 'rested'
    }
  })

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation()

    if (selection !== 'selected') {
      actions.selectScope(data.turnId, data.item.scopeId)
    }
  }

  const color = CallFlowNodeTypeColors[data.type]
  const colorAlpha = CallFlowNodeTypeColorsAlpha[data.type]

  const animates: Record<'selected' | 'unselected' | 'rested', TargetAndTransition> = {
    selected: {
      boxShadow: tokens.shadow16,
      borderColor: color,
      color: tokens.colorNeutralForeground1,
    },
    unselected: {
      boxShadow: 'none',
      borderColor: colorAlpha,
      color: tokens.colorNeutralForeground4,
    },
    rested: {
      boxShadow: 'none',
      borderColor: color,
      color: tokens.colorNeutralForeground1,
    },
  }

  return (
    <motion.div
      className={mergeClasses(styles.root)}
      animate={animates[selection]}
      whileHover={{
        boxShadow: selection === 'selected' ? tokens.shadow16Brand : tokens.shadow4,
        borderColor: color,
      }}
      transition={{ duration: 0.16 }}
      onClick={handleClick}
    >
      <Handle type="target" position={targetPosition} isConnectable={isConnectable} className={styles.handle} />
      <Column hAlign="stretch" vAlign="center" title={data.label} fill>
        <Row className={styles.body} hAlign="center" vAlign="center">
          <Caption1 wrap={false} truncate>
            {data.label}
          </Caption1>
        </Row>
      </Column>
      <Handle type="source" position={sourcePosition} isConnectable={isConnectable} className={styles.handle} />
    </motion.div>
  )
})

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',

    backgroundColor: tokens.colorNeutralBackground1,
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
    borderRadius: tokens.borderRadiusMedium,
    overflow: 'hidden',
    borderTopWidth: '4px',
    borderBottomWidth: '1px',
    borderLeftWidth: '1px',
    borderRightWidth: '1px',
  },

  body: {
    padding: '8px',
    flex: '1 1 0',
  },

  handle: {
    opacity: 0,

    '&.react-flow__handle-top': {
      transform: 'translate(-50%, 0)',
    },

    '&.react-flow__handle-bottom': {
      transform: 'translate(-50%, 0)',
    },

    '&.react-flow__handle-left': {
      transform: 'translate(0, -50%)',
    },

    '&.react-flow__handle-right': {
      transform: 'translate(0, -50%)',
    },
  },
})
