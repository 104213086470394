import { AsyncSnapshots } from '@copilot-dash/core'
import { useTicketKustoEventLog } from '@copilot-dash/store'
import { ReactNode } from 'react'
import { TicketScreenStore } from '../../../../../TicketScreenStore'
import { LoadingStatusComponent } from '../../../../common/LoadingStatusComponent'
import { TicketScreenLogView } from '../../../../common/TicketScreenLogView'

export function EventLogView(): ReactNode {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  if (!selectedTurnId) {
    const snapshot = AsyncSnapshots.error(new Error('No conversation selected'))
    return <LoadingStatusComponent promiseSnapshot={snapshot} content={() => null} />
  }

  return <Content ticketId={ticketId} selectedTurnId={selectedTurnId} />
}

function Content(props: { ticketId: string; selectedTurnId: string }) {
  const snapshot = useTicketKustoEventLog({
    ticketId: props.ticketId,
    messageId: props.selectedTurnId,
  })

  return <TicketScreenLogView snapshot={snapshot} />
}
