import { Button } from '@fluentui/react-components'
import { HistoryRegular } from '@fluentui/react-icons'
import { useStyles } from './ActivityHistoryPanelTriggerButton.styles'

interface IProps {
  onClick?: () => void
}
export function ActivityHistoryPanelTriggerButton({ onClick }: IProps) {
  const styles = useStyles()

  return (
    <Button size="medium" onClick={onClick}>
      <HistoryRegular fontSize={20} className={styles.icon} />
      History
    </Button>
  )
}
