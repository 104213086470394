import { ProductName, TeamViewSubMenuIds } from '@copilot-dash/domain'
import { ISearchTicketArgs } from '@copilot-dash/store'
import { z } from 'zod'

interface ITeamViewRouteState {
  readonly refreshTicker?: number
}

export interface ITeamViewRouteArgs extends ISearchTicketArgs, ITeamViewRouteState {
  readonly product: ProductName
  readonly subMenu?: TeamViewSubMenuIds
  readonly hash?: string
  readonly topIssueBatchId?: string | null
}

export const teamViewRouteStateSchema = z.object({
  refreshTicker: z.number().optional(),
})
