import { ISearchScreenPropsQuery } from '../SearchScreen'
import { ISearchScreenState } from './ISearchScreenStoreState'
import { createSearchScreenStateForm } from './createSearchScreenStateForm'

export function createSearchScreenState(query: ISearchScreenPropsQuery): ISearchScreenState {
  return {
    searchSnapshot: { status: 'none' },
    productsSnapshot: { status: 'none' },
    loadMoreSnapshot: { 0: { status: 'none' } },
    searchFeedbackResult: { total: 0, tickets: [], hasMore: true },
    form: createSearchScreenStateForm(query),
    isColumnSettingPanelOpen: false,
    isTicketsFilterPanelOpen: true,
    isTicketSummaryPanelOpen: false,
    clickedTicketInfo: undefined,
    searchSessionId: undefined,
    focusedTab: query.tab ?? 'allFeedback',
  }
}
