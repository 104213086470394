import { ApiTicket, apiTicketSchema } from './ApiTicket'
import { z } from 'zod'

export interface ApiTicketsResponse {
  readonly tickets: Array<ApiTicket>
  readonly hasMore: boolean
  readonly ticketCount?: number
}

export const apiTicketsResponseSchema = z.object({
  tickets: z.array(apiTicketSchema),
  hasMore: z.boolean(),
  ticketCount: z.number().optional(),
})
