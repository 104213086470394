import { ITopIssuesBatchInfoList } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'
import { TeamViewDataConverter } from './converters/TeamViewDataConverter'
import { PromiseSnapshot } from '@copilot-dash/core'

export async function getTeamIssueClusteringBatchInfoList(
  context: IDashStoreContext,
): Promise<ITopIssuesBatchInfoList> {
  const response = await context.api.logCollector.getIssueClusteringBatchInfoList()
  return TeamViewDataConverter.fromApiTopIssuesBatchInfoListResponse(response)
}

export function getOrFetchTeamIssueClusteringBatchInfoList(
  context: IDashStoreContext,
): PromiseSnapshot<ITopIssuesBatchInfoList> {
  return context.getOrFetch({
    get: (state) => {
      return state.topIssue.batchList
    },
    set: (state, snapshot) => {
      state.topIssue.batchList = snapshot
    },
    fetch: () => {
      return getTeamIssueClusteringBatchInfoList(context)
    },
  })
}
