import { makeStyles } from '@fluentui/react-components'
import { Scrollbar } from '../../../../../components/Scrollbar/Scrollbar'
import { SymptomReport } from '../../../../../components/SymptomReport/SymptomReport'
import { TicketScreenStore } from '../../../TicketScreenStore'

export function TicketScreenSymptomReport() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const ticketTurnId = TicketScreenStore.use((state) => state.selectedTurnId)

  if (!ticketTurnId) {
    return
  }

  return (
    <Scrollbar className={styles.root}>
      <SymptomReport ticketId={ticketId} turnId={ticketTurnId} />
    </Scrollbar>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '16px 16px',
  },
})
