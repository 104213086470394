import { useEffect, useRef, useState } from 'react'
import { AvoidShareDataMessage } from '../../../components/Hint/AvoidShareDataMessage'
import { Column, Row } from '../../../components/Layout'
import { ResizeDimentions, useResizeObserver } from '../../../hooks/useResizeObserver'
import { useStyles } from './SearchScreenBar.styles'
import { SMALL_SCREEN_WIDTH } from './SearchScreenForm.styles'

export function SearchScreenBar() {
  const styles = useStyles()
  const hasOdsAccess = application.store.use((state) => state.basic.hasOdsAccess)
  const widthRef = useRef<HTMLDivElement>(null)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  const handleResize = (width: number) => {
    if (width < SMALL_SCREEN_WIDTH) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }
  const ref = useResizeObserver(ResizeDimentions.Width, handleResize)
  const [bannerWidth, setBannerWidth] = useState<number>(0)

  useEffect(() => {
    setBannerWidth(widthRef?.current?.offsetWidth || 0)
  }, [])

  return (
    <Row className={styles.container} ref={ref}>
      <Column
        className={isSmallScreen ? styles.smallScreenWarning : styles.largeScreenWarning}
        ref={widthRef}
        style={{ left: `calc(50% - ${bannerWidth / 2}px)` }}
      >
        {hasOdsAccess && <AvoidShareDataMessage />}
      </Column>
    </Row>
  )
}
