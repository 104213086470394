import { Column } from '../../Layout'
import { TicketDiscussionPanel } from '../TicketDiscussionPanel'
import { DiscussionPanelEmpty } from '../children/DiscussionPanelEmpty'
import { IComment } from '@copilot-dash/domain'
import { UserMessage } from '../children/UserMessage'

export function DiscussionPanelBody() {
  const commentsSnapshot = TicketDiscussionPanel.use((state) => state.commentsSnapshot)

  return (
    <Column>
      {commentsSnapshot.data?.length === 0 ? (
        <DiscussionPanelEmpty />
      ) : (
        commentsSnapshot.data?.map((comment: IComment, index: number) => (
          <UserMessage
            key={index}
            commentId={comment.commentId}
            userId={comment.userId}
            ticketId={comment.ticketId}
            createTime={comment.createTime}
            message={comment.content}
            modifyTime={comment.modifyTime}
          />
        ))
      )}
    </Column>
  )
}
