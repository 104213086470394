import { DashRing } from '@copilot-dash/core'
import { createDashSettingsForFlights } from './createDashSettingsForFlights'
import { createDashSettingsForUser } from './createDashSettingsForUser'
import { IDashSettings } from './IDashSettings'

export function createDashSettings(ring: DashRing): IDashSettings {
  return {
    user: createDashSettingsForUser(),
    feature: createDashSettingsForFlights(ring),
  }
}
