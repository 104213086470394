import {
  ApiEntitySets,
  ApiOds3sOnlineConvergedRequest,
  ApiOds3sOnlineConvergedResponse,
  apiReplayRequestSchema,
  apiReplayResponseSchema,
  parseTo,
} from '@copilot-dash/api'
import {
  IKustoGwsLogItem,
  ITicketTurnHealthData,
  ITicketTurnTransactionHealthData,
  KustoLogTable,
} from '@copilot-dash/domain'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { isEmpty } from 'lodash'
import { IDashStoreContext } from '../IDashStoreContext'
import { getRaw3sOfflineData } from './getRaw3sOfflineData'
import { getRaw3sOnlineData } from './getRaw3sOnlineData'
import { getTicket } from './getTicket'
import { getTicketKustoData } from './actions-kusto/getTicketKustoData'
import { getTicketSession } from './getTicketSession'

export function getTicketHealthData(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ITicketTurnHealthData> {
  return context.getOrFetch<ITicketTurnHealthData>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.health
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.health = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketTurnHealthData> {
    const ticket = await getTicket(context, ticketId).promise
    if (!ticket.tag.sssTriggered) {
      throw TicketError.create('No3SDueToNoNotTriggered', { ticketId })
    }

    const session = await getTicketSession(context, ticketId).promise

    // 1. From GWS Logs
    const results: Record<string, ITicketTurnTransactionHealthData> = {}
    const logs = (await getTicketKustoData(context, ticketId, turnId, KustoLogTable.GwsLog).promise).GwsLog
    for (const item of logs) {
      if (item.httpStatusCode === '500' && item.transactionId) {
        results[item.transactionId] = getHealthItemData(item, item.transactionId, undefined, undefined, 'GWS log')
      }
    }

    const interaction = session.interactions.find((item) => item.messageId === turnId)
    const impressionIds = interaction?.impressionIds ?? []

    // From Offline Data
    if (impressionIds.length > 0) {
      const offlineDataPromise = getRaw3sOfflineData(context, ticketId, turnId).promise
      const offlineData = await offlineDataPromise.catch(() => undefined)
      if (offlineData) {
        for (const impression of offlineData.EnterpriseSearchImpressions ?? []) {
          const impressionId = impression.ImpressionId
          if (impressionId && impressionIds.includes(impressionId)) {
            // 1. Get request
            let request: ApiOds3sOnlineConvergedRequest | undefined
            try {
              request =
                typeof impression.Request === 'string'
                  ? parseTo(impression.Request, apiReplayRequestSchema)
                  : impression.Request
            } catch (error) {
              // do nothing
            }

            // 2. Get response
            let response: ApiOds3sOnlineConvergedResponse | undefined
            try {
              response =
                typeof impression.Response === 'string'
                  ? parseTo(impression.Response, apiReplayResponseSchema)
                  : impression.Response
            } catch (error) {
              // do nothing
            }

            results[impressionId] = getHealthItemData(undefined, impressionId, request, response, 'offline data')
          }
        }
      }

      if (!isEmpty(results)) {
        return results
      }
    }

    // From 3s online data
    try {
      const replayData = await getRaw3sOnlineData(context, ticketId, turnId).promise

      const replayList = interaction?.sssOnlineDataList ?? []
      for (const item of replayList) {
        const onlineId = item.replayServiceCallTraceId
        const online = replayData.find((item) => item.ReplayResponse?.TraceId === onlineId)
        if (item.transactionId) {
          results[item.transactionId] = getHealthItemData(
            undefined,
            item.transactionId,
            online?.ReplayRequest,
            online?.ReplayResponse?.TrimmedResponse,
            '3S online data',
          )
        }
      }
    } catch (error) {
      // do nothing
    }

    // 4. Check
    return results
  }

  function getHealthItemData(
    fullGWSLog: IKustoGwsLogItem | undefined,
    transactionId: string,
    requestData: ApiOds3sOnlineConvergedRequest | undefined,
    responseData: ApiOds3sOnlineConvergedResponse | undefined,
    source: string,
  ): ITicketTurnTransactionHealthData {
    const entitySets = getEntitySets(responseData?.EntitySets)
    const answerEntitySets = getEntitySets(responseData?.AnswerEntitySets)

    const newDiagnosticData: IKustoGwsLogItem['diagnosticData'] = {}
    if (fullGWSLog) {
      const { diagnosticData } = fullGWSLog
      Object.keys(diagnosticData!).forEach((key) => {
        if (
          key.startsWith('ShouldExecuteDomain') &&
          diagnosticData?.[key]?.toString().includes('ShouldExecute: True')
        ) {
          newDiagnosticData[key] = diagnosticData[key]
        }
      })
      if (diagnosticData?.PeopleNaturalSearchAnswerWorkflow) {
        newDiagnosticData['PeopleNaturalSearchAnswerWorkflow'] = diagnosticData?.PeopleNaturalSearchAnswerWorkflow
      }
    }

    const entityRes = requestData?.EntityRequests || requestData?.AnswerEntityRequests

    return {
      transactionId,
      source,
      httpStatusCode: fullGWSLog?.httpStatusCode,
      query: entityRes?.[0]?.Query?.QueryString,
      lu: responseData?.ExtendedData?.Info,
      response: {
        entitySets: entitySets && entitySets?.length > 0 ? `{${entitySets.toString()}}` : '',
        answerEntitySets: answerEntitySets && answerEntitySets?.length > 0 ? `{${answerEntitySets.toString()}}` : '',
      },
      gwsLog: {
        isSubstrateSearchExceptionEvent: false,
        diagnosticData: newDiagnosticData,
        responseMetaJson: fullGWSLog?.responseMetaJson,
      },
    }
  }

  function getEntitySets(entitySets: ApiEntitySets[] | undefined) {
    return entitySets?.map((item) => {
      return `[${item?.EntityType === 'Message' ? item?.ResultSets?.[0]?.Provenance || item?.EntityType : item?.EntityType}, ${item?.ResultSets?.[0]?.Results?.length || 0}]`
    })
  }
}
