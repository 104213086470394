import { IKustoStateDurationLogItem } from '@copilot-dash/domain'
import { makeStyles } from '@fluentui/react-components'
import { Edge, Node, PanOnScrollMode, ReactFlow, useEdgesState, useNodesState } from '@xyflow/react'
import '@xyflow/react/dist/style.css'
import { useEffect, useRef, useState } from 'react'
import { useElementWidth } from '../../../../hooks/useElementWidth'
import { useThemeMode } from '../../../../theme/useThemeMode'
import { CallFlowEdge, CallFlowNode } from '../../store/IState'
import { TicketCallFlowStore } from '../../TicketCallFlowStore'
import { generateEdges } from '../../utils/generateEdges'
import { generateNodes } from '../../utils/generateNodes'
import { layoutNodes } from '../../utils/layoutNodes'
import { TurnViewFlowEdgeView } from './TurnViewFlowEdgeView'
import { TurnViewFlowNodeView } from './TurnViewFlowNodeView'

const NODE_TYPE_DEFAULT = 'custom'
const NODE_TYPES = {
  [NODE_TYPE_DEFAULT]: TurnViewFlowNodeView,
}

const EDGE_TYPE_DEFAULT = 'custom'
const EDGE_TYPES = {
  [EDGE_TYPE_DEFAULT]: TurnViewFlowEdgeView,
}

interface IProps {
  readonly turnId: string
  readonly items: IKustoStateDurationLogItem[]
}

export function TurnViewFlow({ turnId, items }: IProps) {
  const styles = useStyles()

  const [nodes, setNodes, onNodesChange] = useNodesState<Node<CallFlowNode>>([])
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge<CallFlowEdge>>([])
  const [height, setHeight] = useState<number>(200)

  const rootRef = useRef<HTMLDivElement>(null)
  const width = useElementWidth(rootRef)

  const selectedNodeTypes = TicketCallFlowStore.use((state) => state.selectedTypes)
  const themeMode = useThemeMode()

  useEffect(() => {
    if (!width) {
      return
    }

    const nodes = generateNodes(turnId, items, selectedNodeTypes)
    const edges = generateEdges(nodes)

    layoutNodes({
      nodes: nodes,
      canvasWidth: width,
    })

    setNodes(nodes)
    setEdges(edges)

    const last = nodes[nodes.length - 1]
    if (last) {
      setHeight(last.position.y + 32 + 48)
    }
  }, [items, selectedNodeTypes, setEdges, setNodes, turnId, width])

  return (
    <ReactFlow
      ref={rootRef}
      className={styles.root}
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={NODE_TYPES}
      edgeTypes={EDGE_TYPES}
      nodesDraggable={false}
      // elementsSelectable={false}
      nodesConnectable={false}
      // zoomOnScroll={false}
      // zoomOnDoubleClick={false}
      // selectNodesOnDrag={false}
      // panOnScroll={true}
      draggable={false}
      panOnDrag={false}
      panOnScrollMode={PanOnScrollMode.Vertical}
      minZoom={1.0}
      maxZoom={1.0}
      colorMode={themeMode}
      proOptions={{
        hideAttribution: true,
      }}
      defaultViewport={{
        x: 0,
        y: 0,
        zoom: 1.0,
      }}
      preventScrolling={false}
      zoomOnScroll={false}
      style={{
        minHeight: height,
        maxHeight: height,
        height: height,
      }}
    />
  )
}

const useStyles = makeStyles({
  root: {
    '& .react-flow__pane': {
      // cursor: 'default',
    },
  },
})
