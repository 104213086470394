import { AxiosResponse } from 'axios'
import { ApiError } from './ApiError'
import { ApiRequestConfig } from './ApiClient'
import { ApiClientConfig } from './ApiClientConfig'
import { ApiErrorDataUtils } from './ApiErrorDataUtils'

export class ApiErrorUtils {
  static onRequestError(client: ApiClientConfig, request: ApiRequestConfig, error: unknown): ApiError {
    return new ApiError(ApiErrorDataUtils.onRequestError(client, request, error))
  }

  static onResponseError(
    client: ApiClientConfig,
    request: ApiRequestConfig<unknown>,
    response: AxiosResponse,
    error: unknown,
  ): ApiError {
    return new ApiError(ApiErrorDataUtils.onResponseError(client, request, response, error))
  }
}
