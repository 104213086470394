import { Table, TableBody, TableCell, TableRow, Text, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Fragment } from 'react'
import { ErrorView } from '../../Error'
import { Row } from '../../Layout'
import { RowFor3STransactionHeader } from '../children/RowFor3STransactionHeader'
import { RowForConversationSuggestions } from '../children/RowForConversationSuggestions'
import { RowForPreChecks } from '../children/RowForPreChecks'
import { RowForPreChecksOCV } from '../children/RowForPreChecksOCV'
import { RowForPreChecksWebToggleOff } from '../children/RowForPreChecksWebToggleOff'
import { RowForRetrieval3S } from '../children/RowForRetrieval3S'
import { RowForRetrieval3SProviderAndEntities } from '../children/RowForRetrieval3SProviderAndEntities'
import { RowForRetrievalGwsLog } from '../children/RowForRetrievalGwsLog'
import { RowForRetrievalLU } from '../children/RowForRetrievalLU'
import { RowForRetrievalLUDomain } from '../children/RowForRetrievalLUDomain'
import { RowForRetrievalSearchQuery } from '../children/RowForRetrievalSearchQuery'
import { RowForSynthesisChatFCFR } from '../children/RowForSynthesisChatFCFR'
import { RowForSynthesisCitation } from '../children/RowForSynthesisCitation'
import { RowForSynthesisError } from '../children/RowForSynthesisError'
import { RowForUtteranceInputCIQ } from '../children/RowForUtteranceInputCIQ'
import { RowForUtteranceInputInvocation } from '../children/RowForUtteranceInputInvocation'
import { RowForWebSearch } from '../children/RowForWebSearch'
import { SymptomReportLoadingIcon } from '../children/SymptomReportLoadingIcon'

interface IProps {
  readonly ticketId: string
  readonly turnId: string
}

export function SymptomReportLayout({ ticketId, turnId }: IProps) {
  const styles = useStyles()
  const ticketHealth = app.store.use.getTicketHealthData(ticketId, turnId)

  return (
    <Row className={styles.symptomReport}>
      <Row className={styles.symptomReportTable}>
        <Table className={styles.table}>
          <TableBody>
            <RowForPreChecks ticketId={ticketId} turnId={turnId} />
            <RowForPreChecksOCV ticketId={ticketId} />
            <RowForPreChecksWebToggleOff ticketId={ticketId} />
            <RowForUtteranceInputInvocation ticketId={ticketId} />
            <RowForUtteranceInputCIQ ticketId={ticketId} />
            <RowForRetrieval3S ticketId={ticketId} turnId={turnId} />
            <RowForRetrievalLUDomain ticketId={ticketId} />
            <RowForWebSearch ticketId={ticketId} turnId={turnId} />

            {ticketHealth.status === 'done' ? (
              Object.entries(ticketHealth.data).map(([transactionId, item], index) => {
                return (
                  <Fragment key={transactionId}>
                    <RowFor3STransactionHeader index={index} data={item} />
                    {item.httpStatusCode !== '500' && (
                      <>
                        <RowForRetrievalSearchQuery data={item} />
                        <RowForRetrievalLU data={item} />
                        <RowForRetrieval3SProviderAndEntities data={item} />
                        {(item?.gwsLog.diagnosticData || item?.gwsLog.responseMetaJson) && (
                          <RowForRetrievalGwsLog data={item} />
                        )}
                      </>
                    )}
                  </Fragment>
                )
              })
            ) : (
              <>
                <TableRow>
                  <TableCell>
                    <Text weight="semibold">Retrieval - search query</Text>
                  </TableCell>
                  <TableCell>
                    {ticketHealth.status === 'waiting' ? (
                      <SymptomReportLoadingIcon />
                    ) : (
                      <ErrorView type="inline" error={ticketHealth.error} />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text weight="semibold">Retrieval - LU</Text>
                  </TableCell>
                  <TableCell>
                    {ticketHealth.status === 'waiting' ? (
                      <SymptomReportLoadingIcon />
                    ) : (
                      <ErrorView type="inline" error={ticketHealth.error} />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text weight="semibold">Retrieval - 3S provider & entities</Text>
                  </TableCell>
                  <TableCell>
                    {ticketHealth.status === 'waiting' ? (
                      <SymptomReportLoadingIcon />
                    ) : (
                      <ErrorView type="inline" error={ticketHealth.error} />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <Text weight="semibold">Retrieval</Text>
                  </TableCell>
                  <TableCell>
                    {ticketHealth.status === 'waiting' ? (
                      <SymptomReportLoadingIcon />
                    ) : (
                      <ErrorView type="inline" error={ticketHealth.error} />
                    )}
                  </TableCell>
                </TableRow>
              </>
            )}

            <RowForSynthesisCitation ticketId={ticketId} turnId={turnId} />
            <RowForSynthesisChatFCFR ticketId={ticketId} turnId={turnId} />
            <RowForSynthesisError ticketId={ticketId} />
            <RowForConversationSuggestions ticketId={ticketId} turnId={turnId} />
          </TableBody>
        </Table>
      </Row>
    </Row>
  )
}

const useStyles = makeStyles({
  symptomReport: {
    height: 'fit-content',
  },

  table: {
    '& tr': {
      wordWrap: 'break-word',
      ':hover': {
        backgroundColor: tokens.colorNeutralBackground2,
      },
      ':last-child': {
        border: 'none',
      },
    },

    '& tr td:first-child': {
      width: '250px',
    },
  },

  symptomReportTable: {
    width: '100%',
    height: 'fit-content',
    borderEndEndRadius: tokens.borderRadiusLarge,
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStencil1Alpha),
  },
})
