import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { MarkdownView } from '../../../../Markdown/MarkdownView'
import { Scrollbar } from '../../../../Scrollbar/Scrollbar'
import { AdaptiveCard } from '../../../../AdaptiveCard/AdaptiveCard'

/**
 * We use an empty space to display when the message content is empty to prevent the message content from collapsing.
 */
const EMPTY_PLACEHOLDER = `&nbsp;`

interface IProps {
  readonly text: string
  readonly adaptiveCard?: object
}

export function MessageContent({ text, adaptiveCard }: IProps) {
  const styles = useStyles()

  const renderChild = () => {
    // TODO: AdaptiveCard is not stable. Enable it only for non-msit environment.
    if (adaptiveCard && app.env.ring !== 'msit') {
      return <AdaptiveCard payload={adaptiveCard} />
    }

    return <MarkdownView className={styles.markdown}>{text || EMPTY_PLACEHOLDER}</MarkdownView>
  }

  return <Scrollbar className={styles.root}>{renderChild()}</Scrollbar>
}

const useStyles = makeStyles({
  root: {
    padding: '0px 12px',
    backgroundColor: tokens.colorNeutralBackground2,
    borderRadius: tokens.borderRadiusLarge,
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
  },
  markdown: {
    color: tokens.colorNeutralForeground1,
    wordBreak: 'break-word',

    '&> p': {
      marginTop: '12px',
      marginBottom: '12px',
    },

    '&> pre': {
      whiteSpace: 'pre-wrap',
    },
  },
})
