import { I3sLogData, I3sLogDataItem } from '@copilot-dash/domain'
import { Badge, makeStyles, Text, tokens } from '@fluentui/react-components'
import * as React from 'react'
import { ErrorView } from '../../../../../../../components/Error'
import { Row } from '../../../../../../../components/Layout'
import { RouteLink } from '../../../../../../../router'
import { TicketScreenStore } from '../../../../../TicketScreenStore'

function getTransactionUrl(transactionId: string) {
  return `https://3sdash.microsoft.com/searchIssue?traceId=${encodeURIComponent(transactionId)}`
}

function get3sOfflineDataUrl(ticketId: string, transactionId: string) {
  return `https://3sdash.microsoft.com/searchIssue?traceId=${encodeURIComponent(transactionId)}&offline=true&exTicketIds=${encodeURIComponent(ticketId)}`
}

function get3sOnlineDataUrl(ticketId: string, transactionId: string, turnIndex: number) {
  return `https://3sdash.microsoft.com/searchIssue?traceId=${encodeURIComponent(transactionId)}&replay=true&exTicketIds=${encodeURIComponent(ticketId)}&TurnIndex=${encodeURIComponent(turnIndex)}`
}

interface IProps {
  readonly data: I3sLogData
  readonly item: I3sLogDataItem
  readonly index: number
}

export function SssLogViewTreeTitle({ data, item, index }: IProps) {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const styles = useStyles()

  const onTransactionIdClicked = React.useCallback(() => {
    Logger.telemetry.trackEvent('TicketScreen/TransactionId/Click', {
      ticketId: ticketId,
    })
  }, [ticketId])

  const onOnlineButtonClicked = React.useCallback(() => {
    Logger.telemetry.trackEvent('TicketScreen/3SReplayButton/Click', {
      ticketId: ticketId,
    })
  }, [ticketId])

  const renderOfflineDataButton = () => {
    if (item.hasOfflineData) {
      return (
        <RouteLink
          path={get3sOfflineDataUrl(ticketId, item.transactionId)}
          className={styles.route}
          openInNewTab
          onClick={onOnlineButtonClicked}
        >
          Offline data
        </RouteLink>
      )
    }

    if (item.offlineDataError) {
      return <ErrorView type="inline" error={item.offlineDataError} />
    }

    return null
  }

  const renderReplayButton = () => {
    if (item.hasOnlineData) {
      return (
        <RouteLink
          path={get3sOnlineDataUrl(ticketId, item.transactionId, data.ticketTurnIndex)}
          className={styles.route}
          openInNewTab
          onClick={onOnlineButtonClicked}
        >
          Online data
        </RouteLink>
      )
    }

    if (item.onlineDataError) {
      return <ErrorView type="inline" error={item.onlineDataError} />
    }

    return null
  }

  return (
    <Row className={styles.root} gap="gap.small" vAlign="center">
      <Text weight="semibold">3S Transaction {index + 1}: </Text>

      {/* 2. Label */}
      {item.transactionLabels.map((label, index) => {
        return (
          <Badge key={index} appearance="filled" color="informative">
            {label}
          </Badge>
        )
      })}

      {/* 3. Link */}
      <RouteLink path={getTransactionUrl(item.transactionId)} openInNewTab onClick={onTransactionIdClicked} type="link">
        <Text wrap={false}>{item.transactionId}</Text>
      </RouteLink>

      {/* 4. Button */}
      {renderOfflineDataButton()}
      {renderReplayButton()}
    </Row>
  )
}

const useStyles = makeStyles({
  root: {
    flexWrap: 'wrap',
    fontSize: '14px',
  },

  route: {
    backgroundColor: tokens.colorBrandBackground,
    color: tokens.colorNeutralForegroundOnBrand,
    paddingInlineStart: '5px',
    paddingInlineEnd: '5px',
    fontWeight: tokens.fontWeightSemibold,
    fontSize: tokens.fontSizeBase200,
    borderRadius: tokens.borderRadiusMedium,
  },
})
