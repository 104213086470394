import { InternalAxiosRequestConfig } from 'axios'
import { ApiClient } from '../../client/ApiClient'
import { ApiClientEndpoint } from '../../client/ApiClientEndpoint'
import { ApiClientOptions } from '../../client/ApiClientOptions'

const BASE_URL_DEV = 'https://api.diagnosticssdf.office.com'
const BASE_URL_SDF = 'https://portal.diagnosticssdf.office.com'
const BASE_URL_PROD = 'https://portal.diagnostics.office.com'

const SCOPES_DEV = ['https://api.diagnosticssdf.office.com/read']
const SCOPES_SDF = ['https://portal.diagnostics.office.com/read']
const SCOPES_PROD = ['https://portal.diagnostics.office.com/read']

const SDF_ENV_ACCOUNTS = [
  // v-jianliao@microsoft.com
  // 'b38311ec-83e9-4de7-9d5c-9d5e5c151b3f',
  // huayongshi@microsoft.com
  'bbe8fc1c-5972-4937-979c-2bd03d721076',
  // // v-caixiaren@microsoft.com
  // '0b92ce07-9994-445e-b736-6f25b22abd08',
  // // v-chli@microsoft.com
  // '233a3c0a-4abd-475e-9fef-282eef5545aa',
  // // shenda@microsoft.com
  // '8bef0088-9fb6-4f31-9fd3-3c73f71d5968',
  // // ethanfeng@microsoft.com
  // '2ab5a469-4321-44e7-80b6-6236cf5f4aa0',
  // // xiaowenhu@microsoft.com
  // '015903c4-fb70-404e-893b-e280bff50f9c',
]

export class OdsApiClient extends ApiClient {
  constructor(options: ApiClientOptions) {
    super({
      ...options,
      endpoint: ApiClientEndpoint.ODS,
      config: {},
    })
  }

  protected override async onRequest(config: InternalAxiosRequestConfig<unknown>): Promise<void> {
    let baseUrl: string
    let scopes: string[]

    const ring = this.config.getRing()
    const accountId = this.config.getAccountId()
    switch (ring) {
      case 'dev':
        baseUrl = BASE_URL_DEV
        scopes = SCOPES_DEV
        break
      case 'test':
      case 'sdf':
      case 'msit':
      case 'unknown':
        if (ring === 'test' && accountId && SDF_ENV_ACCOUNTS.includes(accountId)) {
          baseUrl = BASE_URL_SDF
          scopes = SCOPES_SDF
        } else {
          baseUrl = BASE_URL_PROD
          scopes = SCOPES_PROD
        }
        break
    }

    config.baseURL = baseUrl
    config.headers.Authorization = `Bearer ${await this.config.acquireToken(scopes)}`
  }
}
