import { DashRing, IDashBuild, IDashBuildCommit, IDashEnvironment } from '@copilot-dash/core'

const DOMAIN_DEV = 'localhost'
const DOMAIN_TEST = 'copilotdash-test.microsoft.com'
const DOMAIN_SDF = 'copilotdash-sdf.microsoft.com'
const DOMAIN_MSIT = 'copilotdash.microsoft.com'

export function createAppEnv(): IDashEnvironment {
  return {
    ring: createRing(),
    build: createBuild(),
  }
}

function createRing(): DashRing {
  if (import.meta.env.DEV) {
    return 'dev'
  }

  if (window.location.hostname.includes(DOMAIN_DEV)) {
    return 'dev'
  }

  if (window.location.hostname.includes(DOMAIN_TEST)) {
    return 'test'
  }

  if (window.location.hostname.includes(DOMAIN_SDF)) {
    return 'sdf'
  }

  if (window.location.hostname.includes(DOMAIN_MSIT)) {
    return 'msit'
  }

  return 'unknown'
}

function createBuild(): IDashBuild {
  return {
    time: import.meta.env.VITE_BUILD_TIME,
    version: Number(import.meta.env.VITE_BUILD_VERSION),
    branch: import.meta.env.VITE_GIT_BRANCH ?? null,
    commit: createBuildCommit(),
  }
}

function createBuildCommit(): IDashBuildCommit | null {
  const commitHash = import.meta.env.VITE_GIT_COMMIT_HASH
  const commitMessage = import.meta.env.VITE_GIT_COMMIT_MESSAGE
  const commitAuthor = import.meta.env.VITE_GIT_COMMIT_AUTHOR
  const commitDate = import.meta.env.VITE_GIT_COMMIT_DATE

  if (!commitHash || !commitMessage || !commitAuthor || !commitDate) {
    return null
  }

  return {
    hash: commitHash,
    message: commitMessage,
    author: commitAuthor,
    timestamp: commitDate,
  }
}
