import { ITeamRootCauseItem } from '@copilot-dash/domain'
import { Name } from './Name'
import { Count } from './Count'
import { State } from './State'
import { Text, tokens } from '@fluentui/react-components'
import { FixBy } from './FixBy'
import { AssignTo } from './AssignTo'

function stringCompare(a: string | undefined, b: string | undefined) {
  if (a === undefined || a === '') return 1
  if (b === undefined || b === '') return -1
  return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
}
function stateCompare(a: string | undefined, b: string | undefined) {
  const order = ['New', 'Active', 'Closed']
  if (a === undefined) return 1
  if (b === undefined) return -1
  return order.indexOf(a) - order.indexOf(b)
}
function countCompare(a: number, b: number) {
  return a - b
}

function eTACompare(a: string | undefined, b: string | undefined) {
  if (a === undefined || a === '') return 1
  if (b === undefined || b === '') return -1
  const aTimestamp = new Date(a).getTime()
  const bTimestamp = new Date(b).getTime()
  return aTimestamp - bTimestamp
}

export const columnsConfig: Array<{
  id: keyof ITeamRootCauseItem
  renderHeaderCell: () => JSX.Element
  renderCell: (data: ITeamRootCauseItem) => JSX.Element
  cellStyle?: React.CSSProperties
  onSort?: (a: ITeamRootCauseItem, b: ITeamRootCauseItem) => number
}> = [
  {
    id: 'rootCauseTitle',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Title
      </Text>
    ),
    renderCell: (data) => <Name rowData={data} />,
    cellStyle: {
      minWidth: 300,
      flex: '1 1 800px',
    },
    onSort: (a, b) => {
      let num = stringCompare(a.rootCauseTitle, b.rootCauseTitle)
      if (num !== 0) return num
      num = stateCompare(a.rootCauseStatus, b.rootCauseStatus)
      if (num !== 0) return num
      return countCompare(a.rootCauseCount, b.rootCauseCount)
    },
  },
  {
    id: 'owner',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Assign To
      </Text>
    ),
    renderCell: (data) => <AssignTo rowData={data} />,
    cellStyle: {
      minWidth: 260,
      maxWidth: 260,
      flex: '0 0 180px',
    },
    onSort: (a, b) => {
      let num = stringCompare(a.owner, b.owner)
      if (num !== 0) return num
      num = stateCompare(a.rootCauseStatus, b.rootCauseStatus)
      if (num !== 0) return num
      return stringCompare(a.rootCauseTitle, b.rootCauseTitle)
    },
  },
  {
    id: 'eTA',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        Fix By
      </Text>
    ),
    renderCell: (data) => <FixBy rowData={data} />,
    cellStyle: {
      minWidth: 180,
      maxWidth: 180,
      flex: '0 0 180px',
    },
    onSort: (a, b) => {
      let num = eTACompare(a.eTA, b.eTA)
      if (num !== 0) return num
      num = stateCompare(a.rootCauseStatus, b.rootCauseStatus)
      if (num !== 0) return num
      return stringCompare(a.rootCauseTitle, b.rootCauseTitle)
    },
  },
  {
    id: 'rootCauseCount',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        DSAT Count
      </Text>
    ),
    renderCell: (data) => <Count rowData={data} />,
    cellStyle: {
      minWidth: 180,
      maxWidth: 180,
      flex: '0 0 180px',
    },
    onSort: (a, b) => {
      let num = countCompare(a.rootCauseCount, b.rootCauseCount)
      if (num !== 0) return num
      num = stateCompare(a.rootCauseStatus, b.rootCauseStatus)
      if (num !== 0) return num
      return stringCompare(a.rootCauseTitle, b.rootCauseTitle)
    },
  },
  {
    id: 'rootCauseStatus',
    renderHeaderCell: () => (
      <Text weight="semibold" size={300} color={tokens.colorNeutralForegroundDisabled}>
        State
      </Text>
    ),
    renderCell: (data) => <State rowData={data} />,
    cellStyle: {
      minWidth: 180,
      maxWidth: 180,
      flex: '0 0 180px',
    },
    onSort: (a, b) => {
      let num = stateCompare(a.rootCauseStatus, b.rootCauseStatus)
      if (num !== 0) return num
      num = countCompare(a.rootCauseCount, b.rootCauseCount)
      if (num !== 0) return -num
      return stringCompare(a.rootCauseTitle, b.rootCauseTitle)
    },
  },
]
