import { useSearchScreenActions, useSearchScreenState } from '../store'
import { useStyles, SMALL_SCREEN_WIDTH } from './SearchScreenForm.styles'
import { Row, Column, Spacer } from '../../../components/Layout'
import { ResizeDimentions, useResizeObserver } from '../../../hooks/useResizeObserver'
import { useState } from 'react'
import { SearchTextFilter } from '../../../components/SearchOptions/SearchTextFilter'
import { EmailFilter } from '../../../components/SearchOptions/EmailFilter'
import { TenantFilter } from '../../../components/SearchOptions/TenantFilter'
import { SearchTextPrefixType } from '@copilot-dash/domain'

interface IProps {
  onDismissTicketSummaryPanel: () => void
}

export function SearchScreenForm({ onDismissTicketSummaryPanel }: IProps) {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const form = useSearchScreenState((state) => state.form)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  const handleResize = (width: number) => {
    if (width < SMALL_SCREEN_WIDTH) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }
  const ref = useResizeObserver(ResizeDimentions.Width, handleResize)

  const onSubmitSearchTextAndPrefix = (searchTextPrefix: SearchTextPrefixType, searchText?: string) => {
    if (searchText !== form.searchText || (searchTextPrefix !== form.searchTextPrefix && searchText)) {
      onDismissTicketSummaryPanel()
      actions.submit({ ...form, searchText: searchText, searchTextPrefix: searchTextPrefix })
    }
  }

  const onChangeUser = (userId?: string, email?: string) => {
    onDismissTicketSummaryPanel()
    actions.submit({ ...form, userId, email })
  }

  const onChangeTenant = (tenantIds: string[] | undefined) => {
    onDismissTicketSummaryPanel()
    actions.submit({ ...form, tenantIds })
  }

  return (
    <Row className={styles.container} ref={ref}>
      {isSmallScreen ? (
        <Column>
          <Row>
            <Column className={styles.smallScreenColumn}>
              <SearchTextFilter
                searchText={form.searchText}
                searchTextPrefix={form.searchTextPrefix}
                onSubmitSearchTextAndPrefix={onSubmitSearchTextAndPrefix}
                disableSearchTextFilter={form.product === 'TenantAdminFeedback'}
              />
            </Column>
            <Spacer width="8px" />
            <Column className={styles.smallScreenColumn}>
              <EmailFilter
                emailAddress={form.email}
                onChangeUser={onChangeUser}
                disableEmailFilter={form.product === 'TenantAdminFeedback'}
              />
            </Column>
          </Row>
          <Spacer height="8px" />
          <Row>
            <Column className={styles.smallScreenColumn}>
              <TenantFilter onChangeTenant={onChangeTenant} value={form.tenantIds} />
            </Column>
          </Row>
        </Column>
      ) : (
        <Row className={styles.filterGroup}>
          <SearchTextFilter
            searchText={form.searchText}
            searchTextPrefix={form.searchTextPrefix}
            onSubmitSearchTextAndPrefix={onSubmitSearchTextAndPrefix}
            disableSearchTextFilter={form.product === 'TenantAdminFeedback'}
          />
          <EmailFilter
            emailAddress={form.email}
            userId={form.userId}
            onChangeUser={onChangeUser}
            disableEmailFilter={form.product === 'TenantAdminFeedback'}
          />
          <TenantFilter onChangeTenant={onChangeTenant} value={form.tenantIds} />
        </Row>
      )}
    </Row>
  )
}
