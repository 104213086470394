import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../IDashStoreContext'
import { getUserConsentFromOdsMetadata } from './converters/utils/getUserConsentFromOdsMetadata'
import { getRawOdsTicket } from './getRawOdsTicket'

export function getTicketEmail(context: IDashStoreContext, ticketId: string): PromiseSnapshot<string> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.email
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.email = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<string> {
    const api = await getRawOdsTicket(context, ticketId).promise.catch((error) => {
      throw TicketError.create('NoEmail', { ticketId })
    })

    // Check if user has given consent
    const hasUserConsent = getUserConsentFromOdsMetadata(api)
    if (!hasUserConsent) {
      throw TicketError.create('NoUserConsentGiven', { ticketId })
    }

    // Check if email is not available
    const email = api.UserEmail || api.UserSMTPEmail || api.UserUpn
    if (!email) {
      throw TicketError.create('NoEmail', { ticketId })
    }

    // Check if email is not available due to tenant not in scope
    if (email === `Tenant not in scope of MSIT`) {
      throw TicketError.create('NoEmailDueToTenantNotInScope', { ticketId })
    }

    return email
  }
}
