import { IUpdateTicketStatusInfoData } from '@copilot-dash/domain'

export interface IState {
  readonly ticketId: string

  readonly supportedOutlineTabs: TicketScreenOutlineTab[]
  readonly supportedMainTabs: TicketScreenMainTab[]
  readonly supportedKustoTabs: TicketScreenKustoTab[]

  readonly selectedTurnId: string | undefined
  readonly selectedScopeId: string | undefined

  readonly selectedOutlineTab: TicketScreenOutlineTab
  readonly selectedMainTab: TicketScreenMainTab | undefined
  readonly selectedKustoTab: TicketScreenKustoTab | undefined

  readonly isOpenDiscussionPanel: boolean
  readonly isOpenActivityDiscussionPanel: boolean

  afterSavedCallback?: (savedInfo: IUpdateTicketStatusInfoData) => void
}

export enum TicketScreenOutlineTab {
  Conversation = 'Conversation',
  CallFlow = 'CallFlow',
}

export enum TicketScreenMainTab {
  SymptomReport = 'SymptomReport',
  ConversationData = 'ConversationData',
  KustoLog = 'KustoLog',
  TicketContext = 'TicketContext',
}

export enum TicketScreenKustoTab {
  SssLogs = 'SssLogs',
  StateAndDuration = 'StateAndDuration',
  TraceLog = 'TraceLog',
  EventLog = 'EventLog',
  LlmLog = 'LlmLog',
  BizChat3SLatency = 'BizChat3SLatency',
  BizChatPerf = 'BizChatPerf',
  ExtensibilityLog = 'ExtensibilityLog',
}

export const TicketScreenKustoTabDefinitions = {
  [TicketScreenKustoTab.SssLogs]: {
    name: '3S Logs',
  },
  [TicketScreenKustoTab.StateAndDuration]: {
    name: 'State & Duration',
  },
  [TicketScreenKustoTab.TraceLog]: {
    name: 'Trace Log',
  },
  [TicketScreenKustoTab.EventLog]: {
    name: 'Event Log',
  },
  [TicketScreenKustoTab.LlmLog]: {
    name: 'LLM Log',
  },
  [TicketScreenKustoTab.BizChat3SLatency]: {
    name: 'BizChat 3S Latency',
  },
  [TicketScreenKustoTab.BizChatPerf]: {
    name: 'BizChat Performance',
  },
  [TicketScreenKustoTab.ExtensibilityLog]: {
    name: 'Extensibility Logs',
  },
} as const satisfies Record<TicketScreenKustoTab, { readonly name: string }>
