import { z } from 'zod'

export interface ApiProductListResponse {
  readonly productList: Array<{
    readonly productId: number
    readonly productName: string
    readonly productTitle: string
  }>
  readonly errorMessage: string
}

export const apiProductListResponseSchema = z.object({
  productList: z.array(
    z.object({
      productId: z.number(),
      productName: z.string(),
      productTitle: z.string(),
    }),
  ),
  errorMessage: z.string(),
})
