import { z } from 'zod'
import { apiRootCauseContextSchema, ApiRootCauseContext } from './ApiRootCauseContext'

export interface ApiSetRootCauseRequest {
  readonly ExternalTicketId: string
  readonly RootCauseActiveList: ApiRootCauseContext[]
  readonly UpdateAreaAndIssues?: boolean
}

export const apiSetRootCauseRequestSchema = z.object({
  ExternalTicketId: z.string(),
  RootCauseActiveList: z.array(apiRootCauseContextSchema),
  UpdateAreaAndIssues: z.boolean().optional(),
})
