import { makeStyles, Skeleton, SkeletonItem, tokens } from '@fluentui/react-components'
import { Row } from '../../../../components/Layout'
import { TicketScreenStore } from '../../TicketScreenStore'
import { TicketScreenBarTicketADOLink } from './TicketScreenBarTicketADOLink'
import { TicketScreenBarTicketDate } from './TicketScreenBarTicketDate'
import { TicketScreenBarTicketDiscussion } from './TicketScreenBarTicketDiscussion'
import { TicketScreenBarTicketEmotion } from './TicketScreenBarTicketEmotion'
import { TicketScreenBarTicketHistory } from './TicketScreenBarTicketHistory'
import { TicketScreenBarTicketID } from './TicketScreenBarTicketID'
import { TicketScreenBarTicketTenant } from './TicketScreenBarTicketTenant'

export function TicketScreenBarTicket() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const ticketSnapshot = application.store.use.getTicket(ticketId)

  switch (ticketSnapshot.status) {
    case 'waiting':
      return (
        <Row className={styles.root} vAlign="center">
          <Skeleton className={styles.skeleton}>
            <SkeletonItem />
          </Skeleton>
        </Row>
      )
    case 'error':
      return (
        <Row className={styles.root} vAlign="center">
          <TicketScreenBarTicketID />
        </Row>
      )
    case 'done':
      return (
        <Row className={styles.root} vAlign="center">
          <TicketScreenBarTicketEmotion ticket={ticketSnapshot.data} />
          <TicketScreenBarTicketID />
          <TicketScreenBarTicketDate ticket={ticketSnapshot.data} />
          <TicketScreenBarTicketTenant ticket={ticketSnapshot.data} />
          <TicketScreenBarTicketADOLink ticket={ticketSnapshot.data} />
          <TicketScreenBarTicketDiscussion />
          <TicketScreenBarTicketHistory />
        </Row>
      )
  }
}

const useStyles = makeStyles({
  root: {
    gap: '16px',
  },
  skeleton: {
    minWidth: '240px',
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'row',
    gap: tokens.spacingHorizontalM,
  },
})
