import React, { useState, useMemo, useEffect } from 'react'
import { Combobox, Option, OptionGroup, useId, InfoLabel, mergeClasses, Text } from '@fluentui/react-components'
import type { OptionOnSelectData, SelectionEvents, InfoLabelProps } from '@fluentui/react-components'
import { useStyles } from './CopilotDashSelectDropdown.styles'
import { IFilterOption } from './IFilterOption.types'
import { Column } from '../Layout'
interface IProps {
  comboId: string
  filterType: string
  placeholder?: string
  infoLabelContent?: React.HTMLAttributes<HTMLElement> & InfoLabelProps['info']
  isGroup: boolean
  optionsList: IFilterOption[]
  defaultSelectedOption: string[] | undefined
  onChangeFilter: (item: string[]) => void
}

export const CopilotDashMultiSelectDropdown: React.FC<IProps> = ({
  comboId,
  filterType,
  placeholder = 'Select',
  infoLabelContent,
  isGroup,
  optionsList,
  defaultSelectedOption,
  onChangeFilter,
}) => {
  const styles = useStyles()
  const optionsListWithAll = useMemo(() => {
    const all = optionsList.some((item) => item.key === 'all')
    if (!all) {
      return [{ key: 'all', text: 'Select All' }].concat(optionsList)
    }
    return optionsList
  }, [optionsList])

  const allItems = useMemo(() => {
    return Object.values(optionsList)
      .flat()
      .map((item: IFilterOption) => item.key)
  }, [optionsList])

  const allItemsWithSelectAll = useMemo(() => {
    const all = optionsList.some((item) => item.key === 'all')
    if (!all) {
      return [...allItems, 'all']
    }
    return allItems
  }, [optionsList, allItems])

  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    defaultSelectedOption
      ? defaultSelectedOption.length === optionsList.length
        ? [...defaultSelectedOption, 'all']
        : defaultSelectedOption
      : [],
  )

  const groups = optionsList.reduce((acc: { [key: string]: IFilterOption[] }, item: IFilterOption) => {
    const menu = item.menu || ''
    ;(acc[menu] = acc[menu] || []).push(item)
    return acc
  }, {})

  const [displayValue, setDisplayValue] = useState<string | undefined>(
    defaultSelectedOption === null || defaultSelectedOption === undefined || defaultSelectedOption.length === 0
      ? ''
      : defaultSelectedOption.length === optionsList.length
        ? `All selected`
        : defaultSelectedOption.length === 1
          ? optionsList.filter((option) => defaultSelectedOption.includes(option.key)).map((option) => option.text)[0]
          : `${defaultSelectedOption.length} selected`,
  )
  const [inputValue, setInputValue] = useState('')
  const [isActive, setIsActive] = useState(false)

  const onFocus = () => {
    setIsActive(true)
    setInputValue('')
  }

  useEffect(() => {
    let selectedOptionsText
    if (defaultSelectedOption && defaultSelectedOption.length === 1) {
      selectedOptionsText = optionsList.find((option) => option.key === defaultSelectedOption[0])!.text
    }
    setSelectedOptions(
      defaultSelectedOption
        ? defaultSelectedOption.length === optionsList.length
          ? [...defaultSelectedOption, 'all']
          : defaultSelectedOption
        : [],
    )
    setDisplayValue(
      defaultSelectedOption === null || defaultSelectedOption === undefined || defaultSelectedOption.length === 0
        ? ''
        : defaultSelectedOption.length === 1
          ? selectedOptionsText
          : defaultSelectedOption.length === optionsList.length
            ? `All selected`
            : `${defaultSelectedOption.length} selected`,
    )
  }, [defaultSelectedOption, optionsList])

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayValue(event.target.value)
    setInputValue(event.target.value)
  }

  const onOptionSelect = (ev: SelectionEvents, data: OptionOnSelectData) => {
    if (data.optionValue && data.optionValue.length > 0) {
      if (data.optionValue === 'all') {
        if (selectedOptions.length === allItemsWithSelectAll.length) {
          // Case 2: If "Select All" and all other options are already selected, clear the selection
          setSelectedOptions([])
          onChangeFilter([])
        } else {
          // Case 1: If "Select All" is selected and not all other options are selected, select all options
          setSelectedOptions(allItemsWithSelectAll)
          onChangeFilter(allItems)
        }
      } else {
        if (data.selectedOptions.includes('all')) {
          // Case 3: If an option other than "Select All" is deselected while "Select All" is selected, deselect "Select All"
          setSelectedOptions(data.selectedOptions.filter((option: string) => option !== 'all'))
          onChangeFilter(data.selectedOptions.filter((option: string) => option !== 'all'))
        } else if (data.selectedOptions.length === allItems.length) {
          // Case 4: If all options other than "Select All" are selected, also select "Select All"
          setSelectedOptions(allItemsWithSelectAll)
          onChangeFilter(allItems)
        } else {
          // If none of the above cases apply, simply update the selected options
          setSelectedOptions(data.selectedOptions)
          onChangeFilter(data.selectedOptions)
        }
      }
    }
  }

  const onBlur = () => {
    setIsActive(false)
    setInputValue('')
    setDisplayValue(
      selectedOptions.length === allItemsWithSelectAll.length
        ? `All selected`
        : selectedOptions.length === 1
          ? optionsList.filter((option) => selectedOptions.includes(option.key)).map((option) => option.text)[0]
          : selectedOptions.length > 0
            ? `${selectedOptions.length} selected`
            : '',
    )
  }
  const optionRender = (item: IFilterOption) => {
    return (
      <Option value={item.key} key={item.key} text={item.key} title={item.text}>
        <Text className={styles.option}>{item.text}</Text>
      </Option>
    )
  }
  function groupFilterOptions(options: [string, IFilterOption[]][]) {
    const filteredOptions: { [key: string]: IFilterOption[] } = {}
    options.forEach(([group, items]: [string, IFilterOption[]]) => {
      const includesOption = items.filter((item) =>
        item.text.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()),
      )
      if (includesOption.length > 0) {
        filteredOptions[group] = includesOption
      }
    })

    return Object.keys(filteredOptions).length > 0 ? (
      <>
        <Option value="all" key="all" title="Select All">
          Select All
        </Option>
        {Object.entries(filteredOptions).map(([group, items]: [string, IFilterOption[]]) =>
          group ? (
            <OptionGroup key={group} label={group} className={styles.optionGroup}>
              {items.map((item) => optionRender(item))}
            </OptionGroup>
          ) : (
            items.map((item) => optionRender(item))
          ),
        )}
      </>
    ) : (
      <Text>No option match your search.</Text>
    )
  }

  function filterOptions(optionsListWithAll: IFilterOption[]) {
    const filteredOptions = optionsListWithAll.filter((item) => {
      return item.text.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    })
    return filteredOptions.length > 0 ? (
      filteredOptions.map((item) => optionRender(item))
    ) : (
      <Text>No option match your search.</Text>
    )
  }

  const targetFinalOptions = isGroup ? groupFilterOptions(Object.entries(groups)) : filterOptions(optionsListWithAll)

  const _displayValue = isActive ? inputValue : displayValue

  return (
    <Column className={styles.root}>
      <InfoLabel id={useId(comboId)} weight="semibold" info={infoLabelContent}>
        {filterType}:
      </InfoLabel>
      <Combobox
        aria-labelledby={useId(comboId)}
        placeholder={placeholder}
        multiselect={true}
        value={_displayValue}
        selectedOptions={selectedOptions}
        onFocus={onFocus}
        onChange={onChange}
        onOptionSelect={onOptionSelect}
        onBlur={onBlur}
        positioning={{
          align: 'end',
          pinned: false,
          position: 'below',
          fallbackPositions: ['above', 'below'],
        }}
        className={displayValue ? mergeClasses(styles.commonDropdown, styles.selectedDropdown) : styles.commonDropdown}
      >
        {targetFinalOptions}
      </Combobox>
    </Column>
  )
}
