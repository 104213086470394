import { useEffect } from 'react'
import { TicketScreenStore } from '../../screens/ticket/TicketScreenStore'
import { ErrorViewBoundary } from '../Error'
import { ActivityHistoryPanelLayout } from './ActivityHistoryPanelLayout'
import { useActivityHistoryPanelStore } from './store/store'
import { TelemetryScope } from '@copilot-dash/logger'

interface IProps {
  readonly ticketId: string
}

export function ActivityHistoryPanel({ ticketId }: IProps) {
  const ticketScreenActions = TicketScreenStore.useActions()
  const isOpenActivityDiscussionPanel = TicketScreenStore.use((state) => state.isOpenActivityDiscussionPanel)

  useEffect(() => {
    if (isOpenActivityDiscussionPanel) {
      useActivityHistoryPanelStore.getState().setTicketId(ticketId)
      useActivityHistoryPanelStore.getState().getActivityHistory(ticketId)
    }
  }, [isOpenActivityDiscussionPanel, ticketId])

  return (
    <ErrorViewBoundary label="ActivityHistoryPanel">
      <TelemetryScope scope="ActivityHistoryPanel" properties={{ ticketId: ticketId }} key={ticketId}>
        <ActivityHistoryPanelLayout
          isPanelOpen={isOpenActivityDiscussionPanel}
          setIsPanelOpen={ticketScreenActions.handleActivityHistoryPanelButtonClick}
        />
      </TelemetryScope>
    </ErrorViewBoundary>
  )
}
