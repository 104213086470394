import { Button } from '@fluentui/react-components'
import { ChatHelpRegular } from '@fluentui/react-icons'
import { ReactNode, useCallback } from 'react'
import { getFeedbackUrl } from '../../../../constants/getFeedbackUrl'
import { RouteLink } from '../../../../router'

const FEEDBACK_LIST_URL = 'https://aka.ms/copilotdash-issues'

export function HeaderFeedbackButton(): ReactNode {
  let timeout: NodeJS.Timeout

  const handleClick = useCallback(() => {
    Logger.telemetry.trackEvent('SendFeedback', { url: window.location.href })
  }, [])

  const handleMouseDown = () => {
    timeout = setTimeout(() => {
      application.router.openInNewTab(FEEDBACK_LIST_URL)
      clearTimeout(timeout)
    }, 1000)
  }

  const handleMouseUp = () => {
    clearTimeout(timeout)
  }

  return (
    <RouteLink path={getFeedbackUrl()} title="Report a problem" openInNewTab>
      <Button
        appearance="transparent"
        shape="square"
        size="medium"
        onClick={handleClick}
        icon={<ChatHelpRegular />}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        Feedback
      </Button>
    </RouteLink>
  )
}
