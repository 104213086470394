import { IKustoStateDurationLogItem } from '@copilot-dash/domain'
import { Node } from '@xyflow/react'
import { compact } from 'lodash'
import { CallFlowNode, CallFlowNodeType } from '../store/IState'
import { generateNode } from './generateNode'

export function generateNodes(
  turnId: string,
  items: IKustoStateDurationLogItem[],
  types: Array<CallFlowNodeType>,
): Node<CallFlowNode>[] {
  const scopeNames = new Set<string>()
  const scopeIds = new Set<string>()

  const nodes = items.map((item) => {
    if (!item.scopeName || !item.scopeId) {
      return
    }

    if (scopeNames.has(item.scopeName) || scopeIds.has(item.scopeId)) {
      return
    }

    scopeNames.add(item.scopeName)
    scopeIds.add(item.scopeId)
    return generateNode(turnId, item)
  })

  return compact(nodes).filter((node) => {
    return types.length === 0 || types.includes(node.data.type)
  })
}
