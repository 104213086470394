import { IProductData, parseProductName, DefaultProductChannelMapping } from '@copilot-dash/domain'
import { useCallback, useMemo } from 'react'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { GlobalProductSelector } from '../../../providers/product'
import { SearchScreen } from '../../../screens/search/SearchScreen'
import { NotFoundRoutePage } from '../404/NotFoundRoutePage'
import { SearchRoute } from './SearchRoute'
import { useSearchScreenNavigate } from './useSearchScreenNavigate'
import { useSearchScreenQuery } from './useSearchScreenQuery'

export function SearchRoutePage() {
  const args = SearchRoute.navigator.useArgs()
  const query = useSearchScreenQuery()
  const navigate = useSearchScreenNavigate()

  const handleSelectProduct = useCallback((product: IProductData) => {
    SearchRoute.navigator.navigate({
      product: product.name,
      tab: 'allFeedback',
      channel: DefaultProductChannelMapping[product.name],
    })
  }, [])

  const isProductValid = useMemo(() => {
    return parseProductName(args.product) !== undefined
  }, [args.product])
  if (!isProductValid) {
    return <NotFoundRoutePage />
  }

  return (
    <GlobalProductSelector value={args.product} onChange={handleSelectProduct}>
      <PageTitle title="Search">
        <SearchScreen key={args.product} query={query} onNavigate={navigate} />
      </PageTitle>
    </GlobalProductSelector>
  )
}
