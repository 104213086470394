import { ISearchTicketOptions } from '@copilot-dash/store'
import { FC, memo, useCallback, useState } from 'react'
import { ColumnSettingButton } from '../../../components/Button/ColumnSettingButton'
import { TicketsFilterButton } from '../../../components/Button/TicketsFilterButton'
import { Right, Row, Spacer } from '../../../components/Layout'
import { EmailFilter } from '../../../components/SearchOptions/EmailFilter'
import { TenantFilter } from '../../../components/SearchOptions/TenantFilter'
import { ResizeDimentions, useResizeObserver } from '../../../hooks/useResizeObserver'
import { TeamRoute } from '../../../router'
import { useTeamViewStore } from '../storeNew'
import { TeamViewSubMenuIds, SearchTextPrefixType } from '@copilot-dash/domain'
import { SMALL_SCREEN_WIDTH, useStyles } from './QuickSearch.styles'
import { SUPPORTED_TAGS } from './SupportedTags'
import { SearchTextFilter } from '../../../components/SearchOptions/SearchTextFilter'

export const QuickSearch: FC = memo(() => {
  const styles = useStyles()
  const form = useTeamViewStore((state) => state.tickets.filterForm)
  const currentArgs = TeamRoute.navigator.getArgs()

  const handleChange = useCallback(
    (kv: Partial<ISearchTicketOptions>) => {
      // all fetch tickets actions will be trigged by the url args change, so we just need to navigate to the right url
      // the url monitor is in the src/screens/team/TeamScreen.tsx
      TeamRoute.navigator.navigate({
        ...currentArgs,
        ...kv,
      })
    },
    [currentArgs],
  )

  const handleChangeSearchTextAndPrefix = useCallback(
    (searchTextPrefix: SearchTextPrefixType, searchText?: string) => {
      // all fetch tickets actions will be trigged by the url args change, so we just need to navigate to the right url
      // the url monitor is in the src/screens/team/TeamScreen.tsx
      if (searchText !== currentArgs.searchText || (searchTextPrefix !== currentArgs.searchTextPrefix && searchText)) {
        TeamRoute.navigator.navigate({
          ...currentArgs,
          searchTextPrefix: searchTextPrefix,
          searchText: searchText,
        })
      }
    },
    [currentArgs],
  )
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  const handleResize = (width: number) => {
    if (width < SMALL_SCREEN_WIDTH) {
      isSmallScreen === false && setIsSmallScreen(true)
    } else {
      isSmallScreen === true && setIsSmallScreen(false)
    }
  }
  const ref = useResizeObserver(ResizeDimentions.Width, handleResize)
  const filterPanelOpened = useTeamViewStore((state) => state.computed.filterPanelOpen)
  const columnSettingOpened = useTeamViewStore((state) => state.computed.columnSettingOpen)

  const validTags = SUPPORTED_TAGS.filter((tag) => form[tag.key])

  return (
    <Row className={styles.wrapper} wrap={isSmallScreen} ref={ref}>
      <SearchTextFilter
        className={styles.utteranceAndResponseFilter}
        searchText={form.searchText}
        searchTextPrefix={form.searchTextPrefix}
        onSubmitSearchTextAndPrefix={handleChangeSearchTextAndPrefix}
        disableSearchTextFilter={form.product === 'TenantAdminFeedback'}
        placeholder="Search"
      />
      <Spacer width={6} height={32} />
      <EmailFilter
        emailAddress={form.email}
        userId={form.userId}
        onChangeUser={(userId, email) => handleChange({ userId, email })}
        disableEmailFilter={form.product === 'TenantAdminFeedback'}
        className={styles.emailFilter}
      />
      <Spacer width={6} height={32} />
      <TenantFilter
        onChangeTenant={(tenantIds) => handleChange({ tenantIds })}
        value={form.tenantIds}
        className={styles.tenantIds}
      />
      <Spacer height={32} style={{ flexGrow: 1 }} />
      <Right className={styles.right}>
        <TicketsFilterButton
          placeholder={isSmallScreen ? '' : 'Filters'}
          isTicketsFilterPanelOpen={filterPanelOpened}
          onClickButton={() => {
            useTeamViewStore.getState().toggleFilterPanel((bool) => !bool)
          }}
          badgeCount={validTags.length + (currentArgs.subMenu === TeamViewSubMenuIds.TopIssues ? 0 : 1)}
        />
        <Spacer width={6} />
        <ColumnSettingButton
          placeholder={isSmallScreen ? '' : 'Column Settings'}
          isColumnSettingPanelOpen={columnSettingOpened}
          onClickButton={() => {
            useTeamViewStore.getState().toggleColumnSetting((bool) => !bool)
          }}
        />
      </Right>
    </Row>
  )
})

QuickSearch.displayName = 'QuickSearch'
