import { useFilterOptions } from '@copilot-dash/store'
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderTitle,
  Spinner,
  Text,
} from '@fluentui/react-components'
import { Dismiss24Regular, Filter16Filled } from '@fluentui/react-icons'
import { forwardRef } from 'react'
import { CustomizedFilterDialog } from '../../CustomizedFilterDialog/CustomizedFilterDialog'
import { Column, Row } from '../../Layout'
import { useScrollbar } from '../../Scrollbar/useScrollbar'
import { FilterPanelBody } from '../children/FilterPanelBody'
import { ITicketFilterFormRef } from '../children/TicketFilterForm/TicketFilterForm.types'
import { ITicketFilterPanel } from '../TicketFilterPanel.types'
import { useStyles } from './TicketsFilterPanelLayout.styles'
import { useTicketFilterStore } from '../store/store'
import { ProductIds } from '@copilot-dash/domain'

export const TicketsFilterPanelLayout = forwardRef<ITicketFilterFormRef, ITicketFilterPanel>(
  (props: ITicketFilterPanel, ref) => {
    const styles = useStyles()
    const filterOptionsSnapshot = useFilterOptions('', '')
    const scrollbarRef = useScrollbar()
    const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)

    return (
      <Drawer
        className={styles.drawer}
        type="inline"
        separator
        open={props.isPanelOpen}
        onOpenChange={props.onDismiss}
        position="end"
      >
        <DrawerHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close Panel"
                icon={<Dismiss24Regular />}
                onClick={props.onDismiss}
              />
            }
          >
            <Filter16Filled />
            <Text className={styles.title}>Filters</Text>
          </DrawerHeaderTitle>
        </DrawerHeader>

        <DrawerBody ref={scrollbarRef}>
          <Column fill>
            <Divider />
            {filterOptionsSnapshot.status === 'waiting' ? (
              <Row fill vAlign="center" hAlign="center">
                <div className={styles.loading}>
                  <Spinner labelPosition="below" label="Loading..." />
                </div>
              </Row>
            ) : (
              <FilterPanelBody {...props} ref={ref} />
            )}
          </Column>
        </DrawerBody>

        <DrawerFooter className={styles.footer}>
          {props.selectedProductId !== ProductIds.TenantAdminFeedback && (
            <CustomizedFilterDialog
              ticketFilterForm={props.ticketFilterForm}
              supportedTicketFilterItems={filterPanelItems}
            >
              <Button disabled={filterOptionsSnapshot.status === 'waiting'}>Add filters</Button>
            </CustomizedFilterDialog>
          )}

          <Button onClick={props.onClickReset} disabled={filterOptionsSnapshot.status === 'waiting'}>
            Reset
          </Button>
        </DrawerFooter>
      </Drawer>
    )
  },
)
TicketsFilterPanelLayout.displayName = 'TicketsFilterPanelLayout'
