import { IKustoStateDurationLogItem } from '@copilot-dash/domain'
import { CallFlowNode } from '../store/IState'
import { generateNodeType } from './generateNodeType'

export function generateNodeData(turnId: string, item: IKustoStateDurationLogItem): CallFlowNode {
  return {
    id: item.scopeName,
    label: item.scopeName,
    type: generateNodeType(item.scopeName),
    turnId,
    item,
  }
}
