import { TimeRange } from '@copilot-dash/core'
import { TicketRingType, SearchFilterFieldNames, TicketDSATStatusType } from '@copilot-dash/domain'

export interface ITicketFilterFormData {
  range?: TimeRange
  client?: string[]
  customerTypes?: string[]
  promptLanguages?: string[]
  ring?: TicketRingType[]
  groundedPrompts?: string[]
  scenarioType?: string[]
  thumbs?: string[]
  hasVerbatim?: string[]
  hasUserConsent?: string[]
  hasScreenshot?: string[]
  has3SLog?: string[]
  hasConversationHistory?: string[]
  isTest?: string[]
  invocationType?: string[]
  isWebToggleOn?: string[]
  triggeredSkill?: string[]
  hasCitation?: string[]
  hasEntityCard?: string[]
  searchResultSegment?: string[]
  botEndPoint?: string[]
  hitAvalon?: string[]
  isApology?: string[]
  priority?: string[]
  isSTCAChina?: string[]
  isTopi18N?: string[]
  responseHeroType?: string[]
  responseLinkType?: string[]
  semanticSearchType?: string[]
  scenarioGroup?: string[]
  bizchatScenario?: string[]
  experienceType?: string[]
  hasConnector?: string[]
  hasGPTExtension?: string[]
  hasMessageExtension?: string[]
  hasCopilotExtensionIds?: string[]
  optionsSets?: string
  errorCode?: string[]
  channel?: string[]
  isGCIntent?: string[]
  hasConnectorResult?: string[]
  dSATStatus?: TicketDSATStatusType[]
  customTags?: string[]
  dSATAssignedTo?: string
  sliceIds?: string
  copilotExtensionIds?: string
}

export interface ITicketFilterFormProps {
  onSubmit: (data?: ITicketFilterFormData) => void
  defaultValues?: ITicketFilterFormData
}

export type WatchFn = (values: ITicketFilterFormProps['defaultValues']) => void

export interface ITicketFilterFormRef {
  setValues: (values: Partial<ITicketFilterFormProps['defaultValues']>) => void
  getValues: () => ITicketFilterFormProps['defaultValues']
  watch: (fn: WatchFn) => void
}

export const customFiltersToAdd: Array<{ key: keyof ITicketFilterFormData; name: SearchFilterFieldNames }> = [
  { key: 'isTopi18N', name: SearchFilterFieldNames.isTopi18N },
  { key: 'isSTCAChina', name: SearchFilterFieldNames.isSTCAChina },
  { key: 'hasGPTExtension', name: SearchFilterFieldNames.hasGPTExtension },
  { key: 'hasMessageExtension', name: SearchFilterFieldNames.hasMessageExtension },
  { key: 'hasCopilotExtensionIds', name: SearchFilterFieldNames.hasCopilotExtensionIds },
  { key: 'hasConnector', name: SearchFilterFieldNames.hasConnector },
  { key: 'isGCIntent', name: SearchFilterFieldNames.isGCIntent },
  { key: 'hasConnectorResult', name: SearchFilterFieldNames.hasConnectorResult },
  { key: 'semanticSearchType', name: SearchFilterFieldNames.semanticSearchType },
  { key: 'experienceType', name: SearchFilterFieldNames.experienceType },
  { key: 'errorCode', name: SearchFilterFieldNames.errorCode },
  { key: 'copilotExtensionIds', name: SearchFilterFieldNames.copilotExtensionIds },
]
