import { Row } from '../../../components/Layout'
import { useStyles } from './SearchScreenTooltip.styles'
import { Text } from '@fluentui/react-components'
import { InfoRegular } from '@fluentui/react-icons'

export function SearchScreenTooltip() {
  const styles = useStyles()

  return (
    <Row className={styles.container}>
      <Text>
        <InfoRegular className={styles.icon} fontSize={18} />
      </Text>
      <Text className={styles.text}>
        Search within Utterance/Response only works for tickets received after 4/28. Perfomance may be slow.
      </Text>
    </Row>
  )
}
