import { dayjs } from '@copilot-dash/core'
import { useUserSetting } from '@copilot-dash/settings'
import { Select } from '@fluentui/react-components'
import { ComponentProps, useId } from 'react'
import { GetTimeZoneList } from './GetTimeZoneList'

export function TimeZoneTile() {
  const labelId = useId()
  const [value, setValue] = useUserSetting('timeZone')
  const disabled = application.env.ring === 'msit'
  const onChange: ComponentProps<typeof Select>['onChange'] = (_, data) => {
    const newValue = data.value
    if (newValue) {
      setValue(newValue)
    }
  }
  return (
    <>
      <label htmlFor={labelId}>Select timezone</label>
      <Select id={labelId} value={value ?? dayjs().utcOffset() / 60} onChange={onChange} disabled={disabled}>
        {GetTimeZoneList().map((item) => (
          <option key={item.id} value={item.id}>
            {item.display}
          </option>
        ))}
      </Select>
    </>
  )
}
