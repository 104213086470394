import { ApiBigFunnelLogParam, apiBigFunnelLogParamSchema } from './ApiBigFunnelLogParam'
import { ApiTicketContext, apiTicketContextSchema } from './ApiTicketContext'
import { z } from 'zod'

export interface ApiSearchIssueContext {
  readonly TraceId?: string
  readonly DateTimeUtc?: string
  readonly ServerName?: string
  readonly TicketId?: string
  readonly ClientName?: string
  readonly ClientRequestId?: string
  readonly AdditionalContext?: string
  readonly TicketContexts?: Array<ApiTicketContext>
  readonly Summary?: string
  readonly LogsReady?: boolean
  readonly EyesOnType?: string
  readonly SpeechTraceId?: string
  readonly TransactionId?: string
  readonly LogicalId?: string
  readonly CorrelationId?: string
  readonly TeeRequestId?: string
  readonly RequestContext?: string
  readonly DiagnosticSessionId?: string
  readonly MailboxGuid?: string
  readonly Ring?: string
  readonly RouteAction?: string
  readonly TicketContext?: ApiTicketContext
  readonly SecondaryDiagnosticSessionId?: string
  readonly ApplicationId?: string
  readonly UserId?: string
  readonly BigFunnelLogParams?: Array<ApiBigFunnelLogParam>
  readonly ResultCount?: string
}

export const apiSearchIssueContextSchema = z.object({
  TraceId: z.string().optional(),
  DateTimeUtc: z.string().optional(),
  ServerName: z.string().optional(),
  TicketId: z.string().optional(),
  ClientName: z.string().optional(),
  ClientRequestId: z.string().optional(),
  AdditionalContext: z.string().optional(),
  TicketContexts: z.array(apiTicketContextSchema).optional(),
  Summary: z.string().optional(),
  LogsReady: z.boolean().optional(),
  EyesOnType: z.string().optional(),
  SpeechTraceId: z.string().optional(),
  TransactionId: z.string().optional(),
  LogicalId: z.string().optional(),
  CorrelationId: z.string().optional(),
  TeeRequestId: z.string().optional(),
  RequestContext: z.string().optional(),
  DiagnosticSessionId: z.string().optional(),
  MailboxGuid: z.string().optional(),
  Ring: z.string().optional(),
  RouteAction: z.string().optional(),
  TicketContext: apiTicketContextSchema.optional(),
  SecondaryDiagnosticSessionId: z.string().optional(),
  ApplicationId: z.string().optional(),
  UserId: z.string().optional(),
  BigFunnelLogParams: z.array(apiBigFunnelLogParamSchema).optional(),
  ResultCount: z.string().optional(),
})
