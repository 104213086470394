import { IStoreGetter, IStoreSetter } from '@copilot-dash/core'
import { IActions } from './IActions'
import { IState } from './IState'

interface IParams {
  readonly get: IStoreGetter<IState>
  readonly set: IStoreSetter<IState>
  readonly onSelect: (turnId: string, scopeId: string | undefined) => void
}

export function createActions({ get, set, onSelect }: IParams): IActions {
  return {
    selectTurn(turnId) {
      onSelect(turnId, undefined)
    },

    selectScope(turnId, scopeId) {
      onSelect(turnId, scopeId)
    },

    selectType(type) {
      set((state) => {
        if (!state.selectedTypes.includes(type)) {
          state.selectedTypes.push(type)
        }
      })
    },

    unselectType(type) {
      set((state) => {
        const index = state.selectedTypes.indexOf(type)
        if (index !== -1) {
          state.selectedTypes.splice(index, 1)
        }
      })
    },
  }
}
