import { makeStyles, Text, tokens } from '@fluentui/react-components'
import { compact } from 'lodash'
import { useMemo } from 'react'
import { Column } from '../../../../../components/Layout'
import { IPropertyBlock, PropertyTable } from '../../../../../components/Table/PropertyTable/PropertyTable'
import { useDateDisplayTextParser } from '../../../../../hooks/useDateDisplayTextParser'
import { TicketScreenStore } from '../../../TicketScreenStore'
import { TicketContextConfig } from './TicketContextConfig'

export function TicketContext() {
  const styles = useStyles()

  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const ticketSnapshot = app.store.use.getTicket(ticketId)
  const ticketData = ticketSnapshot.data

  const ticketSessionSnapshot = app.store.use.getTicketSession(ticketId)
  const ticketSession = ticketSessionSnapshot.data

  const emailSnapshot = application.store.use.getTicketEmail(ticketId)
  const timeParser = useDateDisplayTextParser()
  const ticketContextConfig = useMemo(
    () =>
      ticketData && {
        renderHeader: () => <Text className={styles.blockTitle}>Ticket Context</Text>,
        items: TicketContextConfig.buildBlockItems(ticketData, timeParser, ticketSession, emailSnapshot),
        collapsible: true,
      },
    [ticketSession, styles.blockTitle, ticketData, emailSnapshot, timeParser],
  )

  const additionalContextConfig = useMemo((): IPropertyBlock | undefined => {
    const additionalContextObject = ticketData?.additionalContextObject
    if (!additionalContextObject) {
      return undefined
    }

    return {
      renderHeader: () => <Text className={styles.blockTitle}>Additional Context</Text>,
      items: TicketContextConfig.buildAdditionalContextItems(additionalContextObject),
      collapsible: true,
    }
  }, [styles.blockTitle, ticketData?.additionalContextObject])

  return (
    <Column fill className={styles.root}>
      <PropertyTable blocks={compact([ticketContextConfig, additionalContextConfig])} />
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '16px',
    height: 'fit-content',
  },
  blockTitle: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorNeutralForeground1,
  },
})
