import { useAllCustomTags } from '@copilot-dash/store'
import { Spinner } from '@fluentui/react-components'
import { memo, useMemo } from 'react'
import { CopilotDashMultiSelectDropdown } from '../../../Filter/CopilotDashMultiSelectDropdown'
import { IFilterOption } from '../../../Filter/IFilterOption.types'

interface IProps {
  selectedValues: string[] | undefined
  onChangeValue: (value: string[]) => void
}

export const CustomTagsFilter: React.FC<IProps> = memo(({ selectedValues, onChangeValue }) => {
  const filterOptionsSnapshot = useAllCustomTags()
  const filterOptions: IFilterOption[] = useMemo(() => {
    if (filterOptionsSnapshot.status !== 'done') {
      return []
    }
    // Merge filterOptionsSnapshot.data and selectedValues
    const allTags = [...filterOptionsSnapshot.data, ...(selectedValues || [])]
    const uniqueTags = Array.from(new Set(allTags))
    return uniqueTags.map((tag) => ({
      key: tag,
      text: tag,
    }))
  }, [filterOptionsSnapshot.data, filterOptionsSnapshot.status, selectedValues])

  return filterOptionsSnapshot.status === 'waiting' ? (
    <Spinner size="extra-small" />
  ) : (
    <CopilotDashMultiSelectDropdown
      comboId="CustomTagsFilter"
      filterType="Tags"
      optionsList={filterOptions}
      isGroup={false}
      defaultSelectedOption={selectedValues}
      onChangeFilter={onChangeValue}
      placeholder="Search Tags"
    />
  )
})

CustomTagsFilter.displayName = 'CustomTagsFilter'
