import { INewTicketData } from '@copilot-dash/domain'
import { memo } from 'react'
import { groupTicketsByTeam } from './utils'
import { CollapsibleTable } from './CollapsibleTable'
import { Column } from '../../../../../../../components/Layout'

interface IGroupedTicketsByTeamProps {
  tickets: INewTicketData[]
}

export const GroupedTicketsByTeam = memo(function GroupedTicketsByTeam(props: IGroupedTicketsByTeamProps) {
  const { tickets } = props

  const nestedArray = Object.entries(groupTicketsByTeam(tickets))
    .filter(([teamName, tickets]) => tickets.length > 0 && teamName !== 'undefined')
    .sort((a, b) => b[1].length - a[1].length)

  return (
    <Column style={{ width: '100%' }}>
      {nestedArray.map(([teamName, tickets], index) => (
        <CollapsibleTable key={teamName} tickets={tickets} title={teamName} defaultExpanded={index === 0} />
      ))}
    </Column>
  )
})
