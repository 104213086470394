import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  editorContainerStyle: {
    margin: '20px auto',
    borderRadius: '2px',
    color: '#000',
    position: 'relative',
    lineHeight: '20px',
    fontWeight: 400,
    textAlign: 'left',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    width: '100%',
    height: '100%',
  },
  editorInner: {
    background: '#fff',
    position: 'relative',
    width: '100%',
    height: 'auto',
  },
  editorInput: {
    resize: 'none',
    fontSize: '15px',
    caretColor: 'rgb(5, 5, 5)',
    position: 'relative',
    tabSize: 1,
    outline: '0',
    padding: '10px',
    maxHeight: '200px',
    overflowY: 'auto',
  },
  editorPlaceholder: {
    color: '#999',
    overflow: 'hidden',
    position: 'absolute',
    textOverflow: 'ellipsis',
    top: '10px',
    left: '10px',
    fontSize: '15px',
    userSelect: 'none',
    display: 'inline-block',
    pointerEvents: 'none',
  },
  divider: { padding: '0 10px' },
})
