import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
const DISCUSSION_PANEL_WIDTH = 386
export const useStyles = makeStyles({
  grayColor: {
    color: tokens.colorScrollbarOverlay,
  },
  card: {
    width: `${DISCUSSION_PANEL_WIDTH}px`,
    marginTop: '56px',
  },
  drawerHeader: {
    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.10)',
    ...shorthands.padding('10px'),
    width: '100%',
  },
  title: {
    alignItems: 'center',
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
    marginLeft: tokens.spacingHorizontalSNudge,
  },
  drawerBody: {
    ...shorthands.padding('5px', '25px', '5px', '10px'),
    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.10)',
    overflowX: 'hidden',
  },
  footer: {
    display: 'flex',
    ...shorthands.padding(0, 0, '20px', '5px'),
  },
  waiting: {
    minHeight: '521px',
    minWidth: `${DISCUSSION_PANEL_WIDTH}px`,
  },
})
