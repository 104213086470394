import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../IDashStoreContext'
import { raceRequests } from '../utils/raceRequests'
import { getRawOcvTicket } from './getRawOcvTicket'
import { getTicketSession } from './getTicketSession'
import { validateTicketVerbatimContent } from './validators/validateTicketVerbatim'

export function getTicketVerbatim(context: IDashStoreContext, ticketId: string): PromiseSnapshot<string> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.verbatim
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.verbatim = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<string> {
    const verbatim = await doFetch()
    if (verbatim) {
      validateTicketVerbatimContent(ticketId, verbatim)
      return verbatim
    }

    throw TicketError.create('NoVerbatim', { ticketId })
  }

  async function doFetch(): Promise<string> {
    return raceRequests({
      primary: fetchFromSession(),
      secondary: fetchFromOCV(),
    })
  }

  async function fetchFromOCV(): Promise<string> {
    const ocvTicket = await getRawOcvTicket(context, ticketId).promise

    const verbatimFromOcvTicket = ocvTicket?.CustomerVerbatimOriginal
    if (verbatimFromOcvTicket) {
      return verbatimFromOcvTicket
    }

    throw TicketError.create('NoVerbatim', { ticketId })
  }

  async function fetchFromSession(): Promise<string> {
    const sessionTicket = await getTicketSession(context, ticketId).promise

    const verbatimFromTicket = sessionTicket?.verbatim
    if (verbatimFromTicket) {
      return verbatimFromTicket
    }

    throw TicketError.create('NoVerbatim', { ticketId })
  }
}
