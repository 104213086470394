import { TimeRange, Times } from '@copilot-dash/core'
import { useUserSetting } from '@copilot-dash/settings'
import { Input, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { CalendarLtrRegular, ChevronDownRegular } from '@fluentui/react-icons'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { CalendarProps, DateObject, DatePickerProps, DatePickerRef } from 'react-multi-date-picker'
import { DatePickerWrapped } from '../ReactMultiDatePickerWrapped/ReactMultiDatePickerWrapped'
import { TimeRangePickerPanel } from '../ReactMultiDatePickerWrapped/plugins/TimeRangePickerPanel'
import { TimeZoneInfo } from '../ReactMultiDatePickerWrapped/plugins/TimeZoneInfo'
import { toMicroseconds } from '../ReactMultiDatePickerWrapped/utils'
import { DateRangePickerHeader } from './QuickSelectors'
import { Submit } from './Submit'

interface IProps {
  readonly range: TimeRange | undefined
  readonly onChanged: (range: TimeRange) => void
  readonly minDate?: string
  readonly maxDate?: string
}

type DateValue = DateObject | Date | string | number
function isArrayWithTwoDates(value: unknown): value is [DateValue, DateValue] {
  return Array.isArray(value) && value.length === 2
}

export const DateTimeRangePicker = memo(({ range, onChanged, minDate, maxDate }: IProps) => {
  const styles = useStyles()
  const [timezone] = useUserSetting('timeZone')
  const [value, setValue] = useState<CalendarProps['value']>(null) // NOTE: value for the calendar
  const datePickerRef = useRef<DatePickerRef>(null)

  // Reset value from the props
  const resetValue = useCallback(() => {
    if (!range) {
      return
    }

    const { from, to } = Times.formatTimeRange(range, { timezone })
    if (!from || !to) {
      return
    }

    setValue([new Date(from), new Date(to)])
  }, [range, timezone])

  // Reset value when first render
  useEffect(() => {
    resetValue()
  }, [resetValue])

  // Handle calendar value change
  const handleChange: DatePickerProps['onChange'] = (date) => {
    setValue(date)
  }

  // submit the value when OK button is clicked
  const handleOK = useCallback(() => {
    if (isArrayWithTwoDates(value)) {
      const [start, end] = value
      const startNum = toMicroseconds(start)
      const endNum = toMicroseconds(end)

      const startStr = Times.format(startNum, { timezone })
      const endStr = Times.format(endNum, { timezone })
      if (startStr && endStr) {
        onChanged({ type: 'absolute', from: startStr, to: endStr })
      }
    }

    if (datePickerRef.current) {
      datePickerRef.current.closeCalendar()
    }
  }, [onChanged, timezone, value])

  const handleQuickSelectorChange = useCallback(
    (range: TimeRange) => {
      onChanged(range)

      if (datePickerRef.current) {
        datePickerRef.current.closeCalendar()
      }
    },
    [onChanged],
  )

  // NOTE: @Ethan - disable no-explicit-any rule due to the need of picking the correct type from DatePickerProps['render']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderInput = useCallback<Extract<DatePickerProps['render'], (...args: any[]) => any>>(
    (value, openCalendar) => {
      return (
        <Input
          className={styles.input}
          value={range && range.type === 'relative' ? Times.toStringOfRelativeTimeRange(range) : value}
          type="text"
          onClick={openCalendar}
          contentBefore={<CalendarLtrRegular onClick={openCalendar} style={{ cursor: 'pointer' }} />}
          contentAfter={
            <ChevronDownRegular aria-label="Dropdown" onClick={openCalendar} style={{ cursor: 'pointer' }} />
          }
        />
      )
    },
    [styles.input, range],
  )

  return (
    <DatePickerWrapped
      ref={datePickerRef}
      timezone={timezone}
      className={'DateRangePicker'}
      value={value}
      minDate={minDate}
      maxDate={maxDate}
      format="MM/DD HH:mm"
      range={true}
      arrow={false}
      showOtherDays={true}
      disableYearPicker={true}
      numberOfMonths={1}
      monthYearSeparator=" "
      onChange={handleChange}
      render={renderInput}
      onClose={() => {
        // Reset value when the calendar is closed without submitting
        resetValue()
      }}
      plugins={[
        <DateRangePickerHeader key="MyPlugin" position="top" range={range} onChanged={handleQuickSelectorChange} />,
        <TimeZoneInfo key="MessageBarHitPlugin" position="top" timezone={timezone} />,
        <TimeRangePickerPanel key="TimeRangePickerPanel" position="bottom" />,
        <Submit
          key="Submit"
          position="bottom"
          onClick={handleOK}
          submittable={!Array.isArray(value) || (value?.length ?? 0) !== 2}
        />,
      ]}
      dateSeparator=" to "
    />
  )
})

DateTimeRangePicker.displayName = 'DateTimeRangePicker'

const useStyles = makeStyles({
  input: {
    width: '100%',
    marginLeft: '0px',
    ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    '&> span': {
      color: tokens.colorBrandForeground1,
    },
    '&> input': {
      fontWeight: tokens.fontWeightSemibold,
    },
    ':hover': {
      ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    },
  },
})
