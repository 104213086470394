import { IRouteQueryParams } from '../../../types/IRouteParams'

export function generatePathQueryStringByQueryParams(
  queryParams: IRouteQueryParams | undefined,
  withQuestionMark: boolean = true,
) {
  if (!queryParams) {
    return ''
  }

  const urlSearchParams = new URLSearchParams()
  for (const [key, value] of Object.entries(queryParams)) {
    if (typeof value === 'string') {
      if (value.length > 0) {
        urlSearchParams.append(key, value)
      }
    } else if (Array.isArray(value)) {
      value.forEach((v) => {
        urlSearchParams.append(key, v)
      })
    }
  }

  let queryString = urlSearchParams.toString()
  if (queryString.length > 0 && withQuestionMark) {
    queryString = `?${queryString}`
  }

  return queryString
}
