import { memo, useCallback, useEffect, useState } from 'react'
import { Column, Row, Spacer } from '../../../../../../components/Layout'
import { Dropdown, Option, Text, tokens } from '@fluentui/react-components'
import { TopIssueTable } from './TopIssueTable'
import { Legend } from '../../../../../../components/RatioProgressBar/Legend'
import { ITopIssuesBatchInfoList } from '@copilot-dash/domain'
import { SearchRoute } from '../../../../../../router'
import { CalendarRegular } from '@fluentui/react-icons'
import { TimeView } from '../../../../../../components/Time/TimeView'
import { useSearchScreenActions } from '../../../../store'

export const TopIssue = memo(() => {
  const [topIssueBatchList, setTopIssueBatchList] = useState<ITopIssuesBatchInfoList>([])
  const args = SearchRoute.navigator.useArgsOptional()
  const actions = useSearchScreenActions()

  useEffect(() => {
    application.store.actions.getOrFetchTeamIssueClusteringBatchInfoList().then((snapshot) => {
      setTopIssueBatchList(snapshot)
      const currentArgs = SearchRoute.navigator.getArgsOptional()
      if (!currentArgs?.issueBatchId) {
        currentArgs?.product && SearchRoute.navigator.navigate({ ...currentArgs, issueBatchId: snapshot[0]?.batchId })
      }
    })
  }, [])

  const selectedBatchId = args?.issueBatchId ?? topIssueBatchList[0]?.batchId
  const selectedBatchInfo = topIssueBatchList.find((item) => item.batchId === selectedBatchId)

  const handleBatchChange = useCallback(
    (batchId: string) => {
      args?.product && SearchRoute.navigator.navigate({ ...args, issueBatchId: batchId })
      actions.onDismissTicketSummaryPanel()
    },
    [args, actions],
  )

  return (
    <Column>
      <Row>
        <Text weight="semibold" size={500}>
          Top Issues
        </Text>
        <Spacer />
        <Legend
          items={[
            {
              color: tokens.colorPaletteGreenBackground2,
              label: 'Closed',
            },
            {
              color: tokens.colorCompoundBrandBackground,
              label: 'Root Caused',
            },
            {
              color: tokens.colorPaletteMarigoldBackground3,
              label: 'Team Assigned',
            },
          ]}
        />
        <Spacer width={16} />
        <Dropdown
          selectedOptions={selectedBatchId ? [selectedBatchId] : []}
          onOptionSelect={(_, data) => data.optionValue && handleBatchChange(data.optionValue)}
          button={
            selectedBatchInfo ? (
              <Row vAlign="center">
                <CalendarRegular fontSize={20} />
                <Spacer width={4} />
                <TimeView value={selectedBatchInfo.from} format="YYYY/M/D" />
                {' - '}
                <TimeView value={selectedBatchInfo.to} format="YYYY/M/D" />
              </Row>
            ) : undefined
          }
        >
          {topIssueBatchList.map((item) => (
            <Option key={item.batchId} value={item.batchId} text={`${item.from}-${item.to}`}>
              <Text>
                <TimeView value={item.from} format="YYYY/M/D" />
                {' - '}
                <TimeView value={item.to} format="YYYY/M/D" />
              </Text>
            </Option>
          ))}
        </Dropdown>
      </Row>
      <Spacer height={16} />
      <Row>
        <TopIssueTable />
      </Row>
    </Column>
  )
})

TopIssue.displayName = 'TopIssue'
