import { SymptomReport } from '../../SymptomReport/SymptomReport'
import { useTicketSummaryPanelState } from '../store'

export function SymptomReportLayout() {
  const state = useTicketSummaryPanelState()
  const ticketId = state.ticketId
  const turnId = state.selectedTurnId
  if (!turnId) {
    return null
  }

  return <SymptomReport ticketId={ticketId} turnId={turnId} />
}
