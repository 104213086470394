import { Text } from '@fluentui/react-components'
import { FC } from 'react'
import { Row, Spacer } from '../../Layout'
import { TimeView } from '../../Time/TimeView'
import { useStyles } from './ActivityTitle.styles'
import { ActivityTitleAvatarForUser } from './ActivityTitleAvatarForUser'
import { ActivityTitleAvatarForSystem } from './ActivityTitleAvatarForSystem'
import { IActivityHistory } from '@copilot-dash/domain'
import { useActivityHistoryPanelStore } from '../store/store'
import { ChevronUpFilled, ChevronDownFilled } from '@fluentui/react-icons'

interface IProps {
  readonly activity: IActivityHistory
}

export const ActivityTitle: FC<IProps> = ({ activity }) => {
  const styles = useStyles()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)

  return (
    <Row fill vAlign="center">
      {activity.actor === 'user' && activity.userId ? (
        <ActivityTitleAvatarForUser userId={activity.userId} />
      ) : (
        <ActivityTitleAvatarForSystem />
      )}
      <Spacer />
      <Text className={styles.time}>
        <TimeView value={activity.timestamp} format="YYYY/MM/DD hh:mm A" />
        <Text className={styles.icon}>
          {selectedActivity?.activityId === activity.activityId ? <ChevronUpFilled /> : <ChevronDownFilled />}
        </Text>
      </Text>
    </Row>
  )
}
