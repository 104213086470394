import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Row } from '../../Layout'
import { useEffect, useState } from 'react'
import { transformCommentValue } from './CommentUtil'
import { MarkdownView } from '../../Markdown/MarkdownView'
import { useThemeMode } from '../../../theme/useThemeMode'
import DOMPurify from 'dompurify'
import '../../Lexical/LexicalRichTextEditorStyles.css'
import { IsLexicalDataSchema } from '../../../utils/IsLexicalDataSchema'
interface IProps {
  readonly rawComment: string
}

export function CommentContent({ rawComment }: IProps) {
  const styles = useStyles()
  const activeAccount = app.auth.activeAccount
  const theme = useThemeMode()
  const [renderCommentValue, setRenderCommentValue] = useState<string | undefined>(rawComment)

  useEffect(() => {
    const handleUpdateCommentValue = async () => {
      if (!rawComment || !activeAccount?.localAccountId) return
      const updatedValue = await transformCommentValue(rawComment, activeAccount?.localAccountId)

      setRenderCommentValue(updatedValue)
    }

    handleUpdateCommentValue()
  }, [activeAccount?.localAccountId, rawComment])

  if (!IsLexicalDataSchema(rawComment)) {
    return (
      <Row data-color-mode={theme}>
        <MarkdownView className={styles.markdown}>{renderCommentValue}</MarkdownView>
      </Row>
    )
  }

  return (
    <Row className="lexical" data-color-mode={theme}>
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(rawComment) }} />
    </Row>
  )
}

const useStyles = makeStyles({
  markdown: {
    '& p': {
      ...shorthands.margin(0),
    },
    '& span': {
      fontWeight: tokens.fontWeightSemibold,
    },
    '& ul, & ol': {
      margin: '5px',
      whiteSpace: 'normal',
    },
  },
})
