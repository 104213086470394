import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  root: {
    justifyItems: 'start',
    ...shorthands.gap('2px'),
  },
  optionGroup: {
    wordBreak: 'break-word',
  },
  option: {
    maxWidth: '90%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  filterLabel: {
    ...shorthands.margin('0px', '4px', '4px', '2px'),
  },
  singleSelectDropdown: {
    width: '100%',
  },
  commonDropdown: {
    ':after': { ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1) },
  },
  selectedDropdown: {
    ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
    ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    '&> span': {
      color: tokens.colorBrandForeground1,
    },
    '&> input': {
      fontWeight: tokens.fontWeightSemibold,
    },
    ':hover': {
      ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    },
    ':after': {
      ...shorthands.borderTop('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderLeft('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderRight('1px', 'solid', tokens.colorBrandForeground1),
      ...shorthands.borderBottom('2px', 'solid', tokens.colorBrandForeground1),
    },
  },
})
