import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  tips: {
    color: tokens.colorNeutralStroke1Pressed,
  },
  fullWith: {
    width: '100%',
  },
  listBox: {
    maxHeight: '500px',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  addNew: {
    marginTop: '12px',
  },
  createAction: {
    padding: '6px',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground1Hover,
    },
    color: tokens.colorNeutralForeground2BrandHover,
  },

  groupHeader: {
    padding: ' 6px 14px',
    backgroundColor: tokens.colorNeutralBackground1,
    cursor: 'pointer',
  },
})
