import { z } from 'zod'
import { IDashBuild } from './IDashBuild'

export type DashRing = 'dev' | 'test' | 'sdf' | 'msit' | 'unknown'

export interface IDashEnvironment {
  readonly ring: DashRing
  readonly build: IDashBuild
}

export const dashRingSchema = z.union([
  z.literal('dev'),
  z.literal('test'),
  z.literal('sdf'),
  z.literal('msit'),
  z.literal('unknown'),
])
