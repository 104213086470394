import { FC, memo, useMemo } from 'react'
import { Field, makeStyles, Input, tokens } from '@fluentui/react-components'
import DatePicker, { DateObject, DatePickerProps } from 'react-multi-date-picker'
import { CalendarLtrRegular } from '@fluentui/react-icons'
import {
  getOffsetMicrosecondsBetweenLocalAndTargeTimezone,
  toMicroseconds,
} from '../../ReactMultiDatePickerWrapped/utils'
import { useUserSetting } from '@copilot-dash/settings'
import { Times } from '@copilot-dash/core'

interface IDueDateProps {
  value: string | undefined
  onChange: DatePickerProps['onChange']
  orientation?: 'horizontal' | 'vertical'
  className?: string
  style?: React.CSSProperties
  defaultValueText?: string
  disabled?: boolean
  fieldProps?: Partial<React.ComponentProps<typeof Field>>
}

export const DueDate: FC<IDueDateProps> = memo(
  ({ value, onChange, orientation, className, style, disabled, fieldProps }) => {
    const styles = useStyles()
    const [timezone] = useUserSetting('timeZone')
    const handleChange = (
      date: DateObject | DateObject[] | null,
      options: {
        validatedValue: string | Array<string>
        input: HTMLElement
        isTyping: boolean
      },
    ) => {
      if (date && !Array.isArray(date)) {
        const ret = new DateObject(date)
        ret.add(timezoneOffset, 'milliseconds')
        onChange?.(ret, options)
      }
      if (!date) {
        onChange?.(null, options)
      }
    }
    const timezoneOffset = useMemo(
      () => (timezone ? getOffsetMicrosecondsBetweenLocalAndTargeTimezone(timezone) : 0),
      [timezone],
    )
    const valueWithOffset = useMemo(() => {
      if (Array.isArray(value)) {
        return [...value].map((date) => toMicroseconds(date) - timezoneOffset)
      } else if (value) {
        return toMicroseconds(value) - timezoneOffset
      }
      return value
    }, [value, timezoneOffset])

    const minDateWithOffset = useMemo(() => {
      const minDate = Times.startOfDay(new Date(), timezone)
      if (minDate) {
        return toMicroseconds(minDate) - timezoneOffset
      }
      return minDate
    }, [timezoneOffset, timezone])

    const renderInput: DatePickerProps['render'] = (value, openCalendar, handleValueChange) => {
      return (
        <Input
          placeholder="Select a due date"
          className={styles.input}
          value={value}
          onChange={handleValueChange}
          onClick={openCalendar}
          contentAfter={<CalendarLtrRegular onClick={openCalendar} style={{ cursor: 'pointer' }} />}
        />
      )
    }

    return (
      <Field label="Due date:" className={className} orientation={orientation} style={style} {...(fieldProps ?? {})}>
        <DatePicker
          disabled={disabled}
          className={styles.root}
          value={valueWithOffset}
          minDate={minDateWithOffset}
          render={renderInput}
          arrow={false}
          onChange={handleChange}
        />
      </Field>
    )
  },
)

DueDate.displayName = 'DueDate'

const useStyles = makeStyles({
  root: {
    color: tokens.colorNeutralForeground1,
    backgroundColor: tokens.colorNeutralBackground1,
    borderRadius: tokens.borderRadiusLarge,
    overflow: 'hidden',
    boxShadow: tokens.shadow16,
    '& .rmdp-calendar': {
      width: '270px',
      '& .rmdp-day': {
        color: tokens.colorNeutralForeground1,
        '&.rmdp-deactive': {
          color: tokens.colorNeutralForeground4,
        },
        '&.rmdp-disabled, &.rmdp-deactive.rmdp-disabled': {
          color: tokens.colorNeutralForegroundDisabled,
        },
      },
      '& .rmdp-header-values': {
        color: tokens.colorBrandForeground1,
      },
      '& .rmdp-day-picker': {
        justifyContent: 'center',
        '& .rmdp-week-day': {
          color: tokens.colorBrandForeground1,
        },
        '& .rmdp-selected': {
          '& span': {
            backgroundColor: tokens.colorBrandForeground1,
          },
        },
        '& .rmdp-today': {
          color: tokens.colorNeutralForegroundOnBrand,
        },
      },
    },
  },
  input: {
    width: '100%',
    border: 'none',
    borderBottom: '1px solid #616161',
    borderRadius: 0,
  },
})
