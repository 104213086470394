import { ITicketRootCauseItem } from '@copilot-dash/domain'
import { TelemetryScope } from '@copilot-dash/logger'
import { memo, useCallback, useEffect, useState } from 'react'
import { RootCauseSuggestions } from '../../TicketActionForm/components/RootCauseSuggestions'
import { useTicketSummaryPanelState } from '../store'
import { useStyles } from './Suggestions.styles'

export enum RootCauseActionType {
  UserNoAction = '-1',
  UserRejected = '0',
  UserConfirmed = '1',
  DirectLinked = '2',
  DirectLinkedReviewed = '3',
  DirectLinkedRemoved = '4',
}
interface IRootCauseProps {
  readonly onAcceptSuggestion: (suggestion: ITicketRootCauseItem | undefined) => void
  invisibleRootCauseIds: string[]
}

export const Suggestions = memo(({ onAcceptSuggestion, invisibleRootCauseIds }: IRootCauseProps) => {
  const styles = useStyles()
  const state = useTicketSummaryPanelState()
  const ticketId = state.ticketId

  const ticketSession = app.store.use.getTicketSession(state.ticketId)
  const rootCausingResults = ticketSession.data?.rootCause?.topNResults ?? []

  const [rootCauseActions, setRootCauseAction] = useState<ITicketRootCauseItem[]>()
  const [visibleRootCauseActions, setVisibleRootCauseActions] = useState<ITicketRootCauseItem[]>()

  useEffect(() => {
    setRootCauseAction(
      state.rootCauseSnapshot.data?.items?.filter(
        (item) =>
          item.userAction === RootCauseActionType.UserNoAction || item.userAction === RootCauseActionType.DirectLinked,
      ),
    )
  }, [state.rootCauseSnapshot.data])

  useEffect(() => {
    const visibleRootCauseActions = rootCauseActions?.filter((item) => !invisibleRootCauseIds.includes(item.adoIssueId))
    setVisibleRootCauseActions(visibleRootCauseActions)
  }, [rootCauseActions, invisibleRootCauseIds])

  const removeRootCause = useCallback((adoIssueId: string) => {
    setRootCauseAction((prev) => prev?.filter((item) => item.adoIssueId !== adoIssueId))
  }, [])

  const reportAction = useCallback(
    (adoIssueId: string, userAction: string) => {
      const clickItem = rootCauseActions?.find((item) => item.adoIssueId === adoIssueId)
      if (!clickItem) return

      app.store.actions.updateTicketRootCause(ticketId, {
        items: [{ ...clickItem, userAction }],
        updateAreaAndIssues: false,
      })
    },
    [ticketId, rootCauseActions],
  )

  const handleAdd = useCallback(
    (suggestion: ITicketRootCauseItem) => {
      onAcceptSuggestion(suggestion)
      reportAction(suggestion.adoIssueId, RootCauseActionType.UserConfirmed)
      removeRootCause(suggestion.adoIssueId)
    },
    [onAcceptSuggestion, removeRootCause, reportAction],
  )

  const handleReject = useCallback(
    (suggestion: ITicketRootCauseItem) => {
      reportAction(suggestion.adoIssueId, RootCauseActionType.UserRejected)
      removeRootCause(suggestion.adoIssueId)
    },
    [removeRootCause, reportAction],
  )

  if (!visibleRootCauseActions || visibleRootCauseActions.length === 0) return null
  return (
    <TelemetryScope scope="RootCauseView" properties={{ ticketId }}>
      <RootCauseSuggestions
        suggestions={visibleRootCauseActions}
        onAcceptSuggestion={handleAdd}
        onRejectSuggestion={handleReject}
        topNResults={rootCausingResults}
        className={styles.wrapper}
      />
    </TelemetryScope>
  )
})

Suggestions.displayName = 'Suggestions'
