import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    boxShadow: `0px 1px 2px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.12)`,
    borderRadius: '4px',
    overflow: 'auto',
    minWidth: '100%',
  },

  table: {
    width: '100%',
    minWidth: 'fit-content',
    padding: '12px',
  },

  tableRow: {
    borderBottomWidth: 0,
  },

  pointer: {
    cursor: 'pointer',
  },
})
