import { FC, useMemo } from 'react'
import { Row } from '../../../../../components/Layout'
import { UserProfile } from '../../../../../components/User/UserProfile'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { InsightsTag } from './InsightsTag'

interface IProps {
  userId: string
  enableTooltip?: boolean
}

export const VIPUserTag: FC<IProps> = ({ userId, enableTooltip = false }) => {
  const styles = useStyles()
  const truncatedTag = useMemo(() => {
    return (
      <UserProfile
        userId={userId}
        customUserPhotoClassName={styles.customUserPhoto}
        customUserDisplayNameClassName={styles.customUserName}
        isEnableDisplayNameToolTip={enableTooltip}
      />
    )
  }, [enableTooltip, styles.customUserName, styles.customUserPhoto, userId])

  return (
    <Row>
      <InsightsTag
        isTagClearable={false}
        tagKey={'VIPUser'}
        truncatedTagValue={truncatedTag}
        className={styles.tagStyle}
      />
    </Row>
  )
}

const useStyles = makeStyles({
  customUserPhoto: {
    width: '18px',
    height: '18px',
    ...shorthands.borderRadius('50%'),
  },
  customUserName: {
    fontSize: tokens.fontSizeBase200,
    fontWeight: tokens.fontWeightSemibold,
    maxWidth: '70px',
  },
  tagStyle: {
    //No corresponding color found in fluentui
    backgroundColor: '#F1FAFF',
    ':hover': {
      backgroundColor: '#F1FAFF',
    },
    ':active': {
      backgroundColor: '#F1FAFF',
    },
    height: '24px',
  },
})
