import { ApiOds3sOnlineDataGroup3 } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../IDashStoreContext'
import { getTicketFiles } from './getTicketFiles'
import { getTicketSession } from './getTicketSession'
import { validateTicketDiagnosticDataForConversation } from './validators/validateTicketDiagnosticData'

export function getRaw3sOnlineDataGroup3(
  context: IDashStoreContext,
  ticketId: string,
  turnId: string,
): PromiseSnapshot<ApiOds3sOnlineDataGroup3[]> {
  return context.getOrFetch<ApiOds3sOnlineDataGroup3[]>({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[turnId]?.raw3sOnlineDataGroup3
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[turnId] ??= {})
      turn.raw3sOnlineDataGroup3 = snapshot
    },
    fetch: async () => {
      // Validate user consent
      const session = await getTicketSession(context, ticketId).promise
      if (!session.hasUserConsent) {
        throw TicketError.create('NoUserConsentGiven', { ticketId })
      }

      // Validate diagnostic data
      validateTicketDiagnosticDataForConversation(ticketId, turnId, session)

      // Get turn index
      const interaction = session.interactions.find((item) => item.messageId === turnId)
      const index = interaction?.index
      if (index === undefined) {
        throw TicketError.create('No3sOnline', { ticketId, ticketMessageId: turnId })
      }

      // Get turn file
      const files = await getTicketFiles(context, ticketId).promise
      const file = files.sssOnline.find((file) => file.index === index && file.group === 'Group3')
      if (!file) {
        throw TicketError.create('No3sOnline', { ticketId, ticketMessageId: turnId })
      }

      return await context.api.ods.get3sOnlineDataGroup3(file.fileUrl)
    },
  })
}
