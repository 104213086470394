import { memo } from 'react'
import { CopilotDashDropdown } from '../../../Filter/CopilotDashDropdown'

interface IProps {
  value: string | undefined
  onChangeValue: (value?: string) => void
}

export const CopilotExtensionIdsFilter: React.FC<IProps> = memo(({ value, onChangeValue }) => {
  return (
    <CopilotDashDropdown
      value={value}
      onChangeValue={onChangeValue}
      title="CopilotExtensionIds"
      placeholder="Search CopilotExtensionIds"
    />
  )
})

CopilotExtensionIdsFilter.displayName = 'CopilotExtensionIdsFilter'
