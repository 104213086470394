import { CalendarLtrRegular, ChevronDownRegular } from '@fluentui/react-icons'
import { DatePickerWrapped } from '../../../../components/ReactMultiDatePickerWrapped/ReactMultiDatePickerWrapped'
import { DatePickerProps } from 'react-multi-date-picker'
import { useUserSetting } from '@copilot-dash/settings'
import * as React from 'react'
import { Field, Input } from '@fluentui/react-components'
import { useStyles } from '../McpExperimentFeedbackTrend.styles'

interface IProps {
  currentDatePickerRange: Date[]
  setDatePickerRange: (dateRange: Date[]) => void
}

export const FilterDatePicker: React.FC<IProps> = ({ currentDatePickerRange, setDatePickerRange }) => {
  const styles = useStyles()
  const [timezone] = useUserSetting('timeZone')

  // NOTE: @Ethan - disable no-explicit-any rule due to the need of picking the correct type from DatePickerProps['render']
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderInput = React.useCallback<Extract<DatePickerProps['render'], (...args: any[]) => any>>(
    (value, openCalendar) => {
      return (
        <Input
          value={value}
          type="text"
          onClick={openCalendar}
          contentBefore={<CalendarLtrRegular onClick={openCalendar} style={{ cursor: 'pointer' }} />}
          contentAfter={
            <ChevronDownRegular aria-label="Dropdown" onClick={openCalendar} style={{ cursor: 'pointer' }} />
          }
        />
      )
    },
    [],
  )

  return (
    <div className={styles.filterContainer}>
      <div className={styles.filterItem}>
        <Field label="Select Time Range">
          <DatePickerWrapped
            timezone={timezone}
            value={currentDatePickerRange}
            range={true}
            arrow={false}
            showOtherDays={true}
            numberOfMonths={1}
            monthYearSeparator=" "
            onChange={(newRange) => {
              if (newRange && Array.isArray(newRange)) {
                setDatePickerRange(newRange.map((date) => date.toDate()))
              } else if (newRange) {
                setDatePickerRange([newRange.toDate()])
              } else {
                setDatePickerRange([])
              }
            }}
            render={renderInput}
            onClose={() => {
              // Reset value when the calendar is closed without submitting
              //resetValue()
            }}
            dateSeparator=" to "
          />
        </Field>
      </div>
    </div>
  )
}
