import { DashSettingEndpoint } from '@copilot-dash/domain'
import { useUserSetting } from '@copilot-dash/settings'
import { Select } from '@fluentui/react-components'
import { ComponentProps, useId } from 'react'
import { z } from 'zod'

export function EndpointTile() {
  const labelId = useId()
  const [value, setValue] = useUserSetting('endpointUrl')
  const disabled = application.env.ring === 'msit'

  const onChange: ComponentProps<typeof Select>['onChange'] = (_, data) => {
    const endpoint = z.nativeEnum(DashSettingEndpoint).safeParse(data.value).data
    if (endpoint) {
      setValue(endpoint)
    } else {
      setValue(null)
    }
  }

  return (
    <>
      <label htmlFor={labelId}>Server endpoint</label>
      <Select id={labelId} value={value ?? 'default'} onChange={onChange} disabled={disabled}>
        <option value="default">Default</option>
        {Object.values(DashSettingEndpoint).map((endpoint) => (
          <option key={endpoint} value={endpoint}>
            {endpoint}
          </option>
        ))}
      </Select>
    </>
  )
}
