import {
  INewTicketData,
  ITicketRootCauseItem,
  IUpdateTicketStatusInfoData,
  OPGProductEndpoints,
  ScenarioNames,
} from '@copilot-dash/domain'
import { useFeatureFlight } from '@copilot-dash/settings'
import { Button, Drawer, DrawerBody, DrawerFooter, DrawerHeader, Spinner, Text } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'
import { isEqual } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { SearchRoute, TeamRoute, useRouteId } from '../../../router'
import { useTeamViewStore } from '../../../screens/team/storeNew'
import { ErrorView } from '../../Error/ErrorView'
import { Row, Spacer } from '../../Layout'
import { DebugMoreButton } from '../children/DebugMoreButton'
import { Suggestions } from '../children/Suggestions'
import { SymptomReportLayout } from '../children/SymptomReportLayout'
import { TicketBasicInfo } from '../children/TicketBasicInfo'
import { ITicketSummaryRef, mapTicketInfoToTicketActionFormValue, TicketSummary } from '../children/TicketSummary'
import { useTicketSummaryPanelActions, useTicketSummaryPanelSelector } from '../store'
import { useStyles } from './TicketSummaryPanelLayout.styles'

interface ITicketSummaryPanelProps {
  readonly ticketId: string
  readonly isTicketSummaryPanelOpen: boolean
  readonly onDismissTicketSummaryPanel: () => void
  readonly ticketInfo: INewTicketData | undefined
  readonly onSave: (draft: IUpdateTicketStatusInfoData) => Promise<void>
  readonly productId?: number
  readonly afterSavedCallback?: (updateInfo: IUpdateTicketStatusInfoData) => void
}

export const TicketSummaryPanelLayout: React.FC<ITicketSummaryPanelProps> = ({
  ticketId,
  isTicketSummaryPanelOpen,
  onDismissTicketSummaryPanel,
  ticketInfo,
  onSave,
  productId,
  afterSavedCallback,
}) => {
  const styles = useStyles()
  const actions = useTicketSummaryPanelActions()
  const [enableTicketBasicInfo] = useFeatureFlight('EnableTicketBasicInfo')
  const ticketSummaryRef = useRef<null | ITicketSummaryRef>(null)
  const [formIsValid, setFormIsValid] = useState(false)
  const [invisibleRootCauseIds, setInvisibleRootCauseIds] = useState<string[]>([])
  const [hasSuggestionAdded, setHasSuggestionAdded] = useState(false)

  const ticketSnapshot = app.store.use.getTicket(ticketId)

  const ticketSessionSnapshot = app.store.use.getTicketSession(ticketId)

  const ticketUpdatedByAcceptSuggestionCounter = useTeamViewStore(
    (state) => state.tickets.ticketUpdatedByAcceptSuggestionCounter,
  )

  useEffect(() => {
    const messageId = ticketSessionSnapshot.data?.interactions.find((item) => item.index === 1)?.messageId
    if (messageId) {
      actions.selectTurn(messageId)
    }
  }, [actions, ticketSessionSnapshot.data])

  const handleTicketUpdateByAcceptSuggestion = useCallback((suggestion?: ITicketRootCauseItem) => {
    if (!suggestion) return
    ticketSummaryRef.current?.setSummaryDraft((pre) => {
      const issueList = pre.issueList || []
      issueList.push({
        issueId: suggestion.adoIssueId,
        title: suggestion.title,
        vsoAccount: suggestion.project,
      })
      return {
        ...pre,
        issueList,
      }
    })
    setHasSuggestionAdded(true)
  }, [])

  const routeId = useRouteId()
  const enableEdit =
    routeId === TeamRoute.id || (routeId === SearchRoute.id && (ticketInfo?.vsoLink || ticketInfo?.workItemId))

  const hasDraft = useTicketSummaryPanelSelector((state) => state.summaryDraftSubmittable)
  const summaryDraft = useTicketSummaryPanelSelector((state) => state.summaryDraft)
  const [saving, setSaving] = useState(false)
  const latestSummaryDraftRef = useRef<typeof summaryDraft>(null)
  useEffect(() => {
    latestSummaryDraftRef.current = summaryDraft
  }, [summaryDraft])

  const triggerSaveTwice = useCallback(() => {
    if (ticketInfo && summaryDraft) {
      const defaultState = mapTicketInfoToTicketActionFormValue(ticketInfo).state
      const currentState = mapTicketInfoToTicketActionFormValue(summaryDraft).state
      if (defaultState !== currentState && currentState.startsWith('Closed')) {
        return true
      }
      return false
    }
    return false
  }, [ticketInfo, summaryDraft])

  const handleAfterSaved = useCallback(
    (ticketInfo: INewTicketData | undefined, savedInfo: IUpdateTicketStatusInfoData) => {
      if (ticketInfo) {
        const newTicketInfo = {
          ...ticketInfo,
          teamArea: savedInfo.areaPath,
          status: savedInfo.status,
          priority: savedInfo.priority,
          rootCauseList: savedInfo.issueList,
          reasoning: savedInfo.reasoning,
          closedComment: savedInfo.closedComment,
          assignTo: savedInfo.assignTo,
          customTags: savedInfo.customTags,
        }
        actions.updateTicketInfo({
          ...newTicketInfo,
          rootCauseList: newTicketInfo.rootCauseList.map((item) => ({
            issueId: item.issueId,
            rootCauseTitle: item.title,
          })),
        })
      }

      ticketSnapshot.data?.ticketId &&
        app.store.actions.updateTicket(ticketSnapshot.data.ticketId, {
          ...ticketSnapshot.data,
          priority: savedInfo.priority,
          teamArea: savedInfo.areaPath,
          status: savedInfo.status,
          rootCauseList: savedInfo.issueList.map((item) => ({
            issueId: item.issueId,
            rootCauseTitle: item.title,
          })),
          reasoning: savedInfo.reasoning,
          closedComment: savedInfo.closedComment,
          assignTo: savedInfo.assignTo,
          customTags: savedInfo.customTags,
        })
      app.store.actions.updateAllCustomTags(savedInfo.customTags ?? [])

      afterSavedCallback?.(savedInfo)
    },
    [actions, afterSavedCallback, ticketSnapshot.data],
  )

  const triggerOnSave = useCallback(
    (summaryDraft: IUpdateTicketStatusInfoData) => {
      onSave(summaryDraft)
        .then(() => {
          // save success
          handleAfterSaved(ticketInfo, summaryDraft)

          if (isEqual(summaryDraft, latestSummaryDraftRef.current)) {
            // during saving the draft, the draft might be updated by user, so we need to check if the draft is still the same
            actions.setSummaryDraft({})
          }
        })
        .finally(() => {
          setSaving(false)
        })
    },
    [onSave, handleAfterSaved, ticketInfo, actions],
  )

  const handleSave = useCallback(() => {
    if (hasDraft && summaryDraft) {
      setSaving(true)
      if (triggerSaveTwice()) {
        const firstSummaryDraft: IUpdateTicketStatusInfoData = {
          ...summaryDraft,
          status: 'Active',
          reasoning: '',
        }
        onSave(firstSummaryDraft).then(() => {
          // first save success
          triggerOnSave(summaryDraft)
        })
      } else {
        triggerOnSave(summaryDraft)
      }
    }
  }, [hasDraft, onSave, summaryDraft, triggerOnSave, triggerSaveTwice])

  useEffect(() => {
    if (summaryDraft) {
      hasSuggestionAdded && summaryDraft.issueList.length > 0 && handleSave()
    }
  }, [hasSuggestionAdded, summaryDraft, handleSave])

  useEffect(() => {
    actions.updateTicketInfo(ticketInfo)
  }, [actions, ticketInfo])

  useEffect(() => {
    if (isTicketSummaryPanelOpen) {
      actions.getCustomTags(ticketId)
    }
  }, [actions, isTicketSummaryPanelOpen, ticketId])

  return (
    <Drawer className={styles.card} type="inline" separator open={isTicketSummaryPanelOpen} position="end">
      <DrawerHeader className={styles.drawerHeader}>
        <Row vAlign="center" fill>
          <Button
            appearance="subtle"
            aria-label="Close"
            icon={<DismissRegular />}
            onClick={onDismissTicketSummaryPanel}
          />
          <Text className={styles.title}>Ticket Details</Text>
          <Spacer />
          {enableEdit && (
            <Button
              disabled={!formIsValid || !hasDraft || saving}
              appearance="primary"
              onClick={handleSave}
              icon={saving ? <Spinner size="extra-tiny" /> : null}
            >
              Save
            </Button>
          )}
        </Row>
      </DrawerHeader>
      <DrawerBody>
        {enableEdit ? (
          <TicketSummary
            ref={ticketSummaryRef}
            onValidationChange={(isValid) => setFormIsValid(isValid)}
            key={`TicketSummary-${ticketId}-${ticketUpdatedByAcceptSuggestionCounter}`}
            onModified={(data) => setInvisibleRootCauseIds(data?.['rootCauseIDs'] || [])}
            productId={productId}
          />
        ) : null}
        {enableEdit && (
          <Suggestions
            onAcceptSuggestion={handleTicketUpdateByAcceptSuggestion}
            invisibleRootCauseIds={invisibleRootCauseIds}
          />
        )}
        {location.pathname === '/search' ? enableTicketBasicInfo && <TicketBasicInfo /> : null}
        <Text className={styles.symptomReportTitle}>Symptom Report</Text>
        {useMemo(() => {
          if (ticketId && ticketSessionSnapshot.data && ticketSnapshot.data) {
            return ticketId &&
              ticketSnapshot.data?.clientName &&
              !OPGProductEndpoints.find(
                (client) => client.toLowerCase() === ticketSnapshot.data?.clientName?.toLowerCase(),
              ) ? (
              ticketSnapshot.data?.scenarioName?.toLowerCase() === ScenarioNames.AIF.toLowerCase() ? (
                'Symptom Report is not available for admin initiated feedback.'
              ) : ticketSessionSnapshot.data?.hasUserConsent ? (
                <SymptomReportLayout key={ticketId} />
              ) : (
                'Access to diagnostic data is unavailable as the user has not granted consent.'
              )
            ) : (
              'Symptom Report is not available for OPG Copilot'
            )
          }
          if (ticketSessionSnapshot.status === 'error' || ticketSnapshot.status === 'error') {
            return <ErrorView type="inline" error={ticketSessionSnapshot.error || ticketSnapshot.error} />
          }
          return <Spinner size="extra-small" />
        }, [ticketId, ticketSessionSnapshot, ticketSnapshot])}
      </DrawerBody>
      <DrawerFooter className={styles.footer}>
        <DebugMoreButton
          ticketId={ticketId}
          afterSavedCallback={(savedInfo) => handleAfterSaved(ticketInfo, savedInfo)}
        />
      </DrawerFooter>
    </Drawer>
  )
}
