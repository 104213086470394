import { Text } from '@fluentui/react-components'
import { ActivityHistoryPanel } from '../../../../components/ActivityHistory/ActivityHistoryPanel'
import { ActivityHistoryPanelTriggerButton } from '../../../../components/ActivityHistory/ActivityHistoryPanelTriggerButton'
import { TicketScreenStore } from '../../TicketScreenStore'

export function TicketScreenBarTicketHistory() {
  const actions = TicketScreenStore.useActions()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)

  return (
    <Text>
      <ActivityHistoryPanelTriggerButton onClick={actions.handleActivityHistoryPanelButtonClick} />
      <ActivityHistoryPanel ticketId={ticketId} />
    </Text>
  )
}
