import { useMemo } from 'react'
import { AnyData } from '../../../../../components/AnyData/AnyData'

interface IProps {
  readonly ticketId: string
  readonly selectedTurnId: string
  readonly scopeId: string
}

export function ConversationDataFromScopeId({ scopeId }: IProps) {
  const jsonData = useMemo(() => {
    return {
      scopeId: scopeId,
    }
  }, [scopeId])

  return <AnyData key={scopeId} data={jsonData} />
}
