import { Route } from 'react-router-dom'
import { createNavigator } from '../../navigator/createNavigator'
import { TicketRoute } from '../ticket/TicketRoute'
import { TeamViewRouteMapper } from './TeamRouteMapper'
import { TeamRoutePage } from './TeamRoutePage'

export function TeamRoute() {
  return (
    <Route
      id={ID}
      path={PATH}
      element={<TeamRoutePage />}
      loader={({ request }) => {
        const url = new URL(request.url)
        const ticketId = url.searchParams.get('modalTicketId')
        if (ticketId) {
          return TicketRoute.navigator.generateRedirectResponse({
            id: ticketId,
          })
        }

        return null
      }}
    />
  )
}

const ID = 'product.team'
const PATH = '/:product/team'

TeamRoute.id = ID
TeamRoute.path = PATH
TeamRoute.navigator = createNavigator(ID, PATH, TeamViewRouteMapper)
