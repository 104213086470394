import { memo, useCallback, useEffect, useMemo, useState, type MouseEvent } from 'react'
import { useTeamViewStore } from '../../storeNew'
import { TableLoading } from '../TableLoading'
import { ErrorView } from '../../../../components/Error'
import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbItem,
  SearchBox,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Text,
} from '@fluentui/react-components'
import { columnsConfig } from './columns'
import { ProductNames, TeamViewSubMenuIds, getProductIdByName } from '@copilot-dash/domain'
import { Row } from '../../../../components/Layout'
import { ToggleLeftDrawerIcon } from '../ToggleDrawerLeftIcon'
import { useStyles } from './RootCauseTable.styles'
import { RouteLink, TeamRoute } from '../../../../router'
export const RootCauseTable = memo(() => {
  const styles = useStyles()
  const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
  const product = useTeamViewStore((state) => state.product)
  const isM365Chat = product === ProductNames.M365Chat

  const productName = TeamRoute.navigator.useArgsOptional()?.product
  const productId = productName && getProductIdByName(productName)
  const teamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)
  const rootCauseSnapshot = application.store.use((state) => state.team.rootCausesMap?.[`${productId}-${teamId}`])

  useEffect(() => {
    if (productId && teamId) {
      application.store.actions.getOrFetchRootCauseListByTeam(productId, teamId)
    }
  }, [productId, teamId])

  const [searchInput, setSearchInput] = useState('')
  const [sort, setSort] = useState<{ columnId: string; direction: 'ascending' | 'descending' } | null>({
    columnId: 'rootCauseStatus',
    direction: 'ascending',
  })

  const tableItems = useMemo(() => {
    if (rootCauseSnapshot && rootCauseSnapshot.status === 'done') {
      const full = rootCauseSnapshot.data.rootCauseList
      const list = full.filter((rootCause) =>
        rootCause.rootCauseTitle.toLowerCase().includes(searchInput.trim().toLowerCase()),
      )
      if (sort) {
        list.sort((a, b) => {
          const column = columnsConfig.find((c) => c.id === sort.columnId)
          if (column && column.onSort) {
            return sort.direction === 'ascending' ? column.onSort(a, b) : -column.onSort(a, b)
          }
          return 0
        })
      }
      return list
    }
    return []
  }, [rootCauseSnapshot, searchInput, sort])

  const headerSortProps = useCallback(
    (columnId: string) => {
      return {
        onClick: (e: MouseEvent) => {
          const newSort =
            sort && sort.columnId === columnId
              ? {
                  columnId,
                  direction: sort.direction === 'ascending' ? ('descending' as const) : ('ascending' as const),
                }
              : { columnId, direction: 'ascending' as const }
          setSort(newSort)
        },
        sortDirection: sort && sort.columnId === columnId ? sort.direction : undefined,
      }
    },
    [sort],
  )

  const generatePath = useCallback(
    (issueId: string, vsoAccount: string) => {
      const product = useTeamViewStore.getState().product
      const teamName = useTeamViewStore.getState().teams.lastSelectedTeam
      // all fetch tickets actions will be trigged by the url args change, so we just need to navigate to the right url
      // the url monitor is in the src/screens/team/TeamScreen.tsx
      return TeamRoute.navigator.generatePath({
        product,
        subMenu: TeamViewSubMenuIds.RootCauses,
        teamName: teamName || TeamRoute.navigator.getArgs()['teamName'],
        issueId,
        vsoAccount,
        priority: isM365Chat ? ['0', '1'] : undefined,
      })
    },
    [isM365Chat],
  )

  const table = useMemo(() => {
    if (!rootCauseSnapshot || rootCauseSnapshot.status === 'waiting') {
      return <TableLoading />
    }
    if (rootCauseSnapshot.status === 'error') {
      return <ErrorView error={rootCauseSnapshot.error} />
    }
    if (rootCauseSnapshot.status === 'done') {
      return tableItems.length > 0 ? (
        <Table sortable noNativeElements aria-label="Root Causes" className={styles.table}>
          <TableHeader>
            <TableRow className={styles.headerRow}>
              {columnsConfig.map((column) => (
                <TableHeaderCell {...headerSortProps(column.id)} key={column.id} style={column.cellStyle}>
                  {column.renderHeaderCell()}
                </TableHeaderCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {tableItems.map((rootCause) => {
              return (
                <RouteLink path={generatePath(rootCause.issueId, rootCause.vsoAccount)} key={rootCause.issueId}>
                  <TableRow key={rootCause.issueId} className={styles.bodyRow}>
                    {columnsConfig.map((column) => (
                      <TableCell key={column.id} style={column.cellStyle}>
                        {column.renderCell(rootCause)}
                      </TableCell>
                    ))}
                  </TableRow>
                </RouteLink>
              )
            })}
          </TableBody>
        </Table>
      ) : (
        <ErrorView.Custom level="WARNING" message="No root causes found" />
      )
    }
    return null
  }, [rootCauseSnapshot, tableItems, styles.table, styles.headerRow, styles.bodyRow, headerSortProps, generatePath])

  return (
    <>
      <Row className={styles.header}>
        {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
        <Breadcrumb>
          <BreadcrumbItem>
            <BreadcrumbButton size="large" style={{ height: 'unset', minWidth: 80 }}>
              <Text size={500} weight="semibold">
                Root Causes
              </Text>
            </BreadcrumbButton>
          </BreadcrumbItem>
        </Breadcrumb>
      </Row>
      <Row>
        <SearchBox
          className={styles.search}
          value={searchInput}
          onChange={(_, data) => setSearchInput(data.value)}
          placeholder="Search root cause"
        />
      </Row>
      {table}
    </>
  )
})

RootCauseTable.displayName = 'RootCauseTable'
