import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  counterBadge: {
    position: 'absolute',
    top: '5px',
    right: '165px',
    color: tokens.colorNeutralForeground1,
    backgroundColor: tokens.colorNeutralBackground1Pressed,
  },
  icon: {
    color: tokens.colorNeutralForeground1,
    ...shorthands.margin(0, '4px', 0, 0),
  },
})
