import { ApiError, AxiosError } from '@copilot-dash/api'
import { NetworkError, NetworkErrorData } from '@copilot-dash/error'
import { IErrorConfig } from './interfaces/IErrorConfig'

export class ErrorConfigForApiNetwork implements IErrorConfig {
  private readonly error: ApiError
  private readonly cause: AxiosError
  private readonly data: NetworkErrorData

  constructor(error: ApiError, cause: AxiosError) {
    this.error = error
    this.cause = cause
    this.data = NetworkError.create(error.data.statusCode).data
  }

  buildMessage() {
    return this.data.message
  }

  buildMessageDetail() {
    return this.data.description
  }
}
