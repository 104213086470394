import { CallFlowNodeType, CallFlowNodeTypeFlags } from '../store/IState'

export function generateNodeType(scopeName: string): CallFlowNodeType {
  return (
    Object.values(CallFlowNodeType).find((type) => {
      return CallFlowNodeTypeFlags[type].some((flag) => {
        return scopeName.toLowerCase().includes(flag.toLowerCase())
      })
    }) ?? CallFlowNodeType.Other
  )
}
