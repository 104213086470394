import { ApiSearchIssueContext, apiSearchIssueContextSchema } from './ApiSearchIssueContext'
import { ApiTicketContext, apiTicketContextSchema } from './ApiTicketContext'
import { z } from 'zod'

export interface ApiTicket {
  readonly context: ApiTicketContext
  readonly searchIssues?: Array<ApiSearchIssueContext>
  readonly contexts?: Array<ApiTicketContext>
}

export const apiTicketSchema = z.object({
  context: apiTicketContextSchema,
  searchIssues: z.array(apiSearchIssueContextSchema).optional(),
  contexts: z.array(apiTicketContextSchema).optional(),
})
