import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const TICKET_SUMMARY_PANEL_WIDTH = 720

export const useStyles = makeStyles({
  card: {
    width: `${TICKET_SUMMARY_PANEL_WIDTH}px`,
    height: '100%',
  },

  drawerHeader: {
    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.10)',
    width: '100%',
  },

  summary: {
    width: '100%',
    backgroundColor: tokens.colorNeutralBackground2,
    ...shorthands.padding('20px'),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
  },

  summaryRow: {
    flexDirection: 'row',
    marginBottom: '20px',
  },

  summaryItemLeft: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },

  summaryItemRight: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },

  summaryLabel: {
    width: '74px',
    fontWeight: tokens.fontWeightSemibold,
  },

  flexGrow: {
    flexGrow: 1,
  },

  fixed180Width: {
    width: '180px',
  },

  symptomReportTitle: {
    display: 'block',
    marginBottom: '20px',
    marginTop: '20px',
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightBold,
  },

  symptomReportTable: {
    width: '100%',
    height: 'fit-content',
    borderEndEndRadius: tokens.borderRadiusLarge,
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    ...shorthands.border(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStencil1Alpha),
  },

  symptomReport: {
    height: 'fit-content',
  },

  symptomReportTableRow: {
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground2,
    },
    ':active': {
      backgroundColor: tokens.colorNeutralBackground2,
    },
  },

  symptomReportTableTitle: {
    backgroundColor: tokens.colorNeutralStroke2,
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
    ':hover': {
      backgroundColor: tokens.colorNeutralStroke2,
    },
    ':active': {
      backgroundColor: tokens.colorNeutralStroke2,
    },
  },

  symptomReportMessage: {
    overflowX: 'scroll',
    marginTop: '10px',
    marginBottom: '10px',
  },

  leftArrow: {
    minWidth: 0,
  },

  title: {
    alignItems: 'center',
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
    marginLeft: tokens.spacingHorizontalSNudge,
  },

  columnWidth: {
    width: '200px',
  },

  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },

  loadling: {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  loading: {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },

  errorColor: {
    color: 'red',
  },

  warningColor: {
    color: 'orange',
  },

  okColor: {
    color: 'green',
  },

  infoColor: {
    color: 'grey',
  },

  rootCause: {
    backgroundColor: tokens.colorBrandBackgroundInvertedHover,
    marginTop: '10px',
    marginBottom: '20px',
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
  },

  rootCauseHeader: {
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },

  rootCauseTitle: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
    paddingTop: '20px',
  },

  rootCauseArea: {
    color: 'grey',
    paddingBottom: '10px',
  },

  rootCauseContent: {
    paddingBottom: '10px',
  },

  rootCauseJustifications: {
    maxHeight: '200px',
    overflowY: 'auto',
    marginBottom: '10px',
  },

  rootCauseTip: {
    color: '#616161',
    fontSize: tokens.fontSizeBase200,
  },

  rootCauseAddButton: {
    backgroundColor: tokens.colorPaletteCornflowerBorderActive,
    color: tokens.colorNeutralForegroundInverted2,
    marginRight: '10px',
    ':hover': {
      backgroundColor: tokens.colorPaletteCornflowerBorderActive,
      color: tokens.colorNeutralForegroundInverted2,
    },
  },

  saveIcon: {
    marginRight: '10px',
    verticalAlign: 'middle',
  },
})
