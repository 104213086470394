import { ITagDefinition } from './ITagDefinition.type'
import { useSearchScreenState } from '../../store'
import { TruncateTagFunction } from './TruncateTagFunction'
import { GetTagValueTextFunction } from './GetTagValueTextFunction'

export const ScenarioGroupTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = GetTagValueTextFunction('Prompt Details', 'Scenario Group', form.scenarioGroup || [])

  return {
    key: 'scenarioGroup',
    tagName: 'Scenario group',
    toolTipContentValue: valueTexts.join(', '),
    truncatedTagValue: TruncateTagFunction(valueTexts),
    IsShowTag: false,
    isTagClearable: true,
  }
}
