import { useCallback, useState } from 'react'
import { useStyles } from './McpExperimentExperimentScreen.styles'
import { useUserSetting } from '@copilot-dash/settings'
import { IMcpExperimentFeedbackResponse } from '@copilot-dash/domain'
import { getDefaultDates } from './children/tools/getDefaultDates'
import { usePromise } from '@copilot-dash/core'
import { ScreenLayout } from './layout/McpExperimentFeedbackScreenLayout'
import { FilterLayout } from './layout/McpExperimentFeedbackFilterLayout'

export function McpExperimentFeedbackScreen() {
  const [timezone] = useUserSetting('timeZone')
  const defaultDates = getDefaultDates(timezone)
  const [datePickerRange, setDatePickerRange] = useState<[Date, Date]>([defaultDates.startDate, defaultDates.endDate])
  const [flightName, setFlightName] = useState<string>('EnableSydneyMCPChat')
  const [chartTimeWindow, setChartTimeWindow] = useState<string>('1d')
  const [clientRings, setClientRings] = useState<string[]>([])
  const [clientPlatforms, setClientPlatforms] = useState<string[]>([])
  const [deployRings, setDeployRings] = useState<string[]>([])

  const styles = useStyles()

  const getData = useCallback(async (): Promise<IMcpExperimentFeedbackResponse> => {
    const startTimeUtc = datePickerRange[0].toISOString()
    const endTimeUtc = datePickerRange[1].toISOString()

    const data: IMcpExperimentFeedbackResponse = await application.store.actions.getMcpExperimentFeedback(
      startTimeUtc,
      endTimeUtc,
      flightName,
      chartTimeWindow,
      deployRings,
      clientRings,
      clientPlatforms,
    )
    return data
  }, [chartTimeWindow, clientPlatforms, clientRings, datePickerRange, deployRings, flightName])

  const [promise, setPromise] = useState(() => {
    return getData()
  })

  const snapshot = usePromise(promise)

  return (
    <div className={styles.pageStyle}>
      <FilterLayout
        datePickerRange={datePickerRange}
        flightName={flightName}
        deployRings={deployRings}
        clientRings={clientRings}
        clientPlatforms={clientPlatforms}
        setDatePickerRange={setDatePickerRange}
        setFlightName={setFlightName}
        setDeployRings={setDeployRings}
        setClientRings={setClientRings}
        setClientPlatforms={setClientPlatforms}
        setPromise={setPromise}
        snapshot={snapshot}
        getData={getData}
        chartTimeWindow={chartTimeWindow}
        setChartTimeWindow={setChartTimeWindow}
      />
      <ScreenLayout snapshot={snapshot} chartTimeWindow={chartTimeWindow} />
    </div>
  )
}
