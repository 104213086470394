import { IDashSettingsForFlights, useFeatureFlight } from '@copilot-dash/settings'
import { Switch } from '@fluentui/react-components'
import { ComponentProps } from 'react'

const flightLabels: {
  [K in keyof IDashSettingsForFlights]: string
} = {
  ExperimentalFeatures: 'Enable Experimental features',
  EnableTicketBasicInfo: 'Enable ticket details in side panel',
  EnableMcpExperimentFeedbackBoard: 'Enable MCP Experiment Feedback Board',
  EnableTicketCallFlow: 'Enable Ticket Call Flow',
  EnableSliceIdsOptionsSetsFilter: 'Enable SliceIDs and OptionsSets Filter',
}

interface IProps<K extends keyof IDashSettingsForFlights> {
  readonly flight: K
}

export function FeatureFlightTile<K extends keyof IDashSettingsForFlights>(props: IProps<K>) {
  const [enabled, setEnabled] = useFeatureFlight(props.flight)

  const onChange: ComponentProps<typeof Switch>['onChange'] = (_, data) => {
    setEnabled(data.checked ?? false)
  }

  return <Switch checked={enabled} label={flightLabels[props.flight]} onChange={onChange} />
}
