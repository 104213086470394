import { z } from 'zod'

export enum ApiEyesOnType {
  diagnostics = 'diagnostics',
  eyeson = 'eyeson',
  or = 'or',
  none = 'none',
}

export const apiEyesOnTypeSchema = z.nativeEnum(ApiEyesOnType)
