import { ApiRootCauseListByTeamResponse } from '@copilot-dash/api'
import { isAssignedTo3SDashTorus } from '../../utils/is3SDashTorus'
import { IRootCauseListByTeam } from '@copilot-dash/domain'

export function convertRootCauseList(apiData: ApiRootCauseListByTeamResponse): IRootCauseListByTeam {
  const convertRootCauseData = apiData.rootCauseList.map((item) => {
    const { owner, ...list } = item
    return {
      ...list,
      owner: isAssignedTo3SDashTorus(owner) ? undefined : owner,
    }
  })
  const convertRootCauseList = { rootCauseList: convertRootCauseData }
  return convertRootCauseList
}
