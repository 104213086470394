import { makeStyles, shorthands, Tag } from '@fluentui/react-components'
import { CheckboxCheckedFilled, CheckboxUncheckedFilled, SquareFilled } from '@fluentui/react-icons'
import { CallFlowNodeType, CallFlowNodeTypeColors, CallFlowNodeTypeLabels } from '../../store/IState'
import { TicketCallFlowStore } from '../../TicketCallFlowStore'

interface IProps {
  readonly type: CallFlowNodeType
}

export function TicketCallFlowBarLegendItem({ type }: IProps) {
  const styles = useStyles()
  const actions = TicketCallFlowStore.useActions()
  const hasSelection = TicketCallFlowStore.use((state) => state.selectedTypes.length > 0)
  const selected = TicketCallFlowStore.use((state) => state.selectedTypes.includes(type))

  const handleSetChecked = () => {
    if (selected) {
      actions.unselectType(type)
    } else {
      actions.selectType(type)
    }
  }

  return (
    <Tag
      className={styles.root}
      appearance="outline"
      media={
        selected ? (
          <CheckboxCheckedFilled color={CallFlowNodeTypeColors[type]} fontSize={24} />
        ) : hasSelection ? (
          <CheckboxUncheckedFilled color={CallFlowNodeTypeColors[type]} fontSize={24} />
        ) : (
          <SquareFilled color={CallFlowNodeTypeColors[type]} fontSize={24} />
        )
      }
      onClick={handleSetChecked}
    >
      {CallFlowNodeTypeLabels[type]}
    </Tag>
  )
}

const useStyles = makeStyles({
  root: {
    ...shorthands.borderColor('transparent'),
  },
})
