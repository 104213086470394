import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    ...shorthands.flex(1),
  },
  header: {
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },

  expandIcon: {
    marginRight: '20px',
    minWidth: '22px',
  },

  search: {
    width: '70%',
    minWidth: '300px',
    maxWidth: 'unset',
  },

  table: {
    marginTop: '24px',
  },

  headerRow: {
    height: '40px',
    color: tokens.colorNeutralForeground4,
  },
  bodyRow: {
    minHeight: '60px',
  },
})
