import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogProps,
  DialogSurface,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import { useScreenType } from '../../../layout'
import { DashDialogInstanceContext } from '../context/DashDialogInstanceContext'
import { DashDialogProvider } from '../DashDialogProvider'
import { IDashDialogInstance } from '../store/DashDialogActions'
import { DashDialogItemTitle } from './DashDialogItemTitle'

interface IProps {
  readonly id: string
  readonly instance: IDashDialogInstance
}

export function DashDialogItemForCenter({ id, instance }: IProps) {
  const styles = useStyles()
  const actions = DashDialogProvider.useActions()
  const status = DashDialogProvider.use((state) => state.items.find((item) => item.id === id)?.status)
  const isOpen = !status || status === 'open'

  const handleOpenChange: DialogProps['onOpenChange'] = (e, data) => {
    if (!data.open) {
      instance?.onDismissed?.()
      actions.close(id)
    }
  }

  const handleClose = () => {
    instance?.onDismissed?.()
    actions.close(id)
  }

  let responsiveSurfaceStyle = styles.surface
  const screenType = useScreenType()
  switch (screenType) {
    case 'desktop':
      responsiveSurfaceStyle = styles.surfaceStretchDesktop
      break
    case 'tablet':
      responsiveSurfaceStyle = styles.surfaceStretchLaptop
      break
    case 'mobile':
      responsiveSurfaceStyle = styles.surfaceStretchMobile
      break
  }

  return (
    <DashDialogInstanceContext.Provider value={id}>
      <Dialog defaultOpen={true} open={isOpen} onOpenChange={handleOpenChange}>
        <DialogSurface className={mergeClasses('DashDialogItem-DialogSurface', styles.surface, responsiveSurfaceStyle)}>
          <DialogBody className={mergeClasses('DashDialogItem-DialogBody', styles.body)}>
            <DashDialogItemTitle instance={instance} onClose={handleClose} />
            <DialogContent className={mergeClasses('DashDialogItem-DialogContent', styles.content)}>
              {instance.content}
            </DialogContent>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </DashDialogInstanceContext.Provider>
  )
}

const useStyles = makeStyles({
  surface: {
    padding: 0,
    maxWidth: 'unset',
    maxHeight: 'unset',
    backgroundColor: tokens.colorNeutralBackground1,
    overflow: 'hidden',
    border: 'none',
  },
  surfaceStretchDesktop: {
    width: 'calc(100vw - 128px)',
    height: 'calc(100vh - 128px)',
  },
  surfaceStretchLaptop: {
    width: 'calc(100vw - 64px)',
    height: 'calc(100vh - 64px)',
  },
  surfaceStretchMobile: {
    width: 'calc(100vw - 0px)',
    height: 'calc(100vh - 0px)',
  },
  body: {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    gap: '0',
  },
  content: {
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',

    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',

    '& > *:first-child': {
      flex: '1',
      overflow: 'hidden',
    },
  },
})
