import * as React from 'react'
import { PeoplePicker } from '../PeoplePicker/PeoplePicker'

interface EmailFilterProps {
  onChangeUser?: (userId?: string, emailAddress?: string) => void
  emailAddress?: string
  userId?: string
  disableEmailFilter?: boolean
  className?: string
}

export const EmailFilter: React.FC<EmailFilterProps> = ({
  onChangeUser,
  emailAddress,
  userId,
  disableEmailFilter,
  className,
}) => {
  return (
    <PeoplePicker
      onSelect={(userId, userEmail) => {
        onChangeUser?.(userId, userEmail)
        Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FromEmail', value: userEmail })
      }}
      onDeselect={() => {
        onChangeUser?.()
        Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FromEmail', value: null })
      }}
      userId={userId}
      userEmail={emailAddress}
      disable={disableEmailFilter}
      className={className}
    />
  )
}
