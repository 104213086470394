import { INewTicketData } from '@copilot-dash/domain'
import { memo, useMemo, useState } from 'react'
import { ColumnSettingButton } from '../../../../components/Button/ColumnSettingButton'
import { TicketsFilterButton } from '../../../../components/Button/TicketsFilterButton'
import { ErrorView } from '../../../../components/Error'
import { Body, Column, Header, Row, Spacer } from '../../../../components/Layout'
import { ExtendedTableColumnDefinition } from '../../../../components/Table/ExtendedTableColumn'
import { ResizeDimentions, useResizeObserver } from '../../../../hooks/useResizeObserver'
import { useSearchScreenActions, useSearchScreenState } from '../../store'
import { LoaderComponent } from '../loader/LoaderComponent'
import { SMALL_SCREEN_WIDTH } from '../SearchScreenForm.styles'
import { SearchScreenTableView } from '../table/SearchScreenTableView'
import { SearchScreenTag } from '../tags/SearchScreenTag'
import { SearchScreenBar } from '../SearchScreenBar'
import { SearchScreenForm } from '../SearchScreenForm'
import { SearchScreenTooltip } from '../SearchScreenTooltip'
import { useStyles } from './AllFeedback.styles'

interface IProps {
  readonly selectedColumns: ExtendedTableColumnDefinition<INewTicketData>[]
}

export const AllFeedback = memo(function SearchScreenBody({ selectedColumns }: IProps) {
  const styles = useStyles()
  const searchSnapshot = useSearchScreenState((state) => state.loadMoreSnapshot)
  const searchFeedbackResult = useSearchScreenState((state) => state.searchFeedbackResult)
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)

  const isColumnSettingPanelOpen = useSearchScreenState((state) => state.isColumnSettingPanelOpen)
  const isTicketsFilterPanelOpen = useSearchScreenState((state) => state.isTicketsFilterPanelOpen)
  const actions = useSearchScreenActions()
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  const handleResize = (width: number) => {
    if (width < SMALL_SCREEN_WIDTH) {
      setIsSmallScreen(true)
    } else {
      setIsSmallScreen(false)
    }
  }
  const ref = useResizeObserver(ResizeDimentions.Width, handleResize)

  const tableContent = useMemo(() => {
    let tableItem: INewTicketData[] = []
    switch (searchSnapshot[0]?.status) {
      case 'none':
        return null
      case 'waiting':
        return <LoaderComponent />
      case 'done':
        tableItem = searchFeedbackResult.tickets
        return (
          <SearchScreenTableView
            tableItem={tableItem}
            selectedColumns={selectedColumns}
            keyPicker={(ticket, _) => ticket.ticketId}
          />
        )
      case 'error':
        return <ErrorView error={searchSnapshot[0].error} className={styles.errorLayout} />
      default:
        return null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFeedbackResult.tickets, searchSnapshot, selectedColumns, ticketId])

  return (
    <Column className={styles.container} ref={ref}>
      {/* Header */}
      <Header>
        <Header>
          <SearchScreenBar />
          <SearchScreenForm onDismissTicketSummaryPanel={actions.onDismissTicketSummaryPanel} />
          <SearchScreenTooltip />
        </Header>
        <Row className={styles.tagsBar}>
          {/* Left */}
          <Spacer />
          {/* Right */}
          <SearchScreenTag
            onClickTagFunction={actions.handleTicketsFilterPanelButtonClick}
            isSmallScreen={isSmallScreen}
          />
          <Spacer width="8px" />
          <TicketsFilterButton
            onClickButton={actions.handleTicketsFilterPanelButtonClick}
            isTicketsFilterPanelOpen={isTicketsFilterPanelOpen}
            placeholder={isSmallScreen ? '' : 'Filters'}
          />
          {/* Column Setting Button */}
          <Spacer width="8px" />
          <ColumnSettingButton
            onClickButton={actions.handleColumnSettingButtonClick}
            isColumnSettingPanelOpen={isColumnSettingPanelOpen}
            placeholder={isSmallScreen ? '' : 'Column Settings'}
          />
        </Row>
        <Spacer height="8px" />
      </Header>
      {/* Body */}
      <Body className={styles.body}>{tableContent}</Body>
    </Column>
  )
})
