import { useTicketKustoStateDurationLog } from '@copilot-dash/store'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { ErrorView } from '../../../Error'
import { Column } from '../../../Layout'
import { TicketCallFlowStore } from '../../TicketCallFlowStore'
import { TurnViewFlow } from './TurnViewFlow'
import { TurnViewSkeleton } from './TurnViewSkeleton'

interface IProps {
  readonly turnId: string
  readonly selectedAppearance?: 'outline' | 'none'
}

export function TurnView({ turnId, selectedAppearance = 'outline' }: IProps) {
  const styles = useStyles()
  const actions = TicketCallFlowStore.useActions()
  const ticketId = TicketCallFlowStore.use((state) => state.ticketId)
  // const selected = TicketCallFlowStore.use((state) => state.selectedTurnId === turnId)
  const snapshot = useTicketKustoStateDurationLog({ ticketId, messageId: turnId })

  const handleOnSelect = () => {
    actions.selectTurn(turnId)
  }

  const renderChild = () => {
    switch (snapshot.status) {
      case 'waiting':
        return <TurnViewSkeleton />
      case 'error':
        return <ErrorView error={snapshot.error} />
      case 'done':
        return <TurnViewFlow turnId={turnId} items={snapshot.data} />
    }
  }

  return (
    <Column className={styles.root} onClick={handleOnSelect}>
      {/* <div
        className={mergeClasses(
          styles.border,
          selected ? 'selected' : 'unselected',
          selectedAppearance === 'outline' ? 'outline' : 'none',
        )}
      /> */}
      {renderChild()}
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    // borderRadius: tokens.borderRadiusXLarge,
    // backgroundColor: tokens.colorNeutralBackground1,
    position: 'relative',
    overflow: 'hidden',
  },
  border: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: tokens.borderRadiusXLarge,
    ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke2),
    overflow: 'hidden',
    // pointerEvents: 'none',

    '&.selected': {
      // pointerEvents: 'none',
    },

    '&.selected.outline': {
      ...shorthands.border('3px', 'solid', tokens.colorBrandStroke1),
    },

    '&.unselected': {
      cursor: 'pointer',
    },

    '&.unselected:hover': {
      ...shorthands.borderColor(tokens.colorBrandStroke2),
    },
  },
})
