import { useEffect, useState } from 'react'
import { Persona, Option, Popover, PopoverSurface, Spinner } from '@fluentui/react-components'
import { AsyncSnapshot } from '@copilot-dash/core'
import { User } from '@copilot-dash/api'
import { MenuOption } from '@lexical/react/LexicalTypeaheadMenuPlugin'
import { MentionUser } from './MentionsPlugin'
import { useStyles } from './MentionsMenu.styles'
import { Row } from '../../../Layout'

interface MentionsMenuProps {
  anchorElementRef: React.RefObject<HTMLElement>
  selectOptionAndCleanUp: (option: MentionTypeaheadOption) => void
  setHighlightedIndex: (index: number) => void
  snapshot: AsyncSnapshot<User[]>
}

export class MentionTypeaheadOption extends MenuOption {
  mentionUser: MentionUser
  constructor(mentionUser: MentionUser) {
    super(mentionUser.displayName)
    this.mentionUser = mentionUser
  }
}

export const MentionsMenu: React.FC<MentionsMenuProps> = ({
  anchorElementRef,
  selectOptionAndCleanUp,
  setHighlightedIndex,
  snapshot,
}) => {
  const styles = useStyles()
  const [rect, setRect] = useState(anchorElementRef.current?.getBoundingClientRect())
  const newCurrentRect = anchorElementRef.current?.getBoundingClientRect()

  function MentionsTypeAheadMenuItem({
    index,
    onClick,
    onMouseEnter,
    option,
    snapshot,
  }: {
    index?: number
    onClick?: () => void
    onMouseEnter?: () => void
    option?: MentionTypeaheadOption
    snapshot: AsyncSnapshot<User[]>
  }) {
    switch (snapshot.status) {
      case 'waiting':
        return (
          <Row hAlign="center" className={styles.status}>
            <Spinner label="Loading..." />
          </Row>
        )
      case 'done':
        if (snapshot.data.length === 0) {
          return (
            <Row hAlign="center" className={styles.status}>
              No results found
            </Row>
          )
        }
        break
    }

    if (option) {
      return (
        <Option
          key={option.mentionUser.uid}
          text={option.mentionUser.displayName}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          id={'typeahead-item-' + index}
          className={styles.option}
        >
          <Persona
            avatar={{ color: 'colorful', 'aria-hidden': true }}
            key={option.mentionUser.uid}
            name={option.mentionUser.displayName}
            secondaryText={option.mentionUser.emailAddress}
          />
        </Option>
      )
    }
    return null
  }

  useEffect(() => {
    if (!rect || !rect?.x || !rect.y) {
      if (newCurrentRect?.x && newCurrentRect?.y) {
        setRect(newCurrentRect)
      }
    }
  }, [newCurrentRect, rect])

  let target = undefined
  if (rect) {
    target = {
      getBoundingClientRect: () => {
        return rect
      },
      contextElement: anchorElementRef.current ?? undefined,
    }
  }

  return (
    <Popover
      positioning={{
        target: target,
        position: 'above',
        align: 'start',
      }}
      open={true}
      unstable_disableAutoFocus
    >
      <PopoverSurface style={{ padding: 0 }}>
        <div className={styles.mentionDropDown}>
          {snapshot.data && snapshot.status === 'done' && snapshot.data.length > 0 ? (
            snapshot.data.map((user: User, i) => {
              if (user.id && user.displayName) {
                return (
                  <MentionsTypeAheadMenuItem
                    key={user.id}
                    index={i}
                    onClick={() => {
                      if (user.displayName && user.id) {
                        setHighlightedIndex(i)
                        selectOptionAndCleanUp({
                          mentionUser: {
                            displayName: user.displayName,
                            uid: user.id,
                            emailAddress: user.mail ?? undefined,
                          },
                          key: user.id,
                          setRefElement: (element: HTMLElement | null) => {},
                        })
                      }
                    }}
                    onMouseEnter={() => setHighlightedIndex(i)}
                    option={{
                      mentionUser: {
                        displayName: user.displayName,
                        uid: user.id,
                        emailAddress: user.mail ?? undefined,
                      },
                      key: user.id,
                      setRefElement: (element: HTMLElement | null) => {},
                    }}
                    snapshot={snapshot}
                  />
                )
              }
              return null
            })
          ) : (
            <MentionsTypeAheadMenuItem snapshot={snapshot} />
          )}
        </div>
      </PopoverSurface>
    </Popover>
  )
}
