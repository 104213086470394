import { useMemo } from 'react'
import { SearchFilterFieldNames } from '@copilot-dash/domain'
import { Body1Stronger } from '@fluentui/react-components'
import { Control, Controller } from 'react-hook-form'
import {
  ExperienceTypeFilter,
  IsSTCAChinaFilter,
  IsTopi18NTenantFilter,
  SemanticSearchTypeFilter,
  HasConnectorFilter,
  HasGPTExtensionFilter,
  HasMessageExtensionFilter,
  HasCopilotExtensionIdsFilter,
  ErrorCodeFilter,
  HasConnectorResultFilter,
  IsGCIntentFilter,
  CopilotExtensionIdsFilter,
} from '../filters'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'
import { Card } from './Card'
import { useStyles } from './Card.styles'
import { useTicketFilterStore } from '../../store/store'
import { CustomFilterCardFilters } from './config'

interface IProps {
  control: Control<ITicketFilterFormData>
}

export function CustomFiltersCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const customizedFilters = useTicketFilterStore((state) => state.customizedFilters)
  const selectedProductId = useTicketFilterStore((state) => state.selectedProductId)

  const hasCustomizedFilters = useMemo(() => {
    return (
      customizedFilters.length > 0 &&
      filterPanelItems.some((item) => customizedFilters.includes(item)) &&
      CustomFilterCardFilters.some((item) => customizedFilters.includes(item))
    )
  }, [customizedFilters, filterPanelItems])

  if (!hasCustomizedFilters) return null

  return (
    <section className={styles.section}>
      <Body1Stronger>Custom filters</Body1Stronger>
      <Card className={styles.card}>
        {/* Is Top i18N Tenants */}
        {customizedFilters?.includes(SearchFilterFieldNames.isTopi18N) &&
          filterPanelItems.includes(SearchFilterFieldNames.isTopi18N) && (
            <Controller
              name="isTopi18N"
              control={control}
              render={({ field }) => (
                <IsTopi18NTenantFilter selectedValues={field.value} onChangeValue={field.onChange} />
              )}
            />
          )}

        {/* Is STCA China  */}
        {customizedFilters?.includes(SearchFilterFieldNames.isSTCAChina) &&
          filterPanelItems.includes(SearchFilterFieldNames.isSTCAChina) && (
            <Controller
              name="isSTCAChina"
              control={control}
              render={({ field }) => <IsSTCAChinaFilter selectedValues={field.value} onChangeValue={field.onChange} />}
            />
          )}

        {/* Has GPT-Extension keywords */}
        {customizedFilters?.includes(SearchFilterFieldNames.hasGPTExtension) &&
          filterPanelItems.includes(SearchFilterFieldNames.hasGPTExtension) && (
            <Controller
              name="hasGPTExtension"
              control={control}
              render={({ field }) => (
                <HasGPTExtensionFilter selectedValues={field.value} onChangeValue={field.onChange} />
              )}
            />
          )}

        {/* Has MessageExtension keywords  */}
        {customizedFilters?.includes(SearchFilterFieldNames.hasMessageExtension) &&
          filterPanelItems.includes(SearchFilterFieldNames.hasMessageExtension) && (
            <Controller
              name="hasMessageExtension"
              control={control}
              render={({ field }) => (
                <HasMessageExtensionFilter selectedValues={field.value} onChangeValue={field.onChange} />
              )}
            />
          )}

        {/* Has CopilotExtensionIDs  */}
        {customizedFilters?.includes(SearchFilterFieldNames.hasCopilotExtensionIds) &&
          filterPanelItems.includes(SearchFilterFieldNames.hasCopilotExtensionIds) && (
            <Controller
              name="hasCopilotExtensionIds"
              control={control}
              render={({ field }) => (
                <HasCopilotExtensionIdsFilter selectedValues={field.value} onChangeValue={field.onChange} />
              )}
            />
          )}
        {/* Has Connector keywords */}
        {customizedFilters?.includes(SearchFilterFieldNames.hasConnector) &&
          filterPanelItems.includes(SearchFilterFieldNames.hasConnector) && (
            <Controller
              name="hasConnector"
              control={control}
              render={({ field }) => <HasConnectorFilter selectedValues={field.value} onChangeValue={field.onChange} />}
            />
          )}
        {/* Is GC Intent  */}
        {customizedFilters?.includes(SearchFilterFieldNames.isGCIntent) &&
          filterPanelItems.includes(SearchFilterFieldNames.isGCIntent) && (
            <Controller
              name="isGCIntent"
              control={control}
              render={({ field }) => <IsGCIntentFilter selectedValues={field.value} onChangeValue={field.onChange} />}
            />
          )}
        {/* Has Connector result  */}
        {customizedFilters?.includes(SearchFilterFieldNames.hasConnectorResult) &&
          filterPanelItems.includes(SearchFilterFieldNames.hasConnectorResult) && (
            <Controller
              name="hasConnectorResult"
              control={control}
              render={({ field }) => (
                <HasConnectorResultFilter selectedValues={field.value} onChangeValue={field.onChange} />
              )}
            />
          )}

        {/* Semantic search type */}
        {customizedFilters?.includes(SearchFilterFieldNames.semanticSearchType) &&
          filterPanelItems.includes(SearchFilterFieldNames.semanticSearchType) && (
            <Controller
              name="semanticSearchType"
              control={control}
              render={({ field }) => (
                <SemanticSearchTypeFilter selectedValues={field.value} onChangeValue={field.onChange} />
              )}
            />
          )}
        {/* PromptMetadata_ExperienceType  */}
        {customizedFilters?.includes(SearchFilterFieldNames.experienceType) &&
          filterPanelItems.includes(SearchFilterFieldNames.experienceType) && (
            <Controller
              name="experienceType"
              control={control}
              render={({ field }) => (
                <ExperienceTypeFilter selectedValues={field.value} onChangeValue={field.onChange} />
              )}
            />
          )}

        {/* Error Code  */}
        {customizedFilters?.includes(SearchFilterFieldNames.errorCode) &&
          filterPanelItems.includes(SearchFilterFieldNames.errorCode) && (
            <Controller
              name="errorCode"
              control={control}
              render={({ field }) => (
                <ErrorCodeFilter
                  selectedValues={field.value}
                  onChangeValue={field.onChange}
                  selectedProductId={selectedProductId}
                />
              )}
            />
          )}

        {/* GPT Identifiers  */}
        {customizedFilters?.includes(SearchFilterFieldNames.copilotExtensionIds) &&
          filterPanelItems.includes(SearchFilterFieldNames.copilotExtensionIds) && (
            <Controller
              name="copilotExtensionIds"
              control={control}
              render={({ field }) => <CopilotExtensionIdsFilter value={field.value} onChangeValue={field.onChange} />}
            />
          )}
      </Card>
    </section>
  )
}
