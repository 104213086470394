import { Logger } from '@copilot-dash/logger'
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { buildMemoryStorage, CacheProperties, Header, setupCache } from 'axios-cache-interceptor'
import { ZodType } from 'zod'
import { parseToStandardDate } from '../utils'
import { serializeParamsToString } from '../utils/serializeParamsToString'
import { ApiClientConfig } from './ApiClientConfig'
import { ApiError } from './ApiError'
import { ApiErrorUtils } from './ApiErrorUtils'

export type ApiCacheOptions = false | Partial<CacheProperties>
export type ApiRequestConfig<T = unknown> = AxiosRequestConfig & {
  readonly schema: ZodType<T>
  readonly cache?: ApiCacheOptions
}

export abstract class ApiClient {
  protected readonly config: ApiClientConfig
  protected readonly delegate: AxiosInstance

  protected constructor(config: ApiClientConfig) {
    this.config = config
    this.delegate = axios.create(config.config)

    // 1. Init params serializer
    this.delegate.defaults.paramsSerializer = (params) => {
      return serializeParamsToString(params)
    }

    // 2. Init request interceptor
    this.delegate.interceptors.request.use(async (config) => {
      await this.onRequest?.(config)
      return config
    })

    // 3. Init response cache
    this.delegate.interceptors.response.use(async (response) => {
      response.headers[Header.CacheControl] = ''
      response.headers[Header.Expires] = ''

      return response
    })

    // 4. Init response interceptor
    this.delegate.interceptors.response.use(async (response) => {
      await this.onResponse?.(response)
      return response
    })

    // 5. Init cache
    // https://axios-cache-interceptor.js.org/guide/getting-started
    setupCache(this.delegate, {
      storage: buildMemoryStorage(),
      ttl: 0,
    })
  }

  async get<T>(url: string, config: ApiRequestConfig<T> & { data?: never }): Promise<T> {
    return this.request({
      ...config,
      url,
      method: 'GET',
    })
  }

  async post<T>(url: string, config: ApiRequestConfig<T>): Promise<T> {
    return this.request({
      ...config,
      url,
      method: 'POST',
    })
  }

  async put<T>(url: string, config: ApiRequestConfig<T> & { cache?: never }): Promise<T> {
    return this.request({
      ...config,
      url,
      method: 'PUT',
    })
  }

  async delete<T>(url: string, config: ApiRequestConfig<T> & { cache?: never }): Promise<T> {
    return this.request({
      ...config,
      url,
      method: 'DELETE',
    })
  }

  protected onRequest?(config: InternalAxiosRequestConfig<unknown>): Promise<void>
  protected onResponse?(response: AxiosResponse<unknown>): Promise<void>

  private async request<T>(config: ApiRequestConfig<T>): Promise<T> {
    try {
      // Step1: Request
      let response: AxiosResponse
      try {
        response = await this.delegate(config)
      } catch (error) {
        throw ApiErrorUtils.onRequestError(this.config, config, error)
      }

      // Step2: Response check
      if (response.status < 200 || response.status >= 300) {
        throw ApiErrorUtils.onResponseError(this.config, config, response, undefined)
      }

      // Step3: Response clean
      let data: unknown
      try {
        data = parseToStandardDate(response.data)
      } catch (error) {
        throw ApiErrorUtils.onResponseError(this.config, config, response, error)
      }

      // Step4: Response validate
      try {
        return config.schema.parse(data)
      } catch (error) {
        throw ApiErrorUtils.onResponseError(this.config, config, response, error)
      }
    } catch (error) {
      // Step5: Print error
      if (error instanceof ApiError) {
        Logger.trace.error('Failed to call API.', {
          message: error.message,
          endpoint: error.data.endpoint,
          baseUrl: error.data.baseUrl,
          statusCode: error.data.statusCode,
          statusMessage: error.data.statusMessage,
          errorCode: error.data.errorCode,
          errorMessages: error.data.errorMessages,
        })
      } else {
        Logger.trace.error('Failed to call API. An unknown error occurred.', String(error))
      }

      throw error
    }
  }
}
