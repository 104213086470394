import { ApiKustoLogName, ApiKustoLogRequest } from '@copilot-dash/api'
import { IKustoLogData, KustoLogTable } from '@copilot-dash/domain'
import { IDashStoreContext } from '../../../IDashStoreContext'
import { KustoLogDataConvert } from '../converter/KustoLogDataConvert'

interface IFetchKustoDataParams {
  readonly table: Exclude<KustoLogTable, KustoLogTable.BizChat3SLatencyLog>
  readonly interactionTime: string
  readonly messageId: string
}

export async function fetchTicketKustoData(
  context: IDashStoreContext,
  params: IFetchKustoDataParams,
): Promise<IKustoLogData> {
  const request = createRequestParams(params)
  const response = await context.api.logCollector.getSydneySingleInfo(request)

  return KustoLogDataConvert.fromApi(params.table, response)
}

function createRequestParams(params: IFetchKustoDataParams): ApiKustoLogRequest {
  return {
    FetchLogName: convertKustoLogName(params.table),
    HappenTimeArray: [params.interactionTime],
    MessageId: params.messageId,
  }
}

function convertKustoLogName(table: KustoLogTable): ApiKustoLogName {
  switch (table) {
    case KustoLogTable.BizChat3SLatencyLog:
      return 'bizChat3SLatencyLog'
    case KustoLogTable.BizChatPerfLog:
      return 'bizchatPerformanceLog'
    case KustoLogTable.EventLog:
      return 'eventLog'
    case KustoLogTable.GwsLog:
      return 'gwsLog'
    case KustoLogTable.LLMLog:
      return 'llmLog'
    case KustoLogTable.StateDurationLog:
      return 'stateDurationLog'
    case KustoLogTable.TraceLog:
      return 'traceLog'
  }
}
