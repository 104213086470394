import {
  createPresenceComponentVariant,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderNavigation,
  makeStyles,
  motionTokens,
  OverlayDrawer,
  tokens,
} from '@fluentui/react-components'
import { Collapse } from '@fluentui/react-motion-components-preview'
import { ComponentProps } from 'react'
import { Column } from '../../Layout'
import { DashDialogInstanceContext } from '../context/DashDialogInstanceContext'
import { DashDialogProvider } from '../DashDialogProvider'
import { IDashDialogInstance } from '../store/DashDialogActions'

interface IProps {
  readonly id: string
  readonly instance: IDashDialogInstance
}

export function DashDialogItemForBottom({ id, instance }: IProps) {
  const styles = useStyles()
  const actions = DashDialogProvider.useActions()
  const status = DashDialogProvider.use((state) => state.items.find((item) => item.id === id)?.status)
  const isOpen = !status || status === 'open'

  const handleOpenChange: ComponentProps<typeof OverlayDrawer>['onOpenChange'] = (_, data) => {
    if (!data.open) {
      instance?.onDismissed?.()
      actions.close(id)
    }
  }

  // const handleClose = () => {
  //   instance?.onDismissed?.()
  //   actions.close(id)
  // }

  return (
    <DashDialogInstanceContext.Provider value={id}>
      <CustomizedCollapse appear visible={isOpen} unmountOnExit animateOpacity>
        <OverlayDrawer
          position="bottom"
          open
          onOpenChange={handleOpenChange}
          className={styles.root}
          surfaceMotion={null}
          backdropMotion={null}
          style={{ flex: '1 1 0' }}
        >
          <DrawerHeader className={styles.header}>
            <DrawerHeaderNavigation>
              {/* <Toolbar>
              <Spacer />
              <ToolbarButton
                aria-label="Close panel"
                appearance="subtle"
                icon={<Dismiss24Regular />}
                onClick={handleClose}
              />
            </Toolbar> */}
            </DrawerHeaderNavigation>
          </DrawerHeader>
          <DrawerBody className={styles.body}>
            <Column fill>{instance.content}</Column>
          </DrawerBody>
        </OverlayDrawer>
      </CustomizedCollapse>
    </DashDialogInstanceContext.Provider>
  )
}

const CustomizedCollapse = createPresenceComponentVariant(Collapse, {
  enter: { duration: motionTokens.durationSlow, easing: motionTokens.curveDecelerateMid },
  exit: { duration: motionTokens.durationNormal, easing: motionTokens.curveAccelerateMid },
})

const useStyles = makeStyles({
  root: {
    padding: 0,
    margin: 0,
    height: 'calc(100vh - 80px)',

    backgroundColor: tokens.colorNeutralBackground3,
    borderTopLeftRadius: tokens.borderRadiusLarge,
    borderTopRightRadius: tokens.borderRadiusLarge,
  },
  header: {
    padding: '8px 24px',
    // borderBottom: `1px solid ${tokens.colorNeutralStroke1}`,
  },
  body: {
    padding: 0,
    margin: 0,
    height: '100%',
    cursor: 'default',
  },
})
