import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Column, Row, Spacer } from '../../../../../../components/Layout'
import {
  mergeClasses,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@fluentui/react-components'
import { columnsConfig } from './tableColumns'
import { useStyles } from './TopIssueTable.style'
import { SearchRoute } from '../../../../../../router'
import { ITopIssueExtendInfo, ITopIssueList } from '@copilot-dash/domain'
import { LoaderComponent } from '../../../loader/LoaderComponent'
import { IRowData } from './tableColumns/types'
import { useSearchScreenActions } from '../../../../store'

export const TopIssueTable = memo(() => {
  const styles = useStyles()
  const batchIdRef = useRef<string | undefined | null>()
  const actions = useSearchScreenActions()

  const args = SearchRoute.navigator.useArgsOptional()
  const selectedBatchId = args?.issueBatchId
  batchIdRef.current = selectedBatchId

  const [loading, setLoading] = useState(true)
  const [issueList, setIssueList] = useState<ITopIssueList>([])
  const [issueExtendInfoMap, setIssueExtendInfoMap] = useState<{ [key: string]: ITopIssueExtendInfo }>({})

  useEffect(() => {
    if (!selectedBatchId) return
    const ALL_TEAMS_ID = 0
    setLoading(true)
    setIssueList([])
    application.store.actions
      .getOrFetchTeamIssueClusteringInfoList(selectedBatchId, ALL_TEAMS_ID)
      .then((issues) => {
        const sortedIssues = [...issues].sort((a, b) => {
          if (a.priority === b.priority) {
            return b.ticketCount - a.ticketCount
          }
          return a.priority - b.priority
        })
        setIssueList(sortedIssues.slice(0, 10))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [selectedBatchId])

  useEffect(() => {
    if (issueList.length > 0 && batchIdRef.current) {
      const issueIds = issueList.map((issue) => issue.issueId)
      application.store.actions.getTopIssueExtendInfo(batchIdRef.current, issueIds).then((extendInfoMap) => {
        setIssueExtendInfoMap(extendInfoMap)
      })
    }
  }, [issueList])

  const tableItems = useMemo(() => {
    return issueList.map((issue) => {
      const key = `${selectedBatchId}-${issue.issueId}`
      const extendInfo = issueExtendInfoMap[key]
      return {
        issueTitle: issue.issueTitle,
        issueId: issue.issueId,
        priority: issue.priority,
        coverage: issue.coverage,
        trends: issue.trends,
        isNew: issue.isNew,
        ticketCount: issue.ticketCount,
        impactedTenantsCount: issue.impactedTenantsCount,
        extendInfo: extendInfo ? { status: 'done' as const, data: extendInfo } : { status: 'waiting' as const },
      }
    })
  }, [issueList, issueExtendInfoMap, selectedBatchId])

  const handleRowClick = (issueId: string, issue: IRowData) => {
    const args = SearchRoute.navigator.getArgs()
    SearchRoute.navigator.navigate(
      { issueId, issueBatchId: args.issueBatchId, product: args.product, tab: 'feedbackInsights' },
      {
        state: {
          issueExtendInfo: issue.extendInfo.data,
        },
      },
    )
    actions.onDismissTicketSummaryPanel()
  }

  return (
    <Row className={styles.container}>
      <Table aria-label="TopIssueTable" noNativeElements className={styles.table}>
        <TableHeader>
          <TableRow className={styles.tableRow}>
            {columnsConfig.map((column) => (
              <TableHeaderCell key={column.id} style={column.cellStyle}>
                {column.renderHeaderCell()}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {loading ? (
            <Column vAlign="center" fill>
              <Spacer height={50} />
              <LoaderComponent />
              <Spacer height={50} />
            </Column>
          ) : (
            tableItems.map((topIssue) => {
              return (
                <TableRow
                  key={topIssue.issueId}
                  onClick={() => handleRowClick(topIssue.issueId, topIssue)}
                  className={mergeClasses(styles.tableRow, styles.pointer)}
                >
                  {columnsConfig.map((column, index) => (
                    <TableCell key={column.id} style={column.cellStyle}>
                      {column.renderCell(topIssue, index, tableItems)}
                    </TableCell>
                  ))}
                </TableRow>
              )
            })
          )}
        </TableBody>
      </Table>
    </Row>
  )
})

TopIssueTable.displayName = 'TopIssueTable'
