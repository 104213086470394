import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    alignItems: 'center',
    ...shorthands.gap('8px'),
  },
  interactionTagPrimary: {
    backgroundColor: tokens.colorNeutralBackground4,
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground4,
    },
    ':active': {
      backgroundColor: tokens.colorNeutralBackground4,
    },
  },
  textStyle: {
    color: tokens.colorNeutralForeground1,
    display: 'block',
    width: 'auto',
  },
  tooltip: {
    boxShadow: tokens.shadow2,
    paddingRight: '0px',
  },
  tooltipContent: {
    paddingRight: '16px',
    fontSize: tokens.fontSizeBase300,
    lineHeight: tokens.lineHeightBase300,
  },
})
