import { z } from 'zod'

export enum ApiResponseStatusCode {
  OK = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,
  NotFound = 404,
  BadRequest = 400,
  Unauthorized = 401,
  InternalServerError = 500,
}

export const apiResponseStatusCodeSchema = z.nativeEnum(ApiResponseStatusCode)
