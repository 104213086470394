import { PromiseSnapshots } from '@copilot-dash/core'
import { useTicketKustoEventLog } from '@copilot-dash/store'
import { TableCell, TableRow, Text } from '@fluentui/react-components'
import { useMemo } from 'react'
import { ErrorView } from '../../Error'
import { SymptomReportLoadingIcon } from './SymptomReportLoadingIcon'

interface IProps {
  readonly ticketId: string
  readonly turnId: string
}

export function RowForWebSearch({ ticketId, turnId }: IProps) {
  const ticket = application.store.use.getTicket(ticketId)

  // Get countWorkResults from the event log
  const eventLogSnapshot = useTicketKustoEventLog({ ticketId, messageId: turnId })
  const countWebResultsSnapshot = useMemo(() => {
    return PromiseSnapshots.map(eventLogSnapshot, (data) => {
      return data.find((item) => {
        return item.eventName === 'WebPlusWorkResultInfo'
      })?.messageObject?.CountWebResults
    })
  }, [eventLogSnapshot])

  return (
    <TableRow>
      <TableCell>
        <Text weight="semibold">Web Search</Text>
      </TableCell>
      <TableCell>
        {(() => {
          switch (ticket.status) {
            case 'waiting':
              return <SymptomReportLoadingIcon />
            case 'error':
              return <ErrorView type="inline" error={ticket.error} />
            case 'done': {
              const webSearchTriggered = ticket.data.tag.webSearchTriggered
              if (!webSearchTriggered) {
                return 'WebSearch is not triggered'
              }

              return (
                <span>
                  WebSearch is triggered
                  {countWebResultsSnapshot?.data && `, CountWebResults: ${countWebResultsSnapshot.data}`}
                </span>
              )
            }
          }
        })()}
      </TableCell>
    </TableRow>
  )
}
