import { themeModeSchema } from '@copilot-dash/domain'
import { useUserSetting } from '@copilot-dash/settings'
import { Select } from '@fluentui/react-components'
import { ComponentProps, useId } from 'react'

export function ThemeModeTile() {
  const labelId = useId()
  const [targetRing, setTargetRing] = useUserSetting('themeMode')

  const onChange: ComponentProps<typeof Select>['onChange'] = (_, data) => {
    const themeMode = themeModeSchema.safeParse(data.value).data
    if (themeMode) {
      setTargetRing(themeMode)
    }
  }

  return (
    <>
      <label htmlFor={labelId}>Theme mode</label>
      <Select id={labelId} value={targetRing} onChange={onChange}>
        <option value="light">Light</option>
        <option value="dark">Dark</option>
        <option value="system">System</option>
      </Select>
    </>
  )
}
