import { z } from 'zod'

export interface ApiTeamNameListResponse {
  readonly teamNamesList: Array<string>
  readonly errorMessage: string
}

export const apiTeamNameListResponseSchema = z.object({
  teamNamesList: z.array(z.string()),
  errorMessage: z.string(),
})
