export type ProductName =
  | 'M365Chat'
  | 'M365ChatWebChat'
  | 'ExcelCopilot'
  | 'WordCopilot'
  | 'PPTCopilot'
  | 'TeamsMeetingCopilot'
  | 'ODSPCopilot'
  | 'LoopCopilot'
  | 'OneNoteCopilot'
  | 'TenantAdminFeedback'

export const ProductNames = {
  M365Chat: 'M365Chat',
  M365ChatWebChat: 'M365ChatWebChat',
  ExcelCopilot: 'ExcelCopilot',
  WordCopilot: 'WordCopilot',
  PPTCopilot: 'PPTCopilot',
  TeamsMeetingCopilot: 'TeamsMeetingCopilot',
  ODSPCopilot: 'ODSPCopilot',
  LoopCopilot: 'LoopCopilot',
  OneNoteCopilot: 'OneNoteCopilot',
  TenantAdminFeedback: 'TenantAdminFeedback',
} satisfies { [x: string]: ProductName }

export function parseProductName(value: string | null | undefined): ProductName | undefined {
  if (!value) return undefined

  return Object.values(ProductNames).find((name) => name.toLowerCase() === value.toLowerCase())
}
