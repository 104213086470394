import { Button, DialogTitle } from '@fluentui/react-components'
import { DismissRegular } from '@fluentui/react-icons'
import { Row, Spacer } from '../../Layout'
import { IDashDialogInstance } from '../store/DashDialogActions'

interface IProps {
  readonly instance: IDashDialogInstance
  readonly onClose?: () => void
}

export function DashDialogItemTitle({ instance, onClose }: IProps) {
  const title = instance.title
  if (!title) {
    return null
  }

  if (title === true) {
    return (
      <DialogTitle style={{ minHeight: 40, paddingLeft: 16, paddingRight: 16 }}>
        <Row vAlign="center" fill>
          <Spacer />
          <Button appearance="subtle" icon={<DismissRegular />} onClick={onClose} title="Close" />
        </Row>
      </DialogTitle>
    )
  } else {
    return title
  }
}
