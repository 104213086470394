import {
  Dropdown,
  mergeClasses,
  Option,
  OptionOnSelectData,
  SelectionEvents,
  Spinner,
  Text,
} from '@fluentui/react-components'
import * as React from 'react'
import { TeamRoute } from '../../../router'
import { useGlobalStore } from '../../../store'
import { CategoryTypeIds } from '../storeNew/const'
import { useTeamViewStore } from '../storeNew/store'
import { useStyles } from './TeamFilter.styles'
import { ALL_TEAMS } from '../../../store/data/team/createTeamStore'
import { TeamViewSubMenuIds, DefaultProductChannelMapping } from '@copilot-dash/domain'
import { Column } from '../../../components/Layout'

interface ITeamFilterProps {
  className?: string
}

interface Option {
  value: string
  text: string
}

export const TeamFilter: React.FC<ITeamFilterProps> = ({ className }) => {
  const styles = useStyles()

  const teamList = useGlobalStore((state) => state.team.teamList)
  const lastSelectedTeam = useTeamViewStore((state) => state.teams.lastSelectedTeam)
  const lastSelectedTeamId = useTeamViewStore((state) => state.teams.computed.selectedTeamId)

  const onSelectChange = React.useCallback((_event: SelectionEvents, data: OptionOnSelectData) => {
    if (!data.optionText) return
    // all fetch tickets actions will be trigged by the url args change, so we just need to navigate to the right url
    // the url monitor is in the src/screens/team/TeamScreen.tsx
    if (data.optionText.trim() === ALL_TEAMS) {
      TeamRoute.navigator.navigate({
        product: useTeamViewStore.getState().product,
        subMenu: TeamViewSubMenuIds.TopIssues,
        teamName: data.optionText.trim(),
        channel: DefaultProductChannelMapping[useTeamViewStore.getState().product],
      })
      useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.TopIssues)
      useTeamViewStore.getState().teams.setLastSelectedTeam(data.optionText.trim())
    } else {
      TeamRoute.navigator.navigate({
        product: useTeamViewStore.getState().product,
        subMenu: TeamViewSubMenuIds.UnRootCaused,
        teamName: data.optionText.slice(0, -5),
        ticketStatus: CategoryTypeIds.UnRootCaused,
        channel: DefaultProductChannelMapping[useTeamViewStore.getState().product],
      })
      useTeamViewStore.getState().teams.setSelectedSubMenu(TeamViewSubMenuIds.UnRootCaused)
      useTeamViewStore.getState().teams.setLastSelectedTeam(data.optionText.slice(0, -5))
    }

    useTeamViewStore.getState().rootCauses.setSelectedRootCause(null)
  }, [])

  return (
    <Dropdown
      className={mergeClasses(styles.container, className)}
      onOptionSelect={onSelectChange}
      button={
        <Text
          className={styles.selectedOption}
        >{`${lastSelectedTeam} ${lastSelectedTeam !== ALL_TEAMS ? 'Team' : ''}`}</Text>
      }
      selectedOptions={lastSelectedTeamId ? [String(lastSelectedTeamId)] : lastSelectedTeamId === 0 ? ['0'] : []}
    >
      {teamList.status === 'done' ? (
        <Column style={{ maxHeight: '70vh' }}>
          {(teamList.data ?? []).map((team) => (
            <Option key={team.id} value={String(team.id)}>
              {`${team.name} ${team.name !== ALL_TEAMS ? 'Team' : ''}`}
            </Option>
          ))}
        </Column>
      ) : (
        <Spinner />
      )}
    </Dropdown>
  )
}
