import { getProductIdByName, ProductIds, SearchFilterFieldNames } from '@copilot-dash/domain'
import { isEqual } from 'lodash'
import { memo, useEffect, useRef, useMemo } from 'react'
import {
  ITicketFilterFormData,
  ITicketFilterFormRef,
} from '../../../../components/TicketsFilterPanel/children/TicketFilterForm/TicketFilterForm.types'
import { TicketsFilterPanel } from '../../../../components/TicketsFilterPanel/TicketsFilterPanel'
import { ISearchScreenForm, useSearchScreenActions, useSearchScreenState } from '../../store'
import { getFilterItems } from '../../../../components/TicketsFilterPanel/config/getFilterItems'
import { useUserSetting } from '@copilot-dash/settings'

export const FilterPanel = memo(function FilterPanel() {
  const actions = useSearchScreenActions()
  const state = useSearchScreenState((state) => state)
  const form = state.form
  const formRef = useRef<ITicketFilterFormRef | null>(null)

  const onSubmitFilterPanel = (data?: ITicketFilterFormData) => {
    const updateForm: ISearchScreenForm = {
      ...data,
      range: data?.range,
      tenantIds: form.tenantIds,
      utteranceAndResponse: form.utteranceAndResponse,
      verbatimFeedback: form.utteranceAndResponse,
      email: form.email,
      userId: form.userId,
      product: form.product,
      defaultRange: form.defaultRange,
    }
    if (!isEqual(updateForm, form)) {
      actions.submit(updateForm)
      Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FilterPanel', value: null })
    }
  }

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setValues({
        ...form,
        range: form.range ?? form.defaultRange,
      })
    }
  }, [form])

  const onClickReset = () => {
    Logger.telemetry.trackEvent('TicketFilter/ResetButton/Click')
    actions.resetFilters()
  }
  const supportedTicketFilterItems: SearchFilterFieldNames[] = useMemo(() => {
    return getFilterItems(getProductIdByName(form.product) ?? ProductIds.M365Chat, 'search')
  }, [form.product])

  const [searchScreenCustomizedFilters] = useUserSetting('searchScreenCustomizedFilterItems')

  return (
    <TicketsFilterPanel
      ref={formRef}
      isPanelOpen={state.isTicketsFilterPanelOpen}
      onDismiss={() => actions.updateFiltersPanelVisibility(false)}
      onClickReset={onClickReset}
      isAIF={form.product === 'TenantAdminFeedback'}
      selectedProductId={getProductIdByName(form.product)}
      ticketFilterForm={form}
      onSubmitFilterPanel={onSubmitFilterPanel}
      defaultRange={form.defaultRange}
      supportedTicketFilterItems={supportedTicketFilterItems}
      customizedFilters={searchScreenCustomizedFilters}
      selectDateRangeWithTime={true}
    />
  )
})
