import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '20px',
    maxWidth: '300px',
    borderRadius: '4px',
    overflow: 'hidden',
    '& .item': {
      opacity: 1,
      transition: 'opacity 0.3s ease',
    },
    '&:hover .item': {
      opacity: 0.5,
    },
    '&:hover .item:hover': {
      opacity: 1,
    },
  },
})
