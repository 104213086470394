import { User } from '@copilot-dash/api'
import {
  makeStyles,
  mergeClasses,
  shorthands,
  Skeleton,
  SkeletonItem,
  Text,
  tokens,
  Tooltip,
} from '@fluentui/react-components'
import { useMemo } from 'react'
import { Row } from '../Layout'

interface IProps {
  readonly userId: string
  readonly className?: string
  readonly isEnableToolTip?: boolean
  readonly fallback?: React.ReactNode
}

export function UserDisplayName({ userId, className, isEnableToolTip = true, fallback }: IProps) {
  const styles = useStyles()
  const userSnapshot = app.store.use.getUserProfile(userId)

  const user: User | undefined = useMemo(() => {
    switch (userSnapshot.status) {
      case 'done':
        return userSnapshot.data
      default:
        return undefined
    }
  }, [userSnapshot.data, userSnapshot.status])

  const text = useMemo(() => {
    if (userSnapshot.status === 'done') {
      return user?.displayName
    }
    if (userSnapshot.status === 'error') {
      return fallback
    }
    if (userSnapshot.status === 'waiting') {
      return (
        <Skeleton appearance="translucent" className={styles.skeleton}>
          <SkeletonItem />
        </Skeleton>
      )
    }
    return null
  }, [fallback, styles.skeleton, user?.displayName, userSnapshot.status])

  return (
    <Row>
      {isEnableToolTip && text ? (
        <Tooltip relationship={'label'} withArrow content={<span>{text}</span>}>
          <Text wrap={false} truncate className={mergeClasses(styles.displayName, className)}>
            {text}
          </Text>
        </Tooltip>
      ) : (
        <Text wrap={false} truncate className={mergeClasses(styles.displayName, className)}>
          {text}
        </Text>
      )}
    </Row>
  )
}

const useStyles = makeStyles({
  displayName: {
    fontSize: tokens.fontSizeBase400,
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
  skeleton: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100px',
    ...shorthands.gap('8px'),
  },
})
