import { ApiExtensibilityLog } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import {
  IExtensibilityLogItem,
  IKustoStateDurationLogItem,
  IKustoTraceLogItem,
  KustoLogTable,
} from '@copilot-dash/domain'
import { TicketError } from '@copilot-dash/error'
import { IDashStoreContext } from '../../IDashStoreContext'
import { getTicketKustoData } from './getTicketKustoData'

const ImportantScopeNamePrefixes = [
  'SubstrateSearchServiceUserConfig',
  'MetaOsGptProvider.GetGptAsync',
  'TuringBot.GptConfigRetriever',
  'EntityServeService',
  'SkillDiscoveryServicePluginSource',
  'ExtensionRunner:ext:aiplugin-openapi-loader-',
  'ConfirmationHandler.SendConfirmationDialog',
  'PluginRequestAuthHandler',
  'OpenApiContainer.RunFunction/',
  'ExtensionRunner:ext:OpenAPI-spec.OpenAPI-spec//',
  'CustomEngineCopilotMcsClient',
]

const ImportantEventNames = [
  'GptEnabledToolsChecker',
  'SkillDiscoveryPluginSource',
  'PluginExecution',
  'DeveloperInfoEvent',
  'ReferencesAdded',
]

export function getExtensibilityLog(
  context: IDashStoreContext,
  ticketId: string,
  messageId: string,
): PromiseSnapshot<IExtensibilityLogItem[]> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.turns?.[messageId]?.extensibilityLog
    },
    set: (state, snapshot) => {
      const ticket = (state.tickets[ticketId] ??= {})
      const turns = (ticket.turns ??= {})
      const turn = (turns[messageId] ??= {})
      turn.extensibilityLog = snapshot
    },
    fetch: () => fetch(),
  })

  async function fetch(): Promise<IExtensibilityLogItem[]> {
    const response = await context.api.logCollector.getExtensibilityLogsByMessageIds({
      MessageIds: [messageId],
    })

    if (response.extensibilityLogs.length === 0) {
      throw TicketError.create('NoExtensibilityLog', { ticketId })
    }

    return await Promise.all(
      response.extensibilityLogs.map((item) => {
        return convertItem(item)
      }),
    )
  }

  async function convertItem(item: ApiExtensibilityLog): Promise<IExtensibilityLogItem> {
    const stateDurationLogs = await getStateAndDurations()
    const traceLogs = await getTraceLogs()

    return {
      correlationId: item.correlationId,
      botConversationId: item.botConversationId,
      botRequestId: item.botRequestId,
      copilotExtensionIds: item.copilotExtensionIds,
      messageId: item.messageId,
      importantScopes: getImportantScopes(stateDurationLogs),
      importantEvents: getImportantEvents(traceLogs),
    }
  }

  function getImportantScopes(logs: IKustoStateDurationLogItem[]): Record<string, IKustoStateDurationLogItem[]> {
    const result: Record<string, IKustoStateDurationLogItem[]> = {}

    // Store logs with important prefixes for extensibility
    for (const log of logs) {
      for (const prefix of ImportantScopeNamePrefixes) {
        if (log.scopeName.startsWith(prefix)) {
          if (!result[log.scopeName]) {
            result[log.scopeName] = []
          }
          result[log.scopeName]?.push(log)
          break
        }
      }
    }

    // Add empty array for missing prefixes
    for (const prefix of ImportantScopeNamePrefixes) {
      if (!Object.keys(result).some((scopeName) => scopeName.startsWith(prefix))) {
        result[prefix] = []
      }
    }

    return result
  }

  function getImportantEvents(logs: IKustoTraceLogItem[]): Record<string, IKustoTraceLogItem[]> {
    const result: Record<string, IKustoTraceLogItem[]> = {}
    for (const name of ImportantEventNames) {
      result[name] = logs.filter((item) => item.eventName === name)
    }
    return result
  }

  async function getStateAndDurations(): Promise<IKustoStateDurationLogItem[]> {
    try {
      const tables = await getTicketKustoData(context, ticketId, messageId, KustoLogTable.StateDurationLog).promise
      return tables[KustoLogTable.StateDurationLog]
    } catch (error) {
      return []
    }
  }

  async function getTraceLogs(): Promise<IKustoTraceLogItem[]> {
    try {
      const tables = await getTicketKustoData(context, ticketId, messageId, KustoLogTable.TraceLog).promise
      return tables[KustoLogTable.TraceLog]
    } catch (error) {
      return []
    }
  }
}
