class TeamViewTelemetry {
  private _teamViewLoadedAt: number

  constructor() {
    this._teamViewLoadedAt = 0
  }

  markTeamViewLoadNow() {
    this._teamViewLoadedAt = Date.now()
  }

  reportTeamViewFirstPageDataHasShown() {
    if (this._teamViewLoadedAt) {
      const duration = Date.now() - this._teamViewLoadedAt
      duration > 0 && Logger.telemetry.trackMetric('TeamViewFirstPageDataLoadTime', { duration })
      this._teamViewLoadedAt = 0
    }
  }
}

export const teamViewTelemetry = new TeamViewTelemetry()
