import { create } from 'zustand'
import { ProductIds, SearchFilterFieldNames } from '@copilot-dash/domain'

interface TicketFilterState {
  selectedProductId: number
  setSelectedProductId: (productId: number) => void
  supportedTicketFilterItems: SearchFilterFieldNames[]
  setSupportedTicketFilterItems: (items: SearchFilterFieldNames[]) => void
  customizedFilters: SearchFilterFieldNames[]
  setCustomizedFilters: (items: SearchFilterFieldNames[]) => void
  selectDateRangeWithTime: boolean
  setSelectDateRangeWithTime: (value: boolean) => void
}

export const useTicketFilterStore = create<TicketFilterState>((set) => ({
  selectedProductId: ProductIds.M365Chat,
  setSelectedProductId: (productId) => set({ selectedProductId: productId }),
  supportedTicketFilterItems: [],
  setSupportedTicketFilterItems: (items) => set({ supportedTicketFilterItems: items }),
  customizedFilters: [],
  setCustomizedFilters: (items) => set({ customizedFilters: items }),
  selectDateRangeWithTime: false,
  setSelectDateRangeWithTime: (value) => set({ selectDateRangeWithTime: value }),
}))
