import { memo, useEffect, useMemo, useState } from 'react'
import { Column, Row, Spacer } from '../../../../../../../components/Layout'
import { Button, Checkbox, CheckboxProps, mergeClasses } from '@fluentui/react-components'
import { isNil } from 'lodash'
import { useUserSetting } from '@copilot-dash/settings'
import { AllSearchTableColumns } from '../../../../../../../components/TicketTableColumnConfigs'
import { useSearchScreenActions, useSearchScreenState } from '../../../../../store'
import { ResizableColumnsTable } from '../../../../../../../components/Table/ResizableColumnsTable'
import { SearchRoute } from '../../../../../../../router'
import { ColumnKey, INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { LoaderComponent } from '../../../../loader/LoaderComponent'
import { useStyles } from './IssueTickets.styles'
import { GroupedTicketsByState } from './GroupedTicketsByState'
import { GroupedTicketsByTeam } from './GroupedTicketsByTeam'

export interface IssueTicketsProps {
  onTicketsLoaded?: (tickets: INewTicketData[]) => void
}

enum TableType {
  'All',
  'DSATsStatus',
  'AssignedTeam',
}

export const IssueTickets = memo(function IssueTickets({ onTicketsLoaded }: IssueTicketsProps) {
  const styles = useStyles()
  const actions = useSearchScreenActions()
  const ticketId = useSearchScreenState((state) => state.clickedTicketInfo?.ticketId)

  const [activeTable, setActiveTable] = useState<TableType>(TableType.All)
  const [loading, setLoading] = useState(true)
  const [onlyShowVIPDSATs, setOnlyShowVIPDSATs] = useState<CheckboxProps['checked']>(false)
  const [tableItems, setTableItems] = useState<INewTicketData[]>([])
  const args = SearchRoute.navigator.useArgsOptional()
  const issueId = args?.issueId
  const batchId = args?.issueBatchId

  const [columns] = useUserSetting('feedbackInsightsTopIssueDetailsColumns')
  const selectedColumns = useMemo(() => {
    const allColumns = AllSearchTableColumns(SearchTextPrefixType.All)
    return columns
      .map((column) => allColumns.find((c) => c.columnId === column)!)
      .filter((c) => c)
      .filter((c) => (onlyShowVIPDSATs ? true : c.columnId !== ColumnKey.UserProfile))
  }, [columns, onlyShowVIPDSATs])

  useEffect(() => {
    if (issueId && batchId) {
      setLoading(true)
      app.store.actions
        .searchTickets({
          defaultRange: undefined,
          clusteringIssueId: issueId,
          batchId: batchId,
          count: 500,
          offset: 0,
          isAIF: false,
          teamId: 0,
          ticketStatus: 'TopIssue',
          customerTypes: onlyShowVIPDSATs ? ['VIP Power User'] : undefined,
        })
        .then((resp) => {
          setTableItems(resp.tickets)
          if (!onlyShowVIPDSATs) {
            onTicketsLoaded?.(resp.tickets)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [batchId, issueId, onlyShowVIPDSATs, onTicketsLoaded])

  const count = tableItems.length

  const table = useMemo(() => {
    if (tableItems.length === 0) {
      return (
        <Column fill hAlign="center">
          No tickets found
        </Column>
      )
    }
    switch (activeTable) {
      case TableType.All:
        return (
          <ResizableColumnsTable
            items={tableItems}
            columns={selectedColumns}
            keyPicker={(data) => data.ticketId}
            supportLoadMore={false}
            enableColumnResizing={true}
            rowClick={actions.rowClick}
            selectedRowId={ticketId}
          />
        )
      case TableType.DSATsStatus:
        return <GroupedTicketsByState tickets={tableItems} />
      case TableType.AssignedTeam:
        return <GroupedTicketsByTeam tickets={tableItems} />
    }
  }, [actions.rowClick, activeTable, selectedColumns, tableItems, ticketId])

  return (
    <Column>
      <Row>
        <Button
          shape="circular"
          className={mergeClasses(activeTable === TableType.All ? styles.activeBtn : styles.btn)}
          onClick={() => setActiveTable(TableType.All)}
        >
          All DSATs {isNil(count) ? null : `(${count})`}
        </Button>
        <Spacer width={8} />
        <Button
          shape="circular"
          className={mergeClasses(activeTable === TableType.DSATsStatus ? styles.activeBtn : styles.btn)}
          onClick={() => setActiveTable(TableType.DSATsStatus)}
        >
          DSAT State
        </Button>
        <Spacer width={8} />
        <Button
          shape="circular"
          className={mergeClasses(activeTable === TableType.AssignedTeam ? styles.activeBtn : styles.btn)}
          onClick={() => setActiveTable(TableType.AssignedTeam)}
        >
          Assigned Team
        </Button>
        <Spacer />
        <Checkbox
          label="Show VIP DSATs Only"
          checked={onlyShowVIPDSATs}
          onChange={(_, data) => {
            setOnlyShowVIPDSATs(data.checked)
          }}
        />
      </Row>
      <Spacer height={24} />
      <Row>
        {loading ? (
          <Column vAlign="center" fill>
            <Spacer height={50} />
            <LoaderComponent />
          </Column>
        ) : (
          table
        )}
      </Row>
    </Column>
  )
})
