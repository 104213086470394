import { z } from 'zod'

export enum ApiTicketType {
  VoiceSkillTicket = 0,
  SearchTicket = 1,
  IntelligenceTicket = 2,
  ByTicketId = 3,
  ByLogicalId = 4,
  ByCorrelationId = 5,
  AllTickets = 6,
  LUFeedbackTicket = 7,
  WKWTicket = 8,
}

export const apiTicketTypeSchema = z.nativeEnum(ApiTicketType)
