import { Divider, InlineDrawer, mergeClasses } from '@fluentui/react-components'
import { useStyles } from './TeamAreaLayout.styles'
import { TeamFilter } from '../children/TeamFilter'
import Categories from '../children/Categories'
import { Column, Row, Spacer } from '../../../components/Layout'
import { useTeamViewStore } from '../storeNew'
import { ToggleLeftDrawerIcon } from '../children/ToggleDrawerLeftIcon'
import { memo } from 'react'
import { motion } from 'framer-motion'

interface ITeamAreaLayoutProps {
  className?: string
}

export const TeamAreaLayout: React.FC<ITeamAreaLayoutProps> = memo(({ className }) => {
  const styles = useStyles()
  const drawerOpen = useTeamViewStore((state) => state.leftDrawerOpen)
  return (
    <motion.div
      layout
      animate={drawerOpen ? 'open' : 'close'}
      variants={{
        close: { width: 0, marginRight: 14 },
        open: { width: 320 },
      }}
      className={styles.motionLeftPanelContainer}
    >
      <InlineDrawer open={drawerOpen} className={styles.drawer}>
        <Column className={mergeClasses(styles.container, className)}>
          <Row className={styles.alignItemsCenter}>
            <TeamFilter />
            <Spacer />
            <ToggleLeftDrawerIcon className={styles.noShrink} type="contract" />
          </Row>
          <Divider className={styles.noFlexGrow} />
          <Categories />
        </Column>
      </InlineDrawer>
    </motion.div>
  )
})

TeamAreaLayout.displayName = 'TeamAreaLayout'
