import { memo, useMemo, useState } from 'react'
import { ProgressDoughnutChart } from './ProgressDoughnutChart'
import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components'
import { Column, Row, Spacer } from '../../../../../../../components/Layout'
import { SwitchButton } from '../../../../../../../components/SwitchButton/SwitchButton'
import { LegendItem } from '../../../../../../../components/RatioProgressBar/Legend'
import { IssueDetailDataCard } from '../components/IssueDetailDataCard'

export interface ProgressProps {
  loading: boolean
  stateList: Array<{
    state: 'Closed' | 'Team Assigned' | 'Root Caused'
    count: number
  }>
}

export const Progress = memo(function Progress(props: ProgressProps) {
  const styles = useStyles()
  const [selected, setSelected] = useState<'left' | 'right'>('left')

  const toggleSwitch = (side: 'left' | 'right') => {
    setSelected(side)
  }

  const transformedStateList = useMemo(() => {
    const items: LegendItem[] = [
      {
        color: tokens.colorPaletteGreenBackground2,
        label: 'Closed',
      },
      {
        color: tokens.colorCompoundBrandBackground,
        label: 'Root Caused',
      },
      {
        color: tokens.colorPaletteMarigoldBackground3,
        label: 'Team Assigned',
      },
    ]

    return props.stateList.map((item) => {
      const legendItem = items.find((i) => i.label === item.state)
      return {
        count: item.count,
        legendItem: legendItem,
      }
    })
  }, [props.stateList])

  return (
    <Column className={styles.container}>
      <Row vAlign="center">
        <Text className={styles.title}>Progress</Text>
        <Spacer />
        <SwitchButton selected={selected} onToggle={toggleSwitch} labels={['Distribution', 'Data']} />
      </Row>
      {selected === 'left' ? (
        <ProgressDoughnutChart loading={props.loading} stateList={props.stateList} />
      ) : (
        <IssueDetailDataCard loading={props.loading} dataList={transformedStateList} />
      )}
    </Column>
  )
})
const useStyles = makeStyles({
  container: {
    display: 'flex',
    maxHeight: '90%',
    ...shorthands.padding('10px'),
  },
  title: {
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
    marginLeft: tokens.spacingHorizontalSNudge,
  },
})
