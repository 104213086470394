import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartOptions } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { CardLoading } from '../../CardLoading'
import { Column, Row } from '../../../../../../../components/Layout'
import { useStyles } from '../components/Legend.style'
import { DoughnutHtmlLegendPlugin } from '../components/DoughnutHtmlLegendPlugin'
import { useMemo } from 'react'

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

interface AssignedTeamDoughnutChartProps {
  loading: boolean
  teams: Array<{
    name: string
    count: number
  }>
  backgroundColors: string[]
}

const MAX_SHOW_LABEL_LENGTH = 10
export const AssignedTeamDoughnutChart: React.FC<AssignedTeamDoughnutChartProps> = ({
  loading,
  teams,
  backgroundColors,
}) => {
  const styles = useStyles()
  const labels = teams.map((team) => team.name)
  const dataValues = useMemo(() => teams.map((item) => item.count), [teams])
  const total = useMemo(() => dataValues.reduce((sum, value) => sum + value, 0), [dataValues])

  const plugins = [DoughnutHtmlLegendPlugin]
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Team Count',
        data: dataValues,
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const options: ChartOptions<any> = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 50,
        right: 20,
        top: 30,
        bottom: 30,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      htmlLegend: {
        containerID: 'assignedTeam-legend-container',
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: 'auto',
        color: '#000000',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: (value: number, context: any) => {
          const label = context.chart.data.labels[context.dataIndex]
          const percentage = total > 0 ? ((value / total) * 100).toFixed(2) : '0.00'
          const truncatedLabel =
            label.length > MAX_SHOW_LABEL_LENGTH ? `${label.slice(0, MAX_SHOW_LABEL_LENGTH)}...` : label

          return `${truncatedLabel}\n${value} (${percentage}%)`
        },
        anchor: 'end',
        align: 'end',
        offset: 0,
        clamp: true,
      },
    },
    cutout: '70%',
  }

  if (loading) {
    return <CardLoading />
  }

  return (
    <Row fill style={{ width: '100%' }}>
      <Column style={{ height: '200px', width: '70%' }}>
        <Doughnut id="assignedTeam-doughnut-chart" data={data} options={options} plugins={plugins} />
      </Column>
      <Column id="assignedTeam-legend-container" className={styles.legendContainer} />
    </Row>
  )
}
