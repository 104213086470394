import { AsyncSnapshots } from './AsyncSnapshots'
import { IAsyncLoader, IAsyncLoaderRequest } from './IAsyncLoader'
import { Logger } from '@copilot-dash/logger'

export class AsyncLoader<T> implements IAsyncLoader<T> {
  private isAlive: boolean = true
  private promise?: Promise<T>

  submit(request: IAsyncLoaderRequest<T>): void {
    if (!this.isAlive) {
      Logger.trace.warn('It is not allowed to submit a new request to a disposed AsyncLoader')
      return
    }

    this.promise = this.execute(request)
    this.listen(request, this.promise)
  }

  dispose() {
    this.isAlive = false
  }

  private execute(request: IAsyncLoaderRequest<T>): Promise<T> | undefined {
    const promise = request.promise
    if (!promise) {
      return undefined
    }

    if (typeof promise === 'function') {
      return promise() ?? undefined
    }

    return promise
  }

  private async listen(request: IAsyncLoaderRequest<T>, promise: Promise<T> | undefined): Promise<void> {
    if (!promise) {
      request.onFinished?.(AsyncSnapshots.none())
      request.onChanged?.(AsyncSnapshots.none())
      return
    }

    request.onStarted?.(AsyncSnapshots.waiting())
    request.onChanged?.(AsyncSnapshots.waiting())

    try {
      const result = await promise
      if (this.isAlive && this.promise === promise) {
        request.onSucceeded?.(AsyncSnapshots.done(result))
        request.onFinished?.(AsyncSnapshots.done(result))
        request.onChanged?.(AsyncSnapshots.done(result))
      }
    } catch (error) {
      if (this.isAlive && this.promise === promise) {
        request.onFailed?.(AsyncSnapshots.error(error))
        request.onFinished?.(AsyncSnapshots.error(error))
        request.onChanged?.(AsyncSnapshots.error(error))
      }
    }
  }
}
