import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  grid: {
    display: 'grid',
    width: '100%',
  },

  state: {
    gridArea: 'state',
  },
  noActionableReason: {
    gridArea: 'noActionableReason',
  },
  priority: {
    gridArea: 'priority',
  },
  area: {
    gridArea: 'area',
  },
  assignTo: {
    gridArea: 'assignTo',
  },
  rootCauses: {
    gridArea: 'rootCauses',
    alignItems: 'baseline',
  },
  customTags: {
    gridArea: 'customTags',
    alignItems: 'baseline',
  },
  none: {
    display: 'none',
  },
})
