import { z } from 'zod'

export const apiOriginalServiceCallSchema = z.record(z.unknown()).and(
  z.object({
    SearchSessions: z
      .array(
        z.object({
          Searches: z
            .array(
              z.object({
                ServerSearchRequests: z
                  .array(
                    z.object({
                      TraceId: z.string().optional(),
                    }),
                  )
                  .optional(),
              }),
            )
            .optional(),
        }),
      )
      .optional(),
  }),
)

export const apiReplayRequestSchema = z.record(z.unknown()).and(
  z.object({
    AnswerEntityRequests: z
      .array(
        z.object({
          EntityTypes: z.array(z.string()).optional(),
          Query: z
            .object({
              QueryString: z.string().optional(),
            })
            .optional(),
        }),
      )
      .optional(),
    EntityRequests: z
      .array(
        z.object({
          EntityTypes: z.array(z.string()).optional(),
          Query: z
            .object({
              QueryString: z.string().optional(),
            })
            .optional(),
        }),
      )
      .optional(),
  }),
)

export const apiEntityResultSchema = z.record(z.unknown()).and(
  z.object({
    Provenance: z.string().optional(),
    Results: z.array(z.unknown()).optional(),
  }),
)

export const apiEntityCardSchema = z.record(z.unknown()).and(
  z.object({
    EntityType: z.string().optional(),
    EntityIds: z.array(z.string()).optional(),
  }),
)

export const apiExtendedDataInfoSchema = z.record(z.unknown()).and(
  z.object({
    LlmLuOutput: z.unknown().optional(),
    LuAlteredQueries: z.unknown().optional(),
    LuClassification: z.unknown().optional(),
  }),
)

export const apiEntitySetsSchema = z.record(z.unknown()).and(
  z.object({
    ResultSets: z.array(apiEntityResultSchema).optional(),
    EntityType: z.string().optional(),
  }),
)

export const apiWholePageRankingCardSchema = z.record(z.unknown()).and(
  z.object({
    EntityCards: z.array(apiEntityCardSchema).optional(),
  }),
)

export const apiExtendedDataSchema = z.record(z.unknown()).and(
  z.object({
    Info: apiExtendedDataInfoSchema.optional(),
  }),
)

export const apiWholePageRankingSchema = z.record(z.unknown()).and(
  z.object({
    Cards: z.array(apiWholePageRankingCardSchema).optional(),
  }),
)

export const apiReplayResponseSchema = z.record(z.unknown()).and(
  z.object({
    AnswerEntitySets: z.array(apiEntitySetsSchema).optional(),
    EntitySets: z.array(apiEntitySetsSchema).optional(),
    WholePageRankings: z.array(apiWholePageRankingSchema).optional(),
    ExtendedData: apiExtendedDataSchema.optional(),
  }),
)

export const apiEnterpriseSearchImpressionsSchema = z.record(z.unknown()).and(
  z.object({
    ImpressionId: z.string().optional(),
    Request: z.union([z.string(), apiReplayRequestSchema]).optional(),
    Response: z.union([z.string(), apiReplayResponseSchema]).optional(),
  }),
)

export const apiConvergedReplayResponseSchema = z.record(z.unknown()).and(
  z.object({
    TraceId: z.string().optional(),
    TrimmedResponse: apiReplayResponseSchema.optional(),
  }),
)

export const apiOdsTicket3sOfflineDataSchema = z.record(z.unknown()).and(
  z.object({
    EnterpriseSearchImpressions: z.array(apiEnterpriseSearchImpressionsSchema).optional(),
  }),
)

export const apiOdsConvergedReplaySchema = z.record(z.unknown()).and(
  z.object({
    ReplayRequest: apiReplayRequestSchema.optional(),
    ReplayResponse: apiConvergedReplayResponseSchema.optional(),
  }),
)
