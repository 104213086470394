import { useCallback, useEffect, useRef } from 'react'
import { Logger } from '../..'
import { ITelemetryScope } from '../telemetry/ITelemetryScope'

export function useTelemetryScopeViewTracker<T extends keyof ITelemetryScope>(
  scope: T,
  properties: ITelemetryScope[T],
): void {
  const startTimeRef = useRef(Date.now())
  const scopeRef = useRef(scope)
  const propertiesRef = useRef(properties)

  useEffect(() => {
    scopeRef.current = scope
    propertiesRef.current = properties
  }, [scope, properties])

  const trackOnOpen = useCallback(() => {
    Logger.telemetry.trackView(scopeRef.current, {
      ...propertiesRef.current,
      type: 'enter',
      duration: 0,
      url: window.location.href,
      referrer: document.referrer,
    })
  }, [])

  const trackOnClose = useCallback((exitType: 'appClosed' | 'navigation') => {
    Logger.telemetry.trackView(scopeRef.current, {
      ...propertiesRef.current,
      type: 'exit',
      duration: Date.now() - startTimeRef.current,
      url: window.location.href,
      exitType: exitType,
      referrer: document.referrer,
    })
  }, [])

  useEffect(() => {
    trackOnOpen()

    const onUnload = (e: BeforeUnloadEvent) => {
      trackOnClose('appClosed')
    }

    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
      trackOnClose('navigation')
    }
  }, [trackOnClose, trackOnOpen])
}
