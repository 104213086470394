import { makeStyles } from '@fluentui/react-components'
import { Editor } from '@monaco-editor/react'
import { useMemo } from 'react'
import { useThemeMode } from '../../theme/useThemeMode'

interface IProps {
  readonly data: unknown
}

export function MonacoJsonView({ data }: IProps) {
  const styles = useStyles()
  const theme = useThemeMode()

  const dataJsonString = useMemo(() => {
    try {
      return JSON.stringify(data, null, 2)
    } catch (e) {
      return String(e)
    }
  }, [data])

  return (
    <Editor
      language={'json'}
      value={dataJsonString}
      className={styles.root}
      theme={theme === 'light' ? 'light' : 'vs-dark'}
      options={{
        readOnly: true,
      }}
    />
  )
}

const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
    minHeight: '200px',
    minWidth: '400px',
    width: '100%',
  },
})
