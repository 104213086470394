import {
  ApiAIFTicketsResponse,
  ApiAllCustomTagsResponse,
  ApiAreaListAndRootCauseListResponse,
  ApiBatchTicketsCustomTagsRequest,
  ApiBatchTicketsCustomTagsResponse,
  ApiCopilotTicketDetailResponse,
  ApiCopilotTicketRequest,
  ApiDSRTicketResponse,
  ApiExtensibilityLogsRequest,
  ApiExtensibilityLogsResponse,
  ApiFilterTagListResponse,
  ApiGetAssignedTeamMembersResponse,
  ApiIssueClusteringBatchInfoListResponse,
  ApiIssueClusteringInfoListRequest,
  ApiIssueClusteringInfoListResponse,
  ApiKustoLogResponse,
  ApiM365TenantListResponse,
  ApiProductListResponse,
  ApiTeamListResponse,
  ApiTicketCustomTagsResponse,
  ApiTicketsRequest,
  ApiTicketsResponse,
  ApiTicketsWithTicketListRequest,
  ApiUpdateTicketStatusInfoRequest,
  apiAIFTicketsResponseSchema,
  apiAllCustomTagsResponseSchema,
  apiAreaListAndRootCauseListResponseSchema,
  apiBatchTicketsCustomTagsResponseSchema,
  apiCopilotTicketDetailResponseSchema,
  apiDSRTicketResponseSchema,
  apiExtensibilityLogsResponseSchema,
  apiFilterTagListResponseSchema,
  apiGetAssignedTeamMembersResponseSchema,
  apiIssueClusteringBatchInfoListResponseSchema,
  apiIssueClusteringInfoListResponseSchema,
  apiM365TenantListResponseSchema,
  apiProductListResponseSchema,
  apiTeamListResponseSchema,
  apiTicketCustomTagsResponseSchema,
  apiTicketsResponseSchema,
} from './types'
import { ApiActivityHistoryResponse, apiActivityHistoryResponseSchema } from './types/ApiActivityHistoryResponse'
import { ApiAdoTagsResponse, apiAdoTagsResponseSchema } from './types/ApiAdoTagsResponse'
import {
  ApiBatchGetTopIssueExtendInfoRequest,
  ApiBatchGetTopIssueExtendInfoResponse,
  ApiBatchGetTopIssueExtendInfoResponseSchema,
} from './types/ApiTopIssueExtendInfo'
import {
  ApiBatchUpdateTicketStatusInfoResponse,
  ApiBatchUpdateTicketStatusInfoResponseSchema,
} from './types/ApiBatchUpdateTicketStatusInfoResponse'
import { ApiCommentListResponse, apiCommentListResponseSchema } from './types/ApiCommentsResponse'
import { ApiCreateCommentRequest, ApiEditCommentRequest } from './types/ApiUpsertCommentRequest'
import {
  ApiCreateRootCauseRequest,
  ApiCreateRootCauseResponse,
  ApiCreateRootCauseResponseSchema,
} from './types/ApiCreateRootCause'
import { ApiDeleteCommentResponse, apiDeleteCommentResponseSchema } from './types/ApiDeleteCommentResponse'
import { ApiMcpExperimentFeedbackRequest, ApiMcpExperimentFeedbackResponse } from './types/ApiMcpExperimentFeedback'
import {
  ApiNewSearchCopilotTicketsResponse,
  apiNewSearchCopilotTicketsResponseSchema,
} from './types/ApiNewSearchCopilotTicketsResponse'
import {
  ApiRootCauseListByTeamResponse,
  apiRootCauseListByTeamResponseSchema,
} from './types/ApiRootCauseListByTeamResponse'
import { ApiRootCauseResponse, apiRootCauseResponseSchema } from './types/ApiRootCauseResponse'
import { ApiRootCausesByTeamResponse, apiRootCausesByTeamResponseSchema } from './types/ApiTeamViewResponse'
import {
  ApiUpdateRootCauseRequest,
  ApiUpdateRootCauseResponse,
  ApiUpdateRootCauseResponseSchema,
} from './types/ApiUpdateRootCause'
import {
  ApiUpdateTicketStatusInfoResponse,
  apiUpdateTicketStatusInfoResponseSchema,
} from './types/ApiUpdateTicketStatusInfoResponse'
import { ApiUpsertCommentResponse, apiUpsertCommentResponseSchema } from './types/ApiUpsertCommentResponse'

import { ApiClient } from '../../client/ApiClient'
import { ApiClientOptions } from '../../client/ApiClientOptions'
import { ApiKustoLogRequest } from './types/ApiKustoLogRequest'
import { ApiM365TenantRequest } from './types/ApiM365TenantRequest'
import { ApiNewSearchCopilotTicketsRequest } from './types/ApiNewSearchCopilotTicketsRequest'
import { ApiRootCauseListByTeamRequest } from './types/ApiRootCauseListByTeamRequest'
import { ApiRootCauseRequest } from './types/ApiRootCauseRequest'
import { ApiSetRootCauseRequest } from './types/ApiSetRootCauseRequest'
import { ApiTeamListRequest } from './types/ApiTeamListRequest'
import { ApiTeamViewRootCausesByTeamRequest } from './types/ApiTeamViewRequest'
import { Caches } from '../../client/ApiClientCaches'
import { LogCollectorApiClient } from './LogCollectorApiClient'
import { apiKustoLogResponseSchema } from './types/ApiKustoLogResponse.schema'
import { apiMcpExperimentFeedbackResponseSchema } from './types/ApiMcpExperimentFeedback.schema'

/**
 * Please refer to the following bond file for the API definition
 *
 * https://dev.azure.com/msasg/Falcon/_git/DSS?path=/services/LogCollector/src/Service/Api/LogCollector/V1/LogCollectorService.bond&_a=contents&version=GBmaster
 */
export class LogCollectorApi {
  private readonly client: ApiClient

  constructor(options: ApiClientOptions) {
    this.client = new LogCollectorApiClient(options)
  }

  searchAIFTickets(params: ApiTicketsRequest): Promise<ApiAIFTicketsResponse> {
    return this.client.get('api/v1/AIFTickets', {
      params: params,
      schema: apiAIFTicketsResponseSchema,
      cache: Caches.SHORT,
    })
  }

  searchCopilotTickets(data: ApiTicketsWithTicketListRequest): Promise<ApiTicketsResponse> {
    return this.client.post(`/api/v1/SearchCopilotTickets`, {
      data: data,
      schema: apiTicketsResponseSchema,
      cache: Caches.SHORT,
    })
  }

  getCopilotTicket(params: ApiCopilotTicketRequest): Promise<ApiCopilotTicketDetailResponse> {
    return this.client.get('/api/v1/CopilotTicket', {
      params: params,
      schema: apiCopilotTicketDetailResponseSchema,
      cache: Caches.SHORT,
    })
  }

  getDSRTicket(externalTicketId: string): Promise<ApiDSRTicketResponse> {
    return this.client.get(`/api/v1/DSRTicket/${externalTicketId}`, {
      schema: apiDSRTicketResponseSchema,
      cache: Caches.LONG,
    })
  }

  getSydneySingleInfo(param: ApiKustoLogRequest): Promise<ApiKustoLogResponse> {
    return this.client.get('/api/v1/SydneySingleInfo', {
      schema: apiKustoLogResponseSchema,
      cache: Caches.SHORT,
      params: param,
    })
  }

  getAdoTags(): Promise<ApiAdoTagsResponse> {
    return this.client.get('/api/v1/GetVsoTags', {
      schema: apiAdoTagsResponseSchema,
      cache: Caches.LONG,
    })
  }

  getFilterTagList(): Promise<ApiFilterTagListResponse> {
    return this.client.get(`/api/v1/GetFilterTagList`, {
      schema: apiFilterTagListResponseSchema,
      cache: Caches.LONG,
    })
  }

  getM365TenantList(param: ApiM365TenantRequest): Promise<ApiM365TenantListResponse> {
    return this.client.get('/api/v1/GetM365TenantList', {
      schema: apiM365TenantListResponseSchema,
      cache: Caches.LONG,
      params: param,
    })
  }

  getTicketExistedRootCausingActions(param: ApiRootCauseRequest): Promise<ApiRootCauseResponse> {
    return this.client.get('/api/v1/GetTicketExistedRootCausingActionsAsync', {
      schema: apiRootCauseResponseSchema,
      cache: Caches.NONE,
      params: param,
    })
  }

  setTicketRootCausingActions(data: ApiSetRootCauseRequest): Promise<ApiRootCauseResponse> {
    return this.client.post('/api/v1/SetTicketRootCausingActionsAsync', {
      data: data,
      schema: apiRootCauseResponseSchema,
    })
  }

  getTeamList(params: ApiTeamListRequest): Promise<ApiTeamListResponse> {
    return this.client.get(`/api/v1/GetTeamList`, {
      params: params,
      schema: apiTeamListResponseSchema,
      cache: Caches.LONG,
    })
  }

  getRootCauseListByTeam(params: ApiRootCauseListByTeamRequest): Promise<ApiRootCauseListByTeamResponse> {
    return this.client.get(`/api/v1/GetRootCauseListByTeam`, {
      params: params,
      schema: apiRootCauseListByTeamResponseSchema,
      cache: Caches.NONE,
    })
  }

  getAssignedTeamMembersByProductIdAndTeamId(
    params: ApiRootCauseListByTeamRequest,
  ): Promise<ApiGetAssignedTeamMembersResponse> {
    return this.client.get(`/api/v1/GetAssignedTeamMembersByProductIdAndTeamId`, {
      params: params,
      schema: apiGetAssignedTeamMembersResponseSchema,
      cache: Caches.NONE,
    })
  }

  getProductList(): Promise<ApiProductListResponse> {
    return this.client.get(`/api/v1/GetProductList`, {
      schema: apiProductListResponseSchema,
      cache: Caches.LONG,
    })
  }

  getRootCausesByTeam(param: ApiTeamViewRootCausesByTeamRequest): Promise<ApiRootCausesByTeamResponse> {
    return this.client.get(`/api/v1/GetTeamViewInfoByTeamName`, {
      schema: apiRootCausesByTeamResponseSchema,
      params: param,
      cache: Caches.NONE,
    })
  }

  newSearchCopilotTickets(data: ApiNewSearchCopilotTicketsRequest): Promise<ApiNewSearchCopilotTicketsResponse> {
    return this.client.post(`/api/v1/NewSearchCopilotTickets`, {
      data: data,
      schema: apiNewSearchCopilotTicketsResponseSchema,
    })
  }

  updateTicketStatusInfo(data: ApiUpdateTicketStatusInfoRequest): Promise<ApiUpdateTicketStatusInfoResponse> {
    return this.client.post(`/api/v1/UpdateTicketStatusInfo`, {
      data: data,
      schema: apiUpdateTicketStatusInfoResponseSchema,
    })
  }

  batchUpdateTicketStatusInfo(
    data: {
      TicketStatusInfoList: ApiUpdateTicketStatusInfoRequest[]
    },
    signal: AbortSignal,
  ): Promise<ApiBatchUpdateTicketStatusInfoResponse> {
    return this.client.post(`/api/v1/BatchUpdateTicketStatusInfo`, {
      data: data,
      schema: ApiBatchUpdateTicketStatusInfoResponseSchema,
      signal: signal,
    })
  }

  getAreaListAndRootCauseList(): Promise<ApiAreaListAndRootCauseListResponse> {
    return this.client.get(`/api/v1/GetAreaListAndRootCauseList`, {
      schema: apiAreaListAndRootCauseListResponseSchema,
      cache: Caches.NONE,
    })
  }

  getComments(ticketId: string): Promise<ApiCommentListResponse> {
    return this.client.get(`/api/v1/Ticket/${encodeURIComponent(ticketId)}/Comments`, {
      schema: apiCommentListResponseSchema,
      cache: Caches.NONE,
    })
  }

  createComments(ticketId: string, data: ApiCreateCommentRequest): Promise<ApiUpsertCommentResponse> {
    return this.client.post(`/api/v1/Ticket/${encodeURIComponent(ticketId)}/Comments`, {
      schema: apiUpsertCommentResponseSchema,
      data: data,
    })
  }

  editComments(ticketId: string, commentId: string, data: ApiEditCommentRequest): Promise<ApiUpsertCommentResponse> {
    return this.client.put(`/api/v1/Ticket/${encodeURIComponent(ticketId)}/Comments/${encodeURIComponent(commentId)}`, {
      schema: apiUpsertCommentResponseSchema,
      data: data,
    })
  }

  deleteComments(ticketId: string, commentId: string): Promise<ApiDeleteCommentResponse> {
    return this.client.delete(
      `/api/v1/Ticket/${encodeURIComponent(ticketId)}/Comments/${encodeURIComponent(commentId)}`,
      {
        schema: apiDeleteCommentResponseSchema,
      },
    )
  }

  getExtensibilityLogsByMessageIds(request: ApiExtensibilityLogsRequest): Promise<ApiExtensibilityLogsResponse> {
    return this.client.get('/api/v1/GetExtensibilityLogs', {
      params: request,
      schema: apiExtensibilityLogsResponseSchema,
      cache: Caches.SHORT,
    })
  }

  createRootCause(data: ApiCreateRootCauseRequest): Promise<ApiCreateRootCauseResponse> {
    return this.client.post(`/api/v1/CreateRootCauseAsync`, {
      data: data,
      schema: ApiCreateRootCauseResponseSchema,
    })
  }

  updateRootCause(data: ApiUpdateRootCauseRequest): Promise<ApiUpdateRootCauseResponse> {
    return this.client.post('/api/v1/UpdateRootCauseAsync', {
      data: data,
      schema: ApiUpdateRootCauseResponseSchema,
    })
  }

  getIssueClusteringBatchInfoList(): Promise<ApiIssueClusteringBatchInfoListResponse> {
    return this.client.get(`/api/v1/GetClusteringBatchInfoList`, {
      schema: apiIssueClusteringBatchInfoListResponseSchema,
      cache: Caches.SHORT,
    })
  }

  getIssueClusteringInfoList(request: ApiIssueClusteringInfoListRequest): Promise<ApiIssueClusteringInfoListResponse> {
    return this.client.get(`/api/v1/GetIssueClusteringInfoByTeamIdAndBatchId`, {
      params: request,
      schema: apiIssueClusteringInfoListResponseSchema,
      cache: Caches.NONE,
    })
  }

  getIssueExtendInfoList(data: ApiBatchGetTopIssueExtendInfoRequest): Promise<ApiBatchGetTopIssueExtendInfoResponse> {
    return this.client.post(`/api/v1/ObtainFeedbackInsightsByBatchIdAndIssueIdList`, {
      data: data,
      schema: ApiBatchGetTopIssueExtendInfoResponseSchema,
      cache: Caches.LONG,
    })
  }

  getActivityHistory(ticketId: string): Promise<ApiActivityHistoryResponse> {
    return this.client.get(`/api/v1/ActivityHistory/${encodeURIComponent(ticketId)}`, {
      schema: apiActivityHistoryResponseSchema,
      cache: Caches.NONE,
    })
  }

  getMcpExperimentFeedbackResponse(
    request: ApiMcpExperimentFeedbackRequest,
  ): Promise<ApiMcpExperimentFeedbackResponse> {
    return this.client.get(`/api/v1/GetMcpExperimentFeedbackResponse`, {
      params: request,
      schema: apiMcpExperimentFeedbackResponseSchema,
      cache: Caches.NONE,
    })
  }

  getTicketCustomTags(ticketId: string): Promise<ApiTicketCustomTagsResponse> {
    return this.client.get(`/api/v1/Ticket/${encodeURIComponent(ticketId)}/CustomTags`, {
      schema: apiTicketCustomTagsResponseSchema,
      cache: Caches.NONE,
    })
  }

  getBatchTicketsCustomTags(data: ApiBatchTicketsCustomTagsRequest): Promise<ApiBatchTicketsCustomTagsResponse> {
    return this.client.post('/api/v1/CustomTags', {
      data: data,
      schema: apiBatchTicketsCustomTagsResponseSchema,
    })
  }

  getAllCustomTags(): Promise<ApiAllCustomTagsResponse> {
    return this.client.get('/api/v1/GetAllCustomTags', {
      schema: apiAllCustomTagsResponseSchema,
      cache: Caches.SHORT,
    })
  }
}
