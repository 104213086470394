import { makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  commonRow: {
    ':hover': {
      backgroundColor: tokens.colorNeutralBackground2,
      cursor: 'pointer',
    },
    ':active': {
      backgroundColor: tokens.colorNeutralBackground2,
    },
  },
  selectedRow: {
    backgroundColor: tokens.colorBrandBackground2,
    ':hover': {
      backgroundColor: tokens.colorBrandBackground2,
      cursor: 'pointer',
    },
    ':active': {
      backgroundColor: tokens.colorBrandBackground2,
    },
  },
})
