import { IUpdateTicketStatusInfoData } from '@copilot-dash/domain'
import { IState, TicketScreenOutlineTab } from './IState'

interface IParams {
  readonly ticketId: string
  readonly afterSavedCallback?: (savedInfo: IUpdateTicketStatusInfoData) => void
}

export function createState({ ticketId, afterSavedCallback }: IParams): IState {
  return {
    ticketId,
    supportedOutlineTabs: Object.values(TicketScreenOutlineTab),
    supportedMainTabs: [],
    supportedKustoTabs: [],
    selectedOutlineTab: TicketScreenOutlineTab.Conversation,
    selectedMainTab: undefined,
    selectedKustoTab: undefined,
    selectedTurnId: undefined,
    selectedScopeId: undefined,
    isOpenDiscussionPanel: false,
    isOpenActivityDiscussionPanel: false,
    afterSavedCallback: afterSavedCallback,
  }
}
