import { PromiseSnapshot } from '@copilot-dash/core'
import { ITicketFiles } from '@copilot-dash/domain'
import { IDashStoreContext } from '../IDashStoreContext'
import { convertTicketFilesFromApi } from './converters/convertTicketFiles'
import { getTicketSession } from './getTicketSession'
import { validateTicketUserConsent } from './validators/validateTicketUserConsent'

export function getTicketFiles(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ITicketFiles> {
  return context.getOrFetch({
    get: (state) => {
      return state.tickets[ticketId]?.files
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.files = snapshot
    },
    fetch,
  })

  async function fetch(): Promise<ITicketFiles> {
    // 1. Check user consent
    const session = await getTicketSession(context, ticketId).promise
    validateTicketUserConsent(ticketId, session)

    const files = await context.api.ods.getFiles(ticketId)
    return convertTicketFilesFromApi(files)
  }
}
