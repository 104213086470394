import { useUserSetting } from '@copilot-dash/settings'
import { makeStyles } from '@fluentui/react-components'
export const usePrivacyModeClass = () => {
  const styles = useStyles()
  const [privacyModeEnabled] = useUserSetting('privacyModeEnabled')
  return privacyModeEnabled ? styles.privacy : ''
}

const useStyles = makeStyles({
  privacy: {
    filter: 'blur(4px)',
  },
})
