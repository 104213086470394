import { useCallback } from 'react'
import { Column } from '../../../../../components/Layout'
import { TicketCallFlow } from '../../../../../components/TicketCallFlow/TicketCallFlow'
import { TicketScreenStore } from '../../../TicketScreenStore'
import { TicketScreenMainTab } from '../../../store/IState'

export function TicketOutlineCallFlow() {
  const actions = TicketScreenStore.useActions()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const selectedTurnId = TicketScreenStore.use((state) => state.selectedTurnId)
  const selectedScopeId = TicketScreenStore.use((state) => state.selectedScopeId)

  const handleSelectTurn = useCallback(
    (turnId: string, scopeId?: string) => {
      if (scopeId) {
        actions.selectTurnScope(turnId, scopeId)
        actions.selectMainTab(TicketScreenMainTab.ConversationData)
      } else {
        actions.selectTurn(turnId)
      }
    },
    [actions],
  )

  return (
    <Column>
      <TicketCallFlow
        ticketId={ticketId}
        selectedTurnId={selectedTurnId}
        selectedScopeId={selectedScopeId}
        onSelect={handleSelectTurn}
      />
    </Column>
  )
}
