import * as React from 'react'
import { useStyles } from './ToggleDrawerLeftIcon.styles'
import { PanelLeftContractRegular, PanelLeftExpandRegular } from '@fluentui/react-icons'
import { useTeamViewStore } from '../storeNew'
import { mergeClasses } from '@fluentui/react-components'

interface IToggleDrawerLeftIconProps {
  type: 'contract' | 'expand'
  className?: string
}

export const ToggleLeftDrawerIcon: React.FC<IToggleDrawerLeftIconProps> = ({ type, className }) => {
  const styles = useStyles()
  const toggleLeftDrawer = useTeamViewStore((state) => state.toggleLeftDrawer)
  return type === 'contract' ? (
    <PanelLeftContractRegular
      fontSize={20}
      className={mergeClasses(styles.pointer, className)}
      onClick={() => toggleLeftDrawer(false)}
    />
  ) : (
    <PanelLeftExpandRegular
      fontSize={20}
      className={mergeClasses(styles.pointer, className)}
      onClick={() => toggleLeftDrawer(true)}
    />
  )
}
