import {
  ApiSearchTicketsTextPrefixType,
  ApiTicketsRequest,
  ApiTicketsWithTicketListRequest,
  ApiTicketType,
} from '@copilot-dash/api'
import { Times } from '@copilot-dash/core'
import { DefaultTriggeredSkillList, SearchTextPrefixType } from '@copilot-dash/domain'
import { EnableExceptionTracking } from '@copilot-dash/logger'
import { IDashStoreContext } from '../../IDashStoreContext'
import { NewSearchCopilotTicketsResult } from './NewSearchTicketsAction.types'
import { ISearchTicketOptions } from './SearchTicketAction.types'
import { NewTicketConverter } from './converters/NewTicketConverter'

interface IParams {
  readonly searchId: string
  readonly utteranceOrResponseOdsSessionIdList: string[]
  readonly otherOdsSessionIdList: string[]
  readonly options: ISearchTicketOptions
  readonly odsSearchTriggered?: boolean
}

export class SearchTicketWithIdListAction {
  private readonly context: IDashStoreContext

  constructor(context: IDashStoreContext) {
    this.context = context
  }

  @EnableExceptionTracking()
  async search(params: IParams): Promise<NewSearchCopilotTicketsResult> {
    const request = this.createRequest(params)

    const response = await this.context.api.logCollector.newSearchCopilotTickets(request)

    return {
      searchId: params.searchId,
      ticketCount: response.ticketCount ?? response.tickets.length,
      tickets: response.tickets.map(NewTicketConverter.fromApiContext),
      hasMore: response.hasMore ?? false,
      errorMessage: response.errorMessage,
    }
  }

  private createRequest(params: IParams): ApiTicketsWithTicketListRequest {
    const { utteranceOrResponseOdsSessionIdList, otherOdsSessionIdList, options } = params
    //TODO: Align new rule with backend to handle triggered skill
    const triggeredSkills = options.triggeredSkill
      ?.map((val) => {
        const triggeredSkill = DefaultTriggeredSkillList.find((skill) => skill.key === val)
        return triggeredSkill ? triggeredSkill.options : []
      })
      .flat()

    const { from, to } = Times.formatTimeRange(options.range ?? options.defaultRange, { timezone: 'UTC' })

    const requestBody: ApiTicketsRequest = {
      Count: options.count,
      Offset: options.offset,
      EmailAddress: options.userId,
      ClientNames: options.client,
      ScenarioNames: options.channel,
      MetaData: [
        ...(options.promptLanguages && options.promptLanguages.length ? [options.promptLanguages.join('|')] : []),
        ...(options.groundedPrompts?.length ? [options.groundedPrompts.join('|')] : []),
        ...(options.hasUserConsent?.length ? [options.hasUserConsent.join('|')] : []),
        ...(options.isApology?.length ? [options.isApology.join('|')] : []),
        ...(options.hasVerbatim?.length ? [options.hasVerbatim.join('|')] : []),
        ...(options.has3SLog?.length ? [options.has3SLog.join('|')] : []),
        ...(options.hasConversationHistory?.length ? [options.hasConversationHistory.join('|')] : []),
        ...(options.hasScreenshot?.length ? [options.hasScreenshot.join('|')] : []),
        ...(options.isTest?.length ? [options.isTest.join('|')] : []),
        ...(options.customerTypes?.length ? [options.customerTypes.join('|')] : []),
        ...(options.invocationType?.length ? [options.invocationType.join('|')] : []),
        ...(options.isWebToggleOn?.length ? [options.isWebToggleOn.join('|')] : []),
        ...(triggeredSkills ?? []),
        ...(options.hasCitation?.length ? [options.hasCitation.join('|')] : []),
        ...(options.hasEntityCard?.length ? [options.hasEntityCard.join('|')] : []),
        ...(options.searchResultSegment?.length ? [options.searchResultSegment.join('|')] : []),
        ...(options.botEndPoint?.length ? [options.botEndPoint.join('|')] : []),
        ...(options.hitAvalon?.length ? [options.hitAvalon.join('|')] : []),
        ...(options.isSTCAChina?.length ? [options.isSTCAChina.join('|')] : []),
        ...(options.isTopi18N?.length ? [options.isTopi18N.join('|')] : []),
        ...(options.responseHeroType?.length ? [options.responseHeroType.join('|')] : []),
        ...(options.responseLinkType?.length ? [options.responseLinkType.join('|')] : []),
        ...(options.semanticSearchType?.length ? [options.semanticSearchType.join('|')] : []),
        ...(options.scenarioGroup?.length ? [options.scenarioGroup.join('|')] : []),
        ...(options.bizchatScenario?.length ? [options.bizchatScenario.join('|')] : []),
        ...(options.experienceType?.length ? [options.experienceType.join('|')] : []),
        ...(options.hasConnector?.length ? [options.hasConnector.join('|')] : []),
        ...(options.hasGPTExtension?.length ? [options.hasGPTExtension.join('|')] : []),
        ...(options.hasMessageExtension?.length ? [options.hasMessageExtension.join('|')] : []),
        ...(options.hasCopilotExtensionIds?.length ? [options.hasCopilotExtensionIds.join('|')] : []),
        ...(options.errorCode?.length ? [options.errorCode.join('|')] : []),
        ...(options.isGCIntent?.length ? [options.isGCIntent.join('|')] : []),
        ...(options.hasConnectorResult?.length ? [options.hasConnectorResult.join('|')] : []),
      ],
      //ModelLanguages: options.modelLanguages,//Updated ModelLanguages to Utterance Language(from VSO Tag)
      Rings: options.ring,
      From: from,
      To: to,
      TicketType: ApiTicketType.SearchTicket,
      VerbatimFeedback:
        options.searchTextPrefix === SearchTextPrefixType.Verbatim ||
        options.searchTextPrefix === SearchTextPrefixType.All
          ? options.searchText
          : undefined,
      TenantIds: options.tenantIds,
      Thumbs: options.thumbs?.length === 1 ? options.thumbs[0] : undefined,
      ExternalTicketId: options.ticketId,
      Priorities: options.priority?.map(Number) ?? [],
      PrefixType: this.generatePrefixType(options),
      DSATStatus: options.dSATStatus,
      CustomTags: options.customTags,
      DSATAssignedTo: options.dSATAssignedTo,
      OdsSearchTriggered: params.odsSearchTriggered,
    }

    return {
      searchId: params.searchId,
      ODSSessionIdList: [],
      SearchTicketStatus: params.options.ticketStatus,
      TeamName: params.options.teamName,
      IssueId: params.options.issueId,
      VsoAccount: params.options.vsoAccount,
      RecommendedRootCauseId: params.options.recommendedRootCauseId,
      ClusteringIssueId: params.options.clusteringIssueId,
      ClusteringBatchId: params.options.batchId,
      TeamId: params.options.teamId,
      request: requestBody,
      UtteranceOrResponseOdsSessionIdList: utteranceOrResponseOdsSessionIdList,
      OtherOdsSessionIdList: otherOdsSessionIdList,
      IsOtherOdsSearchTriggered: options.optionsSets || options.copilotExtensionIds || options.sliceIds ? true : false,
    }
  }

  private generatePrefixType(options: ISearchTicketOptions): ApiSearchTicketsTextPrefixType {
    if (!options.searchText) return ApiSearchTicketsTextPrefixType.Unset
    if (options.searchTextPrefix === SearchTextPrefixType.Utterance)
      return ApiSearchTicketsTextPrefixType.OnlySearchUtterance
    if (options.searchTextPrefix === SearchTextPrefixType.Response)
      return ApiSearchTicketsTextPrefixType.OnlySearchResponse
    if (options.searchTextPrefix === SearchTextPrefixType.Verbatim)
      return ApiSearchTicketsTextPrefixType.OnlySearchVerbatim
    if (options.searchTextPrefix === SearchTextPrefixType.All)
      return ApiSearchTicketsTextPrefixType.SearchUtteranceOrResponseOrVerbatim
    return ApiSearchTicketsTextPrefixType.Unset
  }
}
