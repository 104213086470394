import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  tooltip: {
    maxWidth: '20%',
    cursor: 'pointer',
  },
  scrollbar: {
    flex: '1 1 auto',
    maxHeight: '150px',
  },
})
