import { useMemo } from 'react'
import { Controller, Control } from 'react-hook-form'
import { Card } from './Card'
import { useStyles } from './Card.styles'
import { Body1Stronger } from '@fluentui/react-components'
import { ITicketFilterFormData } from '../TicketFilterForm/TicketFilterForm.types'
import { SearchFilterFieldNames } from '@copilot-dash/domain'
import { TriggeredSkillFilter, OptionsSetsFilter, HitAvalonFilter, SliceIdsFilter } from '../filters'
import { useTicketFilterStore } from '../../store/store'
import { QueryProcessingCardFilters } from './config'
import { useFeatureFlight } from '@copilot-dash/settings'
interface IProps {
  control: Control<ITicketFilterFormData>
}

export function QueryProcessingCard({ control }: IProps) {
  const styles = useStyles()
  const filterPanelItems = useTicketFilterStore((state) => state.supportedTicketFilterItems)
  const hasQueryProcessingCard = useMemo(() => {
    return filterPanelItems.some((item) => QueryProcessingCardFilters.includes(item))
  }, [filterPanelItems])
  const [isSliceIdsOptionsSetsFilterEnabled] = useFeatureFlight('EnableSliceIdsOptionsSetsFilter')

  if (!hasQueryProcessingCard) return null
  return (
    <section className={styles.section}>
      <Body1Stronger>Query processing</Body1Stronger>
      <Card className={styles.card}>
        {filterPanelItems.includes(SearchFilterFieldNames.hitAvalon) && (
          <Controller
            name="hitAvalon"
            control={control}
            render={({ field }) => <HitAvalonFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.triggeredSkill) && (
          <Controller
            name="triggeredSkill"
            control={control}
            render={({ field }) => <TriggeredSkillFilter selectedValues={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.optionsSets) && isSliceIdsOptionsSetsFilterEnabled && (
          <Controller
            name="optionsSets"
            control={control}
            render={({ field }) => <OptionsSetsFilter value={field.value} onChangeValue={field.onChange} />}
          />
        )}

        {filterPanelItems?.includes(SearchFilterFieldNames.sliceIds) && isSliceIdsOptionsSetsFilterEnabled && (
          <Controller
            name="sliceIds"
            control={control}
            render={({ field }) => <SliceIdsFilter value={field.value} onChangeValue={field.onChange} />}
          />
        )}
      </Card>
    </section>
  )
}
