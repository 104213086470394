import { IProductData, parseProductName, TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST } from '@copilot-dash/domain'
import { useCallback, useMemo } from 'react'
import { PageTitle } from '../../../components/PageTitle/PageTitle'
import { GlobalProductSelector } from '../../../providers/product'
import { TeamScreen } from '../../../screens/team/TeamScreen'
import { NotFoundRoutePage } from '../404/NotFoundRoutePage'
import { TeamRoute } from './TeamRoute'

export function TeamRoutePage() {
  const args = TeamRoute.navigator.useArgs()

  const handleSelectProduct = useCallback((product: IProductData) => {
    TeamRoute.navigator.navigate({
      product: product.name,
    })
  }, [])

  const isProductValid = useMemo(() => {
    return parseProductName(args.product) !== undefined
  }, [args.product])
  if (!isProductValid) {
    return <NotFoundRoutePage />
  }

  return (
    <GlobalProductSelector
      value={args.product}
      onChange={handleSelectProduct}
      isItemDisabled={(product) => !TEAM_VIEW_SUPPORT_PRODUCT_NAME_LIST.includes(product.name)}
    >
      <PageTitle title="Team">
        <TeamScreen key={args.product} args={args} />
      </PageTitle>
    </GlobalProductSelector>
  )
}
