import { makeStyles } from '@fluentui/react-components'
import { AnyData } from '../../../../../components/AnyData/AnyData'
import { Column } from '../../../../../components/Layout'
import { TicketScreenStore } from '../../../TicketScreenStore'
import { ConversationDataFromGroup } from './ConversationDataFromGroup'
import { ConversationDataFromLegacy } from './ConversationDataFromLegacy'
import { ConversationDataFromOffline } from './ConversationDataFromOffline'
import { ConversationDataFromScopeId } from './ConversationDataFromScopeId'

interface IProps {
  readonly ticketId: string
  readonly selectedTurnId: string
}

export function ConversationData({ ticketId, selectedTurnId }: IProps) {
  const styles = useStyles()
  const snapshot = app.store.use.getTicketConversation(ticketId, selectedTurnId)
  const selectedScopeId = TicketScreenStore.use((state) => state.selectedScopeId)

  return (
    <Column vAlign="stretch" hAlign="stretch" fill className={styles.root}>
      {(() => {
        if (selectedScopeId) {
          return (
            <ConversationDataFromScopeId
              ticketId={ticketId}
              selectedTurnId={selectedTurnId}
              scopeId={selectedScopeId}
            />
          )
        }

        switch (snapshot.status) {
          case 'waiting':
            return (
              <AnyData
                data={{
                  traceId: selectedTurnId,
                }}
              />
            )
          case 'error':
            return <ConversationDataFromGroup ticketId={ticketId} selectedTurnId={selectedTurnId} />
          case 'done': {
            switch (snapshot.data.source) {
              case 'conversation-legacy':
                return <ConversationDataFromLegacy ticketId={ticketId} selectedTurnId={selectedTurnId} />
              case 'conversation-group1':
                return <ConversationDataFromGroup ticketId={ticketId} selectedTurnId={selectedTurnId} />
              case 'offline-group1':
                return <ConversationDataFromOffline ticketId={ticketId} selectedTurnId={selectedTurnId} />
            }
          }
        }
      })()}
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    padding: '0px',
  },
})
