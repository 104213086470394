import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
const DISCUSSION_PANEL_WIDTH = 480
export const useStyles = makeStyles({
  card: {
    width: `${DISCUSSION_PANEL_WIDTH}px`,
    marginTop: '56px',
  },
  waiting: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  drawerHeader: {
    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.10)',
    ...shorthands.padding('10px'),
    width: '100%',
  },
  title: {
    fontSize: tokens.fontSizeBase500,
    fontWeight: tokens.fontWeightSemibold,
    marginLeft: tokens.spacingHorizontalSNudge,
  },
  body: {
    padding: '0 16px',
  },
})
