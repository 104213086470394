import { shorthands, makeStyles, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  time: {
    color: tokens.colorScrollbarOverlay,
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    wordBreak: 'normal',
    maxWidth: '40%',
    fontSize: tokens.fontSizeBase200,
  },
  container: {
    maxWidth: '100%',
    overflow: 'hidden',
    ...shorthands.margin(0),
    ...shorthands.padding(0),
    ':hover': {
      cursor: 'pointer',
      backgroundColor: tokens.colorNeutralBackground1Hover,
    },
  },
  userPhoto: {
    width: '32px',
    height: '32px',
    ...shorthands.margin(0),
    borderRadius: '50%',
  },
  summarizeActivity: {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    wordBreak: 'normal',
    maxWidth: '100%',
    marginRight: '8px',
    marginLeft: '32px',
  },
})
