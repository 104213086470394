import { Text } from '@fluentui/react-components'
import { DiscussionPanelTriggerButton } from '../../../../components/DiscussionPanel/DiscussionPanelTriggerButton'
import { TicketDiscussionPanel } from '../../../../components/DiscussionPanel/TicketDiscussionPanel'
import { TicketScreenStore } from '../../TicketScreenStore'

export function TicketScreenBarTicketDiscussion() {
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const actions = TicketScreenStore.useActions()

  return (
    <Text>
      <DiscussionPanelTriggerButton ticketId={ticketId} openDialog={actions.handleDiscussionPanelButtonClick} />
      <TicketDiscussionPanel ticketId={ticketId} />
    </Text>
  )
}
