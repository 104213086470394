import { tokens } from '@fluentui/react-components'

export const transformCommentValue = async (
  oldCommentValue: string | undefined,
  activeAccountId?: string | undefined,
): Promise<string | undefined> => {
  let updatedValue = oldCommentValue

  const mentions = oldCommentValue?.match(/@<([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})>/g)
  if (mentions) {
    for (const mention of mentions) {
      const email = mention.replace('@<', '').replace('>', '')
      const displayName = (await app.store.actions.getUserProfile(email)).displayName
      const currentUser = activeAccountId && (await app.store.actions.getUserProfile(activeAccountId)).mail
      const isUserSelf = email === currentUser
      const color = isUserSelf ? tokens.colorStatusDangerBackground3 : tokens.colorPaletteCornflowerBorderActive
      const fontWeight = isUserSelf ? 'bold' : 'normal'
      updatedValue = updatedValue?.replace(
        mention,
        `<span style="color: ${color}; font-weight: ${fontWeight};">@${displayName}</span>`,
      )
    }
  }

  return updatedValue
}
