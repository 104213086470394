import { FC, useState, useEffect, useCallback } from 'react'
import { mergeClasses } from '@fluentui/react-components'
import { useStyles } from './ActivityComment.styles'
import { Row, Column } from '../../../Layout'
import { useActivityHistoryPanelStore } from '../../store/store'
import { MarkdownView } from '../../../Markdown/MarkdownView'
import { ActivityHistoryRootCauseContext, TicketIssueData } from '@copilot-dash/domain'
import { transformCommentValue } from '../../../DiscussionPanel/children/CommentUtil'
import DOMPurify from 'dompurify'
import { IsLexicalDataSchema } from '../../../../utils/IsLexicalDataSchema'

export const ActivityComment: FC = () => {
  const styles = useStyles()
  const selectedActivity = useActivityHistoryPanelStore((state) => state.selectedActivity)
  const activeAccount = app.auth.activeAccount
  const [renderCommentNewValue, setRenderCommentNewValue] = useState<string | undefined>()
  const [renderCommentOldValue, setRenderCommentOldValue] = useState<string | undefined>()
  const processedNewValue: string | undefined = ProcessValue(selectedActivity?.fieldDiffs?.commentContent?.newValue)
  const processedOldValue: string | undefined = ProcessValue(selectedActivity?.fieldDiffs?.commentContent?.oldValue)

  const handleUpdateCommentValue = useCallback(
    async (
      commentValue: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined,
      setRenderCommentOValue: (value: string | undefined) => void,
    ) => {
      const processedOldValue = ProcessValue(commentValue)
      if (!processedOldValue || !activeAccount?.localAccountId) return

      const updatedValue = await transformCommentValue(processedOldValue, activeAccount?.localAccountId)
      setRenderCommentOValue(updatedValue)
    },
    [activeAccount?.localAccountId],
  )

  function ProcessValue(
    commentValue: string | number | TicketIssueData[] | ActivityHistoryRootCauseContext[] | string[] | undefined,
  ): string | undefined {
    let processedOldValue: string | undefined
    if (typeof commentValue === 'string') {
      processedOldValue = commentValue
    } else if (typeof commentValue === 'number') {
      processedOldValue = commentValue.toString()
    } else {
      processedOldValue = undefined
    }

    return processedOldValue
  }

  useEffect(() => {
    if (processedNewValue && !IsLexicalDataSchema(processedNewValue)) {
      handleUpdateCommentValue(selectedActivity?.fieldDiffs?.commentContent?.newValue, setRenderCommentNewValue)
    }
  }, [selectedActivity?.fieldDiffs?.commentContent?.newValue, handleUpdateCommentValue, processedNewValue])

  useEffect(() => {
    if (processedOldValue && !IsLexicalDataSchema(processedOldValue)) {
      handleUpdateCommentValue(selectedActivity?.fieldDiffs?.commentContent?.oldValue, setRenderCommentOldValue)
    }
  }, [selectedActivity?.fieldDiffs?.commentContent?.oldValue, handleUpdateCommentValue, processedOldValue])

  //Case1: both are markdown format
  if (
    processedNewValue &&
    !IsLexicalDataSchema(processedNewValue) &&
    processedOldValue &&
    !IsLexicalDataSchema(processedOldValue)
  ) {
    return (
      <Column fill>
        {renderCommentNewValue && (
          <Row className={styles.messageContainer}>
            <MarkdownView className={styles.markdown}>{renderCommentNewValue}</MarkdownView>
          </Row>
        )}
        {renderCommentOldValue && (
          <Row className={styles.messageContainer}>
            <MarkdownView className={mergeClasses(styles.markdown, styles.deleted)}>
              {renderCommentOldValue}
            </MarkdownView>
          </Row>
        )}
      </Column>
    )
  }

  //Case2: the old comment was markdown, the new one lexical format
  if (
    processedNewValue &&
    IsLexicalDataSchema(processedNewValue) &&
    processedOldValue &&
    !IsLexicalDataSchema(processedOldValue)
  ) {
    return (
      <Column fill>
        {processedNewValue && (
          <Row className="lexical" style={{ padding: '0 8px' }}>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(processedNewValue) }} />
          </Row>
        )}
        {renderCommentOldValue && (
          <Row className={styles.messageContainer}>
            <MarkdownView className={mergeClasses(styles.markdown, styles.deleted)}>
              {renderCommentOldValue}
            </MarkdownView>
          </Row>
        )}
      </Column>
    )
  }

  //Case3: both are lexical format
  return (
    <Column fill>
      {processedNewValue && (
        <Row className="lexical" style={{ padding: '0 8px' }}>
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(processedNewValue) }} />
        </Row>
      )}
      {processedOldValue && (
        <Row className="lexical">
          <div className={styles.deleted} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(processedOldValue) }} />
        </Row>
      )}
    </Column>
  )
}
