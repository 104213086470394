import { ProductName } from '@copilot-dash/domain'
import { StateCreator } from 'zustand'
import { useGlobalStore } from '../../../store'
import { CategoryTypeIds } from './const'
import { ITeamViewStore, ITeamViewTeams } from './types'

const SELECTED_TEAM_STORAGE_KEY = 'selected_team'

export const createTeamsStore: StateCreator<ITeamViewStore, [['zustand/immer', never]], [], ITeamViewTeams> = (
  set,
  get,
) => ({
  lastSelectedTeam: null,
  selectedSubMenu: CategoryTypeIds.UnRootCaused,
  computed: {
    get selectedTeamId() {
      return useGlobalStore.getState().team.teamList.data?.find((team) => team.name === get().teams.lastSelectedTeam)
        ?.id
    },
  },

  setProduct: (product: ProductName) => {
    set((state) => {
      state.product = product
    })
  },
  setLastSelectedTeam: (teamName: string, sync2Storage = true) => {
    set((state) => {
      state.teams.lastSelectedTeam = teamName
    })
    if (sync2Storage) {
      get().teams.syncLastSelectedTeam2Storage()
    }
  },
  setSelectedSubMenu: (category: string | null) => {
    set((state) => {
      state.teams.selectedSubMenu = category
    })
  },
  syncLastSelectedTeam2Storage: () => {
    const lastSelectedTeam = { name: get().teams.lastSelectedTeam, id: get().teams.computed.selectedTeamId }
    try {
      window.localStorage.setItem(SELECTED_TEAM_STORAGE_KEY, JSON.stringify(lastSelectedTeam))
    } catch (e) {
      // do nothing
    }
  },
  syncLastSelectedTeamFromStorage: () => {
    try {
      const lastSelectedTeam = window.localStorage.getItem(SELECTED_TEAM_STORAGE_KEY)
      lastSelectedTeam &&
        set((state) => {
          state.teams.lastSelectedTeam = JSON.parse(lastSelectedTeam).name
        })
    } catch (e) {
      // do nothing
    }
  },
})
