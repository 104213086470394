import { makeStyles, tokens } from '@fluentui/react-components'
import { Column } from '../../../components/Layout'
import { TicketScreenBar } from '../children/bar/TicketScreenBar'
import { TicketScreenStore } from '../TicketScreenStore'
import { TicketScreenLayoutForData } from './TicketScreenLayoutForData'
import { TicketScreenLayoutForError } from './TicketScreenLayoutForError'
import { TicketScreenLayoutForWaiting } from './TicketScreenLayoutForWaiting'

export function TicketScreenLayout() {
  const styles = useStyles()
  const ticketId = TicketScreenStore.use((state) => state.ticketId)
  const ticketSnapshot = app.store.use.getTicket(ticketId)
  const ticketSessionSnapshot = app.store.use.getTicketSession(ticketId)

  return (
    <Column className={styles.root}>
      <TicketScreenBar />
      <Column className={styles.body} vAlign="center">
        {(() => {
          switch (ticketSnapshot.status) {
            case 'error':
              return <TicketScreenLayoutForError error={ticketSnapshot.error} />
            case 'waiting': {
              switch (ticketSessionSnapshot.status) {
                case 'waiting':
                case 'error':
                  return <TicketScreenLayoutForWaiting />
                case 'done':
                  return <TicketScreenLayoutForData />
              }
              return <TicketScreenLayoutForWaiting />
            }
            case 'done': {
              switch (ticketSessionSnapshot.status) {
                case 'waiting':
                  return <TicketScreenLayoutForWaiting />
                case 'done':
                case 'error':
                  return <TicketScreenLayoutForData />
              }
            }
          }
        })()}
      </Column>
    </Column>
  )
}

const useStyles = makeStyles({
  root: {
    flex: 1,
    backgroundColor: tokens.colorNeutralBackground3,
  },
  header: {
    flex: '0 0 auto',
  },
  body: {
    flex: '1 1 auto',
  },
})
