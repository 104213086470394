import { createContext, useContext } from 'react'
import { ITicketSummaryPanelActions } from './ITicketSummaryPanelActions'
import { ITicketSummaryPanelState } from './ITicketSummaryPanelState'
import { ITicketSummaryPanelStore } from './ITicketSummaryPanelStore'

export const TicketSummaryPanelStoreContext = createContext<{
  readonly store: ITicketSummaryPanelStore
  readonly actions: ITicketSummaryPanelActions
} | null>(null)

function useTicketSummaryPanelContext() {
  const value = useContext(TicketSummaryPanelStoreContext)
  if (value === null) {
    throw new Error('useTicketSummaryPanelContext must be used within a TicketSummaryPanelStoreProvider')
  }

  return value
}

export function useTicketSummaryPanelState(): ITicketSummaryPanelState {
  return useTicketSummaryPanelContext().store.use((state) => state)
}

export function useTicketSummaryPanelSelector<T>(selector: (state: ITicketSummaryPanelState) => T): T {
  return useTicketSummaryPanelContext().store.use(selector)
}

export function useTicketSummaryPanelActions(): ITicketSummaryPanelActions {
  return useTicketSummaryPanelContext().actions
}

export function useTicketSummaryPanelStore(): ITicketSummaryPanelStore {
  return useTicketSummaryPanelContext().store
}
