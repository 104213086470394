import { ApiKustoLogResponse } from '@copilot-dash/api'
import { IKustoLogData, KustoLogTable } from '@copilot-dash/domain'
import { KustoLogItemConvert } from './KustoLogItemConvert'

const EMPTY_KUSTO_TABLE_DATA: Omit<IKustoLogData, 'table' | 'originalTableName'> = {
  [KustoLogTable.BizChat3SLatencyLog]: [],
  [KustoLogTable.BizChatPerfLog]: [],
  [KustoLogTable.EventLog]: [],
  [KustoLogTable.GwsLog]: [],
  [KustoLogTable.LLMLog]: [],
  [KustoLogTable.StateDurationLog]: [],
  [KustoLogTable.TraceLog]: [],
}

type SingleProperty<T> = {
  [P in keyof T]: Pick<T, P>
}[keyof T]

type SingleKustoTableData = SingleProperty<Omit<IKustoLogData, 'table' | 'originalTableName'>> &
  Pick<IKustoLogData, 'table' | 'originalTableName'>

export class KustoLogDataConvert {
  static fromApi(
    table: Exclude<KustoLogTable, KustoLogTable.BizChat3SLatencyLog>,
    api: ApiKustoLogResponse,
  ): IKustoLogData {
    return {
      ...EMPTY_KUSTO_TABLE_DATA,
      ...this.convertToSingleKusto(table, api),
    }
  }

  static convertToSingleKusto(
    table: Exclude<KustoLogTable, KustoLogTable.BizChat3SLatencyLog>,
    api: ApiKustoLogResponse,
  ): SingleKustoTableData {
    switch (table) {
      case KustoLogTable.BizChatPerfLog:
        return this.convertToBizChatPerformanceLog(api, table)
      case KustoLogTable.EventLog:
        return this.convertToEventLog(api, table)
      case KustoLogTable.GwsLog:
        return this.convertToGwsLog(api, table)
      case KustoLogTable.LLMLog:
        return this.convertToLlmLog(api, table)
      case KustoLogTable.StateDurationLog:
        return this.convertToStateDurationLog(api, table)
      case KustoLogTable.TraceLog:
        return this.convertToTraceLog(api, table)
    }
  }

  private static convertToBizChatPerformanceLog(
    api: ApiKustoLogResponse,
    table: KustoLogTable.BizChatPerfLog,
  ): SingleKustoTableData {
    const key: keyof ApiKustoLogResponse['logData'] = 'bizchatPerformanceEvent (Kusto)'
    const rows = api.logData[key] ?? []

    return {
      table,
      originalTableName: key,
      [table]: rows.map((item) => KustoLogItemConvert.fromApiBizPerfLogItem(item, key)),
    }
  }

  private static convertToEventLog(api: ApiKustoLogResponse, table: KustoLogTable.EventLog): SingleKustoTableData {
    const key: keyof ApiKustoLogResponse['logData'] = 'turingBotEventMDSLogs (Kusto)'
    const rows = api.logData[key] ?? []

    return {
      table,
      originalTableName: key,
      [table]: rows.map((item) => KustoLogItemConvert.fromApiEventKustoLogItem(item)),
    }
  }

  private static convertToGwsLog(api: ApiKustoLogResponse, table: KustoLogTable.GwsLog): SingleKustoTableData {
    const key1: keyof ApiKustoLogResponse['logData'] = 'substrateSearchInfoEvent (Kusto)'
    const rows1 = api.logData[key1]

    const key2: keyof ApiKustoLogResponse['logData'] = 'substrateSearchExceptionEvent (Kusto)'
    const rows2 = api.logData[key2]

    const key = rows1 ? key1 : key2
    const rows = rows1 ?? rows2 ?? []
    const isSubstrateSearchExceptionEvent = key === key2

    return {
      table,
      originalTableName: key,
      [table]: rows.map((item) => KustoLogItemConvert.fromApiGwsLogItem(item, isSubstrateSearchExceptionEvent)),
    }
  }

  private static convertToLlmLog(api: ApiKustoLogResponse, table: KustoLogTable.LLMLog): SingleKustoTableData {
    const key: keyof ApiKustoLogResponse['logData'] = 'modelDHttpQAS (Kusto)'
    const rows = api.logData[key] ?? []

    return {
      table,
      originalTableName: key,
      [table]: rows.map((item) => KustoLogItemConvert.fromApiLlmKustoLogItem(item)),
    }
  }

  private static convertToStateDurationLog(
    api: ApiKustoLogResponse,
    table: KustoLogTable.StateDurationLog,
  ): SingleKustoTableData {
    const key: keyof ApiKustoLogResponse['logData'] = 'turingBotMonitoredScopeLogs (Kusto)'
    const rows = api.logData[key] ?? []

    return {
      table,
      originalTableName: key,
      [table]: rows.map((item) => KustoLogItemConvert.fromApiStateAndDurationKustoLogItem(item)),
    }
  }

  private static convertToTraceLog(api: ApiKustoLogResponse, table: KustoLogTable.TraceLog): SingleKustoTableData {
    const key: keyof ApiKustoLogResponse['logData'] = 'turingBotTraceMDSLogs (Kusto)'
    const rows = api.logData[key] ?? []

    return {
      table,
      originalTableName: key,
      [table]: rows.map((item) => KustoLogItemConvert.fromApiTraceKustoLogItem(item)),
    }
  }
}
