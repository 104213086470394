import { SearchFilterFieldNames } from '@copilot-dash/domain'
import { ISearchTicketArgs } from '@copilot-dash/store'
import { IFilterOption } from '../Filter/IFilterOption.types'

export const CustomizedFilterField = (
  form: ISearchTicketArgs,
  supportedTicketFilterItems: SearchFilterFieldNames[],
): IFilterOption[] => [
  {
    key: SearchFilterFieldNames.isTopi18N,
    text: 'Is Top i18N Tenants',
    disabled: !!form.isTopi18N,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.isTopi18N),
  },
  {
    key: SearchFilterFieldNames.isSTCAChina,
    text: 'Is STCA China',
    disabled: !!form.isSTCAChina,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.isSTCAChina),
  },
  {
    key: SearchFilterFieldNames.hasGPTExtension,
    text: 'Has GPT-Extension keywords',
    disabled: !!form.hasGPTExtension,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.hasGPTExtension),
  },
  {
    key: SearchFilterFieldNames.hasMessageExtension,
    text: 'Has MessageExtension keywords',
    disabled: !!form.hasMessageExtension,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.hasMessageExtension),
  },
  {
    key: SearchFilterFieldNames.hasCopilotExtensionIds,
    text: 'Has CopilotExtensionIds',
    disabled: !!form.hasCopilotExtensionIds,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.hasCopilotExtensionIds),
  },
  {
    key: SearchFilterFieldNames.hasConnector,
    text: 'Has Connector keywords',
    disabled: !!form.hasConnector,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.hasConnector),
  },
  {
    key: SearchFilterFieldNames.isGCIntent,
    text: 'Is GC Intent',
    disabled: !!form.isGCIntent,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.isGCIntent),
  },
  {
    key: SearchFilterFieldNames.hasConnectorResult,
    text: 'Has Connector Result',
    disabled: !!form.hasConnectorResult,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.hasConnectorResult),
  },
  {
    key: SearchFilterFieldNames.semanticSearchType,
    text: 'Semantic search type',
    disabled: !!form.semanticSearchType,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.semanticSearchType),
  },
  {
    key: SearchFilterFieldNames.experienceType,
    text: 'PromptMetadata_ExperienceType',
    disabled: !!form.experienceType,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.experienceType),
  },
  {
    key: SearchFilterFieldNames.errorCode,
    text: 'Error code',
    disabled: !!form.errorCode,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.errorCode),
  },
  {
    key: SearchFilterFieldNames.copilotExtensionIds,
    text: 'CopilotExtensionIds',
    disabled: !!form.copilotExtensionIds,
    hidden: !supportedTicketFilterItems.includes(SearchFilterFieldNames.copilotExtensionIds),
  },
]
