import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: '100vw',
    ...shorthands.flex(1),
  },
  icon: {
    color: tokens.colorNeutralForeground4,
    marginRight: '8px',
    marginLeft: '26px',
  },
  text: {
    color: tokens.colorBackgroundOverlay,
    fontSize: tokens.fontSizeBase200,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    ...shorthands.overflow('hidden'),
  },
})
