import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Column, Row, Spacer } from '../../../components/Layout'
import { ChevronDownRegular, SparkleFilled } from '@fluentui/react-icons'
import { tokens, Text, Button, Spinner } from '@fluentui/react-components'
import { INewTicketData, IRootCauseItem } from '@copilot-dash/domain'
import { useTeamViewStore } from '../storeNew'
import { ExtendedTableColumnDefinition } from '../../../components/Table/ExtendedTableColumn'
import { TableLoading } from './TableLoading'
import { ErrorView } from '../../../components/Error'
import { ResizableColumnsTable, ITableRef } from '../../../components/Table/ResizableColumnsTable'
import { NoRecommendationID } from '../storeNew/const'
import { useStyles } from './ClusteringTickets.styles'

interface IClusteringTickets {
  rootCause: IRootCauseItem
  columns: ExtendedTableColumnDefinition<INewTicketData>[]
  clickedTicketKey?: string
  keyPicker: (item: INewTicketData, index: number) => string
  onRowClick?: (row?: INewTicketData) => void
  onSelectionChange?: (selectedItems: INewTicketData[] | undefined) => void
  tableRef?: (ref: ITableRef | null) => void
  clusteringListLength?: number
}

function ClusteringUnRootCauseTicketsComp({
  rootCause,
  columns,
  clickedTicketKey,
  keyPicker,
  onRowClick,
  onSelectionChange,
  tableRef,
  clusteringListLength,
}: IClusteringTickets) {
  const styles = useStyles()

  const filter = useTeamViewStore((state) => state.tickets.filterForm)

  // Load un-root caused tickets
  useEffect(() => {
    if (rootCause.issueId) {
      useTeamViewStore.getState().tickets.filterUnRootCausedTickets(rootCause.issueId, filter)
    }
  }, [rootCause.issueId, filter])

  const ticketsSnapshot = useTeamViewStore((state) => state.tickets.unRootCausedTickets[rootCause.issueId])

  const [loading, setLoading] = useState(false)

  const handleLoadMore = useCallback(() => {
    const offset = ticketsSnapshot?.data?.tickets?.length ?? 0
    if (ticketsSnapshot?.status === 'done' && ticketsSnapshot.data.hasMore) {
      setLoading(true)
      useTeamViewStore
        .getState()
        .tickets.loadMoreUnRootCausedTickets(rootCause.issueId, offset)
        .finally(() => {
          setLoading(false)
        })
    }
  }, [rootCause.issueId, ticketsSnapshot])

  const table = useMemo(() => {
    if (ticketsSnapshot?.status === 'waiting') {
      return <TableLoading className={styles.loading} />
    }
    if (ticketsSnapshot?.status === 'error') {
      return <ErrorView error={ticketsSnapshot.error} />
    }
    if (ticketsSnapshot?.status === 'done') {
      return ticketsSnapshot.data?.ticketCount > 0 ? (
        <ResizableColumnsTable
          ref={(ref) => tableRef?.(ref)}
          items={ticketsSnapshot.data?.tickets}
          columns={columns}
          keyPicker={keyPicker}
          rowClick={onRowClick}
          selectedRowId={clickedTicketKey}
          enableBodyScroll={false}
          selectable={true}
          selectionMode="multiselect"
          onSelectionChange={onSelectionChange}
        />
      ) : (
        <ErrorView.Custom level="WARNING" message="No tickets found for this query" />
      )
    }
    return null
  }, [ticketsSnapshot, styles.loading, tableRef, columns, keyPicker, onRowClick, clickedTicketKey, onSelectionChange])

  const hasMore =
    ticketsSnapshot?.data?.hasMore &&
    ticketsSnapshot.data.ticketCount - (ticketsSnapshot.data?.tickets?.length ?? 0) > 0

  useEffect(() => {
    // clear tickets in store when unmount
    return () => {
      useTeamViewStore.getState().tickets.clearUnRootCausedTickets(rootCause.issueId)
    }
  }, [rootCause.issueId])

  if (!ticketsSnapshot) return null

  if (
    rootCause.issueId !== NoRecommendationID &&
    ticketsSnapshot?.status === 'done' &&
    ticketsSnapshot.data?.ticketCount === 0
  ) {
    return null
  }

  return (
    <Column className={styles.wrapper}>
      <Row vAlign="center" className={styles.title}>
        {rootCause.issueId !== NoRecommendationID ? (
          <>
            <SparkleFilled fontSize={20} color={tokens.colorCompoundBrandForeground1} />
            <Spacer width={12} />
            <Text size={400}>Root Cause Recommendation:</Text>
            <Spacer width={6} />
            <Text size={400} className={styles.blue}>
              {rootCause.title}
            </Text>
            <Spacer width={12} />
            <Text size={400}>
              {ticketsSnapshot.data && ticketsSnapshot.data.ticketCount > 0
                ? ` (${ticketsSnapshot.data.ticketCount})`
                : ''}
            </Text>
          </>
        ) : clusteringListLength === 1 ? null : (
          <Text size={400}>Others:</Text>
        )}
      </Row>
      {table}
      {hasMore && (
        <Row hAlign="center" className={styles.loadMore}>
          {loading ? (
            <Spinner />
          ) : (
            <Button
              className={styles.loadMoreTickets}
              onClick={handleLoadMore}
              icon={<ChevronDownRegular />}
              iconPosition="after"
            >
              Load more tickets ({ticketsSnapshot.data.ticketCount - (ticketsSnapshot.data?.tickets?.length ?? 0)})
            </Button>
          )}
        </Row>
      )}
    </Column>
  )
}

export const ClusteringTickets = memo(ClusteringUnRootCauseTicketsComp)

ClusteringTickets.displayName = 'ClusteringTickets'
