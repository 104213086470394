import { createStoreContext, useStore } from '@copilot-dash/core'
import { ReactNode, useEffect } from 'react'
import { IActions } from './store/IActions'
import { IState } from './store/IState'
import { createActions } from './store/createActions'
import { createState } from './store/createState'

interface IProps {
  readonly ticketId: string
  readonly selectedTurnId?: string
  readonly selectedScopeId?: string
  readonly onSelect?: (turnId: string, scopeId: string | undefined) => void
  readonly children: ReactNode
}

export function TicketCallFlowStore({ ticketId, selectedTurnId, selectedScopeId, onSelect, children }: IProps) {
  const store = useStore<IState, IActions>((set, get) => ({
    state: createState({
      ticketId,
      selectedTurnId,
      selectedScopeId,
    }),
    actions: createActions({
      set,
      get,
      onSelect: (turnId, scopeId) => {
        onSelect?.(turnId, scopeId)
      },
    }),
  }))

  useEffect(() => {
    store.setState((state) => {
      state.ticketId = ticketId
      state.selectedTurnId = selectedTurnId
      state.selectedScopeId = selectedScopeId
    })
  }, [store, ticketId, selectedTurnId, selectedScopeId])

  return <Context.Provider store={store}>{children}</Context.Provider>
}

const Context = createStoreContext<IState, IActions>()
TicketCallFlowStore.use = Context.use
TicketCallFlowStore.useActions = Context.useActions
