import { Caches } from '../../client/ApiClientCaches'
import { ApiClientOptions } from '../../client/ApiClientOptions'
import { OcvApiClient } from './OcvApiClient'
import { ApiOcvTicket } from './types'
import { apiOcvTicketSchema } from './types/ApiOcvTicket.schema'

/**
 * One possible Swagger API:
 * https://ocv.microsoft.com/api/index.html
 */
export class OcvApi {
  private readonly client: OcvApiClient

  constructor(context: ApiClientOptions) {
    this.client = new OcvApiClient(context)
  }

  async getItem(itemId: string): Promise<ApiOcvTicket> {
    const encodedItemId = encodeURIComponent(itemId)

    return await this.client.get(`/api/public/v1/items/${encodedItemId}`, {
      schema: apiOcvTicketSchema,
      cache: Caches.SHORT,
    })
  }
}
