import { z } from 'zod'

export interface ApiBigFunnelLogParam {
  readonly ClientActivityId?: string
  readonly DeployRing?: string
  readonly RoleInstance?: string
  readonly MailboxGuid?: string
}

export const apiBigFunnelLogParamSchema = z.object({
  ClientActivityId: z.string().optional(),
  DeployRing: z.string().optional(),
  RoleInstance: z.string().optional(),
  MailboxGuid: z.string().optional(),
})
