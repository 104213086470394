import { ProductIds, TeamViewSubMenuIds } from '@copilot-dash/domain'
import { z } from 'zod'

export enum SearchFilterFieldNames {
  dateRange = 'dateRange',
  client = 'client',
  customerTypes = 'customerTypes',
  ring = 'ring',
  channel = 'channel',
  thumbs = 'thumbs',
  hasVerbatim = 'hasVerbatim',
  hasUserConsent = 'hasUserConsent',
  priority = 'priority',
  promptLanguages = 'promptLanguages',
  groundedPrompts = 'groundedPrompts',
  invocationType = 'invocationType',
  isApology = 'isApology',
  hasCitation = 'hasCitation',
  triggeredSkill = 'triggeredSkill',
  optionsSets = 'optionsSets',
  hitAvalon = 'hitAvalon',
  isTest = 'isTest',
  isSTCAChina = 'isSTCAChina',
  isTopi18N = 'isTopi18N',
  responseHeroType = 'responseHeroType',
  responseLinkType = 'responseLinkType',
  semanticSearchType = 'semanticSearchType',
  hasEntityCard = 'hasEntityCard',
  experienceType = 'experienceType',
  scenarioGroup = 'scenarioGroup',
  bizchatScenario = 'bizchatScenario',
  hasConnector = 'hasConnector',
  hasGPTExtension = 'hasGPTExtension',
  hasMessageExtension = 'hasMessageExtension',
  hasCopilotExtensionIds = 'hasCopilotExtensionIds',
  errorCode = 'errorCode',
  isGCIntent = 'isGCIntent',
  hasConnectorResult = 'hasConnectorResult',
  dSATStatus = 'dSATStatus',
  customTags = 'customTags',
  dSATAssignedTo = 'dSATAssignedTo',
  sliceIds = 'sliceIds',
  copilotExtensionIds = 'copilotExtensionIds',
}

export const searchFilterFieldNamesSchema = z.nativeEnum(SearchFilterFieldNames)
export const SearchFilterFieldNamesArraySchema = z.array(searchFilterFieldNamesSchema)

export const DefaultCustomizedFilter: SearchFilterFieldNames[] = [SearchFilterFieldNames.isTopi18N]

export type SearchRouteConfig = {
  search: SearchFilterFieldNames[]
}

type ISubMenuName = keyof typeof TeamViewSubMenuIds | 'default'

export type TeamRouteConfig = {
  team: {
    [teamName: string]: {
      [subMenuName in ISubMenuName]?: SearchFilterFieldNames[]
    }
  }
}

export type IFilterPanelConfig = {
  [product in ProductIds]: SearchRouteConfig | TeamRouteConfig
}
export const FilterPanelConfig: IFilterPanelConfig = {
  [ProductIds.M365Chat]: {
    search: [
      SearchFilterFieldNames.dateRange,
      SearchFilterFieldNames.client,
      SearchFilterFieldNames.customerTypes,
      SearchFilterFieldNames.ring,
      SearchFilterFieldNames.channel,
      SearchFilterFieldNames.thumbs,
      SearchFilterFieldNames.hasVerbatim,
      SearchFilterFieldNames.hasUserConsent,
      SearchFilterFieldNames.priority,
      SearchFilterFieldNames.promptLanguages,
      SearchFilterFieldNames.groundedPrompts,
      SearchFilterFieldNames.invocationType,
      SearchFilterFieldNames.isApology,
      SearchFilterFieldNames.hasCitation,
      SearchFilterFieldNames.triggeredSkill,

      SearchFilterFieldNames.hitAvalon,
      SearchFilterFieldNames.isTest,
      SearchFilterFieldNames.isSTCAChina,
      SearchFilterFieldNames.isTopi18N,
      SearchFilterFieldNames.responseHeroType,
      SearchFilterFieldNames.responseLinkType,
      SearchFilterFieldNames.semanticSearchType,
      SearchFilterFieldNames.hasEntityCard,
      SearchFilterFieldNames.experienceType,
      SearchFilterFieldNames.scenarioGroup,
      SearchFilterFieldNames.bizchatScenario,
      SearchFilterFieldNames.hasConnector,
      SearchFilterFieldNames.hasGPTExtension,
      SearchFilterFieldNames.hasMessageExtension,
      SearchFilterFieldNames.hasCopilotExtensionIds,
      SearchFilterFieldNames.errorCode,
      SearchFilterFieldNames.isGCIntent,
      SearchFilterFieldNames.hasConnectorResult,
      SearchFilterFieldNames.dSATStatus,
      SearchFilterFieldNames.customTags,
      SearchFilterFieldNames.copilotExtensionIds,
    ],
    team: {
      default: {
        default: [
          SearchFilterFieldNames.dateRange,
          SearchFilterFieldNames.client,
          SearchFilterFieldNames.customerTypes,
          SearchFilterFieldNames.ring,
          SearchFilterFieldNames.channel,
          SearchFilterFieldNames.hasVerbatim,
          SearchFilterFieldNames.priority,
          SearchFilterFieldNames.promptLanguages,
          SearchFilterFieldNames.groundedPrompts,
          SearchFilterFieldNames.isApology,
          SearchFilterFieldNames.hasCitation,
          SearchFilterFieldNames.triggeredSkill,

          SearchFilterFieldNames.invocationType,

          SearchFilterFieldNames.hitAvalon,
          SearchFilterFieldNames.isTest,
          SearchFilterFieldNames.isSTCAChina,
          SearchFilterFieldNames.isTopi18N,
          SearchFilterFieldNames.responseHeroType,
          SearchFilterFieldNames.responseLinkType,
          SearchFilterFieldNames.semanticSearchType,
          SearchFilterFieldNames.hasEntityCard,
          SearchFilterFieldNames.experienceType,
          SearchFilterFieldNames.scenarioGroup,
          SearchFilterFieldNames.bizchatScenario,
          SearchFilterFieldNames.hasConnector,
          SearchFilterFieldNames.hasGPTExtension,
          SearchFilterFieldNames.hasMessageExtension,
          SearchFilterFieldNames.hasCopilotExtensionIds,
          SearchFilterFieldNames.errorCode,
          SearchFilterFieldNames.isGCIntent,
          SearchFilterFieldNames.hasConnectorResult,
          SearchFilterFieldNames.dSATStatus,
          SearchFilterFieldNames.customTags,
          SearchFilterFieldNames.dSATAssignedTo,
          SearchFilterFieldNames.copilotExtensionIds,
        ],
        TopIssues: [
          SearchFilterFieldNames.client,
          SearchFilterFieldNames.customerTypes,
          SearchFilterFieldNames.ring,
          SearchFilterFieldNames.channel,
          SearchFilterFieldNames.hasVerbatim,
          SearchFilterFieldNames.priority,
          SearchFilterFieldNames.promptLanguages,
          SearchFilterFieldNames.groundedPrompts,
          SearchFilterFieldNames.isApology,
          SearchFilterFieldNames.hasCitation,
          SearchFilterFieldNames.triggeredSkill,
          SearchFilterFieldNames.invocationType,

          SearchFilterFieldNames.hitAvalon,
          SearchFilterFieldNames.isTest,
          SearchFilterFieldNames.isSTCAChina,
          SearchFilterFieldNames.isTopi18N,
          SearchFilterFieldNames.responseHeroType,
          SearchFilterFieldNames.responseLinkType,
          SearchFilterFieldNames.semanticSearchType,
          SearchFilterFieldNames.hasEntityCard,
          SearchFilterFieldNames.experienceType,
          SearchFilterFieldNames.scenarioGroup,
          SearchFilterFieldNames.bizchatScenario,
          SearchFilterFieldNames.hasConnector,
          SearchFilterFieldNames.hasGPTExtension,
          SearchFilterFieldNames.hasMessageExtension,
          SearchFilterFieldNames.hasCopilotExtensionIds,
          SearchFilterFieldNames.errorCode,
          SearchFilterFieldNames.isGCIntent,
          SearchFilterFieldNames.hasConnectorResult,
          SearchFilterFieldNames.dSATStatus,
          SearchFilterFieldNames.customTags,
          SearchFilterFieldNames.dSATAssignedTo,
          SearchFilterFieldNames.copilotExtensionIds,
        ],
      },
    },
  },
  [ProductIds.M365ChatWebChat]: {
    search: [
      SearchFilterFieldNames.dateRange,
      SearchFilterFieldNames.client,
      SearchFilterFieldNames.customerTypes,
      SearchFilterFieldNames.ring,
      SearchFilterFieldNames.channel,
      SearchFilterFieldNames.thumbs,
      SearchFilterFieldNames.hasVerbatim,
      SearchFilterFieldNames.hasUserConsent,
      SearchFilterFieldNames.priority,
      SearchFilterFieldNames.promptLanguages,
      SearchFilterFieldNames.isApology,
      SearchFilterFieldNames.hasCitation,
      SearchFilterFieldNames.triggeredSkill,
      SearchFilterFieldNames.hitAvalon,
      SearchFilterFieldNames.isTest,
      SearchFilterFieldNames.isSTCAChina,
      SearchFilterFieldNames.isTopi18N,
      SearchFilterFieldNames.errorCode,
      SearchFilterFieldNames.dSATStatus,
      SearchFilterFieldNames.customTags,
      SearchFilterFieldNames.optionsSets,
      SearchFilterFieldNames.sliceIds,
      SearchFilterFieldNames.hasCopilotExtensionIds,
      SearchFilterFieldNames.copilotExtensionIds,
    ],
    team: {
      default: {
        default: [
          SearchFilterFieldNames.dateRange,
          SearchFilterFieldNames.client,
          SearchFilterFieldNames.customerTypes,
          SearchFilterFieldNames.ring,
          SearchFilterFieldNames.channel,
          SearchFilterFieldNames.hasVerbatim,
          SearchFilterFieldNames.priority,
          SearchFilterFieldNames.promptLanguages,
          SearchFilterFieldNames.isApology,
          SearchFilterFieldNames.hasCitation,
          SearchFilterFieldNames.triggeredSkill,
          SearchFilterFieldNames.hitAvalon,
          SearchFilterFieldNames.isTest,
          SearchFilterFieldNames.isSTCAChina,
          SearchFilterFieldNames.isTopi18N,
          SearchFilterFieldNames.errorCode,
          SearchFilterFieldNames.dSATStatus,
          SearchFilterFieldNames.customTags,
          SearchFilterFieldNames.dSATAssignedTo,
          SearchFilterFieldNames.optionsSets,
          SearchFilterFieldNames.sliceIds,
          SearchFilterFieldNames.hasCopilotExtensionIds,
          SearchFilterFieldNames.copilotExtensionIds,
        ],
        TopIssues: [
          SearchFilterFieldNames.client,
          SearchFilterFieldNames.customerTypes,
          SearchFilterFieldNames.ring,
          SearchFilterFieldNames.channel,
          SearchFilterFieldNames.hasVerbatim,
          SearchFilterFieldNames.priority,
          SearchFilterFieldNames.promptLanguages,
          SearchFilterFieldNames.isApology,
          SearchFilterFieldNames.hasCitation,
          SearchFilterFieldNames.triggeredSkill,
          SearchFilterFieldNames.hitAvalon,
          SearchFilterFieldNames.isTest,
          SearchFilterFieldNames.isSTCAChina,
          SearchFilterFieldNames.isTopi18N,
          SearchFilterFieldNames.errorCode,
          SearchFilterFieldNames.dSATStatus,
          SearchFilterFieldNames.customTags,
          SearchFilterFieldNames.dSATAssignedTo,
          SearchFilterFieldNames.optionsSets,
          SearchFilterFieldNames.sliceIds,
          SearchFilterFieldNames.hasCopilotExtensionIds,
          SearchFilterFieldNames.copilotExtensionIds,
        ],
      },
    },
  },
  [ProductIds.ExcelCopilot]: {
    search: [
      SearchFilterFieldNames.dateRange,
      SearchFilterFieldNames.client,
      SearchFilterFieldNames.customerTypes,
      SearchFilterFieldNames.ring,
      SearchFilterFieldNames.thumbs,
      SearchFilterFieldNames.hasVerbatim,
      SearchFilterFieldNames.hasUserConsent,
      SearchFilterFieldNames.promptLanguages,
      SearchFilterFieldNames.isApology,
      SearchFilterFieldNames.hasCitation,

      SearchFilterFieldNames.hitAvalon,
      SearchFilterFieldNames.isTest,
      SearchFilterFieldNames.isSTCAChina,
      SearchFilterFieldNames.isTopi18N,
      SearchFilterFieldNames.errorCode,
      SearchFilterFieldNames.hasCopilotExtensionIds,
      SearchFilterFieldNames.copilotExtensionIds,
    ],
  },
  [ProductIds.WordCopilot]: {
    search: [
      SearchFilterFieldNames.dateRange,
      SearchFilterFieldNames.client,
      SearchFilterFieldNames.customerTypes,
      SearchFilterFieldNames.ring,
      SearchFilterFieldNames.thumbs,
      SearchFilterFieldNames.hasVerbatim,
      SearchFilterFieldNames.hasUserConsent,
      SearchFilterFieldNames.promptLanguages,
      SearchFilterFieldNames.isApology,
      SearchFilterFieldNames.hasCitation,

      SearchFilterFieldNames.hitAvalon,
      SearchFilterFieldNames.isTest,
      SearchFilterFieldNames.isSTCAChina,
      SearchFilterFieldNames.isTopi18N,
      SearchFilterFieldNames.errorCode,
      SearchFilterFieldNames.hasCopilotExtensionIds,
      SearchFilterFieldNames.copilotExtensionIds,
    ],
  },
  [ProductIds.PPTCopilot]: {
    search: [
      SearchFilterFieldNames.dateRange,
      SearchFilterFieldNames.client,
      SearchFilterFieldNames.customerTypes,
      SearchFilterFieldNames.ring,
      SearchFilterFieldNames.thumbs,
      SearchFilterFieldNames.hasVerbatim,
      SearchFilterFieldNames.hasUserConsent,
      SearchFilterFieldNames.promptLanguages,
      SearchFilterFieldNames.isApology,
      SearchFilterFieldNames.hasCitation,

      SearchFilterFieldNames.hitAvalon,
      SearchFilterFieldNames.isTest,
      SearchFilterFieldNames.isSTCAChina,
      SearchFilterFieldNames.isTopi18N,
      SearchFilterFieldNames.errorCode,
      SearchFilterFieldNames.hasCopilotExtensionIds,
      SearchFilterFieldNames.copilotExtensionIds,
    ],
  },
  [ProductIds.TeamsMeetingCopilot]: {
    search: [
      SearchFilterFieldNames.dateRange,
      SearchFilterFieldNames.client,
      SearchFilterFieldNames.customerTypes,
      SearchFilterFieldNames.ring,
      SearchFilterFieldNames.thumbs,
      SearchFilterFieldNames.hasVerbatim,
      SearchFilterFieldNames.hasUserConsent,
      SearchFilterFieldNames.priority,
      SearchFilterFieldNames.promptLanguages,
      SearchFilterFieldNames.isApology,
      SearchFilterFieldNames.hasCitation,
      SearchFilterFieldNames.invocationType,

      SearchFilterFieldNames.isTest,
      SearchFilterFieldNames.isSTCAChina,
      SearchFilterFieldNames.isTopi18N,
      SearchFilterFieldNames.errorCode,
      SearchFilterFieldNames.experienceType,
    ],
    team: {
      default: {
        default: [
          SearchFilterFieldNames.dateRange,
          SearchFilterFieldNames.client,
          SearchFilterFieldNames.customerTypes,
          SearchFilterFieldNames.ring,
          SearchFilterFieldNames.hasVerbatim,
          SearchFilterFieldNames.priority,
          SearchFilterFieldNames.promptLanguages,
          SearchFilterFieldNames.isApology,
          SearchFilterFieldNames.hasCitation,
          SearchFilterFieldNames.triggeredSkill,
          SearchFilterFieldNames.hitAvalon,
          SearchFilterFieldNames.isTest,
          SearchFilterFieldNames.isSTCAChina,
          SearchFilterFieldNames.isTopi18N,
          SearchFilterFieldNames.errorCode,
          SearchFilterFieldNames.dSATStatus,
          SearchFilterFieldNames.customTags,
          SearchFilterFieldNames.dSATAssignedTo,
          SearchFilterFieldNames.experienceType,
          SearchFilterFieldNames.invocationType,
        ],
        TopIssues: [
          SearchFilterFieldNames.client,
          SearchFilterFieldNames.customerTypes,
          SearchFilterFieldNames.ring,
          SearchFilterFieldNames.hasVerbatim,
          SearchFilterFieldNames.priority,
          SearchFilterFieldNames.promptLanguages,
          SearchFilterFieldNames.isApology,
          SearchFilterFieldNames.hasCitation,
          SearchFilterFieldNames.triggeredSkill,
          SearchFilterFieldNames.hitAvalon,
          SearchFilterFieldNames.isTest,
          SearchFilterFieldNames.isSTCAChina,
          SearchFilterFieldNames.isTopi18N,
          SearchFilterFieldNames.errorCode,
          SearchFilterFieldNames.dSATStatus,
          SearchFilterFieldNames.customTags,
          SearchFilterFieldNames.dSATAssignedTo,
          SearchFilterFieldNames.experienceType,
          SearchFilterFieldNames.invocationType,
        ],
      },
    },
  },
  [ProductIds.ODSPCopilot]: {
    search: [
      SearchFilterFieldNames.dateRange,
      SearchFilterFieldNames.client,
      SearchFilterFieldNames.customerTypes,
      SearchFilterFieldNames.ring,
      SearchFilterFieldNames.thumbs,
      SearchFilterFieldNames.hasVerbatim,
      SearchFilterFieldNames.hasUserConsent,
      SearchFilterFieldNames.promptLanguages,
      SearchFilterFieldNames.isApology,
      SearchFilterFieldNames.hasCitation,

      SearchFilterFieldNames.hitAvalon,
      SearchFilterFieldNames.isTest,
      SearchFilterFieldNames.isSTCAChina,
      SearchFilterFieldNames.isTopi18N,
      SearchFilterFieldNames.errorCode,
    ],
  },
  [ProductIds.LoopCopilot]: {
    search: [
      SearchFilterFieldNames.dateRange,
      SearchFilterFieldNames.client,
      SearchFilterFieldNames.customerTypes,
      SearchFilterFieldNames.ring,
      SearchFilterFieldNames.thumbs,
      SearchFilterFieldNames.hasVerbatim,
      SearchFilterFieldNames.hasUserConsent,
      SearchFilterFieldNames.promptLanguages,
      SearchFilterFieldNames.isApology,
      SearchFilterFieldNames.hasCitation,
      SearchFilterFieldNames.hitAvalon,
      SearchFilterFieldNames.isTest,
      SearchFilterFieldNames.isSTCAChina,
      SearchFilterFieldNames.isTopi18N,
      SearchFilterFieldNames.errorCode,
    ],
  },
  [ProductIds.OneNoteCopilot]: {
    search: [
      SearchFilterFieldNames.dateRange,
      SearchFilterFieldNames.client,
      SearchFilterFieldNames.customerTypes,
      SearchFilterFieldNames.ring,
      SearchFilterFieldNames.thumbs,
      SearchFilterFieldNames.hasVerbatim,
      SearchFilterFieldNames.hasUserConsent,
      SearchFilterFieldNames.promptLanguages,
      SearchFilterFieldNames.isApology,
      SearchFilterFieldNames.hasCitation,

      SearchFilterFieldNames.hitAvalon,
      SearchFilterFieldNames.isTest,
      SearchFilterFieldNames.isSTCAChina,
      SearchFilterFieldNames.isTopi18N,
      SearchFilterFieldNames.errorCode,
    ],
  },
  [ProductIds.TenantAdminFeedback]: {
    search: [
      SearchFilterFieldNames.dateRange,
      SearchFilterFieldNames.client,
      SearchFilterFieldNames.ring,
      SearchFilterFieldNames.hasVerbatim,
    ],
  },
}
