import { useState, useEffect } from 'react'
import { Combobox, Label, useId, mergeClasses, Option, Text } from '@fluentui/react-components'
import type { ComboboxProps, OptionOnSelectData, SelectionEvents } from '@fluentui/react-components'
import { useStyles } from './CopilotDashSelectDropdown.styles'
import { IFilterOption } from './IFilterOption.types'
import { Column } from '../Layout'

interface IProps {
  comboId: string
  filterType: string
  placeholder?: string
  optionsList: IFilterOption[]
  defaultSelectedOption: string | undefined
  onChangeFilter: (item: string[]) => void
}

export const CopilotDashSingleSelectDropdown: React.FC<IProps> = ({
  comboId,
  filterType,
  placeholder = 'Select',
  optionsList,
  defaultSelectedOption,
  onChangeFilter,
}) => {
  const styles = useStyles()
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])
  const [value, setValue] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [isActive, setIsActive] = useState(false)

  const onOptionSelect = (ev: SelectionEvents, data: OptionOnSelectData) => {
    if (data.optionValue && data.optionText) {
      onChangeFilter([data.optionValue])
      setInputValue(data.optionText)
    } else {
      onChangeFilter([])
      setInputValue('')
    }
    setSelectedOptions(data.selectedOptions)
  }
  const onChange: ComboboxProps['onChange'] = (event) => {
    setInputValue(event.target.value)
  }

  useEffect(() => {
    if (defaultSelectedOption) {
      setSelectedOptions([defaultSelectedOption])
      const selectedOption = optionsList.find((option) => option.key === defaultSelectedOption)
      setValue(selectedOption?.text || '')
    } else {
      setSelectedOptions([])
      setValue('')
    }
  }, [defaultSelectedOption, optionsList])

  const onFocus = () => {
    setInputValue('')
    setIsActive(true)
  }

  const onBlur = () => {
    setIsActive(false)
    setInputValue('')
  }

  function filterOptions(optionsList: IFilterOption[]) {
    const filteredOptions = optionsList.filter((item) => {
      return item.text.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())
    })
    return filteredOptions.length > 0 ? (
      filteredOptions.map((item) => (
        <Option value={item.key} key={item.key} title={item.text}>
          {item.text}
        </Option>
      ))
    ) : (
      <Text>No option match your search.</Text>
    )
  }

  const targetFinalOptions = filterOptions(optionsList)
  const _displayValue = isActive ? inputValue : value

  return (
    <Column className={styles.root}>
      <Label id={useId(comboId)} weight="semibold">
        {filterType}:
      </Label>
      <Combobox
        aria-labelledby={useId(comboId)}
        placeholder={placeholder}
        clearable
        value={_displayValue}
        selectedOptions={selectedOptions}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onOptionSelect={onOptionSelect}
        className={value ? mergeClasses(styles.commonDropdown, styles.selectedDropdown) : styles.commonDropdown}
      >
        {targetFinalOptions}
      </Combobox>
    </Column>
  )
}
