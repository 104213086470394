import { makeStyles } from '@fluentui/react-components'

export const useStyles = makeStyles({
  container: {
    maxWidth: '100%',
    minWidth: '0',
    flexGrow: 1,
  },
  loading: {
    height: '100px',
  },
  listbox: {
    maxHeight: '240px',
  },
})
