import { ApiOdsTicketData } from '@copilot-dash/api'
import { PromiseSnapshot } from '@copilot-dash/core'
import { IDashStoreContext } from '../IDashStoreContext'

export function getRawOdsTicket(context: IDashStoreContext, ticketId: string): PromiseSnapshot<ApiOdsTicketData> {
  return context.getOrFetch<ApiOdsTicketData>({
    get: (state) => {
      return state.tickets[ticketId]?.rawOds
    },
    set: (state, snapshot) => {
      const item = (state.tickets[ticketId] ??= {})
      item.rawOds = snapshot
    },
    fetch: () => {
      return context.api.ods.getMetadata(ticketId)
    },
  })
}
