import { ProductIds } from '@copilot-dash/domain'
import { TicketScreenKustoTab } from '../store/IState'

export async function getSupportedKustoTabs(ticketId: string): Promise<TicketScreenKustoTab[]> {
  const ticket = await app.store.actions.getTicket(ticketId)

  return Object.values(TicketScreenKustoTab).filter((tab) => {
    switch (tab) {
      case TicketScreenKustoTab.SssLogs:
        return ticket.productId !== undefined && supportThreeSResult(ticket.productId)
      case TicketScreenKustoTab.StateAndDuration:
      case TicketScreenKustoTab.TraceLog:
      case TicketScreenKustoTab.LlmLog:
        return true
      case TicketScreenKustoTab.EventLog:
        return !ticket.isAvalon
      case TicketScreenKustoTab.BizChat3SLatency:
      case TicketScreenKustoTab.BizChatPerf:
      case TicketScreenKustoTab.ExtensibilityLog:
        return ticket.productId === ProductIds.M365Chat || ticket.productId === ProductIds.M365ChatWebChat
    }
  })
}

function supportThreeSResult(productId: number): boolean {
  const supportedProductIds = [ProductIds.M365Chat, ProductIds.TenantAdminFeedback, ProductIds.TeamsMeetingCopilot]

  return supportedProductIds.includes(productId)
}
