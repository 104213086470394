import { PromiseSnapshots } from '@copilot-dash/core'
import { DefaultProducts } from '@copilot-dash/domain'
import { IDashStoreState } from './IDashStoreState'

export const DefaultDashStoreState: IDashStoreState = {
  basic: {
    products: PromiseSnapshots.done(DefaultProducts),
    hasOdsAccess: true,
    hasOcvAccess: true,
  },
  team: {},
  users: {},
  tickets: {},
  topIssue: {
    topIssueListMap: {},
    topIssueExtendInfoMap: {},
  },
}
