import { CopilotDashTooltip } from '../CopilotDashTooltip/CopilotDashTooltip'
import { KeywordsHighLightText } from './KeywordsHighLightText'
import { memo, FC, useMemo } from 'react'
import { Row } from '../Layout'
import { ReplaceMarkdownSyntax } from './HandleMarkdownSyntax'
import { useStyles } from './ToolTipText.styles'
import { mergeClasses } from '@fluentui/react-components'
import { extractKeywordContent } from './utils'
interface IProps {
  text: string
  keywords?: string
  isTwoLinesTruncate?: boolean
  className?: string
}
export const ToolTipText: FC<IProps> = memo(({ text, keywords, isTwoLinesTruncate = false, className }) => {
  const replacedText = useMemo(() => {
    let replacedText = text
    if (keywords) {
      replacedText = extractKeywordContent(text, keywords)
    }
    return ReplaceMarkdownSyntax(replacedText)
  }, [text, keywords])
  const styles = useStyles()
  return (
    <CopilotDashTooltip
      relationShip="label"
      toolTipContent={
        <KeywordsHighLightText
          text={text}
          keywords={keywords}
          className={mergeClasses(styles.tooltipContent, className)}
        />
      }
      className={styles.tooltip}
      toolTipParentContent={
        <Row>
          <KeywordsHighLightText
            text={replacedText}
            keywords={keywords}
            isTwoLinesTruncate={isTwoLinesTruncate}
            className={className}
          />
        </Row>
      }
    />
  )
})
ToolTipText.displayName = 'ToolTipText'
