import { TelemetryScope } from '@copilot-dash/logger'
import { TicketsFilterPanelLayout } from './layout/TicketsFilterPanelLayout'
import { ITicketFilterPanel } from './TicketFilterPanel.types'
import { forwardRef, useEffect } from 'react'
import { ITicketFilterFormRef } from './children/TicketFilterForm/TicketFilterForm.types'
import { useTicketFilterStore } from './store/store'
import { ProductIds } from '@copilot-dash/domain'

export const TicketsFilterPanel = forwardRef<ITicketFilterFormRef, ITicketFilterPanel>(
  (props: ITicketFilterPanel, ref) => {
    useEffect(() => {
      useTicketFilterStore.getState().setSelectedProductId(props.selectedProductId ?? ProductIds.M365Chat)
      useTicketFilterStore.getState().setSupportedTicketFilterItems(props.supportedTicketFilterItems)
      useTicketFilterStore.getState().setCustomizedFilters(props.customizedFilters)
      useTicketFilterStore.getState().setSelectDateRangeWithTime(props.selectDateRangeWithTime ?? false)
    }, [
      props.selectedProductId,
      props.supportedTicketFilterItems,
      props.customizedFilters,
      props.selectDateRangeWithTime,
    ])

    return (
      <TelemetryScope scope="SearchFiltersView">
        <TicketsFilterPanelLayout {...props} ref={ref} />
      </TelemetryScope>
    )
  },
)
TicketsFilterPanel.displayName = 'TicketsFilterPanel'
