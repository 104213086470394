import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const useStyles = makeStyles({
  textArea: {
    width: '100%',
    ...shorthands.margin(0, '10px'),
    ...shorthands.padding(0, '24px', 0, 0),
  },
  textAreaCustomEditStyle: {
    width: '100%',
    ...shorthands.margin(0, '10px'),
    ...shorthands.padding(0),
  },
  sendButton: {
    position: 'absolute',
    fontSize: '20px',
    cursor: 'pointer',
    right: '8px',
    bottom: '8px',
    zIndex: 1,
    ...shorthands.border('none'),
  },
  baseLoadingStyle: {
    position: 'absolute',
    cursor: 'pointer',
    zIndex: 1,
  },
  sendLoading: {
    right: '8px',
    bottom: '8px',
  },
  sendEditLoading: {
    right: '-10px',
    bottom: '0px',
  },
  textAreaHit: {
    position: 'absolute',
    top: '-7px',
    left: '10px',
    fontSize: tokens.fontSizeBase200,
    padding: '0',
    ...shorthands.margin('-13px', 0, '0px', '0px'),
    ...shorthands.padding(0, '5px', 0, '5px'),
    ...shorthands.borderRadius('5px', '5px', 0, 0),
  },
  textareaContainer: {
    position: 'relative',
    width: '100%',
  },
  cancelEditButton: {
    position: 'absolute',
    cursor: 'pointer',
    right: '-30px',
    bottom: '24px',
    zIndex: 1,
    ...shorthands.border('none'),
  },
  submitEditButton: {
    position: 'absolute',
    cursor: 'pointer',
    right: '-30px',
    bottom: '0px',
    zIndex: 1,
    ...shorthands.border('none'),
  },
  mdEditorContainer: {
    width: '100%',
  },
  uploadButton: { ...shorthands.margin('4px', 0, 0, '10px') },
})
