import { INewTicketData, SearchTextPrefixType } from '@copilot-dash/domain'
import { useUserSetting } from '@copilot-dash/settings'
import { Text } from '@fluentui/react-components'
import * as React from 'react'
import { ErrorView } from '../../../components/Error'
import { Row } from '../../../components/Layout'
import { ExtendedTableColumnDefinition } from '../../../components/Table/ExtendedTableColumn'
import { ResizableColumnsTable, ITableRef } from '../../../components/Table/ResizableColumnsTable'
import { AllSearchTableColumns } from '../../../components/TicketTableColumnConfigs'
import { useTeamViewStore } from '../storeNew'
import { QuickSearch } from './QuickSearch'
import { TableLoading } from './TableLoading'
import { useStyles } from './TicketsTableForAll.styles'
import { ToggleLeftDrawerIcon } from './ToggleDrawerLeftIcon'
import { compact } from 'lodash'

interface ITicketsTableForCategoryProps {
  onRowClick?: (row?: INewTicketData) => void
  clickedTicketKey?: string
  keyPicker?: (item: INewTicketData, index: number) => string
}

export const TicketsTableForAll: React.FC<ITicketsTableForCategoryProps> = React.memo(
  ({
    onRowClick,
    clickedTicketKey,
    keyPicker = (item: INewTicketData, _: number) => {
      return item.ticketId
    },
  }) => {
    const ticketsSnapshot = useTeamViewStore((state) => state.tickets.ticketListByAll)
    const hasMore = useTeamViewStore((state) => state.tickets.hasMoreTicketsForAll)
    const loadMore = useTeamViewStore((state) => state.tickets.loadMoreTicketListForAll)
    const leftPanelExpanded = useTeamViewStore((state) => state.leftDrawerOpen)
    const styles = useStyles()
    const [columnSetting] = useUserSetting('teamViewColumns')
    const tableRef = React.useRef<ITableRef>(null)

    const handleSelectionChange = React.useCallback((selectedItems: INewTicketData[] | undefined) => {
      useTeamViewStore.getState().batchTicketsSubStore.setSuggestion(null)
      useTeamViewStore.getState().batchTicketsSubStore.setTickets(selectedItems ?? [])
      useTeamViewStore.getState().toggleBatchUpdatePanel(!!(selectedItems && selectedItems.length > 0))
      selectedItems &&
        useTeamViewStore
          .getState()
          .batchTicketsSubStore.fetchBatchTicketCustomTags(selectedItems.map((item) => item.ticketId))
    }, [])

    const searchText = useTeamViewStore((state) => state.tickets.filterForm.searchText)
    const email = useTeamViewStore((state) => state.tickets.filterForm.email)
    const tenantIds = useTeamViewStore((state) => state.tickets.filterForm.tenantIds)
    const searchTextPrefix = useTeamViewStore((state) => state.tickets.filterForm.searchTextPrefix)

    const tableColumns = React.useMemo(() => {
      const defaultColumns = AllSearchTableColumns(
        searchTextPrefix || SearchTextPrefixType.All,
        searchText,
        email,
        tenantIds,
      )
      const newSelectedColumns: ExtendedTableColumnDefinition<INewTicketData>[] = compact(
        columnSetting.map((columnId) => defaultColumns.find((column) => column.columnId === columnId)),
      )

      return newSelectedColumns
    }, [columnSetting, searchTextPrefix, email, searchText, tenantIds])
    const table = React.useMemo(() => {
      if (ticketsSnapshot.status === 'waiting') {
        return <TableLoading />
      }
      if (ticketsSnapshot.status === 'error') {
        return <ErrorView error={ticketsSnapshot.error} />
      }
      if (ticketsSnapshot.status === 'done') {
        return ticketsSnapshot.data?.ticketCount > 0 ? (
          <ResizableColumnsTable
            ref={tableRef}
            className={styles.container}
            items={ticketsSnapshot.data?.tickets}
            columns={tableColumns}
            keyPicker={keyPicker}
            rowClick={onRowClick}
            supportLoadMore={hasMore}
            virtualizationConfig={{
              itemSize: 56,
            }}
            selectedRowId={clickedTicketKey}
            loadMoreFunction={(offset) => loadMore(offset)}
            selectable={true}
            selectionMode="multiselect"
            onSelectionChange={handleSelectionChange}
          />
        ) : (
          <ErrorView.Custom className={styles.flexGrow} level="WARNING" message="No tickets found for this query" />
        )
      }
      return null
    }, [
      ticketsSnapshot.status,
      ticketsSnapshot.error,
      ticketsSnapshot.data?.ticketCount,
      ticketsSnapshot.data?.tickets,
      styles.container,
      styles.flexGrow,
      tableColumns,
      keyPicker,
      onRowClick,
      hasMore,
      clickedTicketKey,
      handleSelectionChange,
      loadMore,
    ])

    const headerText = 'All DSATs'

    return (
      <>
        <Row className={styles.header}>
          {leftPanelExpanded ? null : <ToggleLeftDrawerIcon className={styles.expandIcon} type="expand" />}
          <Text size={500} weight="semibold">
            {headerText}
            {ticketsSnapshot.status === 'done' && ` (${ticketsSnapshot.data?.ticketCount})`}
          </Text>
        </Row>
        <QuickSearch />
        {table}
      </>
    )
  },
)

TicketsTableForAll.displayName = 'TicketsTableForAll'
