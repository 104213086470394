import { ITagDefinition } from './ITagDefinition.type'
import { useSearchScreenState } from '../../store'
import { TruncateTagFunction } from './TruncateTagFunction'
import { GetTagValueTextFunction } from './GetTagValueTextFunction'

export const IsTestTagConfig = (): ITagDefinition => {
  const form = useSearchScreenState((state) => state.form)
  const valueTexts = GetTagValueTextFunction('Actionability', 'IsTest', form.isTest || [])

  return {
    key: 'isTest',
    tagName: 'Is test',
    toolTipContentValue: valueTexts.join(', '),
    truncatedTagValue: TruncateTagFunction(valueTexts),
    IsShowTag: false,
    isTagClearable: true,
  }
}
