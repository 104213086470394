import * as React from 'react'
import {
  ITicketFilterFormData,
  ITicketFilterFormRef,
} from '../../../components/TicketsFilterPanel/children/TicketFilterForm/TicketFilterForm.types'
import { TicketsFilterPanel } from '../../../components/TicketsFilterPanel/TicketsFilterPanel'
import { TeamRoute } from '../../../router'
import { useTeamViewStore } from '../storeNew'
import {
  getProductIdByName,
  ProductIds,
  SearchFilterFieldNames,
  DefaultProductChannelMapping,
} from '@copilot-dash/domain'
import { getFilterItems } from '../../../components/TicketsFilterPanel/config/getFilterItems'
import { useUserSetting } from '@copilot-dash/settings'
import { TimeRange } from '@copilot-dash/core'

export const FilterPanel = React.memo(() => {
  const currentArgs = TeamRoute.navigator.getArgs()
  const form = useTeamViewStore((state) => state.tickets.filterForm)

  const defaultRange = React.useMemo((): TimeRange => {
    return {
      type: 'relative',
      value: currentArgs.ticketStatus === 'UnRootCaused' ? 30 : 180,
      unit: 'days',
    }
  }, [currentArgs.ticketStatus])

  const formRef = React.useRef<ITicketFilterFormRef | null>(null)

  const handleReset = React.useCallback(() => {
    useTeamViewStore.getState().tickets.resetFilterForm()
    const currentArgs = TeamRoute.navigator.getArgs()
    const ticketsTabType = useTeamViewStore.getState().tickets.ticketsTabType
    // all fetch tickets actions will be trigged by the url args change, so we just need to navigate to the right url
    // the url monitor is in the src/screens/team/TeamScreen.tsx
    if (ticketsTabType === 'category') {
      TeamRoute.navigator.navigate({
        product: currentArgs.product,
        subMenu: currentArgs.subMenu,
        teamId: currentArgs.teamId,
        teamName: currentArgs.teamName,
        ticketStatus: currentArgs.ticketStatus,
        channel: DefaultProductChannelMapping[currentArgs.product],
      })
    } else if (ticketsTabType === 'rootCause') {
      TeamRoute.navigator.navigate({
        product: currentArgs.product,
        subMenu: currentArgs.subMenu,
        teamName: currentArgs.teamName,
        issueId: currentArgs.issueId,
        vsoAccount: currentArgs.vsoAccount,
        channel: DefaultProductChannelMapping[currentArgs.product],
      })
    }
  }, [])

  const onSubmit = React.useCallback((data?: ITicketFilterFormData) => {
    // all fetch tickets actions will be trigged by the url args change, so we just need to navigate to the right url
    // the url monitor is in the src/screens/team/TeamScreen.tsx
    const currentArgs = TeamRoute.navigator.getArgs()
    const updateForm = {
      ...data,
      tenantIds: currentArgs.tenantIds,
      utteranceAndResponse: currentArgs.utteranceAndResponse,
      verbatimFeedback: currentArgs.utteranceAndResponse,
      searchText: currentArgs.searchText,
      email: currentArgs.email,
      userId: currentArgs.userId,
      product: currentArgs.product,
      teamName: currentArgs.teamName,
      issueId: currentArgs.issueId,
      vsoAccount: currentArgs.vsoAccount,
      ticketStatus: currentArgs.ticketStatus,
      subMenu: currentArgs.subMenu,
      topIssueBatchId: currentArgs.topIssueBatchId,
    }
    TeamRoute.navigator.navigate({
      ...updateForm,
    })
    Logger.telemetry.trackEvent('TicketFilter/Changed', { filter: 'FilterPanel', value: null })
  }, [])

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.setValues({
        ...form,
        range: form.range ?? defaultRange,
      })
    }
  }, [defaultRange, form])

  const isTicketsFilterPanelOpen = useTeamViewStore((state) => state.computed.filterPanelOpen)
  const [teamViewCustomizedFilters] = useUserSetting('teamViewCustomizedFilterItems')
  //const selectDateRangeWithTime = !!(currentArgs.teamName && UN_TRIAGED_TEAM_NAMES.includes(currentArgs.teamName))
  const supportedTicketFilterItems: SearchFilterFieldNames[] = React.useMemo(() => {
    return getFilterItems(
      getProductIdByName(currentArgs.product) ?? ProductIds.M365Chat,
      'team',
      currentArgs.teamName,
      currentArgs.subMenu,
    )
  }, [currentArgs.product, currentArgs.teamName, currentArgs.subMenu])

  return (
    <TicketsFilterPanel
      ref={formRef}
      isPanelOpen={isTicketsFilterPanelOpen}
      onDismiss={() => {
        useTeamViewStore.getState().toggleFilterPanel(false)
      }}
      onClickReset={handleReset}
      isAIF={false}
      selectedProductId={getProductIdByName(currentArgs.product)}
      ticketFilterForm={form}
      onSubmitFilterPanel={onSubmit}
      defaultRange={defaultRange}
      supportedTicketFilterItems={supportedTicketFilterItems}
      customizedFilters={teamViewCustomizedFilters}
      selectDateRangeWithTime={true}
    />
  )
})

FilterPanel.displayName = 'FilterPanel'
