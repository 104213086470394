import { CallFlowNodeType, IState } from './IState'

interface IParams {
  readonly ticketId: string
  readonly selectedTurnId?: string
  readonly selectedScopeId?: string
}

export function createState({ ticketId, selectedTurnId, selectedScopeId }: IParams): IState {
  return {
    ticketId,
    selectedTurnId,
    selectedScopeId,
    selectedTypes: [CallFlowNodeType.LLM, CallFlowNodeType.SSS, CallFlowNodeType.Storage],
  }
}
