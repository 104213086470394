import { AsyncLoader } from '@copilot-dash/core'
import { ITopIssueList, ITopIssuesBatchInfoList } from '@copilot-dash/domain'
import { StateCreator } from 'zustand'
import { ITeamViewStore, ITopIssueStore } from './types'

export const createTopIssueStore: StateCreator<ITeamViewStore, [['zustand/immer', never]], [], ITopIssueStore> = (
  set,
  get,
) => {
  const topIssueBatchInfoListAsyncLoader = new AsyncLoader<ITopIssuesBatchInfoList>()
  const topIssueListAsyncLoader = new AsyncLoader<ITopIssueList>()
  return {
    issueClusteringBatchList: { status: 'none' },
    topIssueList: { status: 'none' },

    fetchIssueClusteringBatchList: async () => {
      const promise = application.store.actions.getTeamIssueClusteringBatchInfoList()
      topIssueBatchInfoListAsyncLoader.submit({
        promise,
        onChanged: (snapshot) => {
          set((state) => {
            state.topIssues.issueClusteringBatchList = snapshot
          })
        },
      })
      return promise
    },

    fetchTopIssueList(batchId, teamId) {
      const promise = application.store.actions.getTeamIssueClusteringInfoList(batchId, teamId)
      topIssueListAsyncLoader.submit({
        promise,
        onChanged: (snapshot) => {
          set((state) => {
            state.topIssues.topIssueList = snapshot
            state.topIssues.topIssueList.data?.sort((a, b) => (b.coverage ?? 0) - (a.coverage ?? 0))
          })
        },
      })
      return promise
    },

    clearTopIssueList() {
      set((state) => {
        state.topIssues.topIssueList = { status: 'none' }
      })
    },
  }
}
