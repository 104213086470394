import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

export const FILTER_PANEL_WIDTH = 360

export const useStyles = makeStyles({
  loading: {
    width: '100%',
    textAlign: 'center',
  },
  drawer: {
    width: `${FILTER_PANEL_WIDTH}px`,
    height: '100%',
  },
  card: {
    width: `${FILTER_PANEL_WIDTH}px`,
    maxWidth: '100%',
    height: 'fit-content',
  },

  section: {
    width: 'fit-content',
  },

  title: {
    ...shorthands.margin(0, 0, 0, '12px'),
    alignItems: 'center',
    fontSize: tokens.fontSizeBase400,
    fontWeight: tokens.fontWeightSemibold,
  },

  horizontalCardImage: {
    width: '64px',
    height: '64px',
  },

  headerImage: {
    ...shorthands.borderRadius('4px'),
    paddingTop: '16px',
    maxWidth: '44px',
    maxHeight: '44px',
  },

  caption: {
    color: tokens.colorNeutralForeground3,
  },

  text: {
    ...shorthands.margin(0),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '16px',
  },
})
