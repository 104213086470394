import { useStyles } from './CommentTextArea.styles'
import { Column, Row } from '../../Layout'
import { Button, mergeClasses, Spinner } from '@fluentui/react-components'
import { ArrowCircleUpFilled, CheckmarkRegular, DismissRegular } from '@fluentui/react-icons'
import { LexicalRichTextEditor } from '../../Lexical/LexicalRichTextEditor'

interface IProps {
  onSendComment: () => void
  onCommentValueChange: (value: string | undefined) => void
  commentValue: string | undefined
  sentDone: boolean
  isEdit?: boolean
  setOpenEdit?: () => void
}

export function CommentTextArea({
  onSendComment,
  onCommentValueChange,
  commentValue,
  sentDone,
  isEdit = false,
  setOpenEdit,
}: IProps) {
  const styles = useStyles()

  return (
    <Row className={styles.textareaContainer} style={{ height: isEdit ? 'auto' : '242px' }}>
      <LexicalRichTextEditor
        sentDone={sentDone}
        isEdit={isEdit}
        commentValue={commentValue}
        onCommentValueChange={onCommentValueChange}
      />
      {!isEdit ? (
        <Row>
          {sentDone ? (
            <Button
              icon={<ArrowCircleUpFilled />}
              size="small"
              className={styles.sendButton}
              appearance="transparent"
              onClick={() => onSendComment()}
              disabled={!sentDone}
            />
          ) : (
            <Spinner size="tiny" className={mergeClasses(styles.baseLoadingStyle, styles.sendLoading)} />
          )}
        </Row>
      ) : (
        <Column>
          {sentDone ? (
            <Column>
              <Button
                icon={<DismissRegular />}
                size="small"
                className={styles.cancelEditButton}
                appearance="transparent"
                onClick={() => setOpenEdit && setOpenEdit()}
                disabled={!sentDone}
              ></Button>
              <Button
                icon={<CheckmarkRegular />}
                size="small"
                appearance="transparent"
                className={styles.submitEditButton}
                onClick={() => onSendComment()}
                disabled={!sentDone}
              ></Button>
            </Column>
          ) : (
            <Spinner size="tiny" className={mergeClasses(styles.baseLoadingStyle, styles.sendEditLoading)} />
          )}
        </Column>
      )}
    </Row>
  )
}
